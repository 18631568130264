export const users = [
  "admin", "thinh.phamngoc", "hien.hoangvan", "linh.hoai", "huynh.nguyenchi", "tam.quachthanh", "nhat.nguyen", "giang.phamduc.ctv"
];
// const urlForDFTDevServer = `https://dev.smartf.dft.vn/mobifone.portal3/api/`;
//  const urlForMobifoneServer =  window.origin + `/mobifone.portal3/api/`;
//  const urlForAuthMobifoneServer =  window.origin + `/mobifone.portal3/api/token`;

// export const urlAuth = process.env.REACT_APP_API_AUTH;
// export const urlService = process.env.REACT_APP_API;
export const urlAuth = "http://10.3.40.10:443/connect/token"
export const urlService = "http://10.3.40.10:443/api" 
export const MenuList = [
  {
    name: 'Viễn Thông',
    url: 'vienthong',
    children: [
      {
        name: 'Báo Cáo Ngày',
        url: '/vienthong/baocaongay'
      },
      {
        name: 'Tổng quan',
        url: '/vienthong/tongquan'
      },

      {
        name: 'Chi Tiết',
        url: '/vienthong/chitiet'
      },
      {
        name: 'Chuyên sâu',
        url: '/vienthong/chuyensau'
      },
      {
        name: 'Bản đồ số',
        url: '/vienthong/bandoso'
      },
      {
        name: 'Topology',
        url: '/vienthong/topology'
      },
       {
        name: 'Doanh Thu Chi Phí',
        url: '/vienthong/doanhthu'
      }
      // {
      //   name: 'Báo Cáo Tải',
      //   url: '/vienthong/baocaotai'
      // },
      // {
      //   name: 'Báo Cáo IPLC',
      //   url: '/vienthong/baocaoiplc'
      // },
    ]
  },
  {
    name: 'QLCL',
    url: 'qlcl',
    children: [
      {
        name: 'Quy chuẩn kỹ thuật quốc gia',
        url: '/qlcl/quychuankythuatquocgia',
       
      },
      {
        name: 'QLCL Mobifone',
        url: '/qlcl/qlclmobifone',
        children: [
          {
            name: 'Quản lý chất lượng Viễn thông/Hạ tầng số',
            url: '/qlcl/qlclmobifone/qlcluongvienthonghatangso',
            children: [
              // {
              //   name: 'Chỉ tiêu chất lượng RAN',
              //   url: '/cntt/noc/could'
              // },
              // {
              //   name: 'chỉ tiêu chất lượng CORE',
              //   url: '/cntt/noc/could'
              // },
              // {
              //   name: 'chỉ tiêu chất lượng Truyền dẫn',
              //   url: '/cntt/noc/could'
              // },
              // {
              //   name: 'chỉ tiêu chất lượng KQI',
              //   url: '/cntt/noc/could'
              // },
              // {
              //   name: 'chỉ tiêu chất lượng thoại quốc tế',
              //   url: '/cntt/noc/could'
              // },
            ]
          }
        ]
      }
      
    ]
  },
  {
    name: 'CNTT',
    url: 'cntt',
    children: [
      {
        name: 'Trung tâm NOC',
        url: '/cntt/noc/chitiet/kpithietbimang',
        children: [
          {
            name: 'Báo cáo Cloud',
            url: '/cntt/noc/could'
          },
          {
            name: 'Chi Tiết',
            url: '/cntt/noc/chitiet/kpithietbimang',
            children: [
              {
                name: 'KPI Thiết bị mạng',
                url: '/cntt/noc/chitiet/kpithietbimang'
              },
              {
                name: 'KPI Node',
                url: '/cntt/noc/chitiet/kpinode'
              },
              {
                name: 'Hệ thống Cloud',
                url: '/cntt/noc/chitiet/hethongcloud'
              },
              {
                name: 'Máy ảo đã cấp',
                url: '/cntt/noc/chitiet/hethongprcloud'
              },
              {
                name: 'Tổng hợp cấp phát tài nguyên theo đơn vị',
                url: '/cntt/noc/chitiet/hethongplcloud'
              }
            ]
          }
        ]
      },
      {
        name: 'Trung tâm TCTK',
        url: '/cntt/tctk/baocaongay',
        children: [
          {
            name: 'Báo Cáo Ngày',
            url: '/cntt/tctk/baocaongay'
          },
          {
            name: 'Chi Tiết',
            url: '/cntt/tctk/chitiet',
            children: [
              {
                name: 'KPI Lớp Ứng Dụng',
                url: '/cntt/tctk/chitiet/kpilopungdung'
              },
              {
                name: 'KPI Hoạt Động Hệ Thống',
                url: '/cntt/tctk/chitiet/kpihoatdonghethong'
              },
              {
                name: 'KPI Thiết bị mạng',
                url: '/cntt/tctk/chitiet/kpithietbimang'
              },
              {
                name: 'KPI Node',
                url: '/cntt/tctk/chitiet/kpinode'
              },
              {
                name: 'KPI Nghiệp Vụ',
                url: '/cntt/tctk/chitiet/KPINghiepVu'
              }
            ]
          }
        ]
      },
      {
        name: 'Trung tâm MDS',
        url: '/cntt/mds/baocaongay',
        children: [
          {
            name: 'Báo Cáo Ngày',
            url: '/cntt/mds/baocaongay'
          },
          {
            name: 'Chi Tiết',
            url: '/cntt/mds/chitiet',
            children: [
              {
                name: 'KPI Lớp Ứng Dụng',
                url: '/cntt/mds/chitiet/kpilopungdung'
              },
              {
                name: 'KPI Hoạt Động Hệ Thống',
                url: '/cntt/mds/chitiet/kpihoatdonghethong'
              },
              {
                name: 'KPI Thiết bị mạng',
                url: '/cntt/mds/chitiet/kpithietbimang'
              },
              {
                name: 'KPI Node',
                url: '/cntt/mds/chitiet/kpinode'
              }
            ]
          }
        ]
      },
      {
        name: 'Trung tâm CNTT',
        url: '/cntt/ttcntt/baocaongay',
        children: [
          {
            name: 'Báo Cáo Ngày',
            url: '/cntt/ttcntt/baocaongay'
          },
          {
            name: 'Chi Tiết',
            url: '/cntt/ttcntt/chitiet',
            children: [
              {
                name: 'KPI Lớp Ứng Dụng',
                url: '/cntt/ttcntt/chitiet/kpilopungdung'
              },
              {
                name: 'KPI Hoạt Động Hệ Thống',
                url: '/cntt/ttcntt/chitiet/kpihoatdonghethong'
              },
              {
                name: 'KPI Thiết bị mạng',
                url: '/cntt/ttcntt/chitiet/kpithietbimang'
              },
              {
                name: 'KPI Node',
                url: '/cntt/ttcntt/chitiet/kpinode'
              }
            ]
          }
        ]
      }
    ]
  }
];