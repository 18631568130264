import React from 'react';
import HandlerDataMenu from './HandlerDataMenu';
import AsideMenu from './AsideMenu';
import LoadingMenu from './LoadingMenu';
import { _GET } from 'app/services/apiCrud';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
// Components
import Error from 'app/Components/error/Error';
import { menudata } from '../MenuData';

const Aside = ({ isMobile = false, setMenuOpen }) => {
  // const { isLoading, error, data } = useQuery(['fetchMenu'], () => _GET('navbar'));

  // if (isLoading) return <LoadingMenu />;
  // if (error || isEmpty(data.data)) return <Error />;

  // let menus = HandlerDataMenu(data.data,{BaoCaoGiamTru:true});
  let menus = HandlerDataMenu(menudata);
  if (isMobile) {
    return (
      <React.Fragment>
        {menus.map((item, index) => (
          <AsideMenu
            key={'aside' + index}
            item={item}
            isMobile={true}
            setMenuOpen={setMenuOpen}
          />
        ))}
      </React.Fragment>
    );
  }
  return (
    <div className="sidenav aside-dft">
      {menus.map((item, index) => (
        <AsideMenu key={'aside' + index} item={item} />
      ))}
    </div>
  );
};

export default Aside;
