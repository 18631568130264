export const menudata = [
    {
        "Id": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "ParentId": "9cd91155-3110-450f-b074-fcdf72d4ab42",
        "Name": "noc-sopirs",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b6989ab-ecb1-4f05-9550-315824061715",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "1.MTCL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    
    {
        "Id": "26e6cc3e-da44-4780-a50d-7e579d141a8c",
        "ParentId": "eafde205-f7c3-4f6a-941f-a642acb1fbf0",
        "Name": "1.2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/4.Badcell-Alarm/1.2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f4add99-3db4-4391-9f77-53831f7d6d44",
        "ParentId": "26e6cc3e-da44-4780-a50d-7e579d141a8c",
        "Name": "1.Ngày",
        "Description": "Badcell.Alarm",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/4.Badcell-Alarm/1.2G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a31bf23-fea0-4ca6-a78a-5086f1bdd010",
        "ParentId": "eafde205-f7c3-4f6a-941f-a642acb1fbf0",
        "Name": "10.3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/4.Badcell-Alarm/10.3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f3dea64-7534-40ec-a014-8efa3d717878",
        "ParentId": "5a31bf23-fea0-4ca6-a78a-5086f1bdd010",
        "Name": "1.Ngày",
        "Description": "Badcell.Alarm",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/4.Badcell-Alarm/10.3G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1dc520bc-431d-4de1-8075-d9cb2a7a05e2",
        "ParentId": "8b6989ab-ecb1-4f05-9550-315824061715",
        "Name": "5.2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "ParentId": "1dc520bc-431d-4de1-8075-d9cb2a7a05e2",
        "Name": "1.2023-Ban CNQLM",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "099259e0-3153-45a1-b1c3-2b3af24bfb86",
        "ParentId": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "49d870e1-c45d-40cb-9a5c-cc961f0d9332",
        "ParentId": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5d1989d7-5f16-438c-b73e-788d68655184",
        "ParentId": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e5c1618-68f7-43f6-a748-80a181f97e0e",
        "ParentId": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32a3a7e8-0259-4b60-ae81-8165d200ad8b",
        "ParentId": "9079943a-af99-4ed9-809b-e7059d26f3d2",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/1.2023-Ban CNQLM/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f9c1db4-bebc-4c98-a10d-bf79da97118e",
        "ParentId": "1dc520bc-431d-4de1-8075-d9cb2a7a05e2",
        "Name": "2.2023-NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "ParentId": "1f9c1db4-bebc-4c98-a10d-bf79da97118e",
        "Name": "1.MTCL-2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d15c3c7-0894-4ab4-b982-f1796691495e",
        "ParentId": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "088bce2e-eb65-4197-8e96-68d4b211d284",
        "ParentId": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "Name": "2.Tuần",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "458a7b3e-a6cd-4860-b8f8-0cb6c887e742",
        "ParentId": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "Name": "3.Tháng",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bcd73482-91a3-4194-84a6-7b56fb7f7e8c",
        "ParentId": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "Name": "4.Quý",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa976e89-18c8-4437-84f6-a476c0b0f067",
        "ParentId": "8c05277a-292c-4d5f-91f3-19b9d8dc1bb2",
        "Name": "5.Năm",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/1.MTCL-2023/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "ParentId": "1f9c1db4-bebc-4c98-a10d-bf79da97118e",
        "Name": "2.CSGS-2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "941e5e4c-2fb4-4659-b6e5-54d2ce0c08f0",
        "ParentId": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86056f63-331d-4255-89fe-e33962f790dd",
        "ParentId": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e633736-5336-4995-bbc5-225730090a75",
        "ParentId": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa667fbf-9307-412d-b81c-8a07f27d2f31",
        "ParentId": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "684a9ea8-0206-4e66-ae23-b2fe53342bc9",
        "ParentId": "95697e32-88fe-4bce-8d61-d563953b5a41",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/2.CSGS-2023/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf6f4bb9-1e3e-4ec4-9205-58a1ce77ace3",
        "ParentId": "1f9c1db4-bebc-4c98-a10d-bf79da97118e",
        "Name": "3.BSC-KPI-2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/3.BSC-KPI-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc887365-51dd-4b74-98a5-79444748f72f",
        "ParentId": "cf6f4bb9-1e3e-4ec4-9205-58a1ce77ace3",
        "Name": "0.Trung tâm",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/3.BSC-KPI-2023/0.Trung tâm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8e89d9d-e204-45b0-8db1-6dec901f6c1e",
        "ParentId": "cf6f4bb9-1e3e-4ec4-9205-58a1ce77ace3",
        "Name": "1.Phòng Đài",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2.2023-NOC/3.BSC-KPI-2023/1.Phòng Đài",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd00ef90-e524-4b4e-b8aa-b478b6fda612",
        "ParentId": "1dc520bc-431d-4de1-8075-d9cb2a7a05e2",
        "Name": "2023-TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "ParentId": "bd00ef90-e524-4b4e-b8aa-b478b6fda612",
        "Name": "1.MTCL-2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ca85c13-43b6-4ff5-92b4-c3bd5b1817bd",
        "ParentId": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "060e0473-4c2f-48ed-9fd2-f2d06a4f8ba7",
        "ParentId": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ace6d8a-e157-4a59-9dcc-19fde230ab43",
        "ParentId": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8f29688-7279-4826-8e26-7b24fec6cbb1",
        "ParentId": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9a77cb4-5234-463c-9768-aaa65b01fde2",
        "ParentId": "f2a4924b-ec9b-4a9d-ac1d-c608e33f8924",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "06edf0a8-d1b4-4240-bf9b-b74a0c5673ae",
        "ParentId": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6aa38f57-9e75-4a49-90e0-cbbb7e246ab0",
        "ParentId": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0c439297-cbd8-404f-946b-8c0159125b35",
        "ParentId": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7feb9571-ba06-40cb-8828-701443e0a130",
        "ParentId": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1578791-0085-4fb8-8bbb-d8c015cb75e3",
        "ParentId": "1e1b3f14-e11f-488b-940e-4539407813f9",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad3a19f6-dc11-4f1e-a32f-45c59a2dcf51",
        "ParentId": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7744ab1-dea8-40dd-aabf-dfb213574188",
        "ParentId": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ee35e93-3052-44ce-9468-df6eaf8862ef",
        "ParentId": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b4a3132-c3f8-4719-91ba-16951e77d9a4",
        "ParentId": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aac9b8c2-2ecc-4fbf-b08e-3bd45d0667b0",
        "ParentId": "ae315792-6261-42f7-8b04-9b8c7f7deeb8",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a057c047-7505-47c8-bddd-e88b0588f423",
        "ParentId": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8bd71fe-8278-40f4-9473-b9410ca6ac80",
        "ParentId": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "63c482bd-b18b-4b0c-a2ae-2678c2af3d58",
        "ParentId": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d7cf03c-aa86-4b85-8447-e80889fb41fb",
        "ParentId": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae90acf0-be58-4179-8b83-fa420c5a2529",
        "ParentId": "d54c557b-4ff4-4cf1-82ed-045db1ac1ef3",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/4.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "5.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "629c9587-fd0c-4855-81f5-b8186d41b279",
        "ParentId": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd92d56a-98f4-48a2-aa01-23b8e8ab1fb5",
        "ParentId": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef4ce3a2-0d3d-439e-84af-27813b2484c3",
        "ParentId": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "394861ca-c77c-47a0-998c-53ca761f2e3c",
        "ParentId": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab456786-9258-4335-93e6-be7bc86b655c",
        "ParentId": "3bf47b65-6e72-4301-ba54-5e669e864d05",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/5.DISTRICT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db36389d-81ca-4401-88fe-d46534e1aa54",
        "ParentId": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a0db2d7-576f-48a4-b70e-90f571704d33",
        "ParentId": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "588b5df9-aac7-4c6f-82a3-ea829c991aba",
        "ParentId": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f7c3e08-bdfc-4379-b4a9-73f78948c9b2",
        "ParentId": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "070b7cf6-0604-41a9-adfc-56883091762e",
        "ParentId": "c30ed084-d9f4-4fed-9c17-0e6642e062ce",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "7.BRANCH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e41dc72-392e-4cce-874d-3e089c6b9881",
        "ParentId": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59588d6b-6187-492d-a07c-ca8ba65a9c0d",
        "ParentId": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5687f27-3aad-4d11-aa5f-481d4903c7ee",
        "ParentId": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03715bed-ec86-4830-b66a-57158130ff05",
        "ParentId": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66245270-2d2d-469a-adbf-e44c20e97ced",
        "ParentId": "2c1ccfa7-ab00-49b0-a80e-52d61b141415",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/7.BRANCH/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9687e15d-45d6-48bd-966a-4b06dbeed4d0",
        "ParentId": "9609d189-b170-4050-b301-7806d3c5b2c2",
        "Name": "8.Badcell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/8.Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe0f4b60-dd81-489d-b080-ff867b55b822",
        "ParentId": "9687e15d-45d6-48bd-966a-4b06dbeed4d0",
        "Name": "3.Badcell4G-2023",
        "Description": "Badcell4G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/1.MTCL-2023/8.Badcell/3.Badcell4G-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "ParentId": "bd00ef90-e524-4b4e-b8aa-b478b6fda612",
        "Name": "2.CSGS-2023",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d2fb20b6-8cd3-498a-8165-5abecce43879",
        "ParentId": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3dfe8841-3263-4547-9443-fd36b34e530b",
        "ParentId": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bfa5e1f7-9222-4d26-8705-c248d3bd806e",
        "ParentId": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c5ece80-1131-42e4-bdb4-f1f885e9f9fe",
        "ParentId": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b96c4f3-4a9a-4c8d-8560-dc3fbc8021b0",
        "ParentId": "73fefc43-7fae-4b32-abfb-e1b2bde7b18e",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a84dac47-1d1a-41c3-9e5a-9a3eeddf1b0a",
        "ParentId": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca17de12-6055-4f0e-8f6a-2f2330da15e9",
        "ParentId": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a88c01a-449a-4442-bcb2-b57e2dda4f78",
        "ParentId": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c043caa9-a0a6-407a-a155-049019b11d31",
        "ParentId": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17009750-cf0c-4aa7-955d-9576c7b36d39",
        "ParentId": "e2d780ce-7ce2-444d-9dd4-cb37d24da030",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f866830-f369-44e8-adff-d635d57ac1b1",
        "ParentId": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e78979ca-f06d-4a3d-a7e3-0a56886a8f59",
        "ParentId": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e56aff43-c64f-407f-9191-5177e1540048",
        "ParentId": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "226c2d41-c22b-4db7-a11b-4e393efa8fc9",
        "ParentId": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6078eb7a-a502-4e3b-a469-25029ad79d97",
        "ParentId": "8837572b-2cb5-4aa6-8ce8-9a660db3dbef",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "4.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "400a3e4b-ea7f-49f7-91a4-89ea54f3b1e3",
        "ParentId": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d727c4ac-8fc3-4aba-8ec3-dcf165a7f1d4",
        "ParentId": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b41c8638-0810-4f37-b704-87a459d89994",
        "ParentId": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce1007cb-5d49-42de-8479-25970852d9ed",
        "ParentId": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7644302c-07af-4ee0-a38d-6310b5f308e1",
        "ParentId": "9d51abc8-245b-470c-bf61-ad6a299cfa05",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/4.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "179d2491-4bdb-4a10-9dc5-f2652b36f315",
        "ParentId": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cfa2270c-c0bc-4328-ab5a-3be320c59217",
        "ParentId": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9f7e7ce9-bd22-4ed5-bb59-52429dba35e3",
        "ParentId": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5ad6791-c0ce-4240-9b70-5c4a168d1645",
        "ParentId": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b20fb5c3-3a9f-4280-8b05-dd2c1aab1e76",
        "ParentId": "348b134e-0894-467c-b2b7-fc290b4ef0fc",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8498312-6d5c-477a-87fe-aa0e347b5be4",
        "ParentId": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "406a1452-53bd-486f-84dd-148e04a276eb",
        "ParentId": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72ef9408-073b-4c25-a0ad-2f43fba461d1",
        "ParentId": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9544d27-49d3-400d-96f2-f0b8ca9fa714",
        "ParentId": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1ccf213-865c-46e3-a176-f7d3a7ca4059",
        "ParentId": "ed9edba6-a46c-4bf5-ba4c-e8b8e1ec8fbf",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "ParentId": "a8b75b3b-6626-4bc8-a65a-141d2ff85d38",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46f00459-3cfd-40cb-b276-eee7cc021a8d",
        "ParentId": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31ea3593-35e0-4422-a149-864a6e217226",
        "ParentId": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "Name": "2.Tuần",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22feda8e-0ff4-461c-8af5-f0e7751f0ab4",
        "ParentId": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "Name": "3.Tháng",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f391f61-aade-4064-8e53-86f11139af95",
        "ParentId": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "Name": "4.Quý",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8462c7cf-df33-491d-aedb-d5094f01553a",
        "ParentId": "3258366f-21bb-4659-8abd-9bee5cc516d6",
        "Name": "5.Năm",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.2023/2023-TTML/2.CSGS-2023/7.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "ParentId": "16e9d5a1-9401-469c-bd28-bf1e677f6696",
        "Name": "1.Theo CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a2363ac-302a-416d-b5e5-725411ccea5b",
        "ParentId": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "859477d3-a4e6-4855-aeb0-4276083881f6",
        "ParentId": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0af20a4-836c-43ae-b6d8-e7ea37395f18",
        "ParentId": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7825731-b61e-432d-9a9b-03930c260568",
        "ParentId": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c4e2de93-0d1b-46b3-a228-cf75e0164fdd",
        "ParentId": "c308eb7b-21dc-4e85-965c-9c5ad9161fa5",
        "Name": "6.Năm",
        "Description": "Yr-MNP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/1.Theo CTKD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "ParentId": "16e9d5a1-9401-469c-bd28-bf1e677f6696",
        "Name": "2.Theo chi nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0e390c9-4b10-4057-af1b-0c216c0ec876",
        "ParentId": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5f453f5-b730-4af9-8a95-b6a181169978",
        "ParentId": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1179b2e9-6878-4358-b82f-cb1c5704f830",
        "ParentId": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70c8c877-a4c9-43d1-850a-0256c2c5c9ab",
        "ParentId": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4b0f7629-4a56-4a67-b8fd-8609145d4b18",
        "ParentId": "47b4ea23-de14-4609-8e03-d04722d7a119",
        "Name": "6.Năm",
        "Description": "Yr-MNP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/2.Theo chi nhánh/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "ParentId": "16e9d5a1-9401-469c-bd28-bf1e677f6696",
        "Name": "3.Theo quận huyện",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7277c49f-77b2-4ce3-8ef3-112b7f92aa6b",
        "ParentId": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "baf3e8f3-4910-434c-8128-0e076cd7dbaa",
        "ParentId": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "80a669cc-dba2-4601-b292-f5ea10c49092",
        "ParentId": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0149861-e45e-4cad-a1ff-bc77228350c8",
        "ParentId": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f25f9cc-f6f9-4a7d-9024-e47082acc8ba",
        "ParentId": "d7e069ff-0671-4bda-84f0-d6e12e502c91",
        "Name": "6.Năm",
        "Description": "Yr-MNP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/5.MNP/3.Theo quận huyện/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f09602a4-cb77-428e-bd96-b98613ba5327",
        "ParentId": "8b6989ab-ecb1-4f05-9550-315824061715",
        "Name": "6.2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09247748-95e7-41b4-b6ab-77fc99cb418e",
        "ParentId": "f09602a4-cb77-428e-bd96-b98613ba5327",
        "Name": "1.2022 - NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "ParentId": "09247748-95e7-41b4-b6ab-77fc99cb418e",
        "Name": "1.MTCL 2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0326b98-c766-407e-9a50-df11af96d6fd",
        "ParentId": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "adacc7d4-b1dc-4933-a8df-270b684cf6d9",
        "ParentId": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "Name": "2.Tuần",
        "Description": "WK_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4089226b-3a42-4b52-9fdb-e0084792cfe4",
        "ParentId": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "Name": "3.Tháng",
        "Description": "MN_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfe46f38-3c19-4006-8d8b-a91dd18ab67a",
        "ParentId": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "Name": "4.Quý",
        "Description": "QR_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0823899-b67f-4f00-ad71-873f38ba600d",
        "ParentId": "f249f6bc-5286-4c3d-b703-520507e33f61",
        "Name": "5.Năm",
        "Description": "YR_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/1.MTCL 2022/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "ParentId": "09247748-95e7-41b4-b6ab-77fc99cb418e",
        "Name": "2.Điểm các đơn vị NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50a38b92-8da9-479a-b969-712f7beca3f9",
        "ParentId": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "787cd8d8-1612-4669-8835-a9d7d203e68d",
        "ParentId": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b77a2509-ed83-4030-a48d-96a50a92ebe1",
        "ParentId": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81b92552-5eab-468c-9a61-44cbc33f4e3a",
        "ParentId": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14a93821-472d-426f-ad2f-8aaef74e73e7",
        "ParentId": "3112f8ea-9e1e-4ac3-b3d2-9e45507ae5ef",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/1.2022 - NOC/2.Điểm các đơn vị NOC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51bffac4-03dd-4d99-adb8-b1ced59a05b9",
        "ParentId": "f09602a4-cb77-428e-bd96-b98613ba5327",
        "Name": "2.2022 - RAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "ParentId": "51bffac4-03dd-4d99-adb8-b1ced59a05b9",
        "Name": "1.MTCL 2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46d91113-7d3f-4353-8b90-d614047c898d",
        "ParentId": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cadd279d-a546-4ce4-a7ff-69cc9853a411",
        "ParentId": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e3474a3-e895-449e-acd2-a2111dfafc73",
        "ParentId": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "750816d9-a792-46ce-a56d-903a3bd825f8",
        "ParentId": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ebd818f4-5c4d-49b3-8301-7f46c1623915",
        "ParentId": "029de6d5-2321-4e19-a2a2-6465660660d8",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4262dbbf-2c5b-4f95-863c-5fd6a16de1fd",
        "ParentId": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc4ee85f-d830-4ec8-a563-0517ee0cfada",
        "ParentId": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ee82a63-7a20-499d-b6fd-172cc564a7af",
        "ParentId": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eba2a869-d88a-43df-b5b6-3bdb744c92bf",
        "ParentId": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37cefe6e-91a4-4f80-9bd1-d475e20f55d7",
        "ParentId": "beedf3ce-1998-4c20-8ef0-f679e6cd10ac",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da687dc0-1c62-49b9-96d7-c94d2c4c2e4e",
        "ParentId": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7a43bad-46dd-41a1-b671-f819f41d445a",
        "ParentId": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cce7e267-0b87-4e98-b579-7ee4305772e2",
        "ParentId": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0cda9491-0098-44ba-b48b-4658cefe7572",
        "ParentId": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da095c95-92cf-4bc7-beb0-eedb069aa82d",
        "ParentId": "407d01d4-a286-422f-b5ab-c21788f7c3c5",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "4.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90247b12-9b5b-430e-889b-258d42728356",
        "ParentId": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ed85053-8462-4677-ba31-713cacac7f19",
        "ParentId": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22100366-5298-47d2-9a26-978ba6927c80",
        "ParentId": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb382352-16b8-430f-ad58-ba2e391688f8",
        "ParentId": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d11d35e-ea86-4be0-bf22-0da9e06ea8a2",
        "ParentId": "5077aa94-8e4c-4f0a-b67b-ade7c056213e",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/4.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34c81aaa-4662-4fee-b0fb-8bcf03a936c1",
        "ParentId": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d686072f-1258-49b3-a760-2a910e8111c9",
        "ParentId": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ad111e6-a670-4f06-a879-128bee9a690e",
        "ParentId": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf050d32-49fb-43e8-b08d-2b986491a292",
        "ParentId": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "350becad-7082-421c-86ec-395b49957862",
        "ParentId": "0071c00f-9ef0-4734-af7d-c4aa4b65877d",
        "Name": "5. Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/5.District/5. Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "083fe14a-4c51-4339-aaa7-926b7315939e",
        "ParentId": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca5639c4-0981-47a4-9f8e-724fc9c97521",
        "ParentId": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa412989-1c2c-4b68-b376-81c0189a355f",
        "ParentId": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8daf62b-adaf-4195-8293-8c2c5b1a1d5b",
        "ParentId": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a44f7414-adba-4087-9917-05f81bfa0a05",
        "ParentId": "81d79144-a703-4cb6-a06f-a0959e8793fb",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "7.BRANCH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa858430-6c24-43af-bc69-826ba46ea095",
        "ParentId": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5824862b-a81f-434d-b52c-910de3131d37",
        "ParentId": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e46ea56c-7e1b-4fd7-8a5d-217540ec598f",
        "ParentId": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99d9bace-d44e-4d65-9df7-bf442c29aa4c",
        "ParentId": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb13b6aa-022f-4b43-bf5c-17c31491b5e2",
        "ParentId": "bcdcabf8-741e-40c6-aa7f-698ebb71f7ba",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/7.BRANCH/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "992f0675-6288-4fc9-be83-595243856c38",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "8.BADCELL_2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7fb9f93-3248-4ec1-9998-7cd5bac5d47d",
        "ParentId": "992f0675-6288-4fc9-be83-595243856c38",
        "Name": "1.Badcell2G-2022",
        "Description": "Badcell2G_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/1.Badcell2G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "932d25bb-573a-4f6f-95bf-df573d574b69",
        "ParentId": "992f0675-6288-4fc9-be83-595243856c38",
        "Name": "2.Badcell3G-2022",
        "Description": "Badcel3G_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/2.Badcell3G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3113271c-ee96-438d-b2d0-2fd2630597c3",
        "ParentId": "992f0675-6288-4fc9-be83-595243856c38",
        "Name": "3.Badcell4G-2022",
        "Description": "Badcell4G_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/3.Badcell4G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "934b74f5-0cf7-4258-9399-81803ec67c72",
        "ParentId": "992f0675-6288-4fc9-be83-595243856c38",
        "Name": "4.Badcell theo ngày",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/4.Badcell theo ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa39dca6-c168-45f7-806a-7a957719ace7",
        "ParentId": "934b74f5-0cf7-4258-9399-81803ec67c72",
        "Name": "1.Badcell2G",
        "Description": "Badcell2G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/4.Badcell theo ngày/1.Badcell2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a548bc99-6f3f-4288-af21-02aaf4e402d1",
        "ParentId": "934b74f5-0cf7-4258-9399-81803ec67c72",
        "Name": "2.Badcell3G-DATA",
        "Description": "Badcel3G_DATA_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/4.Badcell theo ngày/2.Badcell3G-DATA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d621d3f-e0a0-4dcf-8bb2-481758ac87b9",
        "ParentId": "934b74f5-0cf7-4258-9399-81803ec67c72",
        "Name": "3.Badcell3G-VOICE",
        "Description": "Badcel3G_VOICE_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/4.Badcell theo ngày/3.Badcell3G-VOICE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33ab0115-6599-4d37-97bd-f582966ef9fa",
        "ParentId": "934b74f5-0cf7-4258-9399-81803ec67c72",
        "Name": "4.Badcell4G",
        "Description": "Badcell4G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/4.Badcell theo ngày/4.Badcell4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23699624-0aee-4701-b9be-227651fa1fa0",
        "ParentId": "992f0675-6288-4fc9-be83-595243856c38",
        "Name": "5.Cảnh báo sớm Badcell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/5.Cảnh báo sớm Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "decc8961-48d7-4565-9c8d-ea5b892973a0",
        "ParentId": "23699624-0aee-4701-b9be-227651fa1fa0",
        "Name": "1.Badcell2G-2022",
        "Description": "Badcell2G_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/5.Cảnh báo sớm Badcell/1.Badcell2G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d2790663-7b66-4851-ad50-8bd8864d3fb5",
        "ParentId": "23699624-0aee-4701-b9be-227651fa1fa0",
        "Name": "2.Badcell3G-2022",
        "Description": "Badcel3G_VOICE_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/5.Cảnh báo sớm Badcell/2.Badcell3G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a21c962-28f7-45aa-b92b-cf62d2377d87",
        "ParentId": "23699624-0aee-4701-b9be-227651fa1fa0",
        "Name": "3.Badcell4G-2022",
        "Description": "Badcell4G_2022",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/8.BADCELL_2022/5.Cảnh báo sớm Badcell/3.Badcell4G-2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b18f0dd-2f54-4946-88c9-d281217d18d2",
        "ParentId": "65f5d3b7-d9a8-4009-aae9-61991bc21722",
        "Name": "9.CELL KHONG DAT MTCL 2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6469e28b-e27c-4896-9997-55248e472c6f",
        "ParentId": "3b18f0dd-2f54-4946-88c9-d281217d18d2",
        "Name": "1.CELL2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44ba3100-5e8a-4f98-af36-cda985893b29",
        "ParentId": "6469e28b-e27c-4896-9997-55248e472c6f",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5d2ee18f-8620-45ab-995f-f87283fd8d39",
        "ParentId": "6469e28b-e27c-4896-9997-55248e472c6f",
        "Name": "2.Tuần",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3dec3fa-c45a-408d-8e2a-5432967fdbac",
        "ParentId": "6469e28b-e27c-4896-9997-55248e472c6f",
        "Name": "3.Tháng",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4e69e00-8a71-423d-86bb-d83b099a8dc8",
        "ParentId": "6469e28b-e27c-4896-9997-55248e472c6f",
        "Name": "4.Quý",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "716a970d-418e-4f28-a1e0-6c31ffd64263",
        "ParentId": "6469e28b-e27c-4896-9997-55248e472c6f",
        "Name": "5.Năm",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/1.CELL2G/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "ParentId": "3b18f0dd-2f54-4946-88c9-d281217d18d2",
        "Name": "2.CELL3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3f5d590-ef5b-4dd4-bc2c-a58e6718c600",
        "ParentId": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "10631e0d-ae97-49bc-8fe4-bc1b3a77f467",
        "ParentId": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "Name": "2. Tuần",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G/2. Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b972bfc-52c2-4719-80e3-07e57847775b",
        "ParentId": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "Name": "3.Tháng",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bae10b5b-31f6-4691-a0a1-fd4b0a6d24bc",
        "ParentId": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "Name": "4.Quý",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8044213-01e9-4507-8800-879fbda52e80",
        "ParentId": "ad799b8a-9994-467b-90af-1c90cbcb7e60",
        "Name": "5.Năm",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/2.CELL3G/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "ParentId": "3b18f0dd-2f54-4946-88c9-d281217d18d2",
        "Name": "3.CELL4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9f0f442-251a-4b9d-87c7-10f861846457",
        "ParentId": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4625dc7b-6101-4bff-9d77-b112fcf2c562",
        "ParentId": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "Name": "2. Tuần",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G/2. Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d68b009d-8a69-4c34-95d8-2b9823bb278a",
        "ParentId": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "Name": "3.Tháng",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd8361a0-5f82-4b18-aaed-abac7715b789",
        "ParentId": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "Name": "4.Quý",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b153b40-edf9-4cd9-a969-c070a0cf66a8",
        "ParentId": "d13b9f8f-c5ac-452f-904f-2367c39235b3",
        "Name": "5. Năm",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/3.CELL4G/5. Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "ParentId": "3b18f0dd-2f54-4946-88c9-d281217d18d2",
        "Name": "4.TOP 20 CELL TỒI",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c118bbf1-4041-41ab-87b7-f28dbe3f823e",
        "ParentId": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83432d50-60eb-4483-b98a-41c87f673c71",
        "ParentId": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bcbf7333-e963-4324-9338-429d926b8719",
        "ParentId": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5756f759-df7b-4192-b964-c44239ead262",
        "ParentId": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a17f1907-6229-4901-a4c3-bc1c1abeab69",
        "ParentId": "9a20bd82-8d7d-4441-abb7-44dd100a4705",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/1.MTCL 2022/9.CELL KHONG DAT MTCL 2022/4.TOP 20 CELL TỒI/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "541423b5-ba73-469d-9d54-1ddcca782188",
        "ParentId": "51bffac4-03dd-4d99-adb8-b1ced59a05b9",
        "Name": "2.CSGS 2022",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07fdba7d-e64e-4e93-b580-5c9360ac5911",
        "ParentId": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ab41ea5-cf06-4299-9b29-c3fe7bb29b07",
        "ParentId": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ae84fa1-6fe0-40d0-9f77-e5018e7fe8a9",
        "ParentId": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3473cce8-e533-4988-9a78-140bfe628422",
        "ParentId": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af175168-8695-4b71-b780-b79fcb8360e7",
        "ParentId": "94950ac2-12a6-4d75-a4b4-cc980b421641",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "daab10e3-df4a-47f5-b751-170045f642b6",
        "ParentId": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "63f0a44c-e720-4490-9923-246c8708bb8f",
        "ParentId": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6512df4-1f9e-4f5e-9e69-0829aadc8947",
        "ParentId": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6e3fea1-967e-487e-ab87-e4b9df94cb0e",
        "ParentId": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6ba86dc-0fa4-4933-b7f6-d5bb07658890",
        "ParentId": "a804762b-5615-45cc-8538-7c9c79ef9b86",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18d2a9bf-6ac6-4916-a70f-400a896e4832",
        "ParentId": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47de66a0-92af-4d7f-8801-2345a5ffcccf",
        "ParentId": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2aa48e8b-bfc6-4ee5-95bf-61b4f0897d0b",
        "ParentId": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7972e21d-aa4b-45c9-83a4-5aff9fa2059b",
        "ParentId": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d5e955d-5de2-49f5-b60c-34fa7cbf8a1c",
        "ParentId": "bd4be2b8-6f9b-47e2-bac5-30d5058d9500",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "4.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3b63f8d-3fe3-4945-a1fc-8f9d6eee9150",
        "ParentId": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b4ce366-24ef-4928-998e-3f0e7eb6c105",
        "ParentId": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46a48957-546d-46e7-a3f5-88dc29cd6dcf",
        "ParentId": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01a8a9f0-5df0-4bed-b3fc-7e5271ba0f3f",
        "ParentId": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df1ee1cd-20e4-4a9c-abe7-f49fae8d07fc",
        "ParentId": "df52628e-686e-47b2-b094-bb4c66ae4c75",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.DISTRICT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "4.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f74689be-bba6-4a33-9406-59575d49df8f",
        "ParentId": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76a377df-839f-4761-aca2-a4fdef2a777e",
        "ParentId": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bcd7ef6-3a8b-44a1-a7c7-239d8b5015f3",
        "ParentId": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0427cc6-567b-4daf-97a9-9ad08b014e00",
        "ParentId": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6048fc1-2755-446b-9e58-364d3d288ff5",
        "ParentId": "9337d35f-d13d-4ab5-bd65-f26d370f07a4",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/4.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6d0c7eb-17cd-412d-8726-b363ff468c2e",
        "ParentId": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6f35072-0b76-428b-afff-34136e81babc",
        "ParentId": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c997f10-399f-4c54-a1f9-b5aae80f53b0",
        "ParentId": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe98b478-c369-4b64-adb9-157916cf7458",
        "ParentId": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3bf8e2c-ba4d-46de-a8a5-4a01b8650316",
        "ParentId": "b3a2abda-3170-4603-a6ef-b61564973ebd",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "ParentId": "541423b5-ba73-469d-9d54-1ddcca782188",
        "Name": "7.BRANCH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "040b1e86-fc84-4281-ba00-062e335c168f",
        "ParentId": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2efad941-dc05-4a00-9bc5-efc8af4bb8d7",
        "ParentId": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f467c309-83c0-4de0-b4ea-a2a13a60aad1",
        "ParentId": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77792a6f-0363-4d47-9023-48d4f7740beb",
        "ParentId": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "Name": "4.Quý",
        "Description": "QR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19bb2e24-f58e-4a6d-b2bf-c3f29ab33f98",
        "ParentId": "04d94730-dd67-4e50-8ab6-ad2b9511011f",
        "Name": "5.Năm",
        "Description": "YR_CSGS2022_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/6.2022/2.2022 - RAN/2.CSGS 2022/7.BRANCH/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5871655d-a3c9-4ddd-8f86-b4d22febbf62",
        "ParentId": "8b6989ab-ecb1-4f05-9550-315824061715",
        "Name": "7.2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bd63daa-759f-4fec-adb3-aef359425e62",
        "ParentId": "5871655d-a3c9-4ddd-8f86-b4d22febbf62",
        "Name": "1.2021 - NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "ParentId": "0bd63daa-759f-4fec-adb3-aef359425e62",
        "Name": "1.MTCL 2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13edb983-16da-44f7-bdd1-25ef9b22a0d5",
        "ParentId": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "Name": "1.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44f05387-376e-4dc2-bb6a-f0aa65d03319",
        "ParentId": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "Name": "2.Tuần",
        "Description": "WK_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d65ea150-aa8e-47ff-a345-b2b0a31e7a96",
        "ParentId": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "Name": "3.Tháng",
        "Description": "MN_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4f34918-deee-4e92-9784-f10fb68bf75b",
        "ParentId": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "Name": "4.Quý",
        "Description": "QR_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "60056e81-44f0-4dc6-b191-4579acf9970f",
        "ParentId": "51d7ad2f-fa7d-446f-af72-f6cc896279af",
        "Name": "5.Năm",
        "Description": "YR_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/1.MTCL 2021/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "ParentId": "0bd63daa-759f-4fec-adb3-aef359425e62",
        "Name": "2.CSGS 2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "053c3daa-ce30-4b7f-b0da-0c474ffaf4aa",
        "ParentId": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "Name": "1.Ngày",
        "Description": "DY_CSGS_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d0485c3-124f-47f9-90d3-a563d13ce433",
        "ParentId": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "Name": "2.Tuần",
        "Description": "WK_CSGS_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "60c72a1e-c223-4cd5-89f4-da414354732c",
        "ParentId": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "Name": "3.Tháng",
        "Description": "MN_CSGS_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24bf92aa-9e14-4025-b177-e7f5eb948813",
        "ParentId": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "Name": "4.Quý",
        "Description": "QR_CSGS_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e49feb50-e8ab-431f-a93a-9cabff18f573",
        "ParentId": "ebfea31d-1a6d-43bf-b46d-89c1774fec02",
        "Name": "5.Năm",
        "Description": "YR_CSGS_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/1.2021 - NOC/2.CSGS 2021/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0e5143e-3e4e-4ce2-a7cc-ec915e678ff6",
        "ParentId": "5871655d-a3c9-4ddd-8f86-b4d22febbf62",
        "Name": "2.2021 - TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "ParentId": "d0e5143e-3e4e-4ce2-a7cc-ec915e678ff6",
        "Name": "1.MTCL 2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54a66337-6356-4f60-8967-fe26e7121cfa",
        "ParentId": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf997cae-9ddb-4689-b42f-8133128fc2f9",
        "ParentId": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c7a864c-df78-4905-90b9-32e40c77e1d4",
        "ParentId": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cecd2b29-0417-4894-a978-0acf123ea408",
        "ParentId": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb0bd3d9-4ccf-42e9-95fd-1ca3746c5927",
        "ParentId": "b0f911cf-fced-4a8c-b28f-4a599ca097f9",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b0329a4c-4665-4150-b2de-e3ae097df5da",
        "ParentId": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0f7e675-2ef3-4e14-a922-bb66179ed87e",
        "ParentId": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40ea1d8c-b754-4c34-aeed-f1db06976e11",
        "ParentId": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "908e18eb-0d5e-4578-bf69-7a75121ecdca",
        "ParentId": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b0e1e42-82b6-4f53-afa9-f1f5184a6a7c",
        "ParentId": "100125d9-6dd2-45d3-8efb-544fa267f767",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dce68f65-95e4-40d5-a208-30b187948b35",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2f9e583-a321-4d8c-a806-fcb09ca0620f",
        "ParentId": "dce68f65-95e4-40d5-a208-30b187948b35",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05e3f98e-3b95-4683-9255-825d1263d266",
        "ParentId": "dce68f65-95e4-40d5-a208-30b187948b35",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca906e2e-85ff-44ea-bb58-43d951b5b4a1",
        "ParentId": "dce68f65-95e4-40d5-a208-30b187948b35",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c658d887-9b5c-4386-97d1-2b79fae52e3d",
        "ParentId": "dce68f65-95e4-40d5-a208-30b187948b35",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb14b1b9-d81e-471a-bee1-e3155745a245",
        "ParentId": "dce68f65-95e4-40d5-a208-30b187948b35",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30446c35-b87b-4694-a4fa-0910a496088e",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ebd66c4a-165c-4d08-9702-760687e0200b",
        "ParentId": "30446c35-b87b-4694-a4fa-0910a496088e",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f317571-5876-4b6d-91c2-e3a679bfa326",
        "ParentId": "30446c35-b87b-4694-a4fa-0910a496088e",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8f9c987-9437-4601-8287-5028d034baca",
        "ParentId": "30446c35-b87b-4694-a4fa-0910a496088e",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "293b6fdf-3cc6-4bfc-89fc-ba253cb71319",
        "ParentId": "30446c35-b87b-4694-a4fa-0910a496088e",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f690450-f4cf-4dc0-893a-fe7151522b61",
        "ParentId": "30446c35-b87b-4694-a4fa-0910a496088e",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/4.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "312dc6f0-2279-4172-8d07-78a5c8f16e1f",
        "ParentId": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c23a4a5a-3c4a-42e3-87a5-9abd0e6e7e90",
        "ParentId": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8ff4605-0d53-4f6c-96ef-bf3e0f0b8faa",
        "ParentId": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cfe306d7-f1b3-49cf-bda0-8019e790bc1b",
        "ParentId": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1828418-9867-4d8d-a5b8-f53aba25305d",
        "ParentId": "32b1336b-88f7-4f8c-943a-3533ad9c106a",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "be9d9fad-776d-418b-a979-b3216942d192",
        "ParentId": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c2d56aa7-3ef9-439f-9c57-306e27de185a",
        "ParentId": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b65b6872-a9ae-473b-8c62-fbd1c22badc5",
        "ParentId": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e9fdf02-d4ca-4dc4-8d42-b6daa9112459",
        "ParentId": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d9fa9fd-e87d-44b2-88c6-d41215342b61",
        "ParentId": "ae0dc6df-a3ee-424d-a031-7822ea614d9f",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6f03012-c46b-435b-b380-157b645113fa",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c35b4a7e-c876-4552-8ef7-45b025398e44",
        "ParentId": "c6f03012-c46b-435b-b380-157b645113fa",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8fa6cf72-ac19-4fed-891f-589a91d0d5ef",
        "ParentId": "c6f03012-c46b-435b-b380-157b645113fa",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90f16054-ec0a-427b-8bb3-8a6ce2114a06",
        "ParentId": "c6f03012-c46b-435b-b380-157b645113fa",
        "Name": "3.Tháng",
        "Description": "MN_MTCL2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fbe4dcc8-522d-48ee-bfa9-2135b9938bae",
        "ParentId": "c6f03012-c46b-435b-b380-157b645113fa",
        "Name": "4.Quý",
        "Description": "QR_MTCL2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34dfaac2-b3ab-4fe2-88bb-80e95f131639",
        "ParentId": "c6f03012-c46b-435b-b380-157b645113fa",
        "Name": "5.Năm",
        "Description": "YR_MTCL2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/7.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "ParentId": "11ffd180-0cfb-409f-9faa-572638ee0b8d",
        "Name": "8.Badcell_2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d581fae1-eb2e-440d-85cf-9ae42d29e4d0",
        "ParentId": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "Name": "1.Badcell2G-2021",
        "Description": "Badcell2G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/1.Badcell2G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0cf43ce-7716-41d9-a1c8-bb04e458099b",
        "ParentId": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "Name": "2.Badcell3G-2021",
        "Description": "Badcell3G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/2.Badcell3G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e2d407ec-6498-40a9-98f2-db62f9f45861",
        "ParentId": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "Name": "3.Badcell4G-2021",
        "Description": "Badcell4G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/3.Badcell4G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffcb2343-f348-46bf-bc78-5b48b2f5506b",
        "ParentId": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "Name": "4.Badcell theo ngày",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/4.Badcell theo ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbb4c4ab-6229-4dd4-a22d-96238cdc391c",
        "ParentId": "ffcb2343-f348-46bf-bc78-5b48b2f5506b",
        "Name": "1.Badcell2G",
        "Description": "Badcell2G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/4.Badcell theo ngày/1.Badcell2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38805b7f-1399-4e70-805b-1e6bd7216f1a",
        "ParentId": "ffcb2343-f348-46bf-bc78-5b48b2f5506b",
        "Name": "2.Badcell3G-DATA",
        "Description": "Badcel3G_DATA_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/4.Badcell theo ngày/2.Badcell3G-DATA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4b61c39d-7b68-4717-9cdd-e425b63c6748",
        "ParentId": "ffcb2343-f348-46bf-bc78-5b48b2f5506b",
        "Name": "3.Badcell3G-VOICE",
        "Description": "Badcel3G_VOICE_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/4.Badcell theo ngày/3.Badcell3G-VOICE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2bc0b850-5f2d-4b56-bcb7-cb9c3bd38d03",
        "ParentId": "ffcb2343-f348-46bf-bc78-5b48b2f5506b",
        "Name": "4.Badcell4G",
        "Description": "Badcell4G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/4.Badcell theo ngày/4.Badcell4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd583498-f590-49ab-84f5-1bda543f73cb",
        "ParentId": "d82903b7-47ed-489a-a848-796ed8fb480c",
        "Name": "5.Cảnh báo sớm Badcell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/5.Cảnh báo sớm Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7456d2f-ce7e-48de-a627-0133b252603f",
        "ParentId": "dd583498-f590-49ab-84f5-1bda543f73cb",
        "Name": "1.Badcell2G-2021",
        "Description": "Badcell2G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/5.Cảnh báo sớm Badcell/1.Badcell2G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5430f29c-3361-4357-af79-667a5e582387",
        "ParentId": "dd583498-f590-49ab-84f5-1bda543f73cb",
        "Name": "2.Badcell3G-2021",
        "Description": "Badcel3G_VOICE_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/5.Cảnh báo sớm Badcell/2.Badcell3G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d464fec-655a-4f7e-946f-cf98fad4f731",
        "ParentId": "dd583498-f590-49ab-84f5-1bda543f73cb",
        "Name": "3.Badcell4G-2021",
        "Description": "Badcell4G_2021",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/1.MTCL 2021/8.Badcell_2021/5.Cảnh báo sớm Badcell/3.Badcell4G-2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "ParentId": "d0e5143e-3e4e-4ce2-a7cc-ec915e678ff6",
        "Name": "2.CSGS 2021",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8c3af67-41eb-48e6-93f6-54b85f06f7b1",
        "ParentId": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5671f20d-ce34-4fd0-935e-91bc2ea44cf9",
        "ParentId": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd7c5d4c-b7d4-4555-8aa7-7ab71be4e02b",
        "ParentId": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc66f504-2262-4397-9e37-17a3298d980c",
        "ParentId": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79e9744a-741c-41bd-b914-e74f2f33d002",
        "ParentId": "40ca6ae0-6a2a-44cc-8c26-fbb7f18cef3e",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37550a8a-0a5e-438d-8a51-52fe04ad4fe4",
        "ParentId": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb30480b-d9fc-4ba1-ac67-ee7f39c76384",
        "ParentId": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "848ea6de-baf4-4c8c-b70b-3d4981b49765",
        "ParentId": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37f817be-cbde-4573-b3c5-12fe0e206baa",
        "ParentId": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e55b7d75-939a-4e50-b591-4b55f3f38f4c",
        "ParentId": "61dcebf2-fb8b-4534-ab1b-111d5620374d",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4eddaeba-0c64-4870-a5c0-fca38f4488a4",
        "ParentId": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18a70dc4-522e-4af6-8e7c-85dc625baf6a",
        "ParentId": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fec9ea79-e87d-4e9d-b613-92c26521bfef",
        "ParentId": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ee2ed52-18f0-422d-8649-7396bec4abba",
        "ParentId": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ba266d85-a923-435c-9220-b455f7d1ac77",
        "ParentId": "4e6ad31c-4a8d-44d3-a5d7-963c2f638eea",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09852313-4270-473b-9f4a-777121f8bbd5",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e6812ad-042c-4cfc-aedb-21f46f59f3a3",
        "ParentId": "09852313-4270-473b-9f4a-777121f8bbd5",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9c5d405-f959-4ed7-9811-f5079f463618",
        "ParentId": "09852313-4270-473b-9f4a-777121f8bbd5",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73dafada-6c18-4863-8095-31f52baca3bf",
        "ParentId": "09852313-4270-473b-9f4a-777121f8bbd5",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47c9812b-3a85-4c2e-8142-a014a6e10a27",
        "ParentId": "09852313-4270-473b-9f4a-777121f8bbd5",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d885bdc4-67c2-44d8-a5f4-21f2ff2c3414",
        "ParentId": "09852313-4270-473b-9f4a-777121f8bbd5",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/4.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "324fceef-9195-41c3-b497-7f93338fccad",
        "ParentId": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8f69e9a-9d8e-41ae-86e7-dc0c0e3783bd",
        "ParentId": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "555a296b-5d07-47aa-990c-442badd25a81",
        "ParentId": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d389e89-53bc-4b3e-9119-45e4a65baf2b",
        "ParentId": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1cad475-a38f-42e5-8b38-aece4299cafd",
        "ParentId": "d9bb3dd8-9e9a-4898-ba27-6f2467fb776e",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1afc0977-2245-40d0-844f-01b672002043",
        "ParentId": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f198e7cd-d928-4938-b621-b50bb6746e30",
        "ParentId": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe57e03e-0cac-4497-952b-45dc43a4a084",
        "ParentId": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3a45abc-8742-440f-be0a-9017d6eb8d2c",
        "ParentId": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54013914-caa6-460c-8425-8ce232233f2d",
        "ParentId": "8f4a5baa-f385-4ae3-8246-6d5f66c63800",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "ParentId": "fe21ba73-6ed5-425f-bded-e98b0b540c8d",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72e184aa-fdaa-4e30-a246-5176d58b3678",
        "ParentId": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f40ed8a3-806d-431c-beda-b99f7fbe1411",
        "ParentId": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec421fea-0fd7-49ce-ae5c-707d08fc6a47",
        "ParentId": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e97f5355-11b6-436f-9f39-89988102c491",
        "ParentId": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "Name": "4.Quý",
        "Description": "QR_CSGS2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d086e514-8477-438b-80d3-2850a605b552",
        "ParentId": "f6827a0a-cd7a-4515-a181-d1ee4288b8ff",
        "Name": "5.Năm",
        "Description": "YR_CSGS2021_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/2.CSGS 2021/7.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "ParentId": "d0e5143e-3e4e-4ce2-a7cc-ec915e678ff6",
        "Name": "3.BC-IBC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "ParentId": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "Name": "1.IBC tổng hợp",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24f21d54-1881-4846-9a33-f57c295d5e7d",
        "ParentId": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "Name": "1.Ngày",
        "Description": "DY_IBC_TH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb0e8783-a6a3-42c8-89a0-b87464671d5b",
        "ParentId": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "Name": "2.Tuần",
        "Description": "WK_IBC_TH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e96af469-e6b0-4496-b645-45e9b8b02520",
        "ParentId": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "Name": "3.Tháng",
        "Description": "MN_IBC_TH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f1f4b64-9395-41e7-bb9a-a1b93a5c24a0",
        "ParentId": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "Name": "4.Quý",
        "Description": "QR_IBC_TH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c90a7b44-41d4-4669-9496-073e12ec32d1",
        "ParentId": "104eb5e8-6c84-4f36-a249-238acd57cd74",
        "Name": "5.Năm",
        "Description": "YR_IBC_TH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/1.IBC tổng hợp/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "ParentId": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "Name": "2.Chi tiết IBC mức cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de4409cc-bd52-4982-afde-fb025452b129",
        "ParentId": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "935a9b82-f29c-4196-a255-6d4d618b3959",
        "ParentId": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "Name": "2.Tuần",
        "Description": "WK_IBC_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff79e01b-3612-43bd-9fc2-2746dcf3d31b",
        "ParentId": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "Name": "3.Tháng",
        "Description": "MN_IBC_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "277b5b10-4f9e-4bc2-a38e-2306ed479394",
        "ParentId": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "Name": "4.Quý",
        "Description": "QR_IBC_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7dcf739-39b6-47ba-844a-f8eef234360c",
        "ParentId": "b7f66b8f-65ee-4aa9-8e76-f2a2967154cf",
        "Name": "5.Năm",
        "Description": "YR_IBC_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/2.Chi tiết IBC mức cell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "455bcdd2-ec0b-4fa4-bf79-4a69aa3377ee",
        "ParentId": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "Name": "3.Chi tiết IBC KPI kém",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/3.Chi tiết IBC KPI kém",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6740fddb-372c-474b-8abf-4de2c0dc423e",
        "ParentId": "455bcdd2-ec0b-4fa4-bf79-4a69aa3377ee",
        "Name": "1.Ngày",
        "Description": "DY_IBC_CELL_KEM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/3.Chi tiết IBC KPI kém/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45e53a59-9bc8-473e-8310-535df29ad98e",
        "ParentId": "455bcdd2-ec0b-4fa4-bf79-4a69aa3377ee",
        "Name": "2.Tuần",
        "Description": "WK_IBC_CELL_KEM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/3.Chi tiết IBC KPI kém/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86bbbb7b-b042-4b36-8a48-6924afa16964",
        "ParentId": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "Name": "4.Chi tiết IBC không đạt TWAMP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/4.Chi tiết IBC không đạt TWAMP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0a36fb6-9b58-41db-a5f4-2694af6e77c8",
        "ParentId": "86bbbb7b-b042-4b36-8a48-6924afa16964",
        "Name": "1.Ngày",
        "Description": "DY_Cell_KD_TWAMP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/4.Chi tiết IBC không đạt TWAMP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed3ec071-517d-4e65-ade1-0cd46e8a0f3f",
        "ParentId": "aeb915d5-dc63-4f3d-b057-fe5a0c83e735",
        "Name": "5.Top 100 cell IBC MCA cao",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/5.Top 100 cell IBC MCA cao",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d097807-8455-4f60-a686-2ccd4aef4549",
        "ParentId": "ed3ec071-517d-4e65-ade1-0cd46e8a0f3f",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/7.2021/2.2021 - TTML/3.BC-IBC/5.Top 100 cell IBC MCA cao/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90cb84bb-2f63-4c76-9fe2-0f1fe896862a",
        "ParentId": "8b6989ab-ecb1-4f05-9550-315824061715",
        "Name": "8.2020",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "ParentId": "90cb84bb-2f63-4c76-9fe2-0f1fe896862a",
        "Name": "3.2020 - NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "acc91cb3-b03a-45dc-ae0c-0cc9d5de5619",
        "ParentId": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7bb4e15-0b0f-4596-8c7a-0b5801656f1a",
        "ParentId": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "323fa228-5dc7-45b1-b89f-fc08e484f1a4",
        "ParentId": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0321907a-7f2e-4818-a29a-9da4abde5387",
        "ParentId": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "Name": "4.Quý",
        "Description": "MTCL_CORE_2020_Quý",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a1934bc-04f4-4b81-ab38-23b4c17feedb",
        "ParentId": "b8d95be2-2ea6-4ee4-b6c3-daac365a21ca",
        "Name": "5.Năm",
        "Description": "MTCL2020_CORE_Năm",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/3.2020 - NOC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a300081-f04d-4e22-8da4-064762ad01c4",
        "ParentId": "90cb84bb-2f63-4c76-9fe2-0f1fe896862a",
        "Name": "4.2020 - TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "ParentId": "4a300081-f04d-4e22-8da4-064762ad01c4",
        "Name": "0.MTCL 2020",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "207eccf3-e9e9-4137-b16a-44b2afe9b9e5",
        "ParentId": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e1400e9-fca4-4c8b-931e-95b61a1d3784",
        "ParentId": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a641bf1-8366-4686-8341-92ec5c604291",
        "ParentId": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "Name": "30.Tháng",
        "Description": "MN_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54e75b75-1034-4008-ae4a-28f9b8439be8",
        "ParentId": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "Name": "4.Quý",
        "Description": "QR_MTCL2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8cac1a57-81d5-464a-ae53-b1fcd097921b",
        "ParentId": "fa78a525-36d9-4faa-8f4c-bb5f779fed29",
        "Name": "5.Năm",
        "Description": "YR_MTCL2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0bbb1a9-e81c-482e-b786-dcdf52b5cf91",
        "ParentId": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6cf65c6-52d9-4495-bb52-abc53c728e4b",
        "ParentId": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5565840-2ed6-46c2-ab6f-89c027cf1490",
        "ParentId": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "Name": "30.Tháng",
        "Description": "MN_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9113090c-f74b-4458-8a47-98bad676c820",
        "ParentId": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "Name": "4.Quý",
        "Description": "MN_MTCL2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5ca8d14-70ea-4dd0-8d9d-ba256b02ba39",
        "ParentId": "19bbd164-affd-41b6-9c98-4471eafff7ad",
        "Name": "5.Năm",
        "Description": "MN_MTCL2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8feea89-c88d-443f-85f0-b4e1a7a1563e",
        "ParentId": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb4b9eb6-2eea-4aff-a516-b23a507a0452",
        "ParentId": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "Name": "2.Tuần",
        "Description": "WK_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68c2c196-9a42-446d-9d0f-a0b99cebd0a5",
        "ParentId": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "Name": "30.Tháng",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "36ea1ff0-0fa9-4d22-8eef-8a1039f8100b",
        "ParentId": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "Name": "4.Quý",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31220a80-8ab3-4aa8-b183-bab01ea52dac",
        "ParentId": "28daa8b3-1b6a-4d59-8237-44a73f6e8da1",
        "Name": "5.Năm",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64641ebf-3d87-4d7a-ae05-d97cb33e571a",
        "ParentId": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf08671e-245a-4519-871d-9a5446bc7ee9",
        "ParentId": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44ede7a0-6251-43f3-90ef-975fe25c0cc8",
        "ParentId": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "Name": "30.Tháng",
        "Description": "MN_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce4bfa72-ba4e-46f1-ae29-4cf57655c0ab",
        "ParentId": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "Name": "4.Quý",
        "Description": "QR_MTCL2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "62898a98-61a3-47bf-9b20-1705f46ffc03",
        "ParentId": "5a5d8d8a-68e2-4382-bc2d-93dd6d3444cb",
        "Name": "5.Năm",
        "Description": "YR_MTCL2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/4.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c248a0a-9798-4f7a-8b01-5b03e27d20a1",
        "ParentId": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b1a1b47f-5045-4ed2-94bd-bde261bf8ad6",
        "ParentId": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "990671ba-9f1d-4a35-8825-d0cafcf92004",
        "ParentId": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "Name": "30.Tháng",
        "Description": "MN_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0523a7b0-fb6c-42f1-a0c3-6ec24bef4bb0",
        "ParentId": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "Name": "4.Quý",
        "Description": "MN_MTCL2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43ba1dc0-dce2-42b5-ae11-84ab6dfcc724",
        "ParentId": "13810942-68b3-4e04-854b-ddc67c51e96b",
        "Name": "5.Năm",
        "Description": "MN_MTCL2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ceb43817-84cc-4955-816b-f13f0d053b27",
        "ParentId": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "720633a4-289e-466d-9276-8173c447db9b",
        "ParentId": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "954355e9-1b72-4719-a50f-4d4e678f8c3a",
        "ParentId": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "Name": "30.Tháng",
        "Description": "MN_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14ba15e5-c2e4-4938-aceb-d59bd1451876",
        "ParentId": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "Name": "4.Quý",
        "Description": "QR_MTCL2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1b8b2fa-b754-4fd4-acba-ec4c982a51f6",
        "ParentId": "abe63e1d-4f52-4deb-8644-b9c1dc7a2267",
        "Name": "5.Năm",
        "Description": "YR_MTCL2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e249063d-be5a-4d86-9a7a-e6386bb27537",
        "ParentId": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "Name": "1.Ngày",
        "Description": "DY_MTCL2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0d2a2ea-2075-4759-8e2c-d2726ae2ad3c",
        "ParentId": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "Name": "2.Tuần",
        "Description": "WK_MTCL2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5d2a7dd-f7cf-4229-a7f3-90b9fcb6dc22",
        "ParentId": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "Name": "30.Tháng",
        "Description": "MN_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch/30.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4fe67d0-3bc5-4172-9254-45a123ff3aab",
        "ParentId": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "Name": "4.Quý",
        "Description": "MN_MTCL2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "658294e3-06a3-4927-aa31-330f9f85308e",
        "ParentId": "6bf69f0e-9daf-4dfb-a722-5f26d2ea6d30",
        "Name": "5.Năm",
        "Description": "MN_MTCL2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/7.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27824da2-636e-4cb7-948d-16c7dff36afe",
        "ParentId": "40900684-5b41-4574-9c36-0e3ffac43e44",
        "Name": "8.Badcell-2020",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e673e37d-ba7e-4a77-afdb-bea6920c489c",
        "ParentId": "27824da2-636e-4cb7-948d-16c7dff36afe",
        "Name": "1.Badcell2G-2020",
        "Description": "badcell2g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/1.Badcell2G-2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f0a0d03-9af1-4884-a6ed-2274bdd909a8",
        "ParentId": "27824da2-636e-4cb7-948d-16c7dff36afe",
        "Name": "2.Badcell3G-2020",
        "Description": "badcel3g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/2.Badcell3G-2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e0b0df2-bd27-4665-b624-47268b4ab2f8",
        "ParentId": "27824da2-636e-4cb7-948d-16c7dff36afe",
        "Name": "3.Badcell4G-2020",
        "Description": "badcell4g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/3.Badcell4G-2020",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1d97f54-ecad-4f0b-be31-b4b9c8056804",
        "ParentId": "27824da2-636e-4cb7-948d-16c7dff36afe",
        "Name": "4.Cảnh báo badcell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/4.Cảnh báo badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dae0fe3f-3fec-4fe9-85f6-9e81121e35f7",
        "ParentId": "d1d97f54-ecad-4f0b-be31-b4b9c8056804",
        "Name": "1.Badcell2G",
        "Description": "badcell2g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/4.Cảnh báo badcell/1.Badcell2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8c76ac9-7ccd-444b-adca-7a8e7479433e",
        "ParentId": "d1d97f54-ecad-4f0b-be31-b4b9c8056804",
        "Name": "2.Badcell3G",
        "Description": "badcel2g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/4.Cảnh báo badcell/2.Badcell3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c836823-b1c9-484f-a5bd-e7cc560c4bd7",
        "ParentId": "d1d97f54-ecad-4f0b-be31-b4b9c8056804",
        "Name": "3.Badcell4G",
        "Description": "badcell4g_2020",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/0.MTCL 2020/8.Badcell-2020/4.Cảnh báo badcell/3.Badcell4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "ParentId": "4a300081-f04d-4e22-8da4-064762ad01c4",
        "Name": "1.Chỉ Số Giám Sát",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "391eecae-33f9-4813-b42d-8605b44a5b55",
        "ParentId": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5dd4c818-a61f-438e-bc80-c28de3a021dc",
        "ParentId": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81363efb-f94c-484c-bf89-cae49464bc1d",
        "ParentId": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a75d793-3778-4c0c-83b9-17402c318387",
        "ParentId": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "Name": "4.Quý",
        "Description": "QR_CSGS2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d353f60f-057d-44ae-a04b-787bee53cf37",
        "ParentId": "8a497418-f942-4272-be89-eb2fe7b23a7f",
        "Name": "5.Năm",
        "Description": "YR_CSGS2020_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f169286-aab2-4abb-a167-e51d6d4a3a9c",
        "ParentId": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ad42cfc-79ba-41a7-a4a4-49c2cf55c44e",
        "ParentId": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d76efa56-529a-4b57-91c6-426c6600e27c",
        "ParentId": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f250fd1b-6629-4f0c-8175-e6923afcc6b0",
        "ParentId": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "Name": "4.Quý",
        "Description": "QR_CSGS2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ba87df7-0808-4a02-930b-944041ee9536",
        "ParentId": "0ef8f43a-16f1-41d8-ba8f-f3920d04a7bc",
        "Name": "5. Năm",
        "Description": "YR_CSGS2020_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/2.DVT/5. Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1184a19d-cbd9-4763-834e-789dcbc27ec9",
        "ParentId": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4240715b-49dc-457a-9c9f-08d1935bd58d",
        "ParentId": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24389367-366e-466a-9c3a-30dcb19a5644",
        "ParentId": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40b5a21e-f556-443f-ab8a-3dee44fce91e",
        "ParentId": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "Name": "4.Quý",
        "Description": "MN_CSGS2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e89b8ef5-e2b5-4ec3-a83c-694715c7fb9e",
        "ParentId": "0fea0bd1-eac6-4ae1-9794-90c94668b20a",
        "Name": "5.Năm",
        "Description": "MN_CSGS2020_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "4.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1de78499-8ead-4b41-9b9b-f68e2b0e081c",
        "ParentId": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f389de08-4908-424b-b1b9-03fa3043db85",
        "ParentId": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ffaab2e-9f1b-4b58-bec5-44f7ce383324",
        "ParentId": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5857f305-21ef-4be3-903b-69a18e61c8b2",
        "ParentId": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "Name": "4.Quý",
        "Description": "MN_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "407611b7-e417-4154-856c-85ddbdb5e29b",
        "ParentId": "89646da8-40c9-4388-9f2c-3cf4f0f5b5e5",
        "Name": "5.Năm",
        "Description": "YR_CSGS2020_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/4.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "5.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c6296b9-6d18-4430-a251-9d01b41605d3",
        "ParentId": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1cbece20-9917-4198-a931-655f0d133e1a",
        "ParentId": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "444e3bc5-a98f-4722-9c7d-32784105a005",
        "ParentId": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c362ba6d-110f-4b3b-b306-19b5516dea8b",
        "ParentId": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "Name": "4.Quý",
        "Description": "QR_CSGS2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd0c2ca5-30d7-426a-8f73-e50f02e7bd9a",
        "ParentId": "47c0614c-2a0a-4a02-8857-f27fa872d288",
        "Name": "5.Năm",
        "Description": "YR_CSGS2020_DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/5.DISTRICT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a5f54a0-f913-4f1c-ae58-4c38a6dc1ae9",
        "ParentId": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8074b0a0-8049-469c-81e8-06640db502a3",
        "ParentId": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7265111b-bcd1-4668-8c17-6d1dc2c318d7",
        "ParentId": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58b3c6da-1568-4d85-8d24-8c2ce9852147",
        "ParentId": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "Name": "4. Quý",
        "Description": "QR_CSGS2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "961216cd-d5bb-4548-bee2-937c34964233",
        "ParentId": "42a084fa-cc00-496b-8c7c-8d7471842417",
        "Name": "5.Năm",
        "Description": "YR_CSGS2020_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "ParentId": "e9e624ea-69a8-4b9c-8953-cf135b28447d",
        "Name": "7.BRANCH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f164a6a4-47ee-4c48-b700-4c89e052e2bb",
        "ParentId": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "Name": "1.Ngày",
        "Description": "DY_CSGS2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b3a4214-80dd-49e3-9b3f-0fce3a9411b0",
        "ParentId": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "Name": "2.Tuần",
        "Description": "WK_CSGS2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d44a2290-49a9-4007-8409-5875fc3343f8",
        "ParentId": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "Name": "3.Tháng",
        "Description": "MN_CSGS2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dffc24d1-3d48-4ac4-81a3-e28d52e1f3d1",
        "ParentId": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "Name": "4.Quý",
        "Description": "QR_CSGS2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "760440c1-cdc0-4c7c-b959-3199e3c54a96",
        "ParentId": "a90eeb0f-d468-4884-90ff-0336ddd34986",
        "Name": "5.Năm",
        "Description": "YR_CSGS2020_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/8.2020/4.2020 - TTML/1.Chỉ Số Giám Sát/7.BRANCH/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "967a705a-7dd0-4bc0-b3c8-ab880cba39c6",
        "ParentId": "8b6989ab-ecb1-4f05-9550-315824061715",
        "Name": "9.2019",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19017d91-16b8-4aab-b272-faefe55790eb",
        "ParentId": "967a705a-7dd0-4bc0-b3c8-ab880cba39c6",
        "Name": "5.2019 - NOC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/5.2019 - NOC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1e2f3ee-6aa0-4a06-bffc-d8f5c6a8b897",
        "ParentId": "19017d91-16b8-4aab-b272-faefe55790eb",
        "Name": "2.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/5.2019 - NOC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e59dd8c5-87ea-4fff-a10a-c756c9a6712d",
        "ParentId": "19017d91-16b8-4aab-b272-faefe55790eb",
        "Name": "3.Tuần",
        "Description": "WK_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/5.2019 - NOC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bbbbae9-a566-4890-9521-9b182ac18434",
        "ParentId": "19017d91-16b8-4aab-b272-faefe55790eb",
        "Name": "4.Tháng",
        "Description": "MN_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/5.2019 - NOC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "ParentId": "967a705a-7dd0-4bc0-b3c8-ab880cba39c6",
        "Name": "6.2019 - TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e844565-d455-40ca-99fd-953110be0605",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c074820-4b7f-43e1-86d6-9cf50f9643c8",
        "ParentId": "5e844565-d455-40ca-99fd-953110be0605",
        "Name": "1.Ngày",
        "Description": "DY_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0690c941-3c12-4838-a29d-5f07ff052394",
        "ParentId": "5e844565-d455-40ca-99fd-953110be0605",
        "Name": "2.Tuần",
        "Description": "WK_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1aa5d296-6149-4b36-b0b3-eeeddc237682",
        "ParentId": "5e844565-d455-40ca-99fd-953110be0605",
        "Name": "3.Tháng",
        "Description": "MN_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ba7971f7-fdf5-4e4a-8d35-2639410330c6",
        "ParentId": "5e844565-d455-40ca-99fd-953110be0605",
        "Name": "4.Quý",
        "Description": "MN_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69885522-b211-48ab-ac4b-b350130564f7",
        "ParentId": "5e844565-d455-40ca-99fd-953110be0605",
        "Name": "5.Năm",
        "Description": "MN_MTCLTTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/1.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "2.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28fd1003-8ef6-42de-b7b5-43924e1c1a1d",
        "ParentId": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "Name": "1.Ngày",
        "Description": "DY_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac465e09-0f9b-43d8-80e7-1da07f2383b9",
        "ParentId": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "Name": "2.Tuần",
        "Description": "WK_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c56ece1-d2f6-49a1-8ce6-cfd914ac0b0e",
        "ParentId": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "Name": "3.Tháng",
        "Description": "MN_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8202d8f6-f48d-44fc-88aa-8891c90f2629",
        "ParentId": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "Name": "4.Quý",
        "Description": "MN_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f51a7f7-f637-4532-a979-3f10df782250",
        "ParentId": "ae4dade1-76da-4392-a03a-254e2c48d372",
        "Name": "5.Năm",
        "Description": "MN_MTCLDVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/2.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "3.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2646dfd3-97d9-4dd0-b0a2-7f5dd7e34353",
        "ParentId": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "Name": "1.Ngày",
        "Description": "DY_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fbac8455-a52a-475f-aefa-16ce776e43b3",
        "ParentId": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "Name": "2.Tuần",
        "Description": "WK_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95573b7e-0781-48db-a73c-b08f9a88e2ff",
        "ParentId": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "Name": "3.Tháng",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ee1f408b-e703-4de5-91a4-5ce4b535ffc8",
        "ParentId": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "Name": "4.Quý",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e65d200-b111-4c68-bc75-626b29b20072",
        "ParentId": "27551d42-83d7-4e93-a592-3cb83291aa69",
        "Name": "5.Năm",
        "Description": "MN_MTCLTVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/3.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b72cff1f-1fed-4904-bc7a-10a2f0da44d8",
        "ParentId": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "Name": "1.Ngày",
        "Description": "DY_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec17bbd8-1d9a-4c57-8a39-09fd0f2dda6a",
        "ParentId": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "Name": "2.Tuần",
        "Description": "WK_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c79099e5-af59-4444-86a9-a54d09287caf",
        "ParentId": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "Name": "3.Tháng",
        "Description": "MN_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d85fa8a4-ae27-4ec8-a542-9565aff79837",
        "ParentId": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "Name": "4.Quý",
        "Description": "MN_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dbf0a6ef-4b75-40d2-a35b-516ecd732b79",
        "ParentId": "05e21973-0c9b-49d8-a397-c58dd2676a85",
        "Name": "5.Năm",
        "Description": "MN_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/4.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7bba53d2-406f-4405-8e9b-d743044b47cf",
        "ParentId": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "Name": "1.Ngày",
        "Description": "DY_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d52b4d45-692a-4baf-9063-56b3cb01d4a8",
        "ParentId": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "Name": "2.Tuần",
        "Description": "WK_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8dd09fcb-7ea6-42e6-926a-b9f8b3d982f4",
        "ParentId": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "Name": "3.Tháng",
        "Description": "MN_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75702e38-302e-4ca3-a5ab-f13beaec721e",
        "ParentId": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "Name": "4.Quý",
        "Description": "MN_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a394ecb0-a3ab-42b9-8812-f07e22067a4a",
        "ParentId": "c05632a0-3970-44e1-9bc7-5c530d18dd08",
        "Name": "5.Năm",
        "Description": "MN_MTCLDISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "778770d7-a450-4f23-b353-285f166500c7",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d89edd7-f82e-46b9-ad38-beee89e536b4",
        "ParentId": "778770d7-a450-4f23-b353-285f166500c7",
        "Name": "1.Ngày",
        "Description": "DY_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6cdbf2b9-6caa-41b5-a5ba-c430ebfde420",
        "ParentId": "778770d7-a450-4f23-b353-285f166500c7",
        "Name": "2.Tuần",
        "Description": "WK_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "584e41c7-97bc-4c8f-ac59-069a37237108",
        "ParentId": "778770d7-a450-4f23-b353-285f166500c7",
        "Name": "3.Tháng",
        "Description": "MN_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26b9f6b2-e824-4a5a-b151-1da9b19f6d30",
        "ParentId": "778770d7-a450-4f23-b353-285f166500c7",
        "Name": "4.Quý",
        "Description": "MN_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "caf71978-cf26-47d8-8e77-9602a512aabd",
        "ParentId": "778770d7-a450-4f23-b353-285f166500c7",
        "Name": "5.Năm",
        "Description": "MN_MTCLCTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/6.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "479e8a18-2563-46b9-ac9b-9e7538ce5bf9",
        "ParentId": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "Name": "1.Ngày",
        "Description": "DY_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9c2aa20-a279-49c3-b1f2-89a7554b72d5",
        "ParentId": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "Name": "2.Tuần",
        "Description": "WK_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e23ca245-ba5f-498f-91d9-ae97d9cf2f5a",
        "ParentId": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "Name": "3.Tháng",
        "Description": "MN_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ba4674b-a02f-4782-9080-5fe50805f103",
        "ParentId": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "Name": "4.Quý",
        "Description": "MN_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8e64c8e-dd6a-40e0-9e5a-6233ac8da93b",
        "ParentId": "2499bdcd-e5f7-4b86-a3b5-15a564e558ef",
        "Name": "5.Năm",
        "Description": "MN_MTCLBRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/7.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df2658b5-940f-4654-a712-ad91d7c6607a",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "8.CORE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/8.CORE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75d7745d-0df5-45d7-802c-703712f80da3",
        "ParentId": "df2658b5-940f-4654-a712-ad91d7c6607a",
        "Name": "2.Ngày",
        "Description": "DY_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/8.CORE/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e54bf387-6d32-4f86-a4f9-ef67a3fc66d0",
        "ParentId": "df2658b5-940f-4654-a712-ad91d7c6607a",
        "Name": "3.Tuần",
        "Description": "WK_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/8.CORE/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "742f06bb-4038-4fcc-a7f8-a4fd96b720e3",
        "ParentId": "df2658b5-940f-4654-a712-ad91d7c6607a",
        "Name": "4.Tháng",
        "Description": "MN_MTCL_CORE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/8.CORE/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "82ee0f93-5b9a-4a43-b053-703a216b141a",
        "ParentId": "2f04e3af-be0b-4b2f-89d9-110f84743927",
        "Name": "9.VAS-IN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/9.VAS-IN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b5f7f31-4c99-41a8-9b80-23378bb7de14",
        "ParentId": "82ee0f93-5b9a-4a43-b053-703a216b141a",
        "Name": "2.Ngày",
        "Description": "DY_MTCL_VAS_IN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/1.MTCL/9.2019/6.2019 - TTML/9.VAS-IN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "10.BC nhanh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2dac863e-0866-4516-80fa-225572a1f027",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "01.KHVIP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/01.KHVIP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ec99afd-9fc4-4677-83e0-eab7cbbd0bdd",
        "ParentId": "2dac863e-0866-4516-80fa-225572a1f027",
        "Name": "1.DIAMOND-GOLD",
        "Description": "KHVIP-DIAMOND,GOLD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/01.KHVIP/1.DIAMOND-GOLD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "748df5cf-d33f-4f12-a0ea-b3c913f1ccd0",
        "ParentId": "2dac863e-0866-4516-80fa-225572a1f027",
        "Name": "2.Rank_loyal",
        "Description": "KHVIP-RANK_LOYAL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/01.KHVIP/2.Rank_loyal",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc89d9ea-e4d2-430a-8fd0-0aead57849d9",
        "ParentId": "2dac863e-0866-4516-80fa-225572a1f027",
        "Name": "3.Subcriber",
        "Description": "KHVIP-SUBCRIBER",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/01.KHVIP/3.Subcriber",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "02.KQI",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d96ea8f-7a7c-46f3-adba-98975ab0c484",
        "ParentId": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6046f30a-b03f-43b5-8625-8aa1d8f2aebb",
        "ParentId": "3d96ea8f-7a7c-46f3-adba-98975ab0c484",
        "Name": "1.Detail",
        "Description": "KQI-TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/1.TTML/1.Detail",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "129e6752-d742-4e63-afc9-f118d9e06e06",
        "ParentId": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "Name": "2.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/2.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69f76f5c-5d7a-48f9-bbef-0c5c072a6700",
        "ParentId": "129e6752-d742-4e63-afc9-f118d9e06e06",
        "Name": "1.Detail",
        "Description": "KQI-PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/2.Province/1.Detail",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74a87bef-dbc3-44e9-89ca-059663a4fb00",
        "ParentId": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "Name": "3.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/3.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "172f8bf8-0ae2-41ef-937d-1eddb52dd51a",
        "ParentId": "74a87bef-dbc3-44e9-89ca-059663a4fb00",
        "Name": "1.Detail",
        "Description": "KQI-DISTRICT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/3.District/1.Detail",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae90569e-0bee-4595-a436-50b5612b695a",
        "ParentId": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "Name": "4.Site",
        "Description": "KQI-SITE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/4.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46bac575-78b2-4fc3-9266-21a2f176ec0e",
        "ParentId": "2712b6ee-e132-4ae8-b4c4-2dc22d153536",
        "Name": "5.Cell",
        "Description": "KQI-CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/02.KQI/5.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab03217b-eb92-43e7-99cd-5db849604d3e",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "03.IMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/03.IMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1320500-4676-44aa-a9a6-78125f5ee0ba",
        "ParentId": "ab03217b-eb92-43e7-99cd-5db849604d3e",
        "Name": "Detail",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/03.IMS/Detail",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a358e5d-de95-4de5-a4f9-d28a263651bb",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "1.iGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/1.iGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6419bd0f-e63b-4f8b-aa00-d55a8f26c3a2",
        "ParentId": "9a358e5d-de95-4de5-a4f9-d28a263651bb",
        "Name": "iGW",
        "Description": "HR_iGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/1.iGW/iGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "2.CSCORE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d916a90c-2449-4df9-b03a-ca6b1cd64e82",
        "ParentId": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "Name": "1.MSC",
        "Description": "cscore_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE/1.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13d744f8-7782-41a1-aa7d-e0e936f8381a",
        "ParentId": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "Name": "2.HLR",
        "Description": "cscore_hlr",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE/2.HLR",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "80309903-ffe6-403a-acb0-b37359a031d5",
        "ParentId": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "Name": "3.UTIL TRUNK",
        "Description": "cscore_ultitrunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE/3.UTIL TRUNK",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38f001ea-61dc-474d-8e9d-c9b91d163c9f",
        "ParentId": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "Name": "4.UTIL Link TDM",
        "Description": "cscore_ultitrunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE/4.UTIL Link TDM",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f080c5f3-abd4-4316-87fd-60ca860ea602",
        "ParentId": "9cde7472-6338-4dbd-853a-5c2c836f5abe",
        "Name": "5.UTIL Link SIGTRAN",
        "Description": "cscore_ultitrunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/2.CSCORE/5.UTIL Link SIGTRAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d358282-1010-40f7-9ceb-dad052b964fe",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "3.SGSN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/3.SGSN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a6e591fa-339a-4e27-acc2-95d56b3b95ab",
        "ParentId": "3d358282-1010-40f7-9ceb-dad052b964fe",
        "Name": "1.Overview",
        "Description": "pscore_sgsn_overview",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/3.SGSN/1.Overview",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6c984a0-7bbf-4698-9fbc-170b04595a10",
        "ParentId": "3d358282-1010-40f7-9ceb-dad052b964fe",
        "Name": "2.Detail",
        "Description": "pscore_sgsn_detail",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/3.SGSN/2.Detail",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5576b40c-568e-4a99-97c4-2c3b35d3ab3a",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "4.GGSN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/4.GGSN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4fbfc6c-6108-49ab-b797-ce480c78c641",
        "ParentId": "5576b40c-568e-4a99-97c4-2c3b35d3ab3a",
        "Name": "GGSN",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/4.GGSN/GGSN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9cb2d50-5ef0-4890-89dd-9b96846756f1",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "5.DEA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.DEA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1528ae35-c324-427e-8c60-1764132436aa",
        "ParentId": "c9cb2d50-5ef0-4890-89dd-9b96846756f1",
        "Name": "1.DEA",
        "Description": "bcn_dea",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.DEA/1.DEA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5af3c1d8-19e8-4b8e-a729-369a69989fb8",
        "ParentId": "c9cb2d50-5ef0-4890-89dd-9b96846756f1",
        "Name": "2.Chart",
        "Description": "bcn_dea_chart",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.DEA/2.Chart",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b86b9a87-951c-4fd8-a73a-c0cfe6c5704e",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "5.VAS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f475aeef-83f1-43d5-b3cb-8c24e675fb4d",
        "ParentId": "b86b9a87-951c-4fd8-a73a-c0cfe6c5704e",
        "Name": "1.CRBT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/1.CRBT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27a47e24-41e4-44d8-81be-056f7d522012",
        "ParentId": "f475aeef-83f1-43d5-b3cb-8c24e675fb4d",
        "Name": "1.General",
        "Description": "bcn_vas_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/1.CRBT/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "afe35124-fefa-4bf0-afb4-852934af77a7",
        "ParentId": "f475aeef-83f1-43d5-b3cb-8c24e675fb4d",
        "Name": "2.Performance",
        "Description": "bcn_vas_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/1.CRBT/2.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "644bee51-ffae-447d-bf1e-dc06a5f31588",
        "ParentId": "b86b9a87-951c-4fd8-a73a-c0cfe6c5704e",
        "Name": "2.SMSCB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/2.SMSCB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d268306-2b58-4d4d-a8cf-cb9228cbed77",
        "ParentId": "644bee51-ffae-447d-bf1e-dc06a5f31588",
        "Name": "1.General",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/2.SMSCB/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55560804-026e-4296-a776-19d2c57a532d",
        "ParentId": "b86b9a87-951c-4fd8-a73a-c0cfe6c5704e",
        "Name": "3.SMSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/3.SMSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9242948-7e7e-46ff-a47f-b9b92ddf7576",
        "ParentId": "55560804-026e-4296-a776-19d2c57a532d",
        "Name": "1.General",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/3.SMSC/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab2f35fd-b6a2-47c6-9fe7-d5c3d5f97a24",
        "ParentId": "b86b9a87-951c-4fd8-a73a-c0cfe6c5704e",
        "Name": "USSDGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/USSDGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fdba2bcc-db54-4fbc-a760-b71b5c7ae9cb",
        "ParentId": "ab2f35fd-b6a2-47c6-9fe7-d5c3d5f97a24",
        "Name": "1.General",
        "Description": "bcn_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/USSDGW/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f538fce-fe36-4188-9f8d-3f7d3d09c075",
        "ParentId": "ab2f35fd-b6a2-47c6-9fe7-d5c3d5f97a24",
        "Name": "2.Performance",
        "Description": "bcn_vas_ussdgw_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/USSDGW/2.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4355685-7be5-45c4-9ce8-463945979844",
        "ParentId": "ab2f35fd-b6a2-47c6-9fe7-d5c3d5f97a24",
        "Name": "3.CHART",
        "Description": "bcn_vas_ussdgw_chart",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/5.VAS/USSDGW/3.CHART",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed6db744-37a8-43ce-af82-4643fbac6019",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "51.IN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/51.IN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5015e763-b574-4257-a4eb-e048dd28b573",
        "ParentId": "ed6db744-37a8-43ce-af82-4643fbac6019",
        "Name": "1.General",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/51.IN/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef9859c3-7faa-4273-bf5c-b44742fa52d6",
        "ParentId": "ed6db744-37a8-43ce-af82-4643fbac6019",
        "Name": "1.IN-QoS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/51.IN/1.IN-QoS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15b3e1ee-5b28-4b9c-9314-3f1d5d457e71",
        "ParentId": "ef9859c3-7faa-4273-bf5c-b44742fa52d6",
        "Name": "1.General",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/51.IN/1.IN-QoS/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a859dd70-16ec-4efd-8807-0f8773570fc5",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "6.RAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a61106d-6865-4072-98da-99a2fca82de1",
        "ParentId": "a859dd70-16ec-4efd-8807-0f8773570fc5",
        "Name": "1.RAN2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/1.RAN2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a062e85-adb2-4827-9dfd-632b52df33cf",
        "ParentId": "0a61106d-6865-4072-98da-99a2fca82de1",
        "Name": "1.Cell",
        "Description": "ran-bcn-ran2g-cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/1.RAN2G/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f284ef1-73bb-4760-a5fe-9842e59281f2",
        "ParentId": "0a61106d-6865-4072-98da-99a2fca82de1",
        "Name": "2.Site",
        "Description": "ran-bcn-ran2g-site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/1.RAN2G/2.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20972b74-21c6-477f-b0a8-02f45c0aa146",
        "ParentId": "0a61106d-6865-4072-98da-99a2fca82de1",
        "Name": "3.BSC",
        "Description": "ran-bcn-ran2g-bsc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/1.RAN2G/3.BSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a56823ae-430a-4da4-b70f-d937fa8e3dd5",
        "ParentId": "a859dd70-16ec-4efd-8807-0f8773570fc5",
        "Name": "2.RAN3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/2.RAN3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34a98961-60dd-40ee-8a43-74d07036f7f2",
        "ParentId": "a56823ae-430a-4da4-b70f-d937fa8e3dd5",
        "Name": "1.CellB",
        "Description": "ran-bcn-ran3g-CellB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/2.RAN3G/1.CellB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e720e6af-f584-4c8e-90e1-42170683393e",
        "ParentId": "a56823ae-430a-4da4-b70f-d937fa8e3dd5",
        "Name": "2.SiteB",
        "Description": "ran-bcn-ran3g-SiteB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/2.RAN3G/2.SiteB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3489a04b-2686-4fa9-8204-51c4d69013af",
        "ParentId": "a56823ae-430a-4da4-b70f-d937fa8e3dd5",
        "Name": "3.RNC",
        "Description": "ran-bcn-ran3g-rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/2.RAN3G/3.RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f98b749f-2d7c-4f37-99fd-5e69365960d8",
        "ParentId": "a859dd70-16ec-4efd-8807-0f8773570fc5",
        "Name": "3.RAN4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/3.RAN4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce491cc0-ae48-40b3-8adb-3cdf3e260226",
        "ParentId": "f98b749f-2d7c-4f37-99fd-5e69365960d8",
        "Name": "1.EUtrancell",
        "Description": "ran-bcn-ran4g-EUtrancell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/3.RAN4G/1.EUtrancell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "575dba9d-5c55-41c1-a14e-acdc85a7d69e",
        "ParentId": "f98b749f-2d7c-4f37-99fd-5e69365960d8",
        "Name": "2.eNodeB",
        "Description": "rran-bcn-ran4g-eNodeB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/3.RAN4G/2.eNodeB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24de315e-9bb2-451f-be38-514dd32140e0",
        "ParentId": "a859dd70-16ec-4efd-8807-0f8773570fc5",
        "Name": "4.All",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/4.All",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "364f0f79-fd2e-48d2-87a6-2bb68b29447f",
        "ParentId": "24de315e-9bb2-451f-be38-514dd32140e0",
        "Name": "1.TTML",
        "Description": "ran-bcn-all-ttml",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/4.All/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b946b882-050c-4388-a828-ef9b859d8525",
        "ParentId": "24de315e-9bb2-451f-be38-514dd32140e0",
        "Name": "2.Province",
        "Description": "ran-bcn-all-province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/4.All/2.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cfa013b4-9743-422c-bcca-6bf43d5352f8",
        "ParentId": "24de315e-9bb2-451f-be38-514dd32140e0",
        "Name": "3.District",
        "Description": "ran-bcn-all-district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/6.RAN/4.All/3.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "ParentId": "46a8e1e2-150b-40bf-946f-6fd766c83e3e",
        "Name": "7.Truyền Dẫn",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6f01cf4-7132-46b6-bebb-37306cde53a4",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "1.Internet",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/1.Internet",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9b1cd7d4-d33e-4d48-b581-43541f7dc514",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "2.Liên Vùng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/2.Liên Vùng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7086ed2c-467d-4818-9f78-d6bca7591543",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "3.Liên Tỉnh",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/3.Liên Tỉnh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13a3f9be-66fd-4263-863f-5a430d36e461",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "4.TWAMP_IPBB_LIENTINH",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/4.TWAMP_IPBB_LIENTINH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9df701a8-dff4-4417-8b3a-64cdfe94416c",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "5.TWAMP_METRO",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/5.TWAMP_METRO",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "284f3793-3159-49c1-b391-fcab38059a5f",
        "ParentId": "190f53ee-fafb-4f41-a3f7-847bfd791790",
        "Name": "7.TWAMP_RAN4G",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/10.BC nhanh/7.Truyền Dẫn/7.TWAMP_RAN4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e8e915b-0b57-4a43-9b24-cdda60b5f8f3",
        "ParentId": "5556f413-0ae9-45e4-b126-4d98f270cf14",
        "Name": "1.Ngày",
        "Description": "21trongdiem",
        "TypeName": "Report",
        "Path": "/noc-sopirs/11.21 tỉnh trọng điểm/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "feaa3602-a397-445b-8999-4081ba4d6d37",
        "ParentId": "5556f413-0ae9-45e4-b126-4d98f270cf14",
        "Name": "2.Tuần",
        "Description": "21trongdiem",
        "TypeName": "Report",
        "Path": "/noc-sopirs/11.21 tỉnh trọng điểm/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "82601630-8f33-4803-a92a-b6956fe1850c",
        "ParentId": "5556f413-0ae9-45e4-b126-4d98f270cf14",
        "Name": "3.Tháng",
        "Description": "21trongdiem",
        "TypeName": "Report",
        "Path": "/noc-sopirs/11.21 tỉnh trọng điểm/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "12.DASHBOARD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/12.DASHBOARD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "15.ran2g",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ddf0878-4a6f-49be-9755-eae58e3532fd",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "ParentId": "2ddf0878-4a6f-49be-9755-eae58e3532fd",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13ef28ec-1392-4996-8821-dc409e47d3d1",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "1.Giờ",
        "Description": "HR_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26f01545-e64f-4783-b058-610ec586d7d2",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94098009-d886-45c1-9d29-247f404f6f10",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da5167c2-f44d-4793-8cf8-bfe06d5ea2cd",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78e8604e-30f5-4327-b6f3-a5881c90df4a",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "603f595a-49c5-476c-93cf-240284b8fd29",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3148f557-25ef-499a-829b-4bf469605627",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "7.BH",
        "Description": "DY_Cell2g_gen_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "88aaf459-b6bb-44a9-b48f-2213319ca341",
        "ParentId": "082a23df-83d8-4fad-a679-eb95e7010eb9",
        "Name": "Badcell",
        "Description": "ran-bcn-ran2g-cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/1.General/Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1f431ae-9083-44bb-ac59-eee570b1872e",
        "ParentId": "079250c8-5b95-41c0-a03b-ec032d760d7b",
        "Name": "2.Ngày",
        "Description": "DY_Cell2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "870cb481-b836-4b49-bab0-8a88b3ff5613",
        "ParentId": "079250c8-5b95-41c0-a03b-ec032d760d7b",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f222f459-5deb-4e7d-b089-d05eaad0d5d5",
        "ParentId": "079250c8-5b95-41c0-a03b-ec032d760d7b",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "412c6f6b-8cde-4afe-baf9-0336b5dcf99f",
        "ParentId": "079250c8-5b95-41c0-a03b-ec032d760d7b",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e578e2d0-85a7-4ebc-a566-3f82753663df",
        "ParentId": "079250c8-5b95-41c0-a03b-ec032d760d7b",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58b1d230-2b3c-4bda-9f48-6b70e619319e",
        "ParentId": "8d128f30-9e8b-4ad0-8785-c240e0ec5d4d",
        "Name": "2.Ngày",
        "Description": "DY_Cell2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9877ea53-e31c-4f3d-be85-172e36a81f5c",
        "ParentId": "8d128f30-9e8b-4ad0-8785-c240e0ec5d4d",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef88515f-2a94-401c-ae07-705d2534c02c",
        "ParentId": "8d128f30-9e8b-4ad0-8785-c240e0ec5d4d",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "001aaa27-ff0f-40f4-9215-95f3cb5df15a",
        "ParentId": "8d128f30-9e8b-4ad0-8785-c240e0ec5d4d",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95cf2369-81c4-4689-ae97-aa77324b0c15",
        "ParentId": "8d128f30-9e8b-4ad0-8785-c240e0ec5d4d",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1047ae8d-b06e-430e-ada8-4e71c2209987",
        "ParentId": "1b9cfda8-e703-4bae-a2bb-711800e9d071",
        "Name": "2.Ngày",
        "Description": "DY_Cell2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/5.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1662ae2-6ead-4bed-855d-d0359c393510",
        "ParentId": "1b9cfda8-e703-4bae-a2bb-711800e9d071",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/5.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4a5a458-d862-4d6a-961c-017a50b53ba6",
        "ParentId": "1b9cfda8-e703-4bae-a2bb-711800e9d071",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/5.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44ab96cf-b04a-447c-8139-de8fb34f0948",
        "ParentId": "1b9cfda8-e703-4bae-a2bb-711800e9d071",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/5.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "014a89be-deeb-4031-8448-97da9e8563b6",
        "ParentId": "1b9cfda8-e703-4bae-a2bb-711800e9d071",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/5.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e732b76e-b25d-46e0-976f-d81a624bc006",
        "ParentId": "e1e99611-e215-465f-8533-641b62996184",
        "Name": "2.Ngày",
        "Description": "DY_Cell2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/6.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79ab62ca-ec60-4a1d-a9f5-d729c0583328",
        "ParentId": "e1e99611-e215-465f-8533-641b62996184",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/6.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31068e77-8e8b-4d4e-b7a2-a94fcb266fc4",
        "ParentId": "e1e99611-e215-465f-8533-641b62996184",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/6.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b27341ee-1c90-4880-a54b-c5f3c5b5473a",
        "ParentId": "e1e99611-e215-465f-8533-641b62996184",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/6.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "843c7266-848d-480e-b355-a02ec0d6854c",
        "ParentId": "e1e99611-e215-465f-8533-641b62996184",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/6.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32ff5de8-6e1b-4a86-8cc7-c493a84a8388",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "1.Giờ",
        "Description": "HR_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb4ee972-686d-4e4f-b6e2-628d70fa3311",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "2.Ngày",
        "Description": "DY_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8071d0ea-a43b-4a6b-a0f7-b3597997b2b3",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "3.Tuần",
        "Description": "WK_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ada34fb-e73b-4228-9f4d-6ab33965f2dc",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "4.Tháng",
        "Description": "MN_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3396afd6-0d7f-4bf7-b375-c2f9270abaae",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "5.Quý",
        "Description": "QR_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5406e70-9cc0-4f76-a919-89c9f4a896ee",
        "ParentId": "321de8f5-dec3-4390-9a20-a9459f4566fe",
        "Name": "6.Năm",
        "Description": "YR_Cell2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/7.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28029ee2-8102-4d88-a64f-742b61fa24bf",
        "ParentId": "2ddf0878-4a6f-49be-9755-eae58e3532fd",
        "Name": "8.Cell with no Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/8.Cell with no Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa6ab09e-d3de-41a2-b59b-ce39363bcd7c",
        "ParentId": "28029ee2-8102-4d88-a64f-742b61fa24bf",
        "Name": "1.Ngày",
        "Description": "TKLL_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/1.Cell/8.Cell with no Traffic/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75578905-a589-45c3-91be-aa21dad02241",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "2.BTS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/2.BTS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "ParentId": "75578905-a589-45c3-91be-aa21dad02241",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd660eb5-bfe8-4064-8a88-f79e99d0cd18",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "1.Giờ",
        "Description": "HR_Cell2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9afe64e9-08de-40f5-8221-0fbb8138bd87",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BTS2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a216c651-f3e7-40bb-b44f-58269834fad9",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1202c4eb-019f-4c00-99b0-239b5442bda9",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5dd3138-d6da-42be-9700-608d261305b2",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39807b91-cfa9-45b7-99d1-c06035980fdc",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34fe8122-8ddd-4281-b92e-a578976d475c",
        "ParentId": "005d9a20-e3b4-4682-a5ca-d0915d921069",
        "Name": "Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/1.General/Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a306a1a-7354-496c-8074-552a33721fb2",
        "ParentId": "65086a1a-0427-4235-8f17-064e5107863c",
        "Name": "2.Ngày",
        "Description": "DY_BTS2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b3218b5-8817-4dcc-b8e5-53598dee27b4",
        "ParentId": "65086a1a-0427-4235-8f17-064e5107863c",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3b50d33-5aa9-4328-b2e1-013719d6076b",
        "ParentId": "65086a1a-0427-4235-8f17-064e5107863c",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d89ad17-22d2-4b5c-b950-69e9299ada5c",
        "ParentId": "65086a1a-0427-4235-8f17-064e5107863c",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8c7c4b4-d280-484c-aebf-ee0307294923",
        "ParentId": "65086a1a-0427-4235-8f17-064e5107863c",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37debfc0-b981-42b0-b825-bc20871ec1d6",
        "ParentId": "c168352e-9618-4825-92c5-79eed8e0e072",
        "Name": "2.Ngày",
        "Description": "DY_BTS2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68dd161b-65d4-4ef2-93c6-b4419fafb547",
        "ParentId": "c168352e-9618-4825-92c5-79eed8e0e072",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0c8f9aab-6154-42e7-92d0-b99f334cf97d",
        "ParentId": "c168352e-9618-4825-92c5-79eed8e0e072",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9be10877-c9d1-465c-9d19-956a5893c5ac",
        "ParentId": "c168352e-9618-4825-92c5-79eed8e0e072",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ea7d2233-426d-4727-a8a1-3f8d9bf3c36f",
        "ParentId": "c168352e-9618-4825-92c5-79eed8e0e072",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b3e61ba-f3d0-449b-a353-5674ffa8249c",
        "ParentId": "09ff86ca-d0e4-43fa-a6d5-ff23357941dd",
        "Name": "2.Ngày",
        "Description": "DY_BTS2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/4.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f920d79-ab17-45ac-8921-967d14e1304e",
        "ParentId": "09ff86ca-d0e4-43fa-a6d5-ff23357941dd",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/4.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd910212-43b2-4fa3-9c71-e279bf2e8377",
        "ParentId": "09ff86ca-d0e4-43fa-a6d5-ff23357941dd",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/4.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c086dc9-9424-426b-9d4e-d9e57acc9ecc",
        "ParentId": "09ff86ca-d0e4-43fa-a6d5-ff23357941dd",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/4.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b50d6023-1944-403f-a98f-4c4cc346ae88",
        "ParentId": "09ff86ca-d0e4-43fa-a6d5-ff23357941dd",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/4.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ec60648-1942-4090-bc20-35f4d3939d35",
        "ParentId": "cafd03dc-87a6-4e49-af0e-b930a3749299",
        "Name": "2.Ngày",
        "Description": "DY_BTS2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/5.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "514ff3a9-96e4-4f2e-b272-12780349719b",
        "ParentId": "cafd03dc-87a6-4e49-af0e-b930a3749299",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/5.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56145b1b-e730-4d81-ae55-e04410b1b79a",
        "ParentId": "cafd03dc-87a6-4e49-af0e-b930a3749299",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/5.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f611cd95-f11c-414a-8b7c-09a7a7165033",
        "ParentId": "cafd03dc-87a6-4e49-af0e-b930a3749299",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/5.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d0fec24-c4ca-4f6c-ab02-851689f0b7ab",
        "ParentId": "cafd03dc-87a6-4e49-af0e-b930a3749299",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/5.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "981262df-70f4-43e7-935a-b95e80895b47",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "1.Giờ",
        "Description": "HR_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99c44234-e7c8-44aa-a7dd-787614ebe7ae",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "2.Ngày",
        "Description": "DY_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c780e57-812f-4f90-a80e-b567980a4873",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "3.Tuần",
        "Description": "WK_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bedee30-f625-4264-8088-1c282545e09d",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "4.Tháng",
        "Description": "MN_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f81017f-b164-4d63-9f4a-0c21889ff5fd",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "5.Quý",
        "Description": "QR_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbca9653-cb99-4ce5-810d-1ac182068c6c",
        "ParentId": "2511461d-cfa6-4785-b603-2cb668d2d6ec",
        "Name": "6.Năm",
        "Description": "YR_BTS2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/2.BTS/Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "3.BSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "63a24121-7641-4848-b331-1d06e088449f",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "1.Giờ",
        "Description": "HR_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a672bedf-0dfe-4cb8-b40e-367e5284ee0b",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "2.Ngày",
        "Description": "DY_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a58c450-55d0-4f0f-b529-5131c0e7e2ff",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21506757-7f4c-411c-9fe2-7aa23371a839",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "caad5ed5-282a-46e0-9541-94fa64edaca7",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e39668cf-9ea8-4603-a807-3f6cd7695aff",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9c814e6-aa88-4a1a-9aa7-1ba58651481b",
        "ParentId": "a7bb2da7-384d-4c29-a019-f34c9978a9ef",
        "Name": "7.BH",
        "Description": "DY_BSC2g_gen_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/1.General/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "2.Accessibility",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8410c71c-119f-48f0-b80f-6e6ffef910c8",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "1.Giờ",
        "Description": "DY_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1119cd4b-05be-4df0-a881-b7a7df464442",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20107251-753c-4056-a2b6-ea91001edf08",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ec7d16a-9543-4fe7-a623-53b68d18ef7c",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19392d3b-b074-470d-b9bd-7f82bcb0d5f9",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17bb4a4f-84b4-4310-ad61-fab667943890",
        "ParentId": "14854ab1-c3bf-458d-aa90-84c6a03d8302",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "3.Availability",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6512123-97bd-4370-a852-24af48146acd",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "1.Giờ",
        "Description": "HR_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "67c8dc79-91e4-4ccf-9362-cbbca782bd0d",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f27af8e0-bb17-4020-87d0-c0b5a24399e8",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd58776d-0459-4eb2-b635-c0c54eb57a90",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "10ff4b0d-e2f9-487a-ba94-2cd70d82af91",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e7e79e7-97fc-430a-8ead-f06b5f753b8e",
        "ParentId": "f3215818-5ce6-4aca-af0d-3cc18b7f6bd9",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "4.Mobility",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9f70572-61fc-4cc4-8af3-f969aa52b92f",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "1.Giờ",
        "Description": "HR_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d3d35f5-e009-4e2d-8b91-f31cfaf9ef58",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "906934b3-b21f-4bbb-b3f9-ef4e0933ffaf",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7fb1586-5eb3-4208-b539-13a65b73258e",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ca70812-1269-4977-8900-1799e96a3e90",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "299cd741-3b9d-4a05-9eb7-2b4bd8b8e479",
        "ParentId": "46bebe6b-088b-44c6-b98b-3effd8049230",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/4.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "5.Retainability",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4ec3e20-2eba-4ab6-a6a7-db314df17e6d",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "1.Giờ",
        "Description": "HR_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "53eeec13-ed02-4c86-b20f-319c75aeef86",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca86acb9-e8a8-49ea-93e1-9f9338f24bde",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "257cb8d9-6996-41db-bf4c-e8a09d420148",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4bd65e85-82bd-4a31-ae9f-a75be92da5e1",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17c656f9-6e90-465d-b918-d091a3bb5ea0",
        "ParentId": "3b8bc70c-def9-49d6-ac94-f9b80dea0f71",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/5.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "ParentId": "22af67f5-9a9e-44ac-9e10-c20ea9ae38ee",
        "Name": "6.Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1fee37fb-f4a1-455a-9c78-316e50882e63",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "1.Giờ",
        "Description": "HR_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6e7e79d-c3cb-44aa-bced-9907caf9bce7",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20239dcc-75cc-4d57-8f9b-04e0b253a95b",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "3.Tuần",
        "Description": "WK_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3af71e83-dcb5-4778-8b27-b08834893951",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "4.Tháng",
        "Description": "MN_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd0de55d-468f-40c0-9de3-4fff607c7b17",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "5.Quý",
        "Description": "QR_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffcbb3d5-db97-4a1e-9d6b-0c2e8e6ba5ba",
        "ParentId": "ce6f23b2-18ca-4910-a711-ab2e8041d079",
        "Name": "6.Năm",
        "Description": "YR_BSC2g_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/3.BSC/6.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "4.ToVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e873c901-9eba-49d0-b8df-2aa805bae923",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73afd451-729a-44f0-9cf8-104fa85ae9d0",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "2.Tuần",
        "Description": "WK_TVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64f4cde3-c561-4253-9b44-0b397bfde435",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "3.Tháng",
        "Description": "MN_TVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bef2038f-ea2a-412e-8fa1-0787aefe3e57",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "4.Quý",
        "Description": "QR_TVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce8326e9-303b-4f66-99d8-4576f7963b7c",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "5.Năm",
        "Description": "YR_TVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d8a474c-79d6-4f71-88ee-ce1bf68fb795",
        "ParentId": "967edd45-013b-4822-9cd7-b52d44d6f6b4",
        "Name": "6.BH",
        "Description": "DY_ToVT_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/4.ToVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "5.DaiVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b0b42202-c5b9-4d11-ac20-584f356a669f",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ba01c3dd-e084-428e-a575-7157cf213df6",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "2.Tuần",
        "Description": "WK_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d60b5145-a04e-411e-be29-242fc6c89d8c",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "3.Tháng",
        "Description": "MN_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "be86f994-e480-40c7-94bd-158ca79d2fc9",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "4.Quý",
        "Description": "QR_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1232276c-8ac8-483e-84a2-f884991361d6",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "5.Năm",
        "Description": "YR_DVT2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32268708-33fe-4d2d-b93e-0b10dd712754",
        "ParentId": "7b6575f2-330f-4967-b492-1cccea39f5de",
        "Name": "6.BH",
        "Description": "DY_DVT2G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/5.DaiVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "6.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/6.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1709859-edf7-4a87-b056-63dcf8cfc837",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_District2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c69c83d-f9b2-4d74-8ff4-e4f0b5f913b9",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "2.Tuần",
        "Description": "WK_District2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68481b39-dbc7-4038-b040-917a35728613",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "3.Tháng",
        "Description": "MN_District2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5736938e-798c-4e25-8aeb-35420a242cc9",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "4.Quý",
        "Description": "QR_District2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24b47ca6-1a66-4c55-9cdc-8741caea2582",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "5.Năm",
        "Description": "YR_District2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b46ced99-1510-4abf-89d3-aab18b5bae9f",
        "ParentId": "7d1b146e-0581-437a-99bb-c570adb7ad8e",
        "Name": "6.BH",
        "Description": "DY_District2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/6.District/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "7.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/7.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1b6f6b5-df61-4ed8-bde8-3453f1294288",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_Province2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "366b98dc-21d1-49a8-9b73-26bdaa4eb4aa",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "2.Tuần",
        "Description": "WK_Province2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "145cfa1a-1f46-4753-bd7b-a1e0a7134859",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "3.Tháng",
        "Description": "MN_Province2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f09daf2c-e4e2-47bb-8aa4-16a834feaa45",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "4.Quý",
        "Description": "QR_Province2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa60a200-f9f9-41ec-93ff-2b4c074f7876",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "5.Năm",
        "Description": "YR_Province2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42644049-5f72-4877-adbc-a606ef1b25e8",
        "ParentId": "5ad1ae79-a79d-4dae-8c6b-0e65f1be08c5",
        "Name": "6.BH",
        "Description": "DY_Province2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/7.Province/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "71.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/71.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d897a98c-5fc1-4b50-802c-d7cd80b021f8",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "1. Từ ngày đến ngày",
        "Description": "DY_branch2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/1. Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6fcce3fb-7ed5-45df-b691-ce98cd31291e",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "2.Tuần",
        "Description": "WK_Branch2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "60bd6870-23e8-4ab9-9c96-46406267e299",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "3.Tháng",
        "Description": "MN_Branch2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "325895ad-8352-48a6-b3ab-fb936e7dedb1",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "4.Quý",
        "Description": "QR_Branch2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c639c300-c35c-4d68-b0a5-576d4bc290e9",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "5.Năm",
        "Description": "YR_Branch2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c829b07-d7b5-4588-9839-8133303fa44d",
        "ParentId": "c98e7d3a-1fcb-4f9a-8d56-8a294fd8a17c",
        "Name": "6.BH",
        "Description": "DY_Branch2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.Branch/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "71.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4488dc13-e670-4432-b594-773ac29e6327",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_CTKD2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0dcd97bf-9283-4652-b72d-68ec46560dbc",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "2.Tuần",
        "Description": "WK_CTKD2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13209935-62f5-43f1-b888-eaca1a5ed999",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "3.Tháng",
        "Description": "MN_CTKD2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66e5a23f-e01e-4e79-910c-e4ef414b3892",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "4.Quý",
        "Description": "QR_CTKD2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9538d3d5-f927-4211-80ba-efe8196db459",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "5.Năm",
        "Description": "YR_CTKD2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "96f62408-a1ef-426d-b775-e5ffe9085d37",
        "ParentId": "c8e002c3-1b80-40f0-b7c0-fa995f0f8f37",
        "Name": "6.BH",
        "Description": "DY_CTKD2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/71.CTKD/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "8.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/8.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cde6e6b3-ac2b-478b-874f-ad059a28309f",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "1.Từ này đến ngày",
        "Description": "DY_TTML2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/1.Từ này đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa9228cd-9cc1-42ab-b71e-f83393be8a1c",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "2.Tuần",
        "Description": "WK_TTML2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a565ed5-5078-4db7-bea3-49c08a95b405",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "3.Tháng",
        "Description": "MN_TTML2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26e9d71f-a4d2-4d1c-aa8f-e6de9801cac8",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "4.Quý",
        "Description": "QR_TTML2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "57c03c0e-1483-4ca8-b037-79c4c3c2e9a5",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "5.Năm",
        "Description": "YR_TTML2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbb2c906-f48f-47cf-b9da-3df73e1e81ea",
        "ParentId": "22f12122-8bb0-4bc1-b977-3261581736c1",
        "Name": "6.BH",
        "Description": "DY_TTML2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/8.TTML/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "9.Vendor",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19314a26-cb8a-4ad8-b6dd-375a220b5a7f",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "1.Ngày",
        "Description": "DY_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37710bf8-13ca-439a-a103-e126ae6dd411",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "078674c1-bb5f-4234-bc19-2e6311062d10",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "2.Tuần",
        "Description": "WK_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "485c148c-fdd7-4e8f-88df-66fb1cd2e2a2",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "3.Tháng",
        "Description": "MN_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0234dce5-e3e7-417a-ac8f-ba2166717d13",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "4.Quý",
        "Description": "QR_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9531ca49-14e5-444b-b644-051c86c69bae",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "5.Năm",
        "Description": "YR_Vendor2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "914979df-5ea9-44c8-990f-0e0dd9f5365b",
        "ParentId": "15826cb2-cac2-453d-a7e5-99e1f302483e",
        "Name": "6.BH",
        "Description": "DY_Vendor2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/9.Vendor/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "91.IBC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/91.IBC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58af4559-9cc1-4afc-aecc-4e1036622398",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "1.Ngày",
        "Description": "DY_IBC2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd60d865-2270-4a47-bf12-a2debb40cc66",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "2.Tuần",
        "Description": "WK_IBC2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9b718a4-e17b-41b4-9715-5187229ed34e",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "3.Tháng",
        "Description": "MN_IBC2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05f535ec-e938-4307-9630-aa7d775c611b",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "4.Quý",
        "Description": "QR_IBC2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b57b3cd-c6e6-4f69-b410-2055ac150ac7",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "5.Năm",
        "Description": "YR_IBC2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "924b7f1d-0003-4169-abe7-13a9c7829e37",
        "ParentId": "91a5436a-cfc3-4ce8-8cfc-4667a4e8059f",
        "Name": "6.BH",
        "Description": "DY_IBC_TTML2g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/91.IBC/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7c5abf7-4902-485d-bead-a1220d683dac",
        "ParentId": "a88a0ca9-38c6-4d89-ac40-73464da4dce7",
        "Name": "92.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/15.ran2g/92.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54bc35f7-39d7-4a47-822d-be5be0477c42",
        "ParentId": "d7c5abf7-4902-485d-bead-a1220d683dac",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/15.ran2g/92.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "2.ran3g",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "541f6293-572a-4127-a58d-7a9e1d2a4fe6",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "1.CellB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/1.CellB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "ParentId": "541f6293-572a-4127-a58d-7a9e1d2a4fe6",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3ee4ef6-adcb-4218-aee7-68144bf09b19",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "1.Giờ",
        "Description": "HR_CellB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f31ad4a-4665-4cd1-b582-a72591335e4a",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_CellB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7250ab87-c27b-4b0c-8f34-939fb1b039c9",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "3.Tuần",
        "Description": "WK_CellB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ddd2b3a7-0f57-4c97-b440-7e6fda1cf045",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "4.Tháng",
        "Description": "MN_CellB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5236ad57-3394-41fd-91b6-37d4e08a5d5a",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "5.Quý",
        "Description": "QR_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02592177-9ec3-43f4-8906-359d5857355b",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "6.Năm",
        "Description": "QR_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e19ca2f-4355-489d-a73b-ea656ce8f673",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "7.BH",
        "Description": "DY_CellB_gen_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f63e36b9-dfce-4047-952b-c6209b0e883b",
        "ParentId": "c970c1bd-2211-4050-8ced-7d4763fb6d50",
        "Name": "Badcell",
        "Description": "ran-bcn-ran3g-CellB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/1.General/Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37f31c41-9987-46b3-9ad4-75f54927b0e5",
        "ParentId": "f9bbb527-2307-400d-a31f-a42537e93ef7",
        "Name": "2.Ngày",
        "Description": "DY_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b025109-982f-45ef-9b8b-72f1be878cce",
        "ParentId": "f9bbb527-2307-400d-a31f-a42537e93ef7",
        "Name": "3.Tuần",
        "Description": "WK_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbaecfe2-7043-4257-be6c-6b1851de27d1",
        "ParentId": "f9bbb527-2307-400d-a31f-a42537e93ef7",
        "Name": "4.Tháng",
        "Description": "MN_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a1b18cb1-c8c4-46e8-83be-3c1116aebe2d",
        "ParentId": "f9bbb527-2307-400d-a31f-a42537e93ef7",
        "Name": "5.Quý",
        "Description": "QR_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d5a686c-a9c6-41da-b36d-1049bcf88bac",
        "ParentId": "f9bbb527-2307-400d-a31f-a42537e93ef7",
        "Name": "6.Năm",
        "Description": "YR_CellB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b9cd244-45e4-4698-8605-ccc1105df39b",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "1.Giờ",
        "Description": "HR_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9193cc7-af8a-4969-bc5b-a575fa8b0deb",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "2.Ngày",
        "Description": "DY_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4fe6476-3114-4ad9-bb8f-1d58acecad59",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "3.Tuần",
        "Description": "WK_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b95505d3-ee7e-4b22-8320-404348fc553b",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "4.Tháng",
        "Description": "MN_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9cf90ced-17d0-48fa-889e-3d60c0a259a0",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "5.Quý",
        "Description": "QR_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0e8d5d3-85d0-40b5-8762-894e3dc3e298",
        "ParentId": "7335f5ee-e6d4-4675-aed7-d28e2fe4186b",
        "Name": "6.Năm",
        "Description": "YR_CellB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "145824d2-cdfc-4386-83a2-d119864a7776",
        "ParentId": "8f160dee-a5a5-424f-b93e-b95bc00d0d68",
        "Name": "2.Ngày",
        "Description": "DY_CellB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/5.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a141a18-1827-449c-a1f6-6276bd0e6197",
        "ParentId": "8f160dee-a5a5-424f-b93e-b95bc00d0d68",
        "Name": "3.Tuần",
        "Description": "WK_CellB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/5.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "235e53f1-cd3d-44d8-a84f-d0c64cab4b5b",
        "ParentId": "8f160dee-a5a5-424f-b93e-b95bc00d0d68",
        "Name": "4.Tháng",
        "Description": "MN_CellB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/5.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e554898-3ea0-4dbb-aaab-6280097d85e6",
        "ParentId": "8f160dee-a5a5-424f-b93e-b95bc00d0d68",
        "Name": "5.Quý",
        "Description": "QR_CellB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/5.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c3f68e7-7ef1-41b9-ae26-1a61af01b400",
        "ParentId": "8f160dee-a5a5-424f-b93e-b95bc00d0d68",
        "Name": "6.Năm",
        "Description": "YR_CellB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/5.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4061c5c-cd87-4fd8-91d8-d13495562078",
        "ParentId": "91c82259-2c07-4235-b10d-707c192e1fc3",
        "Name": "2.Ngày",
        "Description": "DY_CellB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/6.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b740e5bd-0b72-4635-b5fc-68f0470ad5fe",
        "ParentId": "91c82259-2c07-4235-b10d-707c192e1fc3",
        "Name": "3.Tuần",
        "Description": "WK_CellB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/6.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c73b73e8-6894-42ba-8190-a342872d85a1",
        "ParentId": "91c82259-2c07-4235-b10d-707c192e1fc3",
        "Name": "4.Tháng",
        "Description": "MN_CellB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/6.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c1da831-d698-406d-9c56-a37ed301b2b4",
        "ParentId": "91c82259-2c07-4235-b10d-707c192e1fc3",
        "Name": "5.Quý",
        "Description": "QR_CellB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/6.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a7c6891-8d00-4805-ab88-2e598de2d0d7",
        "ParentId": "91c82259-2c07-4235-b10d-707c192e1fc3",
        "Name": "6.Năm",
        "Description": "YR_CellB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/6.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "adbba27a-6782-467e-a0ef-4a8d706d4ce6",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "1.Giờ",
        "Description": "HR_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c42de9f8-e190-400b-a32f-fa397799e4e4",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "2.Ngày",
        "Description": "DY_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77b29b68-4a8f-47e0-b1eb-750d7110579a",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "3.Tuần",
        "Description": "WK_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "402e3b2f-58c4-4924-976d-ec2c1ee1192c",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "4.Tháng",
        "Description": "MN_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51d39089-4c02-498a-a86c-eb0b356a4eb6",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "5.Quý",
        "Description": "QR_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5078c9fb-f4a3-4a78-9125-694fc3941cb6",
        "ParentId": "9629378d-6e0a-4ab3-9350-dee797820e7c",
        "Name": "6.Năm",
        "Description": "YR_CellB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/7.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78a58474-ec9a-4d9a-9ce8-95c902d3b007",
        "ParentId": "541f6293-572a-4127-a58d-7a9e1d2a4fe6",
        "Name": "8.Throughput",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/8.Throughput",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b9dbe3a-4816-42f0-bdfb-082862ca68bf",
        "ParentId": "78a58474-ec9a-4d9a-9ce8-95c902d3b007",
        "Name": "2.Ngày",
        "Description": "DY_CellB_throughput",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/8.Throughput/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e2740c2-1798-4ac7-86f6-3139eeceac9b",
        "ParentId": "78a58474-ec9a-4d9a-9ce8-95c902d3b007",
        "Name": "BH",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/8.Throughput/BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc82d5cd-1f49-41ed-862e-deb99fe43a7c",
        "ParentId": "541f6293-572a-4127-a58d-7a9e1d2a4fe6",
        "Name": "9.CellB with no Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/9.CellB with no Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0f0f6b2-ee9d-45b7-a7b5-54c5ea182744",
        "ParentId": "cc82d5cd-1f49-41ed-862e-deb99fe43a7c",
        "Name": "1.Ngày",
        "Description": "TKLL_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/1.CellB/9.CellB with no Traffic/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "87867637-6c88-4007-b296-6b63b0903f24",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "2.SiteB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e603384-83a9-4023-a145-9f46c021e03e",
        "ParentId": "87867637-6c88-4007-b296-6b63b0903f24",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa1cd84b-6594-4e89-b4aa-4b0a3af291c4",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "1.Giờ",
        "Description": "HR_CellB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf33f70e-efc5-4681-8f05-7dba60556f57",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_SiteB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "684f90cf-4896-4eb4-9e4f-6318b5928c5a",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90a4db5c-bc1c-4070-a5a0-f45c2fd34565",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fac07f8-5fcd-49f6-a0ba-c76cd8b2f277",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "5.Quý",
        "Description": "QR_SiteB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb1dd628-4ac8-460f-8b86-084c47b7a834",
        "ParentId": "7e603384-83a9-4023-a145-9f46c021e03e",
        "Name": "6.Năm",
        "Description": "YR_SiteB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3886692a-6b2e-4db5-8ca8-7992755f7d1a",
        "ParentId": "35e5bfe7-7896-45f2-91b4-830488e51954",
        "Name": "2.Ngày",
        "Description": "DY_SiteB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a269fa0-31d9-412c-87f3-7b12f163df91",
        "ParentId": "35e5bfe7-7896-45f2-91b4-830488e51954",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5bf6e2f0-fc80-4700-a8b5-c65bbc95ec4f",
        "ParentId": "35e5bfe7-7896-45f2-91b4-830488e51954",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d454cc08-a1df-40e5-8858-5b2df3acb251",
        "ParentId": "35e5bfe7-7896-45f2-91b4-830488e51954",
        "Name": "5.Quý",
        "Description": "QR_SiteB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c24b2e0-fc9c-4e1e-bc61-585f880baffa",
        "ParentId": "35e5bfe7-7896-45f2-91b4-830488e51954",
        "Name": "6.Năm",
        "Description": "YR_SiteB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5109c938-ece1-4c30-b1e2-42fe1d25067d",
        "ParentId": "c6e3c769-b561-44d9-b48c-0d71de826f8f",
        "Name": "2.Ngày",
        "Description": "DY_SiteB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0cc9db07-52f0-491d-be73-ec8c4a9eef68",
        "ParentId": "c6e3c769-b561-44d9-b48c-0d71de826f8f",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74e8e30b-554e-4a3c-91b2-445e2261d63d",
        "ParentId": "c6e3c769-b561-44d9-b48c-0d71de826f8f",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "67021a7c-dcd0-43c8-830e-34ddb6a8a09f",
        "ParentId": "c6e3c769-b561-44d9-b48c-0d71de826f8f",
        "Name": "5.Quý",
        "Description": "QR_SiteB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ff4f9d1-77ae-4bbf-8277-24502140a67b",
        "ParentId": "c6e3c769-b561-44d9-b48c-0d71de826f8f",
        "Name": "6.Năm",
        "Description": "YR_SiteB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e271f2cd-b161-4058-a86b-fc7cfe543a4e",
        "ParentId": "91d92f86-462f-4228-b8b8-95e81cd61a07",
        "Name": "2.Ngày",
        "Description": "DY_SiteB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/4.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e90ebc8-5d8e-489f-a6b2-fecf8f2a7ffc",
        "ParentId": "91d92f86-462f-4228-b8b8-95e81cd61a07",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/4.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0dccb768-585f-4f4f-9710-40e3da6fc399",
        "ParentId": "91d92f86-462f-4228-b8b8-95e81cd61a07",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/4.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0fd5fc42-ad01-489d-93b1-fed777334338",
        "ParentId": "91d92f86-462f-4228-b8b8-95e81cd61a07",
        "Name": "5.Quý",
        "Description": "QR_SiteB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/4.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "049092ea-1e7d-4787-b7bf-96d934ff4bf1",
        "ParentId": "91d92f86-462f-4228-b8b8-95e81cd61a07",
        "Name": "6.Năm",
        "Description": "YR_SiteB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/4.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd7bf20d-8938-4d97-aafa-56ddec9fcb94",
        "ParentId": "de3b65a6-2060-4fc4-960b-a04d3984e27c",
        "Name": "2.Ngày",
        "Description": "DY_SiteB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/5.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "baa5637d-ea1b-4a6d-acc1-266e91930f1a",
        "ParentId": "de3b65a6-2060-4fc4-960b-a04d3984e27c",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/5.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3bbb626c-3ee9-4393-9500-6417665870ec",
        "ParentId": "de3b65a6-2060-4fc4-960b-a04d3984e27c",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/5.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "48836624-317b-42c6-8b37-30a1851417a4",
        "ParentId": "de3b65a6-2060-4fc4-960b-a04d3984e27c",
        "Name": "5.Quý",
        "Description": "QR_SiteB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/5.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e53743b7-6a24-46c3-8bb0-3d479ac34aca",
        "ParentId": "de3b65a6-2060-4fc4-960b-a04d3984e27c",
        "Name": "6.Năm",
        "Description": "YR_SiteB_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/5.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "465765d8-1ebd-42b2-912a-b523c2b457b4",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "1.Giờ",
        "Description": "HR_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ced2757f-b453-4578-9cd8-572e1015a549",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "2.Ngày",
        "Description": "DY_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "609f025c-5a3f-4199-89bb-78ce2295714c",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "3.Tuần",
        "Description": "WK_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ceeb7155-edae-404a-a9f5-7b417871ff9a",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "4.Tháng",
        "Description": "MN_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "579c06b6-f9d7-4e7f-abd9-46d032e3ee2a",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "5.Quý",
        "Description": "QR_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7030d6f-fc9a-4566-b3ef-72342e95ca79",
        "ParentId": "36cfe091-282d-437a-816a-3708b5eca4cd",
        "Name": "6.Năm",
        "Description": "YR_SiteB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/2.SiteB/6.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "3.RNC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f1c9640-cbf2-49b8-902e-e7c6fa11ede1",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "1.Giờ",
        "Description": "HR_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9434462e-ee2a-4e9d-a72e-77cbdddbaf40",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "2.Ngày",
        "Description": "DY_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f1f2c9e-d43b-4264-a1f4-cf93a5918136",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "3.Tuần",
        "Description": "WK_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81946e38-40ab-482a-b3d5-7518c5f7a900",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "4.Tháng",
        "Description": "MN_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b689164f-a023-4c61-b950-d1febb5c1a79",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "5.Quý",
        "Description": "QR_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6d53533-1e81-4f41-a74d-e18e39c56fad",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "6.Năm",
        "Description": "YR_RNC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "785a266c-e483-4243-aa91-587fa7ff245d",
        "ParentId": "68bba1c6-815f-407f-a37b-4b048da82b15",
        "Name": "7.BH",
        "Description": "DY_RNC_gen_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/1.General/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "2.Accessibility",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64db8a5e-9547-4a47-9f55-d00c986d0632",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "1.Giờ",
        "Description": "HR_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1979fa34-610c-4f5e-a6b7-82a25b620b0a",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5cc60ec-ce31-40d4-8780-198e802ea82d",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "3.Tuần",
        "Description": "WK_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c2305612-324b-47f5-bc67-de406f166d86",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "4.Tháng",
        "Description": "MN_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "62159e08-e0a4-448b-8b25-fc1d666d384a",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "5.Quý",
        "Description": "QR_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dba55a0d-5064-4f6f-8cdf-346f49285215",
        "ParentId": "e3ccc28a-8d3d-4c70-9fab-7e7132b1dfc4",
        "Name": "6.Năm",
        "Description": "YR_RNC_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "3.Availability",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6593c0f-0e57-4c94-8dfd-5d5291700c32",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "1.Giờ",
        "Description": "HR_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85b7a286-31f6-432e-9531-eb173938ee80",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "acd60759-c380-49ec-8830-a62feb0aa9e9",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "3.Tuần",
        "Description": "WK_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3820786e-590f-4124-86d7-a7d315eb979c",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "4.Tháng",
        "Description": "MN_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7abc50d6-4103-4cd2-872d-7f8edbd02293",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "5.Quý",
        "Description": "QR_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb77b8fc-8089-4ced-be43-9890dc586865",
        "ParentId": "a059624c-0e12-4b37-a1be-22a60f907fc2",
        "Name": "6.Năm",
        "Description": "YR_RNC_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "4.Mobility",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84c63c85-4b75-42be-bda3-2274ef99c528",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "1.Giờ",
        "Description": "HR_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3301f2e-a62c-4598-ae25-b2f8d4a1dc9b",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a027830e-a6a3-4b9a-a4ed-ed6e16e35c9f",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "3.Tuần",
        "Description": "WK_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66755831-f7be-43f1-9d65-6166cf2d95ca",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "4.Tháng",
        "Description": "MN_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a62b9477-44dd-4a7f-8249-4b27c08a8da4",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "5.Quý",
        "Description": "QR_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "392d5904-e917-4767-9111-c2dc17b24599",
        "ParentId": "ae034cac-df44-44f3-9eb9-8a90d6fe3da4",
        "Name": "6.Năm",
        "Description": "YR_RNC_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/4.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "5.Retainability",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db10f139-3959-49be-bed1-0080cfe01afb",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "1.Giờ",
        "Description": "HR_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22b9613e-e120-4928-8c35-8c3eacbb7937",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "04efe490-e401-454a-94fa-0b163151b052",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "3.Tuần",
        "Description": "WK_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85ca0118-78e6-4842-8127-048c6e0fc617",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "4.Tháng",
        "Description": "MN_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41adaf59-3335-4eee-9049-c1190e99696e",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "5.Quý",
        "Description": "QR_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84272b15-3905-4677-b8a2-95d96ea0a33a",
        "ParentId": "e5821b0e-01a7-4f87-b4ad-22e5f459eecb",
        "Name": "6.Năm",
        "Description": "YR_RNC_retain",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/5.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "ParentId": "aa5ccbc2-de81-47b1-ae2a-b6574e8a2e37",
        "Name": "6.Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de9da9b4-c245-4d65-8d79-ef73fdacfc01",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "1.Giờ",
        "Description": "HR_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "daa0fbe5-e5d7-435c-9ced-59d9a5cc7d4d",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "727e47d1-b049-4c9a-8635-3fc4118615b5",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "3.Tuần",
        "Description": "WK_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7fc1c5ae-db92-4008-9ee2-07771b0be3df",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "4.Tháng",
        "Description": "MN_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e66a82f-231a-4901-a9b8-550d06201ff7",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "5.Quý",
        "Description": "QR_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f675135d-3cde-4e66-9d70-748609cb45f2",
        "ParentId": "b6946f6f-d2d9-44ba-8b43-1bd373a79460",
        "Name": "6.Năm",
        "Description": "YR_RNC_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/3.RNC/6.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "4.ToVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15e0a30c-dcdb-4aea-aa52-ee23524514d7",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_ToVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb37eb02-cc47-49a5-bb53-903629ce513d",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "2.Tuần",
        "Description": "WK_ToVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8430e7e5-5eb7-44ca-925c-2fb91229595e",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "3.Tháng",
        "Description": "MN_ToVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a009a8e-d370-4e9e-8c8d-f5618484e57c",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "4.Quý",
        "Description": "QR_ToVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eef8f00c-ebfd-4b33-bc77-064a5a564733",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "5.Năm",
        "Description": "YR_ToVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eebe2ed4-095c-4a26-b864-830e8a4bcf0b",
        "ParentId": "a277cc9b-e3a4-44c5-a6ff-4e1dc2532f1a",
        "Name": "6.BH",
        "Description": "DY_ToVT3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/4.ToVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "5.DaiVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3888c43-ffb6-4ac1-a97d-1a226cb7bbd8",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_DaiVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab6ef11b-4741-423d-a32a-06468d99c1ca",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "2.Tuần",
        "Description": "WK_DaiVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7092f0e-6a03-4025-9a38-9c1e15a957c1",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "3.Tháng",
        "Description": "MN_DaiVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "613ab042-5db2-4fd8-a56e-9862de2de10f",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "4.Quý",
        "Description": "QR_DaiVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25ca69b9-220c-4159-b729-b26945652b1e",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "5.Năm",
        "Description": "YR_DaiVT3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8e376880-3bb5-4861-9c6a-05fd96f435cd",
        "ParentId": "6b0c3c64-35ca-424f-aa97-a75b28fe52a2",
        "Name": "6.BH",
        "Description": "DY_ToVT3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/5.DaiVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "6.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/6.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e610ed9d-9cee-458e-a594-4b277ca147ee",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_District3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de730756-a147-4a3d-ae9f-e177ef4592dc",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "2.Tuần",
        "Description": "WK_District3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "98127693-acd9-416d-b3c3-3c295249a905",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "3.Tháng",
        "Description": "MN_District3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f9b3fd7-7a85-42a8-bf23-e1a25b10dd20",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "4.Quý",
        "Description": "QR_District3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3bdfeda0-58d9-49f0-b33f-eb31ff98b55e",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "5.Năm",
        "Description": "YR_District3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c35c319e-fd0d-4b6e-8fb4-ed009c818fa8",
        "ParentId": "43fb266d-1e75-422d-9e57-79381af3af5e",
        "Name": "6.BH",
        "Description": "DY_District3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/6.District/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "7.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/7.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38aed4f3-dd7d-43c6-9e96-a6ff64d5ea0b",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_Province3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43a03d20-0784-40be-a0da-210453499c4f",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "2.Tuần",
        "Description": "WK_Province3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3fcb270f-3654-453e-9b70-c5f2246547e9",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "3.Tháng",
        "Description": "MN_Province3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c4d5aac5-4c04-4a6d-bee7-75b09e22f911",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "4.Quý",
        "Description": "QR_Province3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90cfc08f-39e3-4978-a5b8-8f05cd0fb353",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "5.Năm",
        "Description": "YR_Province3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb800d2d-0cd2-488d-bc7b-8b1c572d5098",
        "ParentId": "7ca8e177-b3ab-4b12-849b-a93a84f7ac6b",
        "Name": "6.BH",
        "Description": "DY_Province3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/7.Province/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "71.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81c9471e-b23b-4cc2-b902-ce7b7de2b216",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "1.Từ này đến ngày",
        "Description": "DY_CTKD3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/1.Từ này đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2df4ec9c-8519-4320-ae23-c2ee2d45d2c1",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "2.Tuần",
        "Description": "WK_CTKD3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28c9d593-df3c-4ca2-9b51-cf3f207c1537",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "3.Tháng",
        "Description": "MN_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "364f26b3-94e2-48a3-a7b9-251a303570a9",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "4.Quý",
        "Description": "QR_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c064f39f-6182-495f-ac29-6a613c5d4b24",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "5.Năm",
        "Description": "YR_CTKD3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a2d193c-6144-497e-a9c0-e802066b5793",
        "ParentId": "865e70c7-d673-4e15-9573-96f71c5fbd64",
        "Name": "6.BH",
        "Description": "DY_CTKD3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/71.CTKD/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d540839e-ecdc-428a-91bc-031eca39993e",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "72.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/72.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "097baca8-0ba2-46a4-9bb8-22c3b2397bc1",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "1.Ngày",
        "Description": "DY_branch3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "521f3008-385a-42e2-acb6-c6603fdf4511",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "2.Tuần",
        "Description": "MN_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c7c0d90-dabf-421a-bbef-c89d652d5abf",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "3.Tháng",
        "Description": "MN_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72ee6ce8-604a-4278-9298-7827c769a687",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "4.Quý",
        "Description": "QR_Branch3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bdd79d3a-fcf1-46d1-861c-651d238c48d7",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "5.Năm",
        "Description": "YR_Branch3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7dd8dbae-12a4-45ff-bb0a-ae11ac588a12",
        "ParentId": "d540839e-ecdc-428a-91bc-031eca39993e",
        "Name": "6.BH",
        "Description": "DY_Branch3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/72.Branch/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "8.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/8.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64e25397-e0c6-4360-93ef-f07871ef43e7",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_TTML3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83eb2849-bbca-4128-bccf-29aa8b774b7d",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "2.Tuần",
        "Description": "WK_TTML3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1eb7a4e9-b637-476c-abb7-c51e296e72e6",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "3.Tháng",
        "Description": "MN_TTML3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca414b1b-ea8d-462e-8cd9-56be74908a42",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "4.Quý",
        "Description": "QR_TTML3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38bdbbce-aa92-4427-9831-3a340ee61489",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "5.Năm",
        "Description": "YR_TTML3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e39e8a0f-aa7d-47ea-9597-b54894c1561e",
        "ParentId": "cdd2fd63-5823-4f97-8c9f-18eebe823be2",
        "Name": "6.BH",
        "Description": "DY_TTML3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/8.TTML/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "9.Vendor",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76e9c21c-29c4-47cd-ba2a-19f8bfddf34f",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "1.Từ ngày đến ngày",
        "Description": "DY_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/1.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a808c704-cf28-47fe-a305-8f33c6cdca7b",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "2.Tuần",
        "Description": "WK_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24998ca1-7555-4f74-b3fe-b1078c0d1808",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "3.Tháng",
        "Description": "MN_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "916b6cc2-b775-4f54-b303-cdad465e3cdb",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "4.Quý",
        "Description": "QR_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4b2a190-673b-4054-8950-c025711b8755",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "5.Năm",
        "Description": "YR_Vendor3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa3b1c6d-a623-4cf5-a195-b9be186fed9b",
        "ParentId": "88662018-cf3d-46f7-abb8-351a29e7eac8",
        "Name": "6.BH",
        "Description": "DY_Vendor3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/9.Vendor/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "91.IBC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/91.IBC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fef3fa04-8cf2-4138-9ed2-a7ccd24d8492",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "1.Ngày",
        "Description": "DY_IBC3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfe42b2c-0cef-40e3-9ae5-e6ab008080ef",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "2.Tuần",
        "Description": "WK_IBC3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa954e9d-d070-40d6-a751-7f0ab64d5fa1",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "3.Tháng",
        "Description": "MN_IBC3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05791e95-9e62-4eb8-a968-fc2dc2115ebc",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "4.Quý",
        "Description": "QR_IBC3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f790ff65-b2e3-47f6-811d-b66163b1440b",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "5.Năm",
        "Description": "YR_IBC3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "370b9d21-c24a-49ac-a3cf-2ac8004af661",
        "ParentId": "5dd1738f-c8c6-4982-8b7e-07a88dc8257f",
        "Name": "6.BH",
        "Description": "DY_IBC-TTML3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/91.IBC/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "92.Smallcell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77b6a197-9e3d-4cbf-bfde-88d2555a3df8",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "1.Ngày",
        "Description": "DY_Smallcell3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b52ac66a-3d6f-4cf9-ab9b-212e33561360",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "2.Tuần",
        "Description": "WK_Smallcell3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aba0cdff-84dd-4bdd-bc70-7c9f57c8e890",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "3.Tháng",
        "Description": "MN_Smallcell3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5557c89b-6aac-4e4e-9334-2785a2c6aece",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "4.Quý",
        "Description": "QR_Smallcell3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4a58026-eda5-45c8-b6ca-2747de4fdaec",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "5.Năm",
        "Description": "YR_Smallcell3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a1e250e7-97ab-460f-acba-feef4b974d36",
        "ParentId": "64a330c0-18a3-495f-8028-0473b6a5834e",
        "Name": "7.BH",
        "Description": "DY_Smallcell3g_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/92.Smallcell/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d0554cd-0ccb-4ca9-86c3-3bcab57f0e82",
        "ParentId": "7ab47641-ac1f-4fc8-9de9-c8b4e085cd39",
        "Name": "93.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/2.ran3g/93.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ab6d2b6-5374-4a57-8fb1-b3eecc3fe76b",
        "ParentId": "1d0554cd-0ccb-4ca9-86c3-3bcab57f0e82",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/2.ran3g/93.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68adc155-0489-4337-a0e1-483526170f9e",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "3.ran4g",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c9fc418-f21e-4cde-8f45-061189b62a6c",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "1.EUTrancell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "ParentId": "9c9fc418-f21e-4cde-8f45-061189b62a6c",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9caa3a63-90a9-402c-8208-75f2d3259052",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "1.Giờ",
        "Description": "HR_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47078246-c7bb-4b2b-9ae1-d5eabb295a92",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d5cb9ed-a3d5-45a4-95d3-79ac0e81e7b1",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59f5ab6f-0a86-4a10-b0af-db53e3ed3af9",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ecaab7a-a77f-4f28-bec1-8976fb2492d6",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28101e65-77df-40e6-8ceb-b60d986d3bf9",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8552a51d-2171-4f7e-b38d-7b112f563505",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "7.BH",
        "Description": "DY_eUtrancell_gen_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/7.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40958982-1fd5-4c6a-a5ac-45c27f6aa1a8",
        "ParentId": "65ab7d35-8335-41e0-86f0-8794d57ddaea",
        "Name": "Badcell",
        "Description": "ran-bcn-ran4g-EUtrancell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/1.General/Badcell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ddae3e4-c5cb-4100-b4d2-b4407dd80da8",
        "ParentId": "153a9d8d-fcc8-44e2-814a-38ab5ff4fc5f",
        "Name": "2.Ngày",
        "Description": "DY_eUtrancell_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f4b57cef-bc70-4901-8a26-ec99e1f3f12e",
        "ParentId": "153a9d8d-fcc8-44e2-814a-38ab5ff4fc5f",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1424f669-4fed-480e-b844-de599aa809c3",
        "ParentId": "153a9d8d-fcc8-44e2-814a-38ab5ff4fc5f",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "492dc6fa-7c05-4ec6-bfdf-f4341e1ca345",
        "ParentId": "153a9d8d-fcc8-44e2-814a-38ab5ff4fc5f",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61c5d790-45cd-408e-b4d2-59fd2d743e3a",
        "ParentId": "153a9d8d-fcc8-44e2-814a-38ab5ff4fc5f",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89e9fd29-9a94-4f33-a542-04cb84bb7b9b",
        "ParentId": "cfa13d0c-2e0c-4386-914d-24147cac1b69",
        "Name": "2.Ngày",
        "Description": "DY_eUtrancell_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59f8908e-d9bc-4c17-bdba-9173bf75dcc7",
        "ParentId": "cfa13d0c-2e0c-4386-914d-24147cac1b69",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "97ef4700-e138-41e1-a0bb-0e6ae4568869",
        "ParentId": "cfa13d0c-2e0c-4386-914d-24147cac1b69",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "96832f73-ff45-4e47-b1c0-47860a0f6dc5",
        "ParentId": "cfa13d0c-2e0c-4386-914d-24147cac1b69",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcbe8914-32dc-4802-af4c-1a3e06deffcb",
        "ParentId": "cfa13d0c-2e0c-4386-914d-24147cac1b69",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02e4e7fb-316e-41e2-976e-741a3fe354e5",
        "ParentId": "bf6f5833-55e3-4f4a-b792-ce9f0dde9bc4",
        "Name": "2.Ngày",
        "Description": "DY_eUtrancell_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/4.Integrity/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ff6f17e-aa42-45f9-ba25-8a878ec69094",
        "ParentId": "bf6f5833-55e3-4f4a-b792-ce9f0dde9bc4",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/4.Integrity/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90407976-9ee8-4e11-9026-2eec7abc4222",
        "ParentId": "bf6f5833-55e3-4f4a-b792-ce9f0dde9bc4",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/4.Integrity/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd85b3b5-fad4-480b-893c-9f2cc0c9c0e0",
        "ParentId": "bf6f5833-55e3-4f4a-b792-ce9f0dde9bc4",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/4.Integrity/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0166adb1-2346-46bf-aed9-e82ba5ecec44",
        "ParentId": "bf6f5833-55e3-4f4a-b792-ce9f0dde9bc4",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/4.Integrity/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9ed8bad-172b-48fa-a3a8-62373a05734b",
        "ParentId": "efb50150-c5ff-4082-ad18-66f4a3ce3019",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/5.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "057859c7-49a7-419e-afc5-1b213bee7719",
        "ParentId": "efb50150-c5ff-4082-ad18-66f4a3ce3019",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/5.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd9bdb68-b8c1-4506-8402-58bcabdbf487",
        "ParentId": "efb50150-c5ff-4082-ad18-66f4a3ce3019",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/5.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75d25f9c-6f8e-4ab0-89da-efc735585686",
        "ParentId": "efb50150-c5ff-4082-ad18-66f4a3ce3019",
        "Name": "5.Quý",
        "Description": "QR__eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/5.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "87a8cd77-3a85-43db-aeb3-0e2227a94981",
        "ParentId": "efb50150-c5ff-4082-ad18-66f4a3ce3019",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/5.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a7376d8-693a-4054-aa4e-06db5809547e",
        "ParentId": "67b043fd-821a-4838-9e7a-82149502973d",
        "Name": "2.Ngày",
        "Description": "DY_eUtrancell_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/6.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3eb3eb21-933a-40a1-bf96-038d683cb939",
        "ParentId": "67b043fd-821a-4838-9e7a-82149502973d",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/6.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0a4938e-c411-4619-8353-c6882ee1c63b",
        "ParentId": "67b043fd-821a-4838-9e7a-82149502973d",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/6.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78b07a0c-8a29-414d-97c8-9c72bad2c382",
        "ParentId": "67b043fd-821a-4838-9e7a-82149502973d",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/6.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d2a1acf-5f3e-4a63-9bbc-08e76a012e63",
        "ParentId": "67b043fd-821a-4838-9e7a-82149502973d",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/6.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ea925e76-3795-4170-b46e-ae7a92cf6772",
        "ParentId": "2c595c81-1eb3-4ec0-bd63-74519f8904c2",
        "Name": "2.Ngày",
        "Description": "DY_eUtrancell_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/7.Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf44d6d4-5d24-4f11-b302-a73151428caf",
        "ParentId": "2c595c81-1eb3-4ec0-bd63-74519f8904c2",
        "Name": "3.Tuần",
        "Description": "WK_eUtrancell_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/7.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0a81c48-9452-4217-b5e6-0ed0d380ebd6",
        "ParentId": "2c595c81-1eb3-4ec0-bd63-74519f8904c2",
        "Name": "4.Tháng",
        "Description": "MN_eUtrancell_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/7.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ca6a8a7-84e7-4e0b-8780-f46897774c38",
        "ParentId": "2c595c81-1eb3-4ec0-bd63-74519f8904c2",
        "Name": "5.Quý",
        "Description": "QR_eUtrancell_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/7.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "890c5a4f-dae0-48bf-972c-5af46269b0ff",
        "ParentId": "2c595c81-1eb3-4ec0-bd63-74519f8904c2",
        "Name": "6.Năm",
        "Description": "YR_eUtrancell_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/7.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d656ce2-deef-4678-ad2f-0a2fe91d6ef5",
        "ParentId": "9c9fc418-f21e-4cde-8f45-061189b62a6c",
        "Name": "8.NoTraffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/8.NoTraffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85bfe97c-b0a0-4a89-89bb-bfbab02ff49a",
        "ParentId": "4d656ce2-deef-4678-ad2f-0a2fe91d6ef5",
        "Name": "1.Ngày",
        "Description": "TKLL_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTrancell/8.NoTraffic/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b28d7ed8-1224-4373-994e-a35e3063d49d",
        "ParentId": "9c9fc418-f21e-4cde-8f45-061189b62a6c",
        "Name": "9.THP_PRB_BH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/1.EUTranCell/9.THP_PRB_BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ecfdc81-926d-49ca-90ac-25c49e6ffca2",
        "ParentId": "b28d7ed8-1224-4373-994e-a35e3063d49d",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/1.EUTranCell/9.THP_PRB_BH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94a828ed-5b4c-423f-9597-dbb323594b7c",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "2.eNodeB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "ParentId": "94a828ed-5b4c-423f-9597-dbb323594b7c",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef4f50b3-2937-4e82-a7d7-83670292d7c8",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "1.Giờ",
        "Description": "HR_eUtrancell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0d64e19-a14f-4f8d-b833-b093154f4e99",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "2.Từ ngày đến ngày",
        "Description": "DY_eNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50117a8a-d7fc-45d7-90a3-5cd40b43196c",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d235662d-7076-4678-954a-39193afc32fa",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4fe42b2a-13eb-47ac-b12b-414b7842ec73",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3321c616-6059-4e50-99c5-bbb0b93c415a",
        "ParentId": "448d6c3b-f1ce-4581-a3a6-25b0dc3813ee",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0595534-e367-4243-8633-257c334861b8",
        "ParentId": "b5f310cc-854c-4851-bcce-c48b97326d80",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/2.Accessibility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b072cc45-90a6-43a0-bf9c-e1507cf555b9",
        "ParentId": "b5f310cc-854c-4851-bcce-c48b97326d80",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/2.Accessibility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05e53509-a52b-45e7-84a7-03d76b77920a",
        "ParentId": "b5f310cc-854c-4851-bcce-c48b97326d80",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/2.Accessibility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7465ef9-454f-45df-a6fa-48183450d7da",
        "ParentId": "b5f310cc-854c-4851-bcce-c48b97326d80",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/2.Accessibility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd95f8d5-91e9-437a-84ed-34c115737bcc",
        "ParentId": "b5f310cc-854c-4851-bcce-c48b97326d80",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_access",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/2.Accessibility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b8e2a1c-176e-4e86-8a7d-b5d8ebd057c6",
        "ParentId": "544b70e7-ef99-4983-81a4-64e842d04c7b",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/3.Availability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a7376f1-67f6-44d9-bcda-17c73246ebac",
        "ParentId": "544b70e7-ef99-4983-81a4-64e842d04c7b",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/3.Availability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4b8190b-c990-49cd-952b-b1adf58f79f0",
        "ParentId": "544b70e7-ef99-4983-81a4-64e842d04c7b",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/3.Availability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8fb7215-dcb7-4538-900e-e68573dc5b6f",
        "ParentId": "544b70e7-ef99-4983-81a4-64e842d04c7b",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/3.Availability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c58fdeaf-17e9-43ce-9c66-45672a67bb39",
        "ParentId": "544b70e7-ef99-4983-81a4-64e842d04c7b",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_avai",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/3.Availability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "216fb256-68c2-4636-bdd1-5bc3b1f27496",
        "ParentId": "526ec183-72e2-4a5d-8356-7a4629e9c54b",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/4.Integrity/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b560b4ec-c5de-46dc-a358-962ae2bb8ee4",
        "ParentId": "526ec183-72e2-4a5d-8356-7a4629e9c54b",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/4.Integrity/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c1263eb0-27e7-45b8-9fb7-723e911d33d3",
        "ParentId": "526ec183-72e2-4a5d-8356-7a4629e9c54b",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/4.Integrity/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90892ea8-e08c-43f7-b272-bc9b8544d712",
        "ParentId": "526ec183-72e2-4a5d-8356-7a4629e9c54b",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/4.Integrity/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d512c1c9-f962-454c-8ee0-6fdd9751acea",
        "ParentId": "526ec183-72e2-4a5d-8356-7a4629e9c54b",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_inte",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/4.Integrity/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae6ef7a6-8c93-499c-9eb0-e06b007cf6e8",
        "ParentId": "21ad6166-d4f1-4bcd-8f0f-be2a3b2244b3",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/5.Mobility/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3c4cf2f-315c-436b-be33-2aeac0e2c771",
        "ParentId": "21ad6166-d4f1-4bcd-8f0f-be2a3b2244b3",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/5.Mobility/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6d55b71-38b3-401b-bb05-12747363206b",
        "ParentId": "21ad6166-d4f1-4bcd-8f0f-be2a3b2244b3",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/5.Mobility/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5dbe12eb-8cef-4375-a412-f26972d542bd",
        "ParentId": "21ad6166-d4f1-4bcd-8f0f-be2a3b2244b3",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/5.Mobility/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0806f0b-8b3f-427f-b565-72cd57de77e9",
        "ParentId": "21ad6166-d4f1-4bcd-8f0f-be2a3b2244b3",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_mobi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/5.Mobility/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0958c07-51f8-47ea-b853-0960ecf95a03",
        "ParentId": "1fead4dd-953b-4219-b367-f16b9f2ad007",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/6.Retainability/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b61e6f81-12d9-48f1-82d4-494cbf9f8161",
        "ParentId": "1fead4dd-953b-4219-b367-f16b9f2ad007",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/6.Retainability/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "efbfdcf9-ea1c-4910-bbec-d5a34d06004c",
        "ParentId": "1fead4dd-953b-4219-b367-f16b9f2ad007",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/6.Retainability/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27d7093c-a4d1-41c8-82af-9e4002042a22",
        "ParentId": "1fead4dd-953b-4219-b367-f16b9f2ad007",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/6.Retainability/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2db917b8-6d58-4a04-a278-55fd6e63f656",
        "ParentId": "1fead4dd-953b-4219-b367-f16b9f2ad007",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_reta",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/6.Retainability/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb1beb5a-a829-4c6f-89b2-e1862241182a",
        "ParentId": "410650aa-55c6-4d2c-bc22-5a44c72e0e40",
        "Name": "2.Ngày",
        "Description": "DY_eNodeB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/7.Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1ec5c9b-64db-4715-925b-284d2700c6fa",
        "ParentId": "410650aa-55c6-4d2c-bc22-5a44c72e0e40",
        "Name": "3.Tuần",
        "Description": "WK_eNodeB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/7.Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3d6c5d2-f28f-4ee8-9478-c2100a0908c7",
        "ParentId": "410650aa-55c6-4d2c-bc22-5a44c72e0e40",
        "Name": "4.Tháng",
        "Description": "MN_eNodeB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/7.Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a4d2fa0-e184-4205-96a2-084638d84b5d",
        "ParentId": "410650aa-55c6-4d2c-bc22-5a44c72e0e40",
        "Name": "5.Quý",
        "Description": "QR_eNodeB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/7.Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b9ecff44-bcd1-4749-94d3-752ca0ad2531",
        "ParentId": "410650aa-55c6-4d2c-bc22-5a44c72e0e40",
        "Name": "6.Năm",
        "Description": "YR_eNodeB_traf",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/2.eNodeB/7.Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e79a29b9-8765-48de-8613-4619880ae167",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "3.ToVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19b1375c-1355-499a-bb82-b37ccf8b16df",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "1.Ngày",
        "Description": "DY_ToVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5dde93fe-7744-433e-871f-c68202a1ccfe",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "2.Tuần",
        "Description": "WK_ToVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eebdaa11-f32a-4d10-8976-d3012725dc25",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "3.Tháng",
        "Description": "MN_ToVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44dd412e-589b-43e0-8a4e-72ad447886df",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "4.Quý",
        "Description": "QR_ToVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3a2ebb0-47ae-40a1-b364-37343346f421",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "5.Năm",
        "Description": "YR_ToVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "091b9f53-73c7-4f36-be84-73c8752ef553",
        "ParentId": "e79a29b9-8765-48de-8613-4619880ae167",
        "Name": "6.BH",
        "Description": "DY_TVT4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/3.ToVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33164063-247f-4ffc-9ca3-60d33919737c",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "4.DaiVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae1f1eb9-167d-4f74-afe1-2b4d8e133b0d",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "1.Ngày",
        "Description": "DY_DaiVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8cf48a7c-3f5a-45d5-bf59-dbd97891fa6e",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "2.Tuần",
        "Description": "WK_DaiVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e161307-364f-48dd-9d62-3158f3c0e6f3",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "3.Tháng",
        "Description": "MN_DaiVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2db0c137-a6da-4cce-8f4c-fd70c3246418",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "4.Quý",
        "Description": "QR_DaiVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70b563b3-258f-49d5-84d6-73f0b3a0b199",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "5.Năm",
        "Description": "YR_DaiVTLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9e627ef-4993-4651-9c5e-e5eff763ff9a",
        "ParentId": "33164063-247f-4ffc-9ca3-60d33919737c",
        "Name": "6.BH",
        "Description": "DY_TVT4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/4.DaiVT/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c8e0b9f-60f8-4b9c-aa2f-946e9f9b3d34",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "1.Ngày",
        "Description": "DY_DistrictLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcb6aa0a-9c45-4c8e-b6da-ba15a1546783",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "2.Tuần",
        "Description": "WK_DistrictLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08b10fb6-04b5-4675-a93b-5e72cc031c75",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "3.Tháng",
        "Description": "MN_DistrictLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc726227-7e7e-4aaa-a803-8e358dd2f9b5",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "4.Quý",
        "Description": "QR_DistrictLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16e482d3-7105-43f2-b61b-2f7d1fa51e34",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "5.Năm",
        "Description": "YR_DistrictLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2b6cfe79-e239-4e1e-85ed-7501791ea69b",
        "ParentId": "acebc727-aefb-41e9-b784-f2dbdd626376",
        "Name": "6.BH",
        "Description": "DY_District4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/5.District/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "6.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/6.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28a0df5f-3baa-4658-9ba5-d5910832a44d",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "1.Ngày",
        "Description": "DY_ProvinceLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "62084172-127f-49af-9358-d3e7c30d7190",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "2.Tuần",
        "Description": "WK_ProvinceLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3aca3c97-20ec-4b9a-b51e-a2b25651c2cd",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "3.Tháng",
        "Description": "MN_ProvinceLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3d0dea2-04a2-42a8-b299-07b437f71e25",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "4.Quý",
        "Description": "QR_ProvinceLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e434baa6-a4ce-4342-a10c-a562a15efdbd",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "5.Năm",
        "Description": "YR_ProvinceLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "139809f6-797e-431c-9a54-4b5333f4df0c",
        "ParentId": "d52a05f1-eae7-4850-a4a1-7bc6bae1368d",
        "Name": "6.BH",
        "Description": "DY_Province4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/6.Province/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "689ec392-2450-4755-ae74-13f30e16d171",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "7.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/7.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4461b28-d20a-486b-8b52-dc5a90164e74",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "1.Ngày",
        "Description": "DY_TTMLLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f40c2df-b447-4180-849f-99dcfbede386",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "2.Tuần",
        "Description": "WK_TTMLLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ccb554c1-a42b-4c22-9a7a-1d94cad34709",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "3.Tháng",
        "Description": "MN_TTMLLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff7e73f3-1ba7-4d39-857a-476871a7a062",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "4.Quý",
        "Description": "QR_TTMLLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e664707-09ff-475b-ac7d-d88eb098502d",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "5.Năm",
        "Description": "YR_TTMLLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "752686eb-1b42-4db1-9884-ffd30deb239f",
        "ParentId": "689ec392-2450-4755-ae74-13f30e16d171",
        "Name": "6.BH",
        "Description": "DY_TTML4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/7.TTML/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "71.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "963f5155-160f-4064-9c1e-17d32732d864",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "1.Ngày",
        "Description": "DY_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56f4e45e-7198-4600-9aac-583544902e67",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "2.Tuần",
        "Description": "WK_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4b00f93b-e86d-418a-b672-929fd893bbda",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "3.Tháng",
        "Description": "MN_CTKDLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2de6e0fe-30da-47b7-9a31-1a2ac117a662",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "4.Quý",
        "Description": "QR_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79bddfc9-f15f-4b79-b366-86ae74c3552b",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "5.Năm",
        "Description": "YR_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f872d4ab-cf3e-4c33-93ee-ff6cf7d93562",
        "ParentId": "bf15e972-2985-46a4-95b8-0b072ba3fe9b",
        "Name": "6.BH",
        "Description": "DY_CTKD4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/71.CTKD/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "72.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/72.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a9d08a2-5b6a-488b-b5e8-812b108b21b4",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "1.Ngày",
        "Description": "DY_BranchLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "afcfc727-e4bd-4d74-9b73-5ec5e39444e9",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "2.Tuần",
        "Description": "WK_BranchLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5bb5f43a-6d35-42b6-869f-211ba9a10ea1",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "3.Tháng",
        "Description": "MN_BranchLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ca90788-ecfd-4613-b412-cb1cf137d991",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "4.Quý",
        "Description": "QR_BranchLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7fbed9e-e9f6-4c10-b9e1-01ec98704ca8",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "5.Năm",
        "Description": "YR_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c1719dc-f9dd-4c97-b346-262f64d3220c",
        "ParentId": "c059cabc-2f83-46db-a03a-aeeff7baa6e6",
        "Name": "6.BH",
        "Description": "DY_Branch4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/72.Branch/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "8.Vendor",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c81c4d49-13b5-429e-b7ed-3da62bdc2e82",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "1.Ngày",
        "Description": "DY_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a67ae1bc-d5fa-4218-b72b-e575fcb06e50",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "2.Tuần",
        "Description": "WK_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8a03299-8e20-4814-b394-cce52783f34e",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "3.Tháng",
        "Description": "MN_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0884810a-7416-40af-a8c1-be0b25a896d3",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "4.Quý",
        "Description": "QR_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72b333ba-c56e-405e-ade1-cc6281b4f09e",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "5.Năm",
        "Description": "YR_VendorLTE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70a42342-3335-4964-958e-4c185b6fef24",
        "ParentId": "742d1cd0-2d11-481a-a7b9-2644e57d27c8",
        "Name": "6.BH",
        "Description": "DY_Vendor4G_bh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/8.Vendor/6.BH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76ac80c7-526c-4daf-bac1-a9c32b16be91",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "95. Giám sát Counter",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/95. Giám sát Counter",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "890b51d2-0660-4d3a-80d5-7f971669b227",
        "ParentId": "76ac80c7-526c-4daf-bac1-a9c32b16be91",
        "Name": "0.Paging",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/95. Giám sát Counter/0.Paging",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa818271-80ec-4eda-969d-c289a598643e",
        "ParentId": "890b51d2-0660-4d3a-80d5-7f971669b227",
        "Name": "0.SamSung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/95. Giám sát Counter/0.Paging/0.SamSung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e043d98f-b154-4d94-b2a2-56dc821bfa04",
        "ParentId": "fa818271-80ec-4eda-969d-c289a598643e",
        "Name": "0.Giờ",
        "Description": "Hr_GiamSat_SamSung",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/95. Giám sát Counter/0.Paging/0.SamSung/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d6c96ef-2ad1-48c9-adc7-5b5ffa3e50a0",
        "ParentId": "fa818271-80ec-4eda-969d-c289a598643e",
        "Name": "1.Ngày",
        "Description": "DY_GiamSat_SamSung",
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/95. Giám sát Counter/0.Paging/0.SamSung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f304209-971e-4da1-8516-d870c2cd139e",
        "ParentId": "68adc155-0489-4337-a0e1-483526170f9e",
        "Name": "96.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/3.ran4g/96.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6c22a60-a75c-411d-b09d-48a29550bef1",
        "ParentId": "7f304209-971e-4da1-8516-d870c2cd139e",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/3.ran4g/96.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "31.ran5g(thử nghiệm)",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "53978028-62a8-48f1-812b-e661dca410c1",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "1.NR Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "ParentId": "53978028-62a8-48f1-812b-e661dca410c1",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01fc6149-5f26-4855-83dd-0fed1b9f4a7c",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "1.Giờ",
        "Description": "HR_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e6e76e7-6d7f-4be5-be02-d341ad2efeeb",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "2.Ngày",
        "Description": "DY_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5de517d3-cb27-4abf-a18d-a21f3c027850",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "3.Tuần",
        "Description": "WK_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b230e56b-673f-4714-9d20-7f20b860cc03",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "4.Tháng",
        "Description": "MN_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "203707ad-4dca-45ee-bb4b-9026296af979",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "5.Quý",
        "Description": "QR_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa62efb7-4807-4505-b5ab-34ae3d0e5847",
        "ParentId": "5dd6f35e-8f7a-4432-b0b2-2419808b244f",
        "Name": "6.Năm",
        "Description": "YR_NRCell_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/1.NR Cell/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f59a1d8a-14e1-4f51-8b6b-1580cfa03b02",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "2.gNodeB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "ParentId": "f59a1d8a-14e1-4f51-8b6b-1580cfa03b02",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0346b40-a9de-4a11-91ef-bed8a8f0a5f1",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "1.Giờ",
        "Description": "HR_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "677c6200-e164-41da-861a-f8041e245795",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "2.Ngày",
        "Description": "DY_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfdf4d34-93ca-472a-a73c-0da100b84ee7",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "3.Tuần",
        "Description": "WK_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58651f57-d789-47aa-8e70-512071cff2dc",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "4.Tháng",
        "Description": "MN_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ea0e8bf3-ad01-4cf3-9876-3b7a17d2e336",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "5.Quý",
        "Description": "QR_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d33970c-d826-4a36-ab63-4925658c0513",
        "ParentId": "8f6524f1-f27b-45ce-8ec4-753c8288b40f",
        "Name": "6.Năm",
        "Description": "YR_gNodeB_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/2.gNodeB/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f95c1c39-f6ee-4461-9268-a2701cfd5819",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "3.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "ParentId": "f95c1c39-f6ee-4461-9268-a2701cfd5819",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "48e6f52f-0e0d-4d49-a80c-daaeaa401d63",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "1.Giờ",
        "Description": "HR_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "382ac685-f025-4c68-bb09-d0504cb43623",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "2.Ngày",
        "Description": "DY_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6d579ae-1460-4bde-9627-4cdc4bfc868f",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "3.Tuần",
        "Description": "WK_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a9922a6-80d1-4d23-9c75-71590cdd22e3",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "4.Tháng",
        "Description": "MN_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09cca1c1-ad9c-416a-a855-6e5f742ec1bf",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "5.Quý",
        "Description": "QR_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfff6a76-f39e-46ee-ac94-4083b9f01e5c",
        "ParentId": "993e2fc0-022e-41eb-9918-089bfe6ac932",
        "Name": "6.Năm",
        "Description": "YR_District_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/3.District/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d55b3a3d-77f9-46a4-b858-34a5aa496330",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "ParentId": "d55b3a3d-77f9-46a4-b858-34a5aa496330",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3be66a46-4c55-4964-88f3-e8ce53528c6b",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "1.Giờ",
        "Description": "HR_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5d54bae-0da6-4025-b8c6-1537a6e801c6",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "2.Ngày",
        "Description": "DY_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13583200-8cc9-4c74-9b6e-4553255eb51c",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "3.Tuần",
        "Description": "WK_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c141e549-cbd1-4c2f-948f-f7a422bbda24",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "4.Tháng",
        "Description": "MN_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "48d8cd5a-bd55-4dd9-bd42-94df337b8ea1",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "5.Quý",
        "Description": "QR_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c2375f3d-f643-4812-9915-3ca00738eb4d",
        "ParentId": "f93067f9-fb05-4fe3-9f3f-f258a832267c",
        "Name": "6.Năm",
        "Description": "YR_Province_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/4.Province/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7ffae61-7cad-4796-be41-4360bd4a4682",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "5.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "ParentId": "f7ffae61-7cad-4796-be41-4360bd4a4682",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c73e191-4f88-4ec8-af7b-c9165ab36866",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "1.Giờ",
        "Description": "HR_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7539c35-067c-44a0-a804-7cefb516563e",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "2.Ngày",
        "Description": "DY_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7c07c9a-a42a-4de5-8f83-efbf181fea5f",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "3.Tuần",
        "Description": "WK_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dbe73377-b825-4ddd-844b-7c26da26a1d6",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "4.Tháng",
        "Description": "MN_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "35fc68f8-dfd6-482a-941e-a14548142c7e",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "5.Quý",
        "Description": "QR_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca167eaa-ead0-410a-abab-d2ca91bba39b",
        "ParentId": "2b695022-a63c-4ad5-9aeb-000e53eadbe6",
        "Name": "6.Năm",
        "Description": "YR_TVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/5.TVT/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2321d8d1-20e3-466b-a7dc-98a1c0d23936",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "6.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "ParentId": "2321d8d1-20e3-466b-a7dc-98a1c0d23936",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d80cf9b-f8ac-48c9-bff5-56c570ddecf1",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "1.Giờ",
        "Description": "HR_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "193c36a3-7673-4ee6-aa6f-313a25a0e581",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "2.Ngày",
        "Description": "DY_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8f12bba-2303-428d-97bc-4d312bd7b521",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "3.Tuần",
        "Description": "WK_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b4e0fd9-7444-47b5-956c-496898fc9969",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "4.Tháng",
        "Description": "MN_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a1cb564-44d8-468b-88f5-ea6f791d973d",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "5.Quý",
        "Description": "QR_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "555aff17-bd2f-403c-9daa-1b325291d0d0",
        "ParentId": "75c0e57b-6fd7-4d55-852f-0f7cc49520ab",
        "Name": "6.Năm",
        "Description": "YR_DVT_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/6.DVT/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17278ee7-04ff-4fbc-a442-1ec6bbf08845",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "ParentId": "17278ee7-04ff-4fbc-a442-1ec6bbf08845",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e723836d-2224-4ecf-8eb8-49bc1525828a",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "1.Giờ",
        "Description": "HR_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "53929ca8-32df-47ce-9e15-964830ee292e",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "2.Ngày",
        "Description": "DY_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6d2d4be-141a-4f79-a50c-9839b99f6b5a",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "3.Tuần",
        "Description": "WK_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ccc9113a-8ed1-4318-ba55-276534bb5a24",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "4.Tháng",
        "Description": "MN_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8490598d-9a27-44dc-88cc-b07d42e14eb2",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "5.Quý",
        "Description": "QR_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16cd2c4d-4e03-4b06-aa76-14cacef6b772",
        "ParentId": "336116a5-1612-4930-a6b2-5a65d81bc087",
        "Name": "6.Năm",
        "Description": "YR_Branch_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/7.Branch/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b3fe19b-6278-4d7f-bd66-71c675260b99",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31094101-a970-4f26-9704-f25eab51e150",
        "ParentId": "1b3fe19b-6278-4d7f-bd66-71c675260b99",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0ca9a20-02d7-4c93-9fff-466165d733fa",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "1.Giờ",
        "Description": "HR_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2e72cbf-adde-4b1a-bf6a-2a46946884c1",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "2.Ngày",
        "Description": "DY_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0812be52-d1a8-4bcc-88ad-5a3661bb7923",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "3.Tuần",
        "Description": "WK_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b05f2ab-35c5-4220-9e4a-84ece00c80a5",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "4.Tháng",
        "Description": "MN_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32022500-05cc-44bb-ae6b-89a73f20ac4e",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "5.Quý",
        "Description": "QR_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70ea0dee-8f8f-4628-9a4d-d39da76ad345",
        "ParentId": "31094101-a970-4f26-9704-f25eab51e150",
        "Name": "6.Năm",
        "Description": "YR_CTKD_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/8.CTKD/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34b8e313-d749-4646-86f6-f81737621c2a",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "9.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "ParentId": "34b8e313-d749-4646-86f6-f81737621c2a",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab86052e-f7b0-4c37-839e-1f4174819f75",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "1.Giờ",
        "Description": "HR_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23016682-e7bf-4e98-a0c4-8a1653548828",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "2.Ngày",
        "Description": "DY_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d4e65e4-f745-4b6b-a952-200212d8d146",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "3.Tuần",
        "Description": "WK_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42968ba3-3512-48ae-9731-84978e389d83",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "4.Tháng",
        "Description": "MN_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb891746-862b-45e8-93dc-703719220bbd",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "5.Quý",
        "Description": "QR_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a119812c-8fa8-4099-8aae-151adfb0034b",
        "ParentId": "2adf9bea-da25-41cc-9dd9-fa61e90a37a8",
        "Name": "6.Năm",
        "Description": "YR_TTML_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/9.TTML/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cdd66054-e068-47d9-9cb3-0ba1be8a0703",
        "ParentId": "df9e66bf-03af-4d6e-898c-642aaa2c5820",
        "Name": "91.Vendor",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/91.Vendor",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "553d2ac2-c0a1-4d98-a8d9-2f38b3d1a6a1",
        "ParentId": "cdd66054-e068-47d9-9cb3-0ba1be8a0703",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/91.Vendor/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6eca2a0-3ed9-41ee-b4ad-d4be39e92ad8",
        "ParentId": "553d2ac2-c0a1-4d98-a8d9-2f38b3d1a6a1",
        "Name": "2.Ngày",
        "Description": "DY_Vendor_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/31.ran5g(thử nghiệm)/91.Vendor/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "32.SITE_HATANG",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d3eeae8-a0e1-4010-90c8-9834f5664326",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "1.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG/1.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7401e46-f1b3-4577-9b6a-a8bb41498f55",
        "ParentId": "1d3eeae8-a0e1-4010-90c8-9834f5664326",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/1.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec3c9174-b72e-4ae5-bf11-32cca613955f",
        "ParentId": "1d3eeae8-a0e1-4010-90c8-9834f5664326",
        "Name": "2.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/1.TTML/2.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "beae911a-fe32-4f3d-878d-7d5896548063",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "2.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG/2.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5678049b-12b4-42c7-89c8-87a199961b01",
        "ParentId": "beae911a-fe32-4f3d-878d-7d5896548063",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/2.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d82abd53-4e04-42c0-9d37-b6f6fb1503dc",
        "ParentId": "beae911a-fe32-4f3d-878d-7d5896548063",
        "Name": "2.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/2.CTKD/2.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07001c51-8a75-4d8b-b2c8-be6ff24eb217",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "3.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG/3.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eacc0dbd-c65f-4c40-85f1-12b49bd7a6bb",
        "ParentId": "07001c51-8a75-4d8b-b2c8-be6ff24eb217",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/3.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00369ca8-588d-4314-9575-c697fcb12e21",
        "ParentId": "07001c51-8a75-4d8b-b2c8-be6ff24eb217",
        "Name": "2.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/3.Province/2.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81164d76-cd6f-4d1c-99bd-993ad7b5d53a",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "4.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG/4.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "962c5a70-64c7-4e2d-bc93-b089806c18f9",
        "ParentId": "81164d76-cd6f-4d1c-99bd-993ad7b5d53a",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/4.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ba4b817-2ddb-42b9-b0cb-806fec2b4ceb",
        "ParentId": "81164d76-cd6f-4d1c-99bd-993ad7b5d53a",
        "Name": "2.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/4.District/2.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9494c4d-10ae-4c82-aa97-5bfd68153299",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "5.Chi tiết Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/32.SITE_HATANG/5.Chi tiết Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5973e4ae-fba8-43f1-ba99-f6a213fc93c5",
        "ParentId": "c9494c4d-10ae-4c82-aa97-5bfd68153299",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/5.Chi tiết Site/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "80e5b976-7e99-49b1-8538-28442f61ff18",
        "ParentId": "c9494c4d-10ae-4c82-aa97-5bfd68153299",
        "Name": "2.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/5.Chi tiết Site/2.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "622ee117-e1ad-4351-be5d-3914e1b2dcb1",
        "ParentId": "ffc2877d-a8c2-44e5-b515-6691498881fe",
        "Name": "6.Cảnh báo tọa độ gần nhau",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/32.SITE_HATANG/6.Cảnh báo tọa độ gần nhau",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "4.cscore",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "ParentId": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "Name": "0.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7eecf8c3-2cb3-432d-98ec-81428c64b253",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "0.Giờ",
        "Description": "HR_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcddd914-d292-4f8a-b471-bf449f9dbd86",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "1.Ngày",
        "Description": "DY_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9279183f-fdbb-4776-8002-518ed58d4c67",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "2.Tuần",
        "Description": "Wk_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a359c060-4922-423a-b119-3aac406c5939",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "3.Tháng",
        "Description": "MN_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "710e6da7-2f59-4a15-847c-c045ed77867c",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "4.Quý",
        "Description": "QR_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51e62490-4091-4b98-bbc2-814b831bf52a",
        "ParentId": "a0ec2631-3a41-40c9-bdc3-73edd7d51093",
        "Name": "5.Năm",
        "Description": "YR_cs_msc_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/0.General/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Authentication",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "675cae2d-5763-489b-828f-10316f6ea51f",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "1.Giờ",
        "Description": "HR_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "adff35b3-fad3-4efa-8211-5013981fc7c4",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "2.Ngày",
        "Description": "DY_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d54e56a7-4450-4439-b1aa-aa8e8aadf769",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "3.Tuần",
        "Description": "WK_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab8b8fbd-a61d-42b5-a3d5-3f7274814f65",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "4.Tháng",
        "Description": "MN_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "949ca9f6-fb39-45b9-9957-6ac51f7c9583",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "5.Quý",
        "Description": "QR_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c172cb80-29ae-4bf8-989c-0aef8e5294cf",
        "ParentId": "bc89febe-c74b-46d8-9296-134a585f81a2",
        "Name": "6.Năm",
        "Description": "YR_cs_au",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Authentication/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "CAP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "baae699c-20f5-4ec2-8149-8b10316ca47b",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "1.Giờ",
        "Description": "HR_cs_Sig.processCap",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "722ac670-c083-49dc-a33b-7dd37dd1ac78",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "2.Ngày",
        "Description": "DY_cs_Sig.processCap",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5707945b-d459-4eb3-9c5d-a85392ceeb0f",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "3.Tuần",
        "Description": "WK_cs_Sig.processCap",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0ff28a8-bbc0-4ff7-87ae-99bab30ded6b",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "4.Tháng",
        "Description": "MN_cs_Sig.processCAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "98b99e44-d7a0-4a2d-bd12-61b975c3c1da",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "5.Quý",
        "Description": "QR_cs_Sig.processCAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "782626eb-fa37-4288-80e4-55a1fd055a9a",
        "ParentId": "742054e8-8b93-4215-9c29-f2b6f10a7c3f",
        "Name": "6.Năm",
        "Description": "YR_cs_Sig.processCAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/CAP/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Channel-Assign",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d76a158b-490e-44af-9f9a-63fb35ad67ca",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "1.Giờ",
        "Description": "HR_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37ffb602-3ee7-477e-b747-1dff7d242204",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "2.Ngày",
        "Description": "DY_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47b571bd-e6d6-4431-9718-558b4fd5b6a8",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "3.Tuần",
        "Description": "WK_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c0d8fd0-fc77-4588-832a-f5f0e622b977",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "4.Tháng",
        "Description": "MN_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8dc0e12-2664-41de-a9ca-665185febc83",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "5.Quý",
        "Description": "QR_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18abc15d-32dc-4bff-a06a-11987bb15383",
        "ParentId": "bb061875-291f-41bf-b59d-e1210d3277b1",
        "Name": "6.Năm",
        "Description": "YR_cs_assign",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Channel-Assign/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Ciphering",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f2facf1-1234-438f-9c12-99a5ed113b2f",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "1.Giờ",
        "Description": "DY_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9e35f2bd-fc92-41f6-8549-65159da96d7f",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "2.Ngày",
        "Description": "DY_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "940b3dc5-88a7-4f76-bef2-9c20b47e226f",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "3.Tuần",
        "Description": "WK_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "796f1772-ab51-40f0-a8bb-deb422c829d5",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "4.Tháng",
        "Description": "MN_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3322c0b3-b003-467e-9cd8-d22c013123b1",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "5.Quý",
        "Description": "QR_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a836210-482d-4a48-a8f7-70943a99a8a2",
        "ParentId": "ec7e3766-d394-44f8-97cb-6c3843fb95ae",
        "Name": "6.Năm",
        "Description": "YR_cs_cip",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Ciphering/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9b67674a-aba7-4d56-b877-4298673c1317",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Handover",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b727ab4f-45a2-4a00-b130-669120323a83",
        "ParentId": "9b67674a-aba7-4d56-b877-4298673c1317",
        "Name": "HO_Total",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7481c49e-c8ba-466c-8628-0dcdd07dccfe",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "1.Giờ",
        "Description": "HR_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23122de5-db4b-408b-b619-d7ecfc7ec80a",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "2.Ngày",
        "Description": "DY_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "88ca2df4-1d69-417f-aee2-4733d1881708",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "3.Tuần",
        "Description": "WK_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "007f0ed7-9090-400d-9065-9fe3a910efa5",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "4.Tháng",
        "Description": "MN_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab91c3b1-e76e-4219-974c-60abef979883",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "5.Quý",
        "Description": "QR_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c8395ef-7fbf-4d4f-bb32-178eb738a623",
        "ParentId": "b727ab4f-45a2-4a00-b130-669120323a83",
        "Name": "6.Năm",
        "Description": "YR_cs_ho",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO_Total/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "ParentId": "9b67674a-aba7-4d56-b877-4298673c1317",
        "Name": "HO2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "12d9c50f-4854-43c8-8555-c3fbb148d2d2",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "1.Giờ",
        "Description": "HR_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae60358c-dd27-4a68-a41d-95b30355bbe7",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "2.Ngày",
        "Description": "DY_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9f4769b-cc8c-4250-b1d7-eb640b6b557e",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "3.Tuần",
        "Description": "WK_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f486eba-997e-4108-9100-edd690f297c5",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "4.Tháng",
        "Description": "MN_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f24d8bb1-9ebd-41e2-906c-66437d8587dc",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "5.Quý",
        "Description": "QR_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86d62b03-578d-48d0-8236-cad6303274bb",
        "ParentId": "c8f2ac15-0cc8-422c-809d-00c56b4b8d1c",
        "Name": "6.Năm",
        "Description": "YR_cs_ho2g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "ParentId": "9b67674a-aba7-4d56-b877-4298673c1317",
        "Name": "HO3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16657cdf-259d-497d-8339-1e4c50b1d48e",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "1.Giờ",
        "Description": "HR_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42002520-7416-4b66-bf2e-46eda6fe1d32",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "2.Ngày",
        "Description": "DY_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a001d33-f956-4b1a-9ffb-910f0d322607",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "3.Tuần",
        "Description": "WK_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef1fc83d-22a7-40d1-9164-f3a6d5fd8571",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "4.Tháng",
        "Description": "MN_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "466e3f2e-f59a-4bf6-a7a1-628a7099ffb5",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "5.Quý",
        "Description": "QR_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0cde18a-5081-4c85-aef3-55765e920b94",
        "ParentId": "a62cbeee-da5a-4bd0-b136-f917bcc9525c",
        "Name": "6.Năm",
        "Description": "YR_cs_ho3g",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Handover/HO3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Load",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22241069-0e16-4d54-bb52-648695d2aba7",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "1.Giờ",
        "Description": "HR_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f5840d9f-5b57-4471-a1bf-6246b5d022bc",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "2.Ngày",
        "Description": "DY_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd559e6b-2c9b-4268-aa70-d68c8106e841",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "3.Tuần",
        "Description": "WK_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc1accb0-843c-4c4f-8744-b3f23b7a38ef",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "4.Tháng",
        "Description": "MN_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd998317-11a1-423d-b285-319ff1e0d846",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "5.Quý",
        "Description": "QR_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9dc37286-9541-4ed2-ba49-4d4a5e64f784",
        "ParentId": "9a59987b-08c7-4074-8950-97eb196b9e2f",
        "Name": "6.Năm",
        "Description": "YR_cs_load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Load/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb796525-46ac-4b4d-9d53-80d9f02c28c5",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Location-Update",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "ParentId": "fb796525-46ac-4b4d-9d53-80d9f02c28c5",
        "Name": "Global",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "045a3e02-dda9-425e-9db6-ceb65ff013b1",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "1.Giờ",
        "Description": "HR_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8c38456-0768-4271-bb35-03a64c020194",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "2.Ngày",
        "Description": "DY_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "71b629a2-20b6-49b9-b09d-dddad0624998",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "3.Tuần",
        "Description": "WK_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1027f128-ee7c-478e-8758-890af081f966",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "4.Tháng",
        "Description": "MN_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d919ec9e-83b8-4676-a919-1e0ef2dd9ac5",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "5.Quý",
        "Description": "QR_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23c7a8ac-ac1e-45cb-bf29-e29f716ff2c3",
        "ParentId": "85c6fa31-28c3-4d34-9040-ba65021351fa",
        "Name": "6.Năm",
        "Description": "YR_cs_LU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/Global/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "ParentId": "fb796525-46ac-4b4d-9d53-80d9f02c28c5",
        "Name": "LAC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "897290f3-41de-49f1-aad0-a1cff0063e3c",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "1.Giờ",
        "Description": "HR_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c17b45b-6259-47af-ba34-5ca1dece5921",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "2.Ngày",
        "Description": "DY_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c720a94d-2ec9-42c5-9559-c382ad42f09c",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "3.Tuần",
        "Description": "WK_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9737c0d4-cded-4956-b7a4-b4845a8610e9",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "4.Tháng",
        "Description": "MN_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df368312-d7f5-4988-9a0d-38588025e544",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "5.Quý",
        "Description": "QR_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3506bab8-6a35-4030-b75d-779672c49173",
        "ParentId": "d0c117d3-6ecd-4398-a2c2-a3666ad1e9f3",
        "Name": "6.Năm",
        "Description": "YR_cs_LUlac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Location-Update/LAC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7205866-9258-4c28-b4b2-8f16aabe516b",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Paging",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "ParentId": "f7205866-9258-4c28-b4b2-8f16aabe516b",
        "Name": "Global",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9b384287-dfa4-426f-b756-3668ae0061d3",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "1.Giờ",
        "Description": "DY_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "515c6086-b2b1-4e32-8c0c-cf4b7c876d03",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "2.Ngày",
        "Description": "DY_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "255e8091-b559-43a5-9533-ed69977408eb",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "3.Tuần",
        "Description": "WK_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ea1b2e5-bd96-454c-8959-0e0d7873abae",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "4.Tháng",
        "Description": "MN_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5be725b-5506-4bee-9cf5-54ada47bdf65",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "5.Quý",
        "Description": "QR_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c274a6b-18c2-499a-8956-9b8486384e2d",
        "ParentId": "9c28973e-a7cc-4032-bd86-f6166bd745fc",
        "Name": "6.Năm",
        "Description": "YR_cs_pag",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/Global/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "ParentId": "f7205866-9258-4c28-b4b2-8f16aabe516b",
        "Name": "LAC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d41c6ee8-4585-4ee0-bff0-ced1afd3bf99",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "1.Giờ",
        "Description": "DY_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3eae3ec-36ac-4f18-b494-6943b13351aa",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "2.Ngày",
        "Description": "DY_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b2b90f0-6d3d-4481-9815-a6848960b139",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "3.Tuần",
        "Description": "WK_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7bde5cba-f03d-4e38-a578-4e53edb783c5",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "4.Tháng",
        "Description": "MN_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b3e48b3-345a-40af-9870-8150b7b856f7",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "5.Quý",
        "Description": "QR_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05c2842c-557d-4510-a2c9-30cae10cd285",
        "ParentId": "6ef547be-66bd-43be-8b8a-9da8bbc4d560",
        "Name": "6.Năm",
        "Description": "YR_cs_paglac",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Paging/LAC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94944af4-bf89-4590-b8d9-5783fcc90b32",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Signal-Process",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "ParentId": "94944af4-bf89-4590-b8d9-5783fcc90b32",
        "Name": "Map-Operation",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "582178e7-4402-4f50-aa40-28454b72a5ce",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "1.Giờ",
        "Description": "HR_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f29f5d18-c567-45e5-9f34-df7236fa1dd3",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "2.Ngày",
        "Description": "DY_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a003bb6-5abe-482b-bd3d-609f37223764",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "3.Tuần",
        "Description": "WK_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e80f2d2-abb1-4745-82f2-da77e8a688f1",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "4.Tháng",
        "Description": "MN_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8fb8592-7266-41bc-9af6-486e87e21b5a",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "5.Quý",
        "Description": "QR_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f961226-b588-4c52-b919-c0547a3df1f9",
        "ParentId": "cef1998b-7c70-42eb-918e-716a03d4bf40",
        "Name": "6.Năm",
        "Description": "YR_cs_Sig.processMAP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Signal-Process/Map-Operation/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3302959b-2fc2-4b69-bff8-7a20c8784b0d",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "1.Giờ",
        "Description": "DY_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ded42e6-14f5-45d9-89d8-4e209be7fed5",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "2.Ngày",
        "Description": "DY_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6fc8b68-0bd8-4f48-afa7-dc2e8cc182c8",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "3.Tuần",
        "Description": "WK_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0584dab8-076d-4c4f-9c17-f62412e94a6f",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "4.Tháng",
        "Description": "MN_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1be4c1cf-aea1-497e-be58-6e8543a1133d",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "5.Quý",
        "Description": "QR_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8305b20b-36ae-4ae5-b176-a19adb0fd2be",
        "ParentId": "6152fdc0-ec80-43bf-b853-f88ccec704e4",
        "Name": "6.Năm",
        "Description": "YR_cs_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/SMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d888edb7-6781-4129-a388-3ee453c8f106",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30d1b779-04e0-4a28-8815-b46f80183da6",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "1.Giờ",
        "Description": "HR_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a3b0f57-5863-4013-9f44-24ff328f32c3",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "2.Ngày",
        "Description": "DY_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a01a7982-426c-4d25-bcb1-9271d2493fbb",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "3.Tuần",
        "Description": "WK_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14953f4c-892e-48aa-99c3-9beab5d7a7d5",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "4.Tháng",
        "Description": "MN_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f0fc2e7-8d82-49fd-9b0c-9b44b28d83a9",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "5.Quý",
        "Description": "QR_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "589127a8-8410-4fe7-9147-263e53398ffb",
        "ParentId": "d888edb7-6781-4129-a388-3ee453c8f106",
        "Name": "6.Năm",
        "Description": "YR_cs_traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "832816be-1546-4639-bae8-6b575c962845",
        "ParentId": "25900145-d9db-42d0-85f8-c189c65fd0b5",
        "Name": "VRLsubs",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "ParentId": "832816be-1546-4639-bae8-6b575c962845",
        "Name": "LAC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e44298d-00a9-49b8-beb8-a631f97d0655",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "1.Giờ",
        "Description": "HR_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "599e9240-571e-45a7-963b-91bd79267ee1",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "2.Ngày",
        "Description": "DY_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "747cae34-0353-401a-98e3-b9925cf9eedd",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "3.Tuần",
        "Description": "WK_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e370390-17d1-42cd-995c-3f686cf011c1",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "4.Tháng",
        "Description": "MN_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff9d8c95-fd66-4ce6-9505-17d3613083a0",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "5.Quý",
        "Description": "QR_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef03a8b7-f5f3-4d64-b726-e0a8937fea72",
        "ParentId": "ae6a561f-bf16-493e-80b1-ca6a201ebdb7",
        "Name": "6.Năm",
        "Description": "MN_cs_VLRSubsLAC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/LAC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "ParentId": "832816be-1546-4639-bae8-6b575c962845",
        "Name": "MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffa5322d-dda1-49ba-84a8-a80d2f2a44b1",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "1.Giờ",
        "Description": "HR_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b43eec9b-8806-40f2-80bf-064a73a718bd",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "2.Ngày",
        "Description": "DY_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0b48aad-7075-4683-8958-95f815912450",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "3.Tuần",
        "Description": "WK_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ea7f1b8-8e95-423b-875a-cda638031f37",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "4.Tháng",
        "Description": "MN_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2d877ca-c8d1-46bf-81fd-3b5ced4ab906",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "5.Quý",
        "Description": "QR_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01a94ab3-2d4b-4402-a462-2d7ab940d05a",
        "ParentId": "106eba2d-9740-43b5-a9c7-44b118da6b4e",
        "Name": "6.Năm",
        "Description": "YR_cs_VLRSubs",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/MSC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "ParentId": "832816be-1546-4639-bae8-6b575c962845",
        "Name": "Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "62bb6a41-98a9-423a-8f6c-bf5f0ec85c11",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "1.Giờ",
        "Description": "DY_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "844b4b17-2a0a-4e10-a4db-602cacde191f",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "2.Ngày",
        "Description": "DY_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e86867ef-e21f-4d8f-b68d-1009d7f3e84b",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "3.Tuần",
        "Description": "WK_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce1208db-8cf3-46b5-9499-2f8f4cbfa774",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "4.Tháng",
        "Description": "MN_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abc4cd44-8031-4e64-ae56-80f0367eb438",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "5.Quý",
        "Description": "QR_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b77e000c-4fee-41a5-9371-0d65e72c17f3",
        "ParentId": "31e78cd7-6c3a-4e7f-ab9e-1efdc0bf1da4",
        "Name": "6.Năm",
        "Description": "YR_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Province/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "ParentId": "832816be-1546-4639-bae8-6b575c962845",
        "Name": "Tỉnh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfbfcf10-9d5b-424f-91d6-c8f800524da5",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "1.Giờ",
        "Description": "DY_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aaad2f49-67ba-4c4c-8336-c69100f499da",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "2.Ngày",
        "Description": "DY_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1fffc47-b283-4f6b-8153-e37af08b5fd0",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "3.Tuần",
        "Description": "WK_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ac4c0a7-cee3-49fa-8fa0-180b25e9371e",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "4.Tháng",
        "Description": "MN_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22813fbf-b44b-4dda-83ad-c28e41d7cde0",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "5.Quý",
        "Description": "QR_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03f2b075-d94b-4bd0-b129-575d0a02dc19",
        "ParentId": "285f5903-bdd4-4132-b8e1-d65fb7d26c0c",
        "Name": "6.Năm",
        "Description": "YR_cs_VLRSubsPro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/0.MSC/VRLsubs/Tỉnh/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffffe0c2-406a-4c50-89a5-ce3495c6ba29",
        "ParentId": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "Name": "2.STP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b025a77c-cc8e-40d9-a304-431c88d6675d",
        "ParentId": "ffffe0c2-406a-4c50-89a5-ce3495c6ba29",
        "Name": "0.MNPSTP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "ParentId": "b025a77c-cc8e-40d9-a304-431c88d6675d",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "476d881f-87ca-4d2c-923a-b567a3ec23a9",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "1.Giờ",
        "Description": "HR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a693077b-19a8-41e5-ba35-25a80fa89ec3",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "2.Ngày",
        "Description": "DY_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd2a1e33-5238-44b0-b88e-94fb7a28ab1f",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "3.Tuần",
        "Description": "WK_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8176cef-ee72-4cb4-8a78-08d26bdbbdd7",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "4.Tháng",
        "Description": "MN_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7629e21-d699-429d-9db4-3225a7e12739",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "5.Quý",
        "Description": "QR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d367e712-8a9e-47bb-b044-2733cb2d5cba",
        "ParentId": "b6f25fad-f394-450a-b9b7-3c846c78d60a",
        "Name": "6.Năm",
        "Description": "YR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "ParentId": "b025a77c-cc8e-40d9-a304-431c88d6675d",
        "Name": "Signal-Link",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a07be77-4652-44f2-97fb-b5d1b476eb29",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "1.Giờ",
        "Description": "HR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09f67c06-9c49-41fe-96e7-444d699a6714",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "2.Ngày",
        "Description": "DY_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef39de57-cdb6-43a7-ba49-1ac0d2f76115",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "3.Tuần",
        "Description": "WK_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "173b7a8f-13d9-4b7a-aa11-f9f72ba3cfb5",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "4.Tháng",
        "Description": "MN_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a75a081-e452-4a0f-ab3a-7507b1be5bee",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "5.Quý",
        "Description": "QR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb3dfa74-01c4-4515-afb9-0a1b0d466901",
        "ParentId": "a4215abf-ad4f-4df8-be37-783cc1ab2b20",
        "Name": "6.Năm",
        "Description": "YR_MNP_MNPSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/0.MNPSTP/Signal-Link/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "ParentId": "ffffe0c2-406a-4c50-89a5-ce3495c6ba29",
        "Name": "1.iSTP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4e8aff6-4f03-4963-b1b5-1a6b2ef413ac",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ade7879-b8b3-4a34-b6b1-dd86f8c8d78a",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cef79562-0da6-4ab2-a4df-616674f8720f",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c3840a0-d34a-4d7e-bf27-38503d4d6b0a",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "230288a6-a5be-4db9-bba5-28c5a8a9d338",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39ea1d64-5590-4101-9bf8-4807d00c6bb7",
        "ParentId": "e68f5420-20b6-4f8f-a5f1-6dd4be1ae8c6",
        "Name": "6.Năm",
        "Description": "QR_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "1.Signal-Link",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ea2d852-9559-428a-9cdc-d57dc9e948cc",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e92d6a4-24ed-46ab-bb1b-7caaeb12a616",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Signal_Link",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1aac66a7-f707-4996-a940-a66ed4b21635",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Signal_Link",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c1fb426-3977-44fd-89ce-b5b26124ef13",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Signal_Link",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "126cd1b6-8529-4827-89af-fb15c64d10a6",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Signal_Link",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "beb06908-dd6c-4604-ba6b-88c40e3e4f88",
        "ParentId": "08c60c5d-993a-4314-9793-504b78e16e2d",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Signal_Link",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/1.Signal-Link/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "12.Roaming IN Network",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3c9f458-d4f4-4ed1-8aba-be67ec7bc922",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "1.Giờ",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51a0dc8c-07d2-4dd9-af9e-e4c4f59e9bd5",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af467b88-eee9-4c09-8b9a-25cb7e49b7cf",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "3.Tuần",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6f26ebe-4c2a-4f12-9c35-02ba05f2337e",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "4.Tháng",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "598eb6e7-da20-4bdb-a659-478472d8c13b",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "5.Quý",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7ff298c-3d74-4f6f-8915-73ad39df19ab",
        "ParentId": "07ecfc91-ab29-427c-8b13-f0ee994089bb",
        "Name": "6.Năm",
        "Description": "DY_CQT_iSTP_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/12.Roaming IN Network/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "2.Roaming-IN-QoS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08f06e3a-8c83-4007-9079-cf0bdc85f566",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9fbc87f5-fcca-459d-b60d-5bc42337cf2d",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42e9a621-ccd8-4091-a544-12c2e2c9894e",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24a63327-f00e-44d8-9bfa-441acc5fb9c1",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a2735cf9-5edb-4fc0-90ef-dfadd0b76252",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7f04c80-fa6a-45b9-ba11-1aa91c3bc322",
        "ParentId": "25d60e55-cac2-4696-ba09-875e0e6b7272",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_IN_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/2.Roaming-IN-QoS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "21.Roaming IN QoS theo quốc gia_đối tác",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09f0cdfd-6d18-45e5-b488-5a123785fece",
        "ParentId": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec977716-05a4-4bba-b7b3-697349fc5c72",
        "ParentId": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56fb878c-09ad-4bab-afdd-3ba9efa499d6",
        "ParentId": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a12097ca-f6bb-465e-9fdc-ad7cbe44a2e7",
        "ParentId": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32a48ad6-9517-4e20-bf0e-45784b73b2be",
        "ParentId": "e7b019b4-0193-4df1-8e78-c0ab3937d4ef",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/21.Roaming IN QoS theo quốc gia_đối tác/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "3.Roaming-IN-MO-SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91d82a2f-ef87-4c1c-b4d6-e68643af30c6",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ae583de-579a-4564-9725-8b25b0312c02",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4e6595c-2b51-4bb3-8867-db2cc402acd0",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b0caf00-0baa-47e6-a68c-60edf43b774f",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "179fd14c-2217-4461-9965-4712d5b9696b",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9abc2e7e-af4c-43ce-99ff-b8e18542e4d3",
        "ParentId": "4d467e10-b293-42f1-a2ff-2956b0965d18",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_IN_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/3.Roaming-IN-MO-SMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74392e43-36e3-4c14-9791-faaace141482",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "4.Roaming-IN-MT-SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3ed3a67-8940-412b-948d-f5802a39cb4f",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "173ac0f2-ba0c-48cf-88c4-2e39ae1ba4b8",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "725d543e-d8df-4761-bd9e-5b88b89cb9d3",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c2c0a0b-cc04-4d33-8e30-c041f84fb3ef",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff51e64c-dd77-496e-9262-b683d15b0a51",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a55daf49-0241-4fc7-8f2c-c913255e8179",
        "ParentId": "74392e43-36e3-4c14-9791-faaace141482",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_IN_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/4.Roaming-IN-MT-SMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "5.Roaming-Out-QoS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9bcf7384-b97d-4ba2-8d0a-7ef554b1fb5a",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff0f6b85-6560-4af0-86fd-6dd1ce6b8dec",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d67036a3-4313-482b-a5f6-e7c8a2ec231f",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b1bb8eed-7e53-4dbc-b073-22f910f875f8",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "623827ee-f684-4f22-90d7-efabf241ce3d",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17573712-8ce3-4dac-b6bc-44a23291105f",
        "ParentId": "b8e791c3-4a47-4be5-b4a4-017c9bc7c3e9",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_Out_QoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/5.Roaming-Out-QoS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "51.Roaming OUT QoS theo quốc gia_đối tác",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef3f80cf-48bf-433b-afb5-3fb0b8c926d4",
        "ParentId": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3aee98b-bdd4-4683-81b0-c3c57f75b453",
        "ParentId": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d3c5fcf-472b-49fd-b7c8-d606040804d3",
        "ParentId": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e72130a8-dcb5-45de-aa9f-3e0fea34cb3b",
        "ParentId": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f900ed6-4783-4546-8391-f5c23d7156e8",
        "ParentId": "17c431c6-94e6-4c08-a4d6-4d241816efc4",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/51.Roaming OUT QoS theo quốc gia_đối tác/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "6.Roaming-Out-MO-SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c885f87-53e5-49b8-b0ce-1da83cf26632",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "57a8e59f-4354-476c-a7fc-2a540b3d1ed0",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d29e3701-3d30-489c-b722-7643893b715d",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "699b6091-a093-4ef5-ba93-52c492a4512e",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c4c8a89-3424-4e8d-9c92-9552a1112c77",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2fa63d5f-22d3-46b3-8f93-463727f208b5",
        "ParentId": "bc149a69-6dfb-468f-9ca7-dddbbd83a788",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_Out_MO_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/6.Roaming-Out-MO-SMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "7.Roaming-Out-MT-SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd0312c8-efb3-45bd-9d55-372ad3778156",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef2986e1-2676-4f36-983f-0275b13b4b23",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3831c1ba-09c7-4918-9207-8f1afb1897c4",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8d3065a-5aff-4b79-ab9f-3a1bf8a3d2e0",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e8aeb9f-5c5a-4a07-8226-9c62ebdb8aa4",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09056d16-e0e1-4f1e-a0ab-dc05968f78c3",
        "ParentId": "648bc7db-86ba-445c-b8a2-25a5a84c915b",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_Roaming_Out_MT_SMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/7.Roaming-Out-MT-SMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "8.MAP-SRI",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46ab4b80-a3ed-4927-b2dd-ebb1239c45b4",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iSTP_MAP-SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54cc6e8c-b61c-49ca-b334-aef87dd921e8",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iSTP_MAP_SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f74b673-4f9d-4e7c-a415-235ba013d1c5",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iSTP_MAP_SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e89e283-ca0b-4f2e-8f2a-2654e65d4a51",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iSTP_MAP_SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95e81a13-e9e2-441f-91e5-88c200a119e8",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "5.Quý",
        "Description": "QR_CQT_iSTP_MAP_SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "875f0f44-e83a-43f9-9f9d-4574f980cd70",
        "ParentId": "db1e14d1-68cc-4933-8133-a5f3ec28683a",
        "Name": "6.Năm",
        "Description": "YR_CQT_iSTP_MAP_SRI",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/8.MAP-SRI/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "ParentId": "2fc7c858-3acc-4fa9-a51d-210da40fceff",
        "Name": "9.CLI-Nhận dạng đúng số chủ gọi",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c0bc622-9d02-43ee-a5ee-e8de6f14a22a",
        "ParentId": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ffb0fde-d1fa-42a3-9ba3-4e5516c693f0",
        "ParentId": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ccfc75d6-36d7-44a2-85fc-f8bdf2b64cad",
        "ParentId": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6188e53-4847-4bc9-ac30-859f70514eb3",
        "ParentId": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f0248a8-48c5-47a6-859a-51fec3435b86",
        "ParentId": "1c131a1f-5ee3-4c72-8736-ce39c2b83167",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/2.STP/1.iSTP/9.CLI-Nhận dạng đúng số chủ gọi/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4248965f-48b9-4516-b649-300303983f6e",
        "ParentId": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "Name": "3.HLR",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b66abdeb-77d1-4de6-b09f-c14532242d8b",
        "ParentId": "4248965f-48b9-4516-b649-300303983f6e",
        "Name": "HLR-BE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "ParentId": "b66abdeb-77d1-4de6-b09f-c14532242d8b",
        "Name": "0.Subs-Counting",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9a3777c-37b0-43a2-bc0c-b7525468a2d0",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "1.Giờ",
        "Description": "DY_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7159a1c-e8e3-4a5f-9e3f-ec801065ddf2",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "2.Ngày",
        "Description": "DY_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9450898-6afa-4d50-ac76-81b86157c949",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "3.Tuần",
        "Description": "WK_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9bc9654e-21db-43c5-bea9-422415351857",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "4.Tháng",
        "Description": "MN_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "104e895b-a16b-453e-a27e-c42bc7b039dd",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "5.Quý",
        "Description": "QR_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc1eb03f-3f98-4dbd-bb6c-eeab67eaf109",
        "ParentId": "e8962bf7-16b4-407e-b09a-7f248117949d",
        "Name": "6.Năm",
        "Description": "YR_SubsCount",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/0.Subs-Counting/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "ParentId": "b66abdeb-77d1-4de6-b09f-c14532242d8b",
        "Name": "1.CPU LOAD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a83261ad-7e31-4f0b-aaf9-552a86200178",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "1.Giờ",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ff04ec5-8e22-47f0-9f4a-96b304e89272",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "2.Ngày",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7019faa4-916f-47d1-9a15-3054b0b70dd9",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "3.Tuần",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a0752c5-8ff8-4d37-a16f-1777877b6d38",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "4.Tháng",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a7b0ace-2b51-493f-8d16-0d4083303cb2",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "5.Quý",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fab00291-70ae-4810-b30f-7369638c1b78",
        "ParentId": "6829dfcd-2d14-4ac6-b713-3a44b905bfbd",
        "Name": "6.Năm",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/1.CPU LOAD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90773916-c75b-40a0-871c-e12d7c09326e",
        "ParentId": "b66abdeb-77d1-4de6-b09f-c14532242d8b",
        "Name": "2.CPU LOAD DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2cd0d61f-cf56-4d4a-b318-ce95e024676f",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d28add3f-a5fe-4322-9228-e396b2bb67e2",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "470723b9-a0a6-44cf-9d50-9c70548e9764",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "644dc7dc-e12a-4b06-b2cd-e72e080292bb",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "340dd22f-3517-4337-8da7-b7db140c4381",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0608c043-eb13-4a4f-94ba-eafaab38b077",
        "ParentId": "90773916-c75b-40a0-871c-e12d7c09326e",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-BE/2.CPU LOAD DETAIL/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd9ed16c-4f9b-49e3-b254-15ed5239da15",
        "ParentId": "4248965f-48b9-4516-b649-300303983f6e",
        "Name": "HLR-FE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "798f74d9-d61d-4171-ae62-261dccce348c",
        "ParentId": "bd9ed16c-4f9b-49e3-b254-15ed5239da15",
        "Name": "Connection",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "ParentId": "798f74d9-d61d-4171-ae62-261dccce348c",
        "Name": "M3UALink",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "900e95e5-a392-407a-931b-5704c99d2d0b",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "1.Giờ",
        "Description": "DY_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2623304e-6384-4d8d-8dfe-a0878a47db51",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "2.Ngày",
        "Description": "DY_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "820a01ed-2c5e-4c0a-b7cc-abfdb7e0d5af",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "3.Tuần",
        "Description": "WK_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f6203a2-7532-48c8-8d1d-5970145f6691",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "4.Tháng",
        "Description": "MN_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7af8cc85-d7cf-4346-a767-57785e5ee783",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "5.Quý",
        "Description": "QR_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abf600ab-f497-445b-aef2-38f2b4449022",
        "ParentId": "b3deefdd-29c6-4d7c-85c4-d35bc400542a",
        "Name": "6.Năm",
        "Description": "YR_m3ualink",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALink/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "ParentId": "798f74d9-d61d-4171-ae62-261dccce348c",
        "Name": "M3UALinkset",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85b1d998-962d-4434-bec2-12d6a3590f55",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "1.Giờ",
        "Description": "HR_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a147689-e4a8-43af-964b-20a4898528e7",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "2.Ngày",
        "Description": "DY_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dec53cec-eee0-44f3-aba8-276b995b07aa",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "3.Tuần",
        "Description": "WK_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db0bf33c-03cd-4ba5-b24d-5bf2b847daed",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "4.Tháng",
        "Description": "MN_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3bc3ed1b-eb4a-442c-8310-cbe4b1e9fb6d",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "5.Quý",
        "Description": "QR_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ff87ddf-2b3e-40f2-9f89-78d66c9d2446",
        "ParentId": "8b29a6a9-77c7-4731-92f1-97331f79aa36",
        "Name": "6.Năm",
        "Description": "YR_m3ualinkset",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/Connection/M3UALinkset/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "ParentId": "bd9ed16c-4f9b-49e3-b254-15ed5239da15",
        "Name": "CPU LOAD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "ParentId": "bd9ed16c-4f9b-49e3-b254-15ed5239da15",
        "Name": "CPU LOAD DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "667666ca-90f2-479b-85f2-fe87a4d1f941",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "832e876e-c958-48fa-bbaa-924108076ba0",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db958dd8-7c40-4b40-bbea-edf7b0d130cc",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7582ce09-dff0-458e-a3b7-8fd224b04873",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d69a5561-9a29-4d67-b106-f912250d94a9",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9bbb78dd-5113-4548-8dd3-ce0a7de36844",
        "ParentId": "bd5e9ddc-5acc-49b7-9a32-ae5ac24781b6",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD DETAIL/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a52d2bef-82b5-481b-97ac-337ef165bc34",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "1.Giờ",
        "Description": "HR_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bcb3272-27a6-4d6a-a531-50fd5dd6978f",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "2.Ngày",
        "Description": "DY_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ede528f-dc93-4120-a354-f1adad379c0c",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "3.Tuần",
        "Description": "WK_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c0acfff-76ac-4e55-a160-ba176238c9d9",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "4.Tháng",
        "Description": "MN_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1b96f8b-bba8-447f-a86c-d793ee0b3623",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "5.Quý",
        "Description": "QR_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c05500af-f760-40f1-a543-6e20db6bf992",
        "ParentId": "b21c5fc2-9f8f-49f1-8a63-6898fc377bd0",
        "Name": "6.Năm",
        "Description": "YR_FEload",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/CPU LOAD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "ParentId": "bd9ed16c-4f9b-49e3-b254-15ed5239da15",
        "Name": "MAP-Process",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6c2b1aa-9496-42d6-bfb3-03450861b165",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "1.Giờ",
        "Description": "HR_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ef4cda8-cafd-4620-ad37-db74a2f05a81",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "2.Ngày",
        "Description": "DY_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ee6abacd-2cf3-43cc-acd9-3019a4badd64",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "3.Tuần",
        "Description": "WK_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c74bc1db-4c79-46e4-934c-bdb24d3daa9c",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "4.Tháng",
        "Description": "MN_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "660a1ef0-ab80-4ea8-864b-91df4ce1b409",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "5.Quý",
        "Description": "QR_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31181c89-dd1a-4f69-8162-271542cf99c5",
        "ParentId": "5bf59a66-db72-46b2-bf4b-f43830041783",
        "Name": "6.Năm",
        "Description": "YR_MAPProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/HLR-FE/MAP-Process/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "ParentId": "4248965f-48b9-4516-b649-300303983f6e",
        "Name": "Tải HLR",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed9e28b5-70b6-4585-a739-beb2b0404050",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "1.Giờ",
        "Description": "HR_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8c763f7-2b0d-4e8e-b443-554527cf8808",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "2.Ngày",
        "Description": "DY_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a54e404-f11f-4614-9295-54284900868a",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "3.Tuần",
        "Description": "WK_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "118e249a-ef83-42d5-84fe-9beccde0f3a9",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "4.Tháng",
        "Description": "MN_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5d2ecafd-fb51-4d3c-bedd-648b5d06871c",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "5.Quý",
        "Description": "QR_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0614271f-25ce-4f56-8af6-7674f2344686",
        "ParentId": "71d15bbb-59a6-4e1b-ab09-a6989238ccee",
        "Name": "6.Năm",
        "Description": "YR_DBProcess",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/3.HLR/Tải HLR/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d81c9dd-5fdf-408e-be4a-d2388538a3f6",
        "ParentId": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "Name": "4.GW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f7a3446-5590-4964-a8a2-b28e360a39d7",
        "ParentId": "8d81c9dd-5fdf-408e-be4a-d2388538a3f6",
        "Name": "0.MNPGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "ParentId": "7f7a3446-5590-4964-a8a2-b28e360a39d7",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab00cdc2-e523-4246-989f-163888f68ec9",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "1.Giờ",
        "Description": "DY_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9e2a2787-6e4c-49f0-832b-cf59c0a779f9",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "2.Ngày",
        "Description": "DY_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da351aae-534e-4aed-8aab-3527c1659353",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "3.Tuần",
        "Description": "WK_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45baa914-c1c5-4ebc-bb86-6513e90a227c",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "4.Tháng",
        "Description": "MN_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3acd4db-78d5-42c2-b476-e98c75891aa4",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "5.Quý",
        "Description": "QR_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eeb6f6d4-a96e-4549-a0ea-0d9814f29871",
        "ParentId": "e4c41849-5714-49b3-b957-c348f50adcb5",
        "Name": "6.Năm",
        "Description": "YR_MNP_MNPGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "663ea024-8607-46ce-a538-0878d93b667e",
        "ParentId": "7f7a3446-5590-4964-a8a2-b28e360a39d7",
        "Name": "1.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f38d787-6aea-4f6a-b4ad-ae156133025d",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "1.Giờ",
        "Description": "DY_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42a80f40-9d8b-4516-8ffc-4c3a35321f28",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "2.Ngày",
        "Description": "DY_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fab17114-b441-4e39-821f-6fb647e58175",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "3.Tuần",
        "Description": "WK_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39a7f870-3da2-4803-9896-781b4782e84c",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "4.Tháng",
        "Description": "MN_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "745b808c-3334-4cb4-b428-c21a5f5f9fbb",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "5.Quý",
        "Description": "QR_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b45291d-ff09-4309-9373-44e2bc66a751",
        "ParentId": "663ea024-8607-46ce-a538-0878d93b667e",
        "Name": "6.Năm",
        "Description": "YR_MNP_MNPGW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/0.MNPGW/1.Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "ParentId": "8d81c9dd-5fdf-408e-be4a-d2388538a3f6",
        "Name": "1.iGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bcf5e4e-06d6-44b5-830f-d2f23dfe273f",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23469dbe-4294-4484-8948-e0d0072891f2",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68dcdf8c-ba7c-4eae-b027-5784ef702001",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f372003b-be69-4f67-8b6a-0160585dd843",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bde782e-e22b-43ce-845c-115622e2475d",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3051acc4-328d-455f-af1e-9d96010a443d",
        "ParentId": "e1d49a7b-4b57-4393-adcb-56766575aa29",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "1.iSUP-Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64db9cae-14bf-4552-9197-8afe7b9bdee7",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ec0f6d8-2c92-4d08-bf81-69d8a57fc3e7",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3219f984-da99-4987-875f-52164db88edd",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "baad8b8b-3518-4010-a856-61efea587d73",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3932d02d-e5f1-403e-a9aa-7ba9f1a1d03b",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f09c6577-2bde-42c6-955d-5b0acf1a9315",
        "ParentId": "ef43fc9d-7ad7-4f63-a1aa-77e247bb4692",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_iSUP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/1.iSUP-Trunk/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5a388d1-4600-4189-a172-465d39b81f82",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "2.SIP-Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0382a4a7-ff55-4113-80aa-c8966b3d1ecc",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31be7379-f63c-4e13-b431-8488eea67ab0",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ca59536-2df0-4f00-b13c-eaeca6db3e9b",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "544235b4-4f34-46f3-8ad3-453cd52b58fe",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4adc044-f44a-47a2-85a4-e17d5e6ead8f",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a1707fe2-a009-41ac-b2b3-cdf76da3da1e",
        "ParentId": "d5a388d1-4600-4189-a172-465d39b81f82",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_SIP_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/2.SIP-Trunk/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "87c85d24-f347-4d7f-a711-57e69308f600",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "3.Ingress-Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d0ec865-6c23-4dba-8b2c-3277d08ec5dc",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2b1d5e4-6c11-46cb-a0db-2769b5448339",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f848fc8e-1aee-49ed-929c-b9ce1a995dd1",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b24d38bf-e9ea-4b3e-bb35-718c8e736640",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c288f5f2-b8da-4cbf-9529-863da4484edf",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6b0aa95-5cbe-48e3-bb56-2a8e69ee15b4",
        "ParentId": "87c85d24-f347-4d7f-a711-57e69308f600",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_Ingress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/3.Ingress-Trunk/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "4.Exgress-Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b20c6b6-52d1-4f65-963f-61580855d396",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f5230770-07a0-47bc-a921-4fd4698f8fcb",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae5c0777-54ac-417a-9f3a-ec7b1560aa9a",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "60a7851f-3fce-4065-8c40-212daac91f51",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d9154db-378d-40bb-ac80-cc6aeb6334fe",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18d7eb2e-d8f0-4b4d-a236-0708a9b5c378",
        "ParentId": "3525f480-57f9-4d0f-b3fc-db362c236ef4",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_Exgress_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/4.Exgress-Trunk/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "ParentId": "a2b405e6-4fbe-413d-a18b-062bdcfbaf5c",
        "Name": "5.Destination",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07345835-2c82-4bb2-a6c3-882bbe275eff",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "1.Giờ",
        "Description": "HR_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32f0e498-71eb-438e-b7d1-91e176e9334c",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "2.Ngày",
        "Description": "DY_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b35ff5e8-ef3a-43d4-9fd5-c3a0eaabbad1",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "3.Tuần",
        "Description": "WK_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "552e74a7-478f-442c-86ac-13962d3216f4",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "4.Tháng",
        "Description": "MN_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f43d91b-e5e0-47e6-93a3-7d23588e1054",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "5.Quý",
        "Description": "QR_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7dd9ec7e-c9bc-4082-b155-c48b90b60192",
        "ParentId": "197df5d2-204e-4328-bef5-5e7e51cac1d2",
        "Name": "6.Năm",
        "Description": "YR_CQT_iGW_Destination",
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/4.GW/1.iGW/5.Destination/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "456a2409-09c5-46ec-9828-c315eeff622b",
        "ParentId": "780eb9bf-0a56-41f2-93bc-52c7154bd08e",
        "Name": "6.TRUNK",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "ParentId": "456a2409-09c5-46ec-9828-c315eeff622b",
        "Name": "1.Incoming Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89b5b1e3-9b80-4bef-ae8b-37c593357bfc",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "53fd333e-cd76-449e-aa1e-a992332eebb6",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6cf0f5e6-5173-4cb3-951b-28a9ac8ab463",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ae3f84b-afc1-4324-bbbe-4fedfeb6e5e4",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d8d77bf-7b85-4c4e-8cc3-eee4cb060870",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77ddc863-4f1a-4354-99e2-80c3b7c9d1c4",
        "ParentId": "3b128f18-fd45-44fd-a6eb-924afb667075",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/1.Incoming Trunk/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7187e50c-87b0-4621-9acd-176470674ac4",
        "ParentId": "456a2409-09c5-46ec-9828-c315eeff622b",
        "Name": "2.Outgoing Trunk",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "083451aa-6506-4195-9c67-4c6eeb4006d2",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a4518b1-d27a-4df6-8852-8733633616d0",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4fcb5f7-26ca-4b66-a3bf-d73697d51107",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "453c6af9-6666-4b93-8181-21b7794e70f2",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4e93112-81b9-435b-abe5-eb47b30e5b80",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08c93753-284b-4f23-90a1-6b22f526236a",
        "ParentId": "7187e50c-87b0-4621-9acd-176470674ac4",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/4.cscore/6.TRUNK/2.Outgoing Trunk/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e9d07c2-aa0b-4c02-82d9-ab682013d912",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "5.pscore",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "ParentId": "2e9d07c2-aa0b-4c02-82d9-ab682013d912",
        "Name": "0.SGSN-MME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfa7a8a5-cf32-4c05-b636-033836f633f6",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19628740-d964-44a5-b104-5b60254522ec",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1803d7db-d7cc-4db5-8a2b-809ee453da92",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "802fa5bb-2d22-4f0d-997d-19fd54c5eb0d",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73a63161-5dba-4352-b3be-979af1c5bd6f",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "5.Quý",
        "Description": "QR_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd0fdab3-3191-48bb-bf0b-72ed1938ced6",
        "ParentId": "27f3cda9-6cad-404a-af12-5fc4e75a5c9a",
        "Name": "6.Năm",
        "Description": "YR_SGSN_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fd544fc-cf3d-438d-9674-6f7a43214d4a",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Attach",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df39073d-961a-48d1-888e-ccd493369cbe",
        "ParentId": "5fd544fc-cf3d-438d-9674-6f7a43214d4a",
        "Name": "2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe66795b-1e47-42dc-9755-ed06a425981f",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86f74256-a3d8-4bc9-bb1a-bd08ab74e594",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8b501f6-dbbb-4d77-be73-c28d1432c833",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6646146e-78d2-487e-be2f-1718f9a47f83",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e07a8c57-e76f-474d-8fd8-b795b0d67333",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "574a9e60-6485-40b0-8e91-d1f26c52c6b3",
        "ParentId": "df39073d-961a-48d1-888e-ccd493369cbe",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "ParentId": "5fd544fc-cf3d-438d-9674-6f7a43214d4a",
        "Name": "3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dbfd39f5-addc-4db5-8add-4043b6069fe9",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa28443e-5947-401e-a691-511e46224cbb",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6099f02d-42f6-4ec5-832f-b8083b80c043",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1588137d-eedb-4179-aef8-84e56f734cf4",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa15b2e8-db93-473a-9272-2e5833305e2a",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69bce01c-9ebd-458f-a18d-9ef107008e79",
        "ParentId": "a9446a5b-e56d-4081-a1c1-1e8d903bd19b",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "ParentId": "5fd544fc-cf3d-438d-9674-6f7a43214d4a",
        "Name": "4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9ddc6f4-4fc6-45f4-a814-e8a3e8d47972",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8028ec9c-fb18-4f91-98ef-eba8f35e95c7",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3d0d59d-437f-4f0e-869f-8196b2722eb5",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66a2a08b-504e-4a4b-b034-e5f99b539fba",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18804d45-8433-4e21-950a-ac5c117279c1",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9f9f241-d5af-490a-b1e4-ba0fa942614c",
        "ParentId": "94ec8259-2b94-4e5b-9faa-8eb58d8047ca",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/4G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "ParentId": "5fd544fc-cf3d-438d-9674-6f7a43214d4a",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4abe0735-7fff-4918-a227-7f668a6bc275",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dcee083f-dfb2-4c20-821f-581db0f75a2b",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ca39bfd-59a9-4c75-a355-4bad6daf3af3",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dbf61c04-00b3-4e5d-82af-84b5ecff2983",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e7364cc-ab38-4614-8e45-d34f1a0b5af1",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5a6ab55-4e38-4582-80bd-7f576d6de16c",
        "ParentId": "78c7524d-800f-463c-b81a-72f36a9a5dcd",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Attach_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Attach/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c1e8123-a355-451b-bc4d-a54593147aa6",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Authen",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "ParentId": "5c1e8123-a355-451b-bc4d-a54593147aa6",
        "Name": "Authen4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abb456ed-fae5-484a-a398-29dfeb7d36cc",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "1.Giờ",
        "Description": "HR_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01ad0567-f1ed-47ea-bfa4-545a49edbcf7",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "2.Ngày",
        "Description": "DY_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b42ae12-27d8-4bf8-b6f2-cd524ac2846d",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "3.Tuần",
        "Description": "WK_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e24bef4-f536-417b-b307-f0d6261c9a37",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "4.Tháng",
        "Description": "MN_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f66c2eb-6426-4375-b72e-fc659d24cd30",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "5.Quý",
        "Description": "QR_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b710ca8-bd71-4afb-bc41-30eb1fdf5ffe",
        "ParentId": "7fb29b82-d1c9-4c45-b7f1-fb4a7c45e7a2",
        "Name": "6.Năm",
        "Description": "YR_MME_AUTHEN_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/Authen4G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "ParentId": "5c1e8123-a355-451b-bc4d-a54593147aa6",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "701bec2b-59e0-478c-9cbb-90f2f6107f02",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "1.Giờ",
        "Description": "HR_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "869d90ed-bea3-4737-9550-bd90aa627a24",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "2.Ngày",
        "Description": "DY_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dab91852-b2be-47ce-903c-b07a2c8dcb53",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "3.Tuần",
        "Description": "WK_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d350c4ff-0421-4069-9f1f-e2507dc5b4c3",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "4.Tháng",
        "Description": "MN_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "71a1d21f-4439-426f-b0bc-f2a505f83df0",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "5.Quý",
        "Description": "QR_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05403cd4-25f9-4d4e-ad1f-2134789d8586",
        "ParentId": "66dc8dd3-f863-4f18-a9a1-7353ba3fdf79",
        "Name": "6.Năm",
        "Description": "YR_MME_AUTHEN_GEN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Authen/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9e26b65-3987-4a03-9d5b-8a15d13ba46e",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "HO4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "814d756a-9076-40ca-9228-afd9e146e567",
        "ParentId": "d9e26b65-3987-4a03-9d5b-8a15d13ba46e",
        "Name": "InterMME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5564df6e-1a9c-4af0-b8df-038dfe9aeb2a",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "1.Giờ",
        "Description": "HR_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ba280167-5d7c-4f55-aa7a-f90c30dc4db0",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "2.Ngày",
        "Description": "DY_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5362075b-cd83-4edb-a874-394563400283",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "3.Tuần",
        "Description": "WK_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "caa891e2-35f4-42ce-843e-49e273f29bca",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "4.Tháng",
        "Description": "MN_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91d559fb-bd1e-4829-86d7-9f26c688f44e",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "5.Quý",
        "Description": "QR_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a44bac8-1212-494f-b3e0-6224b267cf87",
        "ParentId": "814d756a-9076-40ca-9228-afd9e146e567",
        "Name": "6.Năm",
        "Description": "YR_MME_HO_Inter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterMME/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "ParentId": "d9e26b65-3987-4a03-9d5b-8a15d13ba46e",
        "Name": "InterRATHO",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e654a1a3-3ff0-4891-b3e2-2b85518f7f74",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "1.Giờ",
        "Description": "HR_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a22c8bb-8241-4a51-bbbe-23a0a0e09410",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "2.Ngày",
        "Description": "DY_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "308ad523-6529-405c-b6d2-bd5d27bbb131",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "3.Tuần",
        "Description": "WK_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8314b917-4be3-4d9a-bfd4-cdd8824a8749",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "4.Tháng",
        "Description": "MN_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c41a49f9-21e7-46aa-9c4d-e61ec7d8cdb7",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "5.Quý",
        "Description": "QR_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8aa8fbd2-dd56-4baa-91e4-a42d6b88aad4",
        "ParentId": "4251d672-aecc-4479-94f7-c6e6d06dbfad",
        "Name": "6.Năm",
        "Description": "YR_MME_HO_4G3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/InterRATHO/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "ParentId": "d9e26b65-3987-4a03-9d5b-8a15d13ba46e",
        "Name": "IntraMME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2185c812-666a-488d-8391-44377b999cc4",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "1.Giờ",
        "Description": "HR_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "610e5ed7-4ead-48d3-b2cd-420218df6575",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "2.Ngày",
        "Description": "DY_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "65e0a421-1e7f-47c8-9d1d-df08244983de",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "3.Tuần",
        "Description": "WK_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "839a0707-f31c-447b-820b-10fbe068ca00",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "4.Tháng",
        "Description": "MN_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c1ab37f-dcb1-4c72-8078-521db962d82f",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "5.Quý",
        "Description": "QR_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e1ea296-a217-4791-9145-e3e2df6b6541",
        "ParentId": "d92fccd7-31de-47ea-a07b-c1cda0ec23aa",
        "Name": "6.Năm",
        "Description": "YR_MME_HO_Intra",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/HO4G/IntraMME/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac406aa8-b089-43a4-bd76-b362e96dbc41",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Inter-PAPU-Intra-SGSN-RAU",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "ParentId": "ac406aa8-b089-43a4-bd76-b362e96dbc41",
        "Name": "2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05cb3ce0-3c3c-492e-94c5-4c93d7172f1e",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5bd397f-98b4-45d4-b439-18de3d29918e",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cee13191-4ec2-42df-a41e-77e89f62eb36",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "966c054a-9896-4466-8791-e1ba99d71712",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc09dc17-19df-4db6-a720-399a767adb7b",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc4f9bd2-1c6d-4877-ad2b-62df2be3c0ca",
        "ParentId": "4c6db38d-0477-4858-8747-1db82fbe1f78",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterPAPU_IntraSGSN_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "ParentId": "ac406aa8-b089-43a4-bd76-b362e96dbc41",
        "Name": "3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e55afbc5-3488-418d-a363-a0bcb1e375db",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c80d27a2-f218-401f-863f-4915c1122664",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4575af77-acc4-4461-956a-98cf05ffcc8c",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8007e72-1fad-4c60-9d60-32fcf7062cce",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c96ca67f-b9c3-462d-a038-9a7a85aa9771",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c99a6cbb-907d-4b88-97d6-226bfa352d03",
        "ParentId": "bcf37e2c-7f2c-42be-b89b-37fc539ce653",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterPAPU_IntraSGSN_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "ParentId": "ac406aa8-b089-43a4-bd76-b362e96dbc41",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2529a7e2-f6f2-49d3-966f-310fee7f7b19",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0eb1d40-7c4f-4e21-a129-b9f5988994e8",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e47e4bf7-2d60-48b3-a56b-32251148040a",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a37a2592-82f9-475a-9b62-d0c70a495b7b",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4cece0ed-64db-4258-b2b4-cbacda68ea07",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99df64af-0dba-44d9-bbbb-b1ec8f25ec41",
        "ParentId": "22a3ea3e-9bb6-4784-ad3f-83624c8eba97",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterPAPU_IntraSGSN_Gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-PAPU-Intra-SGSN-RAU/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fbcaa15f-593f-4789-8207-846f23baac2f",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Inter-SGSN-RAU",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "ParentId": "fbcaa15f-593f-4789-8207-846f23baac2f",
        "Name": "2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2b9e0ad3-e2b6-4128-a789-0936aee9c8cb",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30facf98-074e-4224-8a3c-c615a21b3401",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a53f67a-6f1a-49dd-9282-51bb53838f99",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26092a2e-8415-4ca9-b814-77a784a83aea",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "96089d99-c9fe-4377-a18e-98e6d287d3f3",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "907dddc2-fe78-4311-810c-b8ac5d1e4bdd",
        "ParentId": "68bb4bd9-1938-4787-9e23-2993cb83fa35",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterSGSNRAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "ParentId": "fbcaa15f-593f-4789-8207-846f23baac2f",
        "Name": "3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73fd7a5a-6901-4882-8b00-2fe1f42ee65d",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a447baf-dc6d-4a7f-bfc2-4f3e6c4616cd",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e327ca40-22b7-48b6-b34f-6f37f9d66763",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9e84e6ce-6298-4528-b758-55beac2008fe",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f77b7059-1a7f-48ac-9a8b-5f240cdae624",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "803550c6-3961-4419-961e-1d5519b05b77",
        "ParentId": "20ecd250-db4e-48ac-aa52-8bd870472601",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterSGSNRAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "ParentId": "fbcaa15f-593f-4789-8207-846f23baac2f",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1299593-1fb4-4008-834e-f5ad7c6eb391",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e083a9a2-5d2a-4ca5-ac02-4ab1a053b0ec",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e639b182-0797-4cb4-823a-a00492fb4814",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbfe69e5-f98e-4809-a713-77ef4c291aa7",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "150b5cbc-cd9f-4c92-ba65-8033f76dbb1c",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "5.Quý",
        "Description": "QR_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21f9c65a-d235-476f-a876-d96e9ee27a87",
        "ParentId": "a7251e59-c09d-463c-86bd-9f365811d2b0",
        "Name": "6.Năm",
        "Description": "YR_SGSN_InterSGSNRAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Inter-SGSN-RAU/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2703d49d-0f6e-4c1d-8f8f-bc512ee9a2cf",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Intra-PAPU-RAU",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "ParentId": "2703d49d-0f6e-4c1d-8f8f-bc512ee9a2cf",
        "Name": "2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d59674da-1dd5-45e2-892d-98fe135cfed1",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ba69bdc-620b-4e45-abf5-b18e15ba38fb",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb2133b5-e298-4710-9132-d7362c8768d7",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c45375f-9093-4ac2-b1a3-34da4d86b141",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "763a6fc2-c2dd-4b53-a464-0b4d4a5363e9",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "5.Quý",
        "Description": "QR_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3cd7a53d-d640-4ba9-8f38-acd64bbd90ba",
        "ParentId": "642fdf4a-c205-45da-a4d4-b141d9a49042",
        "Name": "6.Năm",
        "Description": "YR_SGSN_IntraPAPURAU_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "ParentId": "2703d49d-0f6e-4c1d-8f8f-bc512ee9a2cf",
        "Name": "3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b97f42b7-c4cd-48ec-ba71-28dfbdb791e7",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d31f0ba-c2e0-4d1a-8d33-008714a4344a",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de57590d-caff-42f0-8e30-93d443870e98",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc8c8b80-73ba-4cc0-bcd9-89a773a7baef",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "82aaba57-8b1d-4ec0-ae24-629e08cc412d",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "5.Quý",
        "Description": "QR_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b935300-f9a7-406f-ac36-652f3df5cfdd",
        "ParentId": "fdeab640-6035-4581-a6c1-9e48b649c60d",
        "Name": "6.Năm",
        "Description": "YR_SGSN_IntraPAPURAU_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "ParentId": "2703d49d-0f6e-4c1d-8f8f-bc512ee9a2cf",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8586ef8c-6086-4623-af21-2d8cb84f8249",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "52681450-4809-4bf9-b0c3-684bdf7294a8",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd94b2e9-273a-4d5c-a1dd-d60f476b2b8d",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "438d241e-6887-437c-8ffb-82fa54f5dd07",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ae529a3-e32c-4e8e-a80f-851481c7604b",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "5.Quý",
        "Description": "QR_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "153e4789-9e6f-4d42-a76e-b26e17360471",
        "ParentId": "f3d9b905-ff5a-40ca-a327-dc4acec06221",
        "Name": "6.Năm",
        "Description": "YR_SGSN_IntraPAPURAU_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Intra-PAPU-RAU/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Load",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb972b2f-6a2e-4de5-a8de-11b43c02e087",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da2f5465-815a-44a6-a4bb-c1a47c633ae8",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0cc9d39d-5dfc-4d85-8425-52dea96a6437",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34adb0c2-32bc-424d-af03-9e881f530d0d",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b9adb8f3-9644-47ea-aa06-273c745036b4",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8cc8d42-1f36-47d6-9a40-14a836c69221",
        "ParentId": "1d0f892f-30c8-42f3-838c-2d15974f503e",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Load",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Load/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Paging",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3305482-04c3-4ccd-bbc5-bacc0bad5749",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7e83f7c-2ba8-48da-8e62-625070450578",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a72ac3d5-07df-4915-b67e-f02deb42bd9e",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3c0d00d-6fd2-41ab-974a-810e25a40c10",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07a12f9c-282d-4131-8a74-67792b48830d",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "5.Quý",
        "Description": "QR_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7c58171-f193-4991-81a5-5f9a1797765c",
        "ParentId": "aa9b4446-4564-46bd-b2a0-5b832936733e",
        "Name": "6.Năm",
        "Description": "YR_SGSN_Paging",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Paging/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "858d060c-b1c3-4bb7-ae4c-fcb26d852623",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "PDP-Act",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "ParentId": "858d060c-b1c3-4bb7-ae4c-fcb26d852623",
        "Name": "2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09c5ee40-2d9e-450a-80fb-99fc545e299b",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f10acb23-7ca9-4ead-a6a1-7e4eef2f97f4",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9542849-da3c-4d10-b67a-ba1ec9576f68",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "594dae34-9685-46d9-909f-9935a47522f7",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7469b652-7fa3-414a-85f0-517bc6957d4e",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "5.Quý",
        "Description": "QR_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ada6a1f-94a6-460f-adcc-6a0a56ebad21",
        "ParentId": "eb781b35-d445-44b4-8ce9-d888a6a4a201",
        "Name": "6.Năm",
        "Description": "YR_SGSN_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/2G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "ParentId": "858d060c-b1c3-4bb7-ae4c-fcb26d852623",
        "Name": "3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "80ee0885-4346-4367-9b93-187e27c991e9",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e371269-acbd-46a4-90f1-244b8d6f794c",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e8e1f6f-3121-41b7-bf7c-d437e31188a1",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c6885c2-1c73-4813-826d-41db78ce0274",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7652333-e7dc-4690-92d2-38acf7bdfdfb",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "5.Quý",
        "Description": "QR_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9f4eb00-5e3d-42ba-a68d-40805c934758",
        "ParentId": "352779fb-f146-46b5-b4a8-2b901e6b30f6",
        "Name": "6.Năm",
        "Description": "YR_SGSN_PDP_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/3G/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de35ff70-db79-49a7-b707-328230e874e9",
        "ParentId": "858d060c-b1c3-4bb7-ae4c-fcb26d852623",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2bececd3-4322-4075-8703-5598bbe8d26d",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "1.Giờ",
        "Description": "HR_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "318af6d4-ee0b-42ec-969d-983310c9a57b",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "2.Ngày",
        "Description": "DY_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e10aa6c4-393f-4828-bb68-4d990eb0f449",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "3.Tuần",
        "Description": "WK_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "424ea97a-d4bb-4e65-bbea-153e26726c76",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "4.Tháng",
        "Description": "MN_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1dc4abbf-5e31-4529-bfc3-40e524cd2b33",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "5.Quý",
        "Description": "QR_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2cd7a4f6-5a86-441f-bcf4-5cb87f6faecc",
        "ParentId": "de35ff70-db79-49a7-b707-328230e874e9",
        "Name": "6.Năm",
        "Description": "YR_SGSN_PDP_General",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/PDP-Act/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Roaming",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe9828b0-f984-4ff0-8108-bc6dc97ebfdc",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "1.Attach2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/1.Attach2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9639596-71da-4404-88d8-0fd9b908f3df",
        "ParentId": "fe9828b0-f984-4ff0-8108-bc6dc97ebfdc",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Roaming_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/1.Attach2G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d08f3c0-697f-4476-b0b2-45343a75ea12",
        "ParentId": "fe9828b0-f984-4ff0-8108-bc6dc97ebfdc",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Roaming_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/1.Attach2G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79b29898-db8d-4d29-8e9b-ddc322747e7e",
        "ParentId": "fe9828b0-f984-4ff0-8108-bc6dc97ebfdc",
        "Name": "2.Tuần",
        "Description": "WK_SGSN_Roaming_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/1.Attach2G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "176e62cb-5654-4fd7-9dad-09e5faad940c",
        "ParentId": "fe9828b0-f984-4ff0-8108-bc6dc97ebfdc",
        "Name": "3.Tháng",
        "Description": "MN_SGSN_Roaming_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/1.Attach2G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f41f802-7d71-404d-8148-c38173b0fd46",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "2.Attach3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/2.Attach3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9aab79f7-0f05-46f7-b023-be7782788459",
        "ParentId": "1f41f802-7d71-404d-8148-c38173b0fd46",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Roaming_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/2.Attach3G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f97d1ac8-2655-4a1c-8d57-2e65316873f4",
        "ParentId": "1f41f802-7d71-404d-8148-c38173b0fd46",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Roaming_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/2.Attach3G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6cc723b1-5bfb-408c-8c6a-96504fd53870",
        "ParentId": "1f41f802-7d71-404d-8148-c38173b0fd46",
        "Name": "2.Tuần",
        "Description": "WK_SGSN_Roaming_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/2.Attach3G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7852a2e-01d9-46a5-b5c3-7bd74d98e5e5",
        "ParentId": "1f41f802-7d71-404d-8148-c38173b0fd46",
        "Name": "3.Tháng",
        "Description": "MN_SGSN_Roaming_Attach_3G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/2.Attach3G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20060e23-deda-40d0-bd25-18f537426f05",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "3.Attach4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/3.Attach4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8351dfaf-0290-42d5-b5df-4d14048a312d",
        "ParentId": "20060e23-deda-40d0-bd25-18f537426f05",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/3.Attach4G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9078e0d-f668-4971-b33a-c6fbc3cb0b57",
        "ParentId": "20060e23-deda-40d0-bd25-18f537426f05",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/3.Attach4G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "49f5cede-28a3-4cf1-9676-2f159456f7ee",
        "ParentId": "20060e23-deda-40d0-bd25-18f537426f05",
        "Name": "2.Tuần",
        "Description": "DY_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/3.Attach4G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a401f3c-54f6-4215-bfa6-15958ec887a9",
        "ParentId": "20060e23-deda-40d0-bd25-18f537426f05",
        "Name": "3.Tháng",
        "Description": "MN_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/3.Attach4G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "314b41a9-7eda-4447-8565-cdc6aa2f7cd0",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "4.PDP2G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/4.PDP2G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8936b46c-6592-4e76-94a7-99509dfb5e29",
        "ParentId": "314b41a9-7eda-4447-8565-cdc6aa2f7cd0",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Roaming_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/4.PDP2G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33859962-5f62-41e8-b1ba-6331f6eb7531",
        "ParentId": "314b41a9-7eda-4447-8565-cdc6aa2f7cd0",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Roaming_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/4.PDP2G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ec70255-8e57-43a8-ad9f-3dc88f1d4d5f",
        "ParentId": "314b41a9-7eda-4447-8565-cdc6aa2f7cd0",
        "Name": "2.Tuần",
        "Description": "WK_SGSN_Roaming_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/4.PDP2G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a447238e-6697-455f-8e19-1301f0047977",
        "ParentId": "314b41a9-7eda-4447-8565-cdc6aa2f7cd0",
        "Name": "3.Tháng",
        "Description": "MN_SGSN_Roaming_PDP_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/4.PDP2G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d49efbf4-c173-43d1-93bf-c81af7dec896",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "5.PDP3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/5.PDP3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c1ce7037-abf3-4ef7-aebe-98301c6c8f5b",
        "ParentId": "d49efbf4-c173-43d1-93bf-c81af7dec896",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/5.PDP3G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ff219eb-142f-45d8-be15-62cea1792734",
        "ParentId": "d49efbf4-c173-43d1-93bf-c81af7dec896",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Roaming_Attach_4G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/5.PDP3G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41eeeb50-8115-49ea-914c-5d0bc654c8b5",
        "ParentId": "d49efbf4-c173-43d1-93bf-c81af7dec896",
        "Name": "2.Tuần",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/5.PDP3G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb94cf4b-6144-45f7-9249-4a1085f416e6",
        "ParentId": "d49efbf4-c173-43d1-93bf-c81af7dec896",
        "Name": "3.Tháng",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/5.PDP3G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c044260a-d783-424f-9f72-2f028cdf66ef",
        "ParentId": "fc787efd-5440-4826-8825-c33e8c4101d9",
        "Name": "6.PDP4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/6.PDP4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e8dd087-7e4f-4c19-ae13-ea85bf1b1de1",
        "ParentId": "c044260a-d783-424f-9f72-2f028cdf66ef",
        "Name": "0.Giờ",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/6.PDP4G/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32e2d404-f1ac-4171-8d16-21878938f5cc",
        "ParentId": "c044260a-d783-424f-9f72-2f028cdf66ef",
        "Name": "1.Ngày",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/6.PDP4G/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f35d3f88-5b32-48e1-b54c-e0f568824e70",
        "ParentId": "c044260a-d783-424f-9f72-2f028cdf66ef",
        "Name": "2.Tuần",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/6.PDP4G/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70a5ce68-01e0-4dbd-9774-ee141e224226",
        "ParentId": "c044260a-d783-424f-9f72-2f028cdf66ef",
        "Name": "3.Tháng",
        "Description": "DY_SGSN_Attach_2G",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Roaming/6.PDP4G/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "Service-Request",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7ad9aa0-a10d-4f58-b4bb-0b558fe35c09",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "1.Giờ",
        "Description": "HR_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d0facde-cb7b-4a20-bf14-13924591397a",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "2.Ngày",
        "Description": "DY_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a22a2cbf-884c-4afc-a1bf-e4e4024dfd7e",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "3.Tuần",
        "Description": "WK_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "988d2361-8f8f-4667-8f5a-c2f7b8376a68",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "4.Tháng",
        "Description": "MN_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6cec60ff-740a-4265-bfc9-824cf35f87bb",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "5.Quý",
        "Description": "QR_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4aefa91c-f19a-42b3-9136-092448b8a1af",
        "ParentId": "29535ee2-ae05-435c-a6db-32808af1d0e4",
        "Name": "6.Năm",
        "Description": "YR_MME_ServiceRq",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/Service-Request/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "ParentId": "3dbe1c43-f076-4707-acd4-3bdba0354097",
        "Name": "TAU",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "ParentId": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "Name": "Inter-ISC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f76cc7d-59b4-4bcb-be3d-79bab420676a",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "1.Giờ",
        "Description": "HR_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8c3ed66-b372-4cdc-ab4b-af119ffb5675",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "2.Ngày",
        "Description": "DY_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9ecb093-bef9-4783-82c4-5ab04b645bce",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "3.Tuần",
        "Description": "WK_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5875e091-973a-4b89-875b-7fcf7ab78795",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "4.Tháng",
        "Description": "MN_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c797bf8-1b2d-466c-b6fd-486b621825af",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "5.Quý",
        "Description": "QR_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb0b3ed7-b925-4bdb-8c8c-6cfe915851aa",
        "ParentId": "c35d231a-070e-42e4-bfe2-4d25ce1ed933",
        "Name": "6.Năm",
        "Description": "YR_MME_Inter_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-ISC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "ParentId": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "Name": "Inter-MME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "903053f3-2026-4125-9906-65f5809c5ab2",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "1.Giờ",
        "Description": "HR_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a7bc808-134a-4e6e-95b0-09088558e701",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "2.Ngày",
        "Description": "DY_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1a07375-2141-476a-905a-e06888ad42a7",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "3.Tuần",
        "Description": "WK_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4ef5688-136d-41ec-bdaf-6a1b5bcfb35a",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "4.Tháng",
        "Description": "MN_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a2b20f3-35e3-4a72-946f-02fbac9a2a4e",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "5.Quý",
        "Description": "QR_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "886e0930-4d3a-4fe7-b53f-1d943ffbbd43",
        "ParentId": "85f066f7-000d-44c6-9b31-20fbd1830b4b",
        "Name": "6.Năm",
        "Description": "YR_MME_Inter_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Inter-MME/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "ParentId": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "Name": "Intra-ISC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3dbf7db7-0a1b-4c10-9b3a-e1348e718d57",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "1.Giờ",
        "Description": "HR_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b79ad23-8201-4e7b-941d-1f6d5f6cd258",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "2.Ngày",
        "Description": "DY_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94fa16a9-ffc5-4808-81ba-9ec5d4768e0c",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "3.Tuần",
        "Description": "WK_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf8c8dbd-ded7-485d-a90d-23dcb77b1c21",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "4.Tháng",
        "Description": "MN_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e62f912-c6f0-43a1-a352-baa5785cd4b8",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "5.Quý",
        "Description": "QR_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c29e510-3bb8-4ce0-8ac8-75209d7d3621",
        "ParentId": "364bb190-57ea-4e6b-907c-0957eee52efd",
        "Name": "6.Năm",
        "Description": "YR_MME_Intra_ISCTAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-ISC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "ParentId": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "Name": "Intra-MME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "11f8bf92-ddf6-4fb0-95be-2913c70a0ef0",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "1.Giờ",
        "Description": "HR_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7563caf5-499d-4c2a-bea7-1f84cbf2b458",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "2.Ngày",
        "Description": "DY_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a241b6d-7a49-4ee1-9a25-98df47c181ab",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "3.Tuần",
        "Description": "WK_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0c80fb05-80cc-448a-ae8b-160e38dbafa0",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "4.Tháng",
        "Description": "MN_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66214324-dcf2-4065-82f5-371f2464e2b9",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "5.Quý",
        "Description": "QR_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7878315a-c38b-4e11-91e6-e872d03cb287",
        "ParentId": "2d41cc51-aa37-4796-ab07-92b039cb8efe",
        "Name": "6.Năm",
        "Description": "YR_MME_Intra_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Intra-MME/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "ParentId": "cb4cd0e1-240d-4ba1-844b-8ab42da86215",
        "Name": "Preodic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec92c53d-5203-4af0-aa12-2ec18d0d3d33",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "1.Giờ",
        "Description": "HR_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bc7bb84-94b5-4eeb-935b-783b018af822",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "2.Ngày",
        "Description": "DY_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac9bda95-484d-4c0f-a90b-1511393bb2ae",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "3.Tuần",
        "Description": "WK_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9e365e8b-70cf-4e4c-975b-8d9e4e595402",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "4.Tháng",
        "Description": "MN_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c2c45131-e6f2-4caa-9c62-0ed9f2cb91f3",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "5.Quý",
        "Description": "QR_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2ce9fad-a087-4681-a069-967b3f7dccda",
        "ParentId": "e69198ad-5d32-4af4-a1ac-edc58d2cb659",
        "Name": "6.Năm",
        "Description": "YR_MME_Preodic_TAU",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/0.SGSN-MME/TAU/Preodic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c5bad9c-9eb2-4684-bcd5-5038a9b91546",
        "ParentId": "2e9d07c2-aa0b-4c02-82d9-ab682013d912",
        "Name": "1.GGSN-SPGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "ParentId": "6c5bad9c-9eb2-4684-bcd5-5038a9b91546",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e1726bf-8302-45b1-9461-e0d6cb8cde6b",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "1.Giờ",
        "Description": "HR_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "605c1783-ef08-4e08-9a71-6a917bbca814",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "2.Ngày",
        "Description": "DY_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eac83519-6f49-4848-95bf-80928e377626",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "3.Tuần",
        "Description": "WK_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f6bd304-e4a1-4024-a644-e0e8f2f572eb",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "4.Tháng",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "36cf021e-1fa2-477b-af14-0a89c530ecb4",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "5.Quý",
        "Description": "QR_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8bd14f0-99ed-4ad0-b65a-c2ff4a2a5161",
        "ParentId": "24e5230d-ce94-47ff-9ca0-34fa3d4c44b4",
        "Name": "6.Năm",
        "Description": "YR_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41db3c3b-1228-4aa3-8917-79d43daade09",
        "ParentId": "6c5bad9c-9eb2-4684-bcd5-5038a9b91546",
        "Name": "2.IPV4V6",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/2.IPV4V6",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a66f7f9-46c0-482a-b68b-07ec1f1489b5",
        "ParentId": "41db3c3b-1228-4aa3-8917-79d43daade09",
        "Name": "0. Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/2.IPV4V6/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f8f76e0-d225-47a4-94e0-8b430717e766",
        "ParentId": "41db3c3b-1228-4aa3-8917-79d43daade09",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/2.IPV4V6/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "245d05da-5018-4ab7-b86a-9c6ebec605f9",
        "ParentId": "41db3c3b-1228-4aa3-8917-79d43daade09",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/2.IPV4V6/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab40215f-e791-4b79-b817-ed673a66c4bb",
        "ParentId": "41db3c3b-1228-4aa3-8917-79d43daade09",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/2.IPV4V6/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "ParentId": "0ef8f249-73cc-4cbb-a7f8-8532a9c49434",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2305d9b6-5a68-49b3-ad78-ffce1c3785f3",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f5894e20-f6b2-4899-bfd9-fa3d432ee60a",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0d75b58-153e-4007-b5bc-8b2cea9fc219",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "411fd341-81a3-4aa6-909c-478c05e68d83",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed8339a4-071c-44d7-809f-c4ddfaf0347e",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d1acbba-6466-4f62-8a4a-c2639961d78a",
        "ParentId": "47ce29de-d4bb-41ed-8b9b-4f6ffb8fa513",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnAPN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/APN/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "ParentId": "c263bcf2-cea2-4e4b-b2fa-ea43465b92ee",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78039933-8a50-4f50-8213-a7ca47f7c434",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6965666a-4f02-4dfe-af4a-de61cec5e74e",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f248f8b6-6868-4fe9-a902-bebdefea73a0",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b63201c3-2ee3-4133-a395-bf85f0105a82",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "500efccc-7290-4298-bef3-e8ec05fee6f3",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a849929-661d-4841-8fda-6c398f446d31",
        "ParentId": "5e16dbe1-e2bc-42d1-ac98-d83979ab04a2",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "ParentId": "c263bcf2-cea2-4e4b-b2fa-ea43465b92ee",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f96e939c-f13c-431e-b5c3-fc0a04b4f49a",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39939daa-4654-4d88-ad5f-72ead6a64665",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c0b72c0-76c2-4bc2-bcfb-e60ba327b69a",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "084ee1f8-2439-4d96-b7c6-388b6129f67f",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d484ed1d-152f-4a26-9817-e960f9c9c225",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43b2e4fd-2ce5-4522-8f03-1de46c86b43d",
        "ParentId": "b30b5efb-0375-42ad-9bf1-a6a3b0656a16",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnCDR",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/CDR/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "ParentId": "dc10c3df-4b8c-4f4c-a933-543c39814338",
        "Name": "PGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ec921b7-16f6-4e3c-8a6a-a5b9a63fcb21",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "1.Giờ",
        "Description": "HR_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a30fe2a0-538b-437d-9ae7-9aad389e33c5",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "2.Ngày",
        "Description": "DY_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b229fc6e-38b1-4e02-94e0-350e1e73de38",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "3.Tuần",
        "Description": "WK_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "886337fe-c754-479d-8bfb-b77e0f554f87",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "4.Tháng",
        "Description": "MN_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "789cb95d-3580-485d-9324-8a3731740d90",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "5.Quý",
        "Description": "QR_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "244e9d31-a8da-44ca-ab73-5e31f5b78314",
        "ParentId": "880170e4-0e62-469a-9828-8a1ca4387aae",
        "Name": "6.Năm",
        "Description": "YR_PGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/PGW/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "ParentId": "dc10c3df-4b8c-4f4c-a933-543c39814338",
        "Name": "SGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc0e434d-35d7-4359-8988-fdc363750532",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "1.GIờ",
        "Description": "HR_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/1.GIờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae7fc28d-0949-4684-9601-8e6e4b41b7c8",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "2.Ngày",
        "Description": "DY_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4edee1df-5da2-49de-a9b1-1d6b8e760a79",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "3.Tuần",
        "Description": "WK_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b33d108-0ae7-410b-b068-4c2cfbe09d61",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "4.Tháng",
        "Description": "MN_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5518e01-c0a7-47b1-bc9e-e264ec74e88c",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "5.Quý",
        "Description": "QR_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ece26cd2-1a43-45a2-adec-d79cf94846d8",
        "ParentId": "2f69609d-b3e6-4247-ba37-ad20bb6852b5",
        "Name": "6.Năm",
        "Description": "YR_SGWDataDrop",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/DataDrop/SGW/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69847723-5db4-4ed1-8705-284633acaad8",
        "ParentId": "609cbad3-7af4-4650-a2ea-de97383c5ffb",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7f68b94-1b09-4da1-8952-ed95c85b2e9e",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5d9726e-db17-4d86-a8ef-744a86fe9144",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e405673-0464-453f-a098-f4a0b90746c4",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "835c243f-9505-4a92-9721-3520732c90f4",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9346832f-ca6a-4172-bd46-f0fae5e8db50",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c51cfce8-7432-4d03-a35f-a1d7ee673b91",
        "ParentId": "69847723-5db4-4ed1-8705-284633acaad8",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "ParentId": "609cbad3-7af4-4650-a2ea-de97383c5ffb",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5d556a65-2cd7-4abe-afb3-7119978ceb1d",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b85f8c39-6803-423e-b2c5-63e3a430ac5a",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2be43838-b491-4790-b961-9a0ea53258d4",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "57f4bacd-e055-49cd-bb9b-2c69362ea6d6",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "927446b5-3360-44de-8486-8074812bc981",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e8847ba-7c0a-4b81-afed-049850ddeb55",
        "ParentId": "19f2d48a-a4db-40cb-8e85-b416389e684c",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnGi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gi-Interface/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0ccddcc-be0d-4323-8b43-5b32304d1d8d",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "1.Giờ",
        "Description": "HR_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15a024c3-70dd-4c2c-b692-97fdfc6a1b60",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "2.Ngày",
        "Description": "DY_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3761a838-295d-4f21-948d-12a304f65935",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "3.Tuần",
        "Description": "WK_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c5f8ed5-114d-48e2-8ad0-62d6506e30c3",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "4.Tháng",
        "Description": "MN_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0da721c5-1242-4fcb-9565-29bfeaa626f9",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "5.Quý",
        "Description": "QR_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f9f34a5-3d5e-47da-817d-d779e3e4e7f4",
        "ParentId": "cad8a17b-81dd-4489-bb59-46070b54664b",
        "Name": "6.Năm",
        "Description": "YR_Gngpinterface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Gp Interface/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "ParentId": "592a7237-a8c6-40ef-8297-3ee8e0ac7955",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "140f2bd3-15e2-48ca-8967-a2eee7d3bd4b",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de9b81d2-30f3-4948-a256-0f3d980e8677",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3cf65831-1e05-48dd-bce3-f5c00adf52d7",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fbac195-a04c-4950-9b9b-061ec6b392b9",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad7b7f61-b2a0-40ab-951b-f34a7738fcdb",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7bf1f35-ca48-47f2-b8c9-82df7fdd3e69",
        "ParentId": "a3b963ac-85d0-403e-a2d6-b24650402f92",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "ParentId": "592a7237-a8c6-40ef-8297-3ee8e0ac7955",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6afc24e-9660-4d72-9dc4-97b2a2593978",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f19caeb2-be66-468d-8527-271436486677",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3daa7c46-75e3-4c0f-b536-5b1285a30f74",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "952d571c-7f8b-4d6b-8942-493f768ec8db",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c24bb6a4-7a30-4dcb-911b-0e2cacdbbe5f",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dcf8bddf-5443-4a7e-929c-5de15f02e497",
        "ParentId": "e2a4938e-196c-4ee9-9881-2e5d7bb87916",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnGn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Gn-Interface/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "ParentId": "d6600c3d-7b7f-4dc8-8ff2-7a03b174dae3",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29702181-98cf-49be-96fc-cc9edfbc9167",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "1.Giờ",
        "Description": "HR_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d1dd8b2-88c6-4e29-9a01-f3ecf732e7c3",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "2.Ngày",
        "Description": "DY_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "35f5073c-981d-42ef-a027-21a0e93c8568",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "3.Tuần",
        "Description": "WK_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae66173e-1ed0-4482-98be-bb076388afa2",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "4.Tháng",
        "Description": "MN_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b828f2ce-65e4-4ad4-a671-064fb488721a",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "5.Quý",
        "Description": "QR_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb0d7976-dae8-4760-b160-efa9302908ad",
        "ParentId": "c9d15a1b-f59b-4535-b602-60c4a8f2761b",
        "Name": "6.Năm",
        "Description": "YR_GgsnGxGy",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/GxGy-Interface/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "ParentId": "d1f31f2f-ca6b-48d0-8369-11e37424ecaf",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ce97263-fdcf-4c0e-bda1-a90d797c2ea4",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69f4fb93-5cf2-4574-830d-5b9e3410b9f1",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ccc6d1f-1c8f-45c3-a756-e285ccc9d267",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5476f848-4dff-4fa2-af0b-a1c054fb3720",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01308ebb-2dc4-4880-9abd-e035ee72783d",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5bd8255-aea3-4c2a-b0e9-66963ecba1a8",
        "ParentId": "5a07cea4-cbaa-48fb-853b-8272de2e57aa",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingIPPool",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/IPPoolManage/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "805804d2-f761-431c-8fec-44f72fa1d740",
        "ParentId": "7a762f2b-ab03-4f95-8d8b-bcf63761213a",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3aef4e8f-b5c9-4e3f-91dd-d5d17452039a",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e43a1663-88ba-4384-9b89-1602d02e3a12",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c78a4004-da84-4d71-bfd5-db5fe3456eb9",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd06efde-5147-41e5-9837-88859ac3a894",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9bf78e3e-b728-48d8-be8e-4e45c300beab",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e6d0fff-cbde-48db-88c5-b5abebe27f0e",
        "ParentId": "805804d2-f761-431c-8fec-44f72fa1d740",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "ParentId": "7a762f2b-ab03-4f95-8d8b-bcf63761213a",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5d208152-f15f-439b-a536-b5dcac6be6a3",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "806c53c7-e69c-4477-a609-1a2fc1639859",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b1b8d555-262f-4da0-b370-212f7301d972",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db2504a6-0e57-4e16-83f1-5181f35a8929",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c069321d-aac3-4d22-b4da-de8a1d07a388",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a06d4d8-d9be-49a0-9278-0fed43f7c939",
        "ParentId": "15ceea16-8f80-46b3-85c6-133f5a9f26da",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnLoad",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Load/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "ParentId": "d855899f-dca9-49cf-aadf-20bc66d8c4d3",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f04b360-f186-4e2d-a5ed-ded7b9e9aaba",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "954a96bb-8bd6-46f8-b1bc-46ecb6210057",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "254b3744-80f7-44d0-a04e-9d4092952d04",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3934de6-c109-429b-841b-3fe9b06e4b11",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4826b9f2-3d66-4eb2-b640-8f2d7cb5fbe8",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "5.Quý",
        "Description": "QR_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9ca529b-8c9a-4c0b-9368-97a9dc396bc0",
        "ParentId": "55fa0ba9-3866-490f-9cc2-60ffe84f622b",
        "Name": "6.Năm",
        "Description": "YR_GgsnFingOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "ParentId": "d855899f-dca9-49cf-aadf-20bc66d8c4d3",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b9a266a-2efb-44cf-acae-7b6d605ee4a6",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2766d119-8ae7-412c-aba7-a797f702bfd0",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7280b131-066f-4874-b5b7-eccb975d09d0",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "856fa62b-2220-4e11-b3c6-d615b536b9ca",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1100179c-5c62-493b-a86d-5888b9e0b41b",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd56b66a-3a65-4cb2-9d4e-f384aeb7c18e",
        "ParentId": "b7bf4fab-35d0-42e8-9e8b-e7b4c3fc01cf",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnOCS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/OCS/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "ParentId": "511b8fa4-71fb-4a9f-a1a8-379d448317bc",
        "Name": "FING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66e0e9d7-8fef-4209-bcfd-22fb35faec51",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "1.GIờ",
        "Description": "HR_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/1.GIờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc5c776e-d5f7-4951-a974-72d618289203",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "2.Ngày",
        "Description": "DY_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99e52a75-4b61-4731-a199-7314351eef01",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "3.Tuần",
        "Description": "WK_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7319df8c-9db3-4335-b50f-e00eea4a0ea1",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "4.Tháng",
        "Description": "MN_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e726b700-ec40-4c5c-ab2c-9f835be2d04b",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "5.Quý",
        "Description": "QR_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70323ceb-1c16-4e5f-a10a-34f05baf637e",
        "ParentId": "636a749c-6f4c-4dc4-acc9-5d275f39c685",
        "Name": "6.Năm",
        "Description": "YR_GgsnP2PFing",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FING/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "ParentId": "511b8fa4-71fb-4a9f-a1a8-379d448317bc",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13a5d533-aad0-43da-afeb-573527e6d847",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "1.Giờ",
        "Description": "HR_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c54849d5-3fe6-4ba0-81c7-ada2ac78127d",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "2.Ngày",
        "Description": "DY_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb389da8-5704-4241-bc61-76760bcf81be",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "3.Tuần",
        "Description": "WK_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a98a0773-c467-4bf7-959d-10d951801532",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "4.Tháng",
        "Description": "MN_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0647e1b-a7f6-48bc-a7cc-d5d5dfdc1129",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "5.Quý",
        "Description": "QR_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72a1a332-7add-4128-82e2-7ef5bea37a67",
        "ParentId": "f87d88c3-7d0f-43e0-8b96-9625dce92dbb",
        "Name": "6.Năm",
        "Description": "YR_GgsnP2PFisn",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/P2P/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76fe42c1-0b8f-412d-bc2d-d46e2c862469",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "1.Giờ",
        "Description": "HR_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8fa54d87-065b-4396-8517-47882bf8b1a8",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "2.Ngày",
        "Description": "DY_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16ec8ba4-1e30-4b12-a151-d1dd8f68ec45",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "3.Tuần",
        "Description": "WK_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd623a9f-71db-465d-8ddb-2c47ba743674",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "4.Tháng",
        "Description": "MN_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2547fe2-d3e8-4b5e-8d63-fea3e9e34af6",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "5.Quý",
        "Description": "QR_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d9a44ed-16ca-4ee1-859f-f752dc502c72",
        "ParentId": "1cbfa36f-9155-4c8c-88cf-a1f76c9ede85",
        "Name": "6.Năm",
        "Description": "YR_s4s11interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S4-S11 Interface/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "882b91ae-c48d-4068-b69a-f7458581281d",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "1.Giờ",
        "Description": "HR_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ed83d3c-af40-400b-9e32-8a7e17c4a1a1",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "2.Ngày",
        "Description": "DY_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b4fe910-d28c-47e8-9632-3f5f3170fb88",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "3.Tuần",
        "Description": "WK_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcf89b80-1647-4ec0-980b-7cdedb366587",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "4.Tháng",
        "Description": "MN_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03983be5-00b1-4fdb-874d-d0320f545c27",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "5.Quý",
        "Description": "QR_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "98760c03-c232-48bc-a6f2-bb0b3abd4177",
        "ParentId": "f54b5028-d856-4c15-a8f5-16f6cd8fee45",
        "Name": "6.Năm",
        "Description": "YR_s5s8interface",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/S5-S8 Interface/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "220c181d-64c5-4b90-8b57-880df634c432",
        "ParentId": "6536baed-08af-420f-87d4-562fe0b8140c",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f70759e1-b50c-4da6-8b97-77d463a0812c",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "1.Giờ",
        "Description": "HR_GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74bc3515-a4bd-4a51-8ae7-f3a8ee3b2ce8",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "2.Ngày",
        "Description": "DY_GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "108d3699-1bb6-45af-a9e6-d7ca219aa8c2",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "3.Tuần",
        "Description": "WK__GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "716d3419-e4c1-4663-9736-9a11910f335b",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "4.Tháng",
        "Description": "MN__GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "799fcf83-6d3c-4ce5-921b-8e1422ab1a67",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "5.Quý",
        "Description": "QR__GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c5dbe16-7f33-41b5-9571-a087b90421e6",
        "ParentId": "220c181d-64c5-4b90-8b57-880df634c432",
        "Name": "6.Năm",
        "Description": "YR__GGSN_SManagement",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/ServiceManagement/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c64e1ba-b6bb-4a98-985d-1e63fe1af3fa",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "1.Giờ",
        "Description": "HR_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f632b249-c2c5-4189-a619-bdd67a702571",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "2.Ngày",
        "Description": "DY_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59777299-7046-407a-bd48-6056daa5b724",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "3.Tuần",
        "Description": "WK_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0145275a-518f-425a-b897-1f028fc62662",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "4.Tháng",
        "Description": "MN_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4b344377-a31c-4888-a190-75a857348bf2",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "5.Quý",
        "Description": "QR_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6059ec80-8087-4722-aef6-05efe86e35ac",
        "ParentId": "cd9337d8-6b41-4668-b36d-381b29bac4f7",
        "Name": "6.Năm",
        "Description": "YR_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/Traffic/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "ParentId": "428e37ee-6a08-49e3-adc6-2d27643571c3",
        "Name": "FISN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bfcfb86-6288-49dd-97b4-7e1cc308b718",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "1.Giờ",
        "Description": "HR_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae3a92e5-4820-45b2-b3f2-521e3586fa91",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "2.Ngày",
        "Description": "DY_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ad6eebe-9bea-4ecb-9abd-3785a98145dd",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "3.Tuần",
        "Description": "WK_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8cf1018f-f538-40c3-a947-f4a67167984a",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "4.Tháng",
        "Description": "MN_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2fe4de52-02e6-4531-8536-fa405110aaf7",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "5.Quý",
        "Description": "QR_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ad6b4b9-a72b-49a5-b48b-e829529e0b24",
        "ParentId": "8b59be92-4709-4c34-88f4-d3da9875fb35",
        "Name": "6.Năm",
        "Description": "YR_GgsnFisnTraffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/1.GGSN-SPGW/TrafficAnalyzer/FISN/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "ParentId": "2e9d07c2-aa0b-4c02-82d9-ab682013d912",
        "Name": "2.CSI",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b10a6b7-21a7-4ad6-b38d-4a7a39b4d659",
        "ParentId": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "Name": "0.MDO",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "ParentId": "1b10a6b7-21a7-4ad6-b38d-4a7a39b4d659",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef337ec8-19b7-453e-a324-897ef70835ae",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "0.Giờ",
        "Description": "HR_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "818014d0-1717-4134-88e1-14046d0cfcca",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "1.Ngày",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c360624c-762c-4ca9-9898-e104a762e42c",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "2.Tuần",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0bafd32-5311-4898-8b6a-ab98e7104788",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "3.Tháng",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f2306cb-1a00-4c31-b283-e7e729842d03",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "4.Quý",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38880f1f-0187-4e21-906d-a14b3208bdd3",
        "ParentId": "7ead5ad4-0826-488d-acdf-f03599f96beb",
        "Name": "5.Năm",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/General/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e898938-cf9e-4874-9067-e5e11836817f",
        "ParentId": "1b10a6b7-21a7-4ad6-b38d-4a7a39b4d659",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ebbaab4c-0df6-475f-b847-70ba2618b9b7",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "0.Giờ",
        "Description": "HR_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "441bc124-442f-4c6a-aee4-862261e566ac",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "1.Ngày",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bf7016e-8154-4147-9c71-f2ad2bf5e023",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "2.Tuần",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d18f1909-50bd-4f92-bc48-45f778deb895",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "3.Tháng",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13b82701-a7d9-4618-80fa-9e03ce88d80a",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "4.Quý",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad231f2c-2491-4199-adb5-9ff02c63c822",
        "ParentId": "0e898938-cf9e-4874-9067-e5e11836817f",
        "Name": "5.Năm",
        "Description": "DY_MDO_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Performance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "ParentId": "1b10a6b7-21a7-4ad6-b38d-4a7a39b4d659",
        "Name": "Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "1.Category",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfe7e912-4efb-40be-9aec-8b7330970f4b",
        "ParentId": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b19697a5-8b00-4971-a0a4-3a818109a65c",
        "ParentId": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99602258-282d-4c81-8753-440c91c10d3b",
        "ParentId": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93e24e05-67da-4465-83d0-ac0542123bca",
        "ParentId": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3617536-e366-476e-86ad-a91527a528ca",
        "ParentId": "72b9d30b-2385-46f4-8353-d12bc32c7194",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/1.Category/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "2.Streaming",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b06c79ef-e0d9-4db8-a39e-60771ae8054a",
        "ParentId": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e9d6fcb-e863-4998-97fe-6c7c547b13a5",
        "ParentId": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3ed08d7-8b98-47d9-a08a-139ab819ca9f",
        "ParentId": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "979e52fc-8c4b-4b52-bd7f-dcb18727010a",
        "ParentId": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd9cba55-0a8a-453e-80bf-f569106e2adf",
        "ParentId": "c098d53a-af99-4bd3-ab13-07697ddb5af5",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/2.Streaming/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50301436-367d-448b-8152-a60a53c93697",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "3.Browsing",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6d923ff-9dde-48b6-95e5-b34e25da6422",
        "ParentId": "50301436-367d-448b-8152-a60a53c93697",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55263d57-10d8-4d56-bd5e-53be8b355e17",
        "ParentId": "50301436-367d-448b-8152-a60a53c93697",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c848e7d2-f282-4ec9-bbff-7228c1a4b02a",
        "ParentId": "50301436-367d-448b-8152-a60a53c93697",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99e496f3-db1c-4ab8-a586-0ae396111028",
        "ParentId": "50301436-367d-448b-8152-a60a53c93697",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "beab72e9-1f5c-4d3c-9531-b33b3ecc6c3e",
        "ParentId": "50301436-367d-448b-8152-a60a53c93697",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/3.Browsing/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "4.Social Media",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcdcfb0d-4761-4cbe-908a-fefebd519473",
        "ParentId": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93955ba2-4455-4ecd-be20-40e8cc81c392",
        "ParentId": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c505f618-4784-4472-b681-aa4b371f9865",
        "ParentId": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da5f7a82-5a0c-4cfc-959c-e6a0dd49b0f2",
        "ParentId": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f34379aa-ea28-4417-9247-500bf255c98f",
        "ParentId": "1bcfbbfd-b034-425f-b72d-f0a8e256eceb",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/4.Social Media/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "5.Instant Messaging",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "acf876fa-efd6-4947-a41c-ac516c592494",
        "ParentId": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69f18669-3661-4385-bc37-02c4a6927d9f",
        "ParentId": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c2136e31-a77d-4465-8cab-1c3593fcbbf1",
        "ParentId": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c929df14-cbf5-46f5-9984-f3aa4f834e0a",
        "ParentId": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aaf9f988-1789-47e8-9cc3-4fe0e4ea2a28",
        "ParentId": "8439d029-3644-4966-b1bc-0e90916e9be6",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/5.Instant Messaging/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b2805b66-8169-4091-86ec-438aefd8560c",
        "ParentId": "20c2b729-b3d8-4e34-a3d3-04d1e67d8387",
        "Name": "6.Voice - Video Calls",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa86e90a-ff85-48c0-a05c-c1d2b86348dd",
        "ParentId": "b2805b66-8169-4091-86ec-438aefd8560c",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d422ea9-d2da-475e-a550-ca7a73e4330c",
        "ParentId": "b2805b66-8169-4091-86ec-438aefd8560c",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e467903-7aee-4fe9-aeea-68dac671c7e0",
        "ParentId": "b2805b66-8169-4091-86ec-438aefd8560c",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8e2cf38-4a54-489c-ab36-3168354097bc",
        "ParentId": "b2805b66-8169-4091-86ec-438aefd8560c",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5959b40-1fea-4d7d-818b-f2068e9c7860",
        "ParentId": "b2805b66-8169-4091-86ec-438aefd8560c",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/0.MDO/Traffic/6.Voice - Video Calls/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de1b9775-19af-44ad-aafb-a0ef3a9b3834",
        "ParentId": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "Name": "1.DEA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "ParentId": "de1b9775-19af-44ad-aafb-a0ef3a9b3834",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f7dead7-3735-46fd-840c-d90f279aea85",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "1.Giờ",
        "Description": "HR_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "493486f9-e463-4163-a993-2a0ea56c16d5",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "2.Ngày",
        "Description": "DY_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e2f523c3-36aa-4170-a18b-7ba3dfd3fa86",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "3.Tuần",
        "Description": "WK_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "268c57a9-0596-489e-bb8d-7ed33101aa95",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "4.Tháng",
        "Description": "MN_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "604e87f6-8de4-4d14-9586-d52ba8655002",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "5.Quý",
        "Description": "QR_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40d71db1-581f-4712-ad19-a24f4b5565c0",
        "ParentId": "13a22389-6f5d-4855-a388-d36c0a6946c4",
        "Name": "6.Năm",
        "Description": "YR_DEA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "ParentId": "de1b9775-19af-44ad-aafb-a0ef3a9b3834",
        "Name": "2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02c42892-9530-4906-bbb1-653d9df91c18",
        "ParentId": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f98fb547-cc15-4dde-ac32-7b1fcce8202a",
        "ParentId": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75e10d0c-7744-4e41-b8d9-b6152029726a",
        "ParentId": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "36c38155-652d-4685-b8e9-fe9a3ee05d1e",
        "ParentId": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fedefd5-829f-4170-8466-26176f9b79c9",
        "ParentId": "94277ec4-c3be-403f-b78f-fa8600e4b1fd",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/2.CSI-DEA-Roaming-IN-4G-Theo Quốc gia_đối tác/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "ParentId": "de1b9775-19af-44ad-aafb-a0ef3a9b3834",
        "Name": "CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31988329-e9b4-4b15-8d05-d451743c57da",
        "ParentId": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9381e7b6-aae3-47d6-a665-6105622612eb",
        "ParentId": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e320419-c8fd-43a5-ad53-4dce96d21c10",
        "ParentId": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56002bb3-7813-4914-b439-3d58b0e04054",
        "ParentId": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f152ddee-1b9e-4426-ba0d-592346634b39",
        "ParentId": "3eec4f92-4384-40d9-8a80-11a1d2c813fc",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.DEA/CSI-DEA-Roaming-OUT-4G-Theo Quốc gia_đối tác/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "845014d7-71c8-4991-a0c9-c6b2a03d691e",
        "ParentId": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "Name": "1.PCRF",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "ParentId": "845014d7-71c8-4991-a0c9-c6b2a03d691e",
        "Name": "General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a4984fd-a803-42bd-a659-46f65845c9a0",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "1.Giờ",
        "Description": "HR_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bfd5d8bb-ee66-4527-be18-f00a60c2de65",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "2.Ngày",
        "Description": "DY_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1efacbf6-f5ff-4f45-ad06-039f711ec12f",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "3.Tuần",
        "Description": "WK_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "730c8754-5ff8-44e2-a700-a0ddf9424549",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "4.Tháng",
        "Description": "MN_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "725a9033-699d-412b-8d30-3f58701cf729",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "5.Quý",
        "Description": "QR_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd16f190-a455-4785-9ea7-dd9229eb601c",
        "ParentId": "d75c167c-c74b-4494-8710-a4a5fc1fb9b9",
        "Name": "6.Năm",
        "Description": "YR_PCRF_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "ParentId": "845014d7-71c8-4991-a0c9-c6b2a03d691e",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1fc64fa1-09af-4c4b-b526-a9e52460489b",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "1.Giờ",
        "Description": "HR_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a0cbafc-b7b9-4614-acd5-424fc6d87b2d",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "2.Ngày",
        "Description": "DY_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eedb4dba-f667-414f-883e-040b2ebd5163",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "3.Tuần",
        "Description": "WK_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8658f851-8a7c-41a3-acc2-a3ab45aa7aea",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "4.Tháng",
        "Description": "MN_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8dc28191-d86d-4fcc-bc87-4a2e6fedcc0d",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "5.Quý",
        "Description": "QR_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb1b7373-5826-4cfe-8a88-655081fb8fbb",
        "ParentId": "fd9b3c13-1333-468e-b395-7e830bb02563",
        "Name": "6.Năm",
        "Description": "YR_PCRF_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/1.PCRF/Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66bf4af0-9b1b-4a06-8831-ce3a38d009eb",
        "ParentId": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "Name": "2.SDC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "ParentId": "66bf4af0-9b1b-4a06-8831-ce3a38d009eb",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08185a83-3fc2-4c54-a4f5-bf89acdf188e",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "1.Giờ",
        "Description": "HR_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f58202e1-fe06-4b0c-b587-9e87441a4fae",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "2.Ngày",
        "Description": "DY_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "872521cb-86f5-4349-bd92-ba7712d9c798",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "3.Tuần",
        "Description": "WK_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a0cdd99-e4b6-4dfb-a0c4-d5726c5214f3",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "4.Tháng",
        "Description": "MN_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "be266396-335a-41f3-80a7-1413421cc8b2",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "5.Quý",
        "Description": "QR_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fda3a155-26dd-4a77-8f1a-51472a47966c",
        "ParentId": "61e9c938-b529-425d-af95-ad94b1cb546b",
        "Name": "6.Năm",
        "Description": "YR_SDC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "ParentId": "66bf4af0-9b1b-4a06-8831-ce3a38d009eb",
        "Name": "1.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7911a4f1-6e44-409b-ad53-49a073dfc7f6",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "1.Giờ",
        "Description": "HR_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "202e41da-9285-44fe-bda5-f80c2930f2f4",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "2.Ngày",
        "Description": "DY_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1bb69fe4-5528-4314-b22f-b18177050965",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "3.Tuần",
        "Description": "WK_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c84ced9f-adbd-4daa-8df6-edf0cc019f30",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "4.Tháng",
        "Description": "MN_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a20d217-404b-443a-ac05-ebd0709e4b25",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "5.Quý",
        "Description": "QR_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "10b913ec-cfb2-436a-8e54-90cf8aa15eaf",
        "ParentId": "c9d5379a-d68b-48d9-aab3-d2fe8879aadd",
        "Name": "6.Năm",
        "Description": "YR_SDC_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/2.SDC/1.Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "715986fe-72c2-4773-8f10-802f3614c5ad",
        "ParentId": "5f6860b5-8079-42ff-87f4-1989b501d922",
        "Name": "1.Giờ",
        "Description": "HR_CSI_AAA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.AAA/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5c5a366-89b3-4ccf-aeb2-78d77baf4145",
        "ParentId": "5f6860b5-8079-42ff-87f4-1989b501d922",
        "Name": "2.Ngày",
        "Description": "DY_CSI_AAA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.AAA/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "157b1d2a-9b89-4990-bfc4-9621de910b32",
        "ParentId": "5f6860b5-8079-42ff-87f4-1989b501d922",
        "Name": "3.Tuần",
        "Description": "WK_CSI_AAA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.AAA/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6785e75-d60c-4970-bd05-3243568f29ee",
        "ParentId": "5f6860b5-8079-42ff-87f4-1989b501d922",
        "Name": "4.Tháng",
        "Description": "MN_CSI_AAA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.AAA/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "ParentId": "f43b1f5c-b15e-42a3-8a7c-4d683aba045d",
        "Name": "3.GIDNS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3dd074fd-8c04-47f8-a568-aa389bc85d6f",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "0.Giờ",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b75b8679-40b1-49c2-86b8-19454ef58b32",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "1.Ngày",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4475a1e1-4570-4fc0-b416-62c983c36082",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "2.Tuần",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6027e99d-d012-42e4-8828-22cdc27a14f1",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "3.Tháng",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30770c70-e650-422b-abc2-6500b6f6f9e0",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "4.Quý",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4933083c-a6e5-44e3-896d-c6d2bb797c9b",
        "ParentId": "673a2168-9521-4fbf-80ee-e4009f8aebb6",
        "Name": "5.Năm",
        "Description": "DY_GIDNS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/3.GIDNS/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8fcf4851-312b-4dc0-bf71-c354ac577e28",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "1.Giờ",
        "Description": "HR_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83348860-dc22-4bcf-8d6c-3c9c2f89588b",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "2.Ngày",
        "Description": "DY_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33f76cfb-3570-42ef-8c5b-06ac98959472",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "3.Tuần",
        "Description": "WK_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9f6809ca-6c0c-493e-becd-e18fad180f30",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "4.Tháng",
        "Description": "MN_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7da9f9b0-0eb8-4257-a90e-e7471751353e",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "5.Quý",
        "Description": "QR_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "634732d1-deb2-4d98-a934-6f761871560b",
        "ParentId": "330ca055-6646-4c84-8ec6-a3babac23b0b",
        "Name": "6.Năm",
        "Description": "YR_CSI_CMD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/5.pscore/2.CSI/4.CMD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8401740c-d51e-4b90-8f57-6b34c97f280e",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "6.IMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "ParentId": "8401740c-d51e-4b90-8f57-6b34c97f280e",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39b52e59-048e-49ee-92e7-c3a942654d5f",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cdfad48e-9f5f-4a09-8d2f-649341e9b91c",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3028773-c980-4cf2-bcbb-6fb7fe28473d",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d274c3e-f8c8-4345-92e2-186eb090ba2b",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dee73d5c-821b-4676-90b2-5d032ce7c15e",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5175c0b3-493e-45ac-8ee7-3047566dbe86",
        "ParentId": "2f93c293-32c6-40aa-bea9-bce5f70289a4",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90e772c0-afef-4938-ac03-ef0abca2b23e",
        "ParentId": "8401740c-d51e-4b90-8f57-6b34c97f280e",
        "Name": "2.EPDG",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/2.EPDG",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "ParentId": "90e772c0-afef-4938-ac03-ef0abca2b23e",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3388fbc1-1948-498e-9e75-71b7653d0e65",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb63911b-ef0f-49b0-892c-3f42e18638e1",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3eaa545-fd09-4a2b-bbf6-8b199e51b40f",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9f2f0b5-89bf-41e7-823f-2599d4f8dbc9",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f52cbc32-769a-4a8d-b282-de70e542f61f",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d0dc691-2428-4574-8bbe-15d12d1815a5",
        "ParentId": "aeaaa8da-d67e-4c54-ab62-18bebea677ab",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34d2569f-4c12-45a6-8632-483feed97212",
        "ParentId": "90e772c0-afef-4938-ac03-ef0abca2b23e",
        "Name": "2.GTP FAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b5b6dac1-8f64-4b7a-8b29-4cef46a59925",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3836783d-fba8-46e8-9d20-fd2a41c04af2",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ff97339-a971-445e-9e2e-e7638c396e80",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3fea221-4269-4a8b-b65d-945ccd76dae7",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b049cad8-db0d-4118-9e08-9225b7202a14",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b68c3758-fa32-48df-826d-f10baf022bb9",
        "ParentId": "34d2569f-4c12-45a6-8632-483feed97212",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/2.GTP FAIL/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "ParentId": "90e772c0-afef-4938-ac03-ef0abca2b23e",
        "Name": "3.IPSEC FAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7497b3c3-b1ab-428e-9379-aee4a97d2034",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "850c58d9-32b1-443a-aa17-b8b45c34f229",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d55a440-a887-4d04-a5a1-1d07ed1b9105",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c4f86986-c6b7-4b3a-bf7b-ff74b74027d7",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9407fd9b-b225-4be0-b65d-143ff7ab2ebd",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "80c71566-eb7c-4d69-9214-253e8b36d4ea",
        "ParentId": "f9112d64-0209-4c4e-9388-61b9d033efee",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/2.EPDG/3.IPSEC FAIL/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14042b8f-ac54-420e-95b1-ddf91fcdaf72",
        "ParentId": "8401740c-d51e-4b90-8f57-6b34c97f280e",
        "Name": "3.vEME",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/3.vEME",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "ParentId": "14042b8f-ac54-420e-95b1-ddf91fcdaf72",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "534fb30a-1d09-4bc6-b257-77af56891aba",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "211d72af-5794-4381-9f22-16a5cec33469",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0be5fd75-c0e3-4cba-9cbd-35a7b3d7e9f4",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3a2eaae-03e2-45a0-98ed-7d7df1d0326a",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed697bbb-183e-4283-92ae-f82d86404f0c",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d41aee63-4eba-411d-9f0f-877c8c9b4d75",
        "ParentId": "5fae890a-0123-4b1a-9b79-d51158c6bcad",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/3.vEME/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8682cee7-558b-46e6-891e-9b21af186579",
        "ParentId": "8401740c-d51e-4b90-8f57-6b34c97f280e",
        "Name": "4.CSCF",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/4.CSCF",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "ParentId": "8682cee7-558b-46e6-891e-9b21af186579",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f060e2a-a3e8-4437-8b91-f479bab42ebe",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "471544b1-6c20-4ac6-8c7c-a34fd8bc2cc5",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6742524a-4f96-420b-991e-344445703600",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "594f3f25-10c4-47fe-b810-8ea289b27b6e",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f08adb9-f68e-44a3-96ea-e6c60ca6f9cc",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7440b9a7-8860-4e45-950a-5f983cbdd008",
        "ParentId": "528d9b8e-bd56-4028-82e2-dadca6c70fd6",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/6.IMS/4.CSCF/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "7.VAS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78b4efbe-a797-45f7-98f6-89c601e09b11",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "1.Ngày",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd7bfc51-88bf-4ee0-ab7c-ca2074b31546",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "2.Tuần",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f5e6e1a3-0285-464e-aedb-0731530afb8f",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "3.Tháng",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64cc428d-66eb-457b-bb73-2cf71cd3a5ea",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "4.Quý",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0c51f70-2b65-44b1-9441-01b75fc8b997",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "5.Năm",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "ParentId": "fd8bb974-994a-4b45-af63-83fb20446b1d",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5bfd6b1c-8a9e-49f3-9357-e68053814f2d",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "2.Giờ",
        "Description": "hr_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/2.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8aa4b0b8-e1f7-42c6-b7d4-7923127246b1",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "3.Ngày",
        "Description": "dy_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/3.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6fdb7e47-8bd7-46b9-a480-8213203b92c5",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "4.Tuần",
        "Description": "wk_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/4.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d085b9e9-1825-43f2-b61c-db90995a3154",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "5.Tháng",
        "Description": "mn_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/5.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9878274c-51b7-4b14-b0bb-a9602e372e51",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "6.Quý",
        "Description": "qr_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/6.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edbee025-3e21-4210-bd2f-40ef65e57bbf",
        "ParentId": "58221e10-f88e-4079-ad3c-2e4aaa471ec2",
        "Name": "7.Năm",
        "Description": "yr_LBS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/0.LBS/Performance/7.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "1.MCA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/1.MCA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4ebc387-8bd3-4106-907f-0ad16f4e59d3",
        "ParentId": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "Name": "1.Ngày",
        "Description": "Dy_MCA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/1.MCA/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8dc1a434-afb0-40a9-9bff-32f295d6158e",
        "ParentId": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "Name": "2.Tuần",
        "Description": "Dy_MCA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/1.MCA/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db88a4ab-996e-4553-ab7a-877cd570bbab",
        "ParentId": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "Name": "3.Tháng",
        "Description": "Dy_MCA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/1.MCA/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "388ce454-fea4-4f0b-9f61-f7d42010cf7a",
        "ParentId": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "Name": "4.Quý",
        "Description": "Dy_MCA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/1.MCA/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a207cf26-3c93-4374-a524-e386435a65e4",
        "ParentId": "89a0e6a6-e70e-40e0-a058-938d250bb57e",
        "Name": "5.Năm",
        "Description": "Dy_MCA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/1.MCA/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "2.MMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/2.MMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6638cedd-77b2-4bc2-8c19-22046f902419",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "1.Giờ",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e52a97c1-bc3c-439d-ac18-571a1cc3621e",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "2.Ngày",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b738eb64-5263-4cb5-9f8d-82caf68100c1",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "3.Tuần",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "700fdb37-6184-4178-ac85-cd36f6d8b69a",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "4.Tháng",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74105262-3be2-4b36-b4d8-96426bcc5162",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "5.Quý",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e697a691-65b7-4217-a473-910c0fc10740",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "6.Năm",
        "Description": "Dy_MMS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3776e98-407e-419f-a535-dee6243a99d9",
        "ParentId": "4fc0e5cc-9c96-4703-b93e-627fface1c41",
        "Name": "Performane",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "52c28914-82ee-43cd-b0ce-efcd632a90f2",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "1.Giờ",
        "Description": "Hr_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d2fc12cd-25c2-49b1-9714-b40bc13b646f",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "2.Ngày",
        "Description": "Dy_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "858fa2b5-2c96-4f66-8e28-f441ced1e8c6",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "3.Tuần",
        "Description": "Wk_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90241be5-9289-47b4-9c88-538f2aeb9bba",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "4.Tháng",
        "Description": "Mn_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6769a12a-2e35-43fd-8b5c-107b16e6d0c3",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "5.Quý",
        "Description": "Qr_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5e93c904-a58d-4fc8-afc8-2fe847731b4a",
        "ParentId": "f3776e98-407e-419f-a535-dee6243a99d9",
        "Name": "6.Năm",
        "Description": "Yr_MMS_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/2.MMS/Performane/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "3.MSP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/3.MSP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b2e86b12-0b99-438b-9c6d-66a20dd427ad",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "0.Giờ",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a95e2aaa-3c51-4251-9f16-3b0374977d6f",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "1.Ngày",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d1b08d5-2d2c-4152-a8af-3199e9cc4548",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e14b8757-f91c-4966-9587-1d710688557e",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21062939-f066-4487-976e-db02af42c732",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "62eafb90-db8b-4f51-a11b-5163e3458349",
        "ParentId": "efa1b5a0-575f-42d0-b7a1-5af592d433d0",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/3.MSP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8a5b132-9103-4815-b2b9-c54192028bac",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "4.SILENT ROAMER",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "ParentId": "a8a5b132-9103-4815-b2b9-c54192028bac",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d265e91-6aa6-4dc0-ab83-d72523714fed",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "0. Giờ",
        "Description": "HR_Silent_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4bf07b40-091f-4703-95b2-ac3c0c527d5a",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "1.Ngày",
        "Description": "DY_Silient_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d921385-2ca2-4cf0-b705-22216a560bf0",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "2.Tuần",
        "Description": "WK_Silent_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56b44ba8-2ecc-4f19-b6a6-c052f8087ed0",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "3.Tháng",
        "Description": "MN_Silent_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f11568ef-84e5-4c4f-abf8-f9fbb43cedb0",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "4.Quý",
        "Description": "QR_Silent_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3ed9441-ced2-45e5-a81b-3fcc2ab52564",
        "ParentId": "e07305a2-4a24-44f6-992e-ef25d68538fa",
        "Name": "5.Năm",
        "Description": "YR_Silent_Roamer",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/1.General/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "ParentId": "a8a5b132-9103-4815-b2b9-c54192028bac",
        "Name": "2.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d7ca044-71e1-41ea-9f61-60da48868102",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "0. Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7591eded-1013-4d8e-b6fc-46eb51c67e2b",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f777b2c-1766-416f-b37d-c68cfa5e197a",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ef046faf-76ce-44d8-af12-53aa7f25feea",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3c0dad9-d8d5-4abd-b66a-b5069c615757",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "04f5eefd-a242-4b6d-8dd7-bf57860b7103",
        "ParentId": "51fc6644-582f-4ce8-a72d-2a11616a8ab9",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SILENT ROAMER/2.Performance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "4.SMPPGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a304429-c4b9-493c-a42c-91080ca05c56",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "0.Giờ",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "383362dc-22c1-4e8a-a5d6-e4b0f15cf710",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "1.Ngày",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ccff071b-f85b-47aa-b4e7-8eed4abdab6d",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "2.Tuần",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "676debb9-27f7-493a-9914-a58b4e354711",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "3.Tháng",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aff36db7-9df1-4c16-9adf-564001a698cc",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "4.Quý",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8b6d37b-d329-4570-a32d-98a124d7ddcc",
        "ParentId": "130cebc0-0d9d-41f6-bf27-bbc465597d2b",
        "Name": "5.Năm",
        "Description": "Dy_SMPPGW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/4.SMPPGW/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "5.SMSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/5.SMSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "daab50a5-d7c7-4b4a-b520-e7b47f9b0077",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "0.Giờ",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9013989-9bff-46b4-8a15-039e17f30eec",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "1.Ngày",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "71723428-0672-400d-b1d1-6872a60d4352",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "2.Tuần",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bcabd0f2-89ab-43d0-82e0-2791d2e7bc9a",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "3.Tháng",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f6206ab-6065-4bc6-8b0e-fcbbc60f11d0",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "4.Quý",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55f08cf5-a3d0-44a4-b493-49cbdc9df7b1",
        "ParentId": "7e7abe15-afed-4699-b507-852dd1a2537e",
        "Name": "5.Năm",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/5.SMSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "6.SMSCB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf0b7e71-12d7-48e4-a049-fc5d200e8819",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "0.Giờ",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca168c9d-f4b1-47f0-a6a1-7cbdb08f86d5",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "1.Ngày",
        "Description": "Dy_SMSCB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81256416-14fe-4202-9fd5-49811394396a",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "2.Tuần",
        "Description": "Dy_SMSCB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "697f8252-5817-40c4-8297-44967da1d92a",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "3.Tháng",
        "Description": "Dy_SMSCB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ee32ef7c-409b-4310-ab63-eaf0cbb55cfe",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "4.Qúy",
        "Description": "Dy_SMSCB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/4.Qúy",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bdedd9e5-a271-4349-8695-52d3dd342c87",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "5.Năm",
        "Description": "Dy_SMSCB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fbafce54-f228-4072-890c-959081c9cc34",
        "ParentId": "8bcbd4bb-1e4f-4485-b91d-706b37619dd1",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "467c7d5a-7847-4f43-a0fe-d0c04d26fa2f",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "0.Giờ",
        "Description": "Hr_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29e30db6-8059-4887-8a6b-ee34907fda0d",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "1.Ngày",
        "Description": "Dy_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c6bb9cc-0517-436d-8902-037030c36cb9",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "2.Tuần",
        "Description": "Wk_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d2dc5920-0414-4cd4-b891-b7bb79738d9a",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "3.Tháng",
        "Description": "MN_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5368bdbb-5ded-4b9a-a236-325e7da88ccb",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "4.Quý",
        "Description": "Qr_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "04933fb5-90b2-4514-b752-6ccb19b1d7e3",
        "ParentId": "fbafce54-f228-4072-890c-959081c9cc34",
        "Name": "5.Năm",
        "Description": "Yr_SMSCB_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/6.SMSCB/Performance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aa0bfdf1-3f0d-484e-85a6-2bbc3a45d6a8",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "7.SMSCMT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "647966a0-478f-44ca-991b-7f66151b3264",
        "ParentId": "aa0bfdf1-3f0d-484e-85a6-2bbc3a45d6a8",
        "Name": "1.Giờ",
        "Description": "HR_SMSCMT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f337a614-cea7-4f1e-b753-6a006d827742",
        "ParentId": "aa0bfdf1-3f0d-484e-85a6-2bbc3a45d6a8",
        "Name": "2.Từ ngày đến ngày",
        "Description": "HR_SMSCMT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/2.Từ ngày đến ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "ParentId": "aa0bfdf1-3f0d-484e-85a6-2bbc3a45d6a8",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "867a9504-ee36-4138-a5dd-15fb7c88c5fd",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "1.Giờ",
        "Description": "HR_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "49484896-e96a-4778-841c-55406a211f42",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "2.Ngày",
        "Description": "DY_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "820e44ab-f4b9-4be5-afec-6c4cbcf5b357",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "3.Tuần",
        "Description": "WK_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c456e48-2993-4fc7-afab-e2685e05163b",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "4.Tháng",
        "Description": "MN_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b68898e3-d838-48c4-a7eb-86e98b3d7004",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "5.Quý",
        "Description": "QR_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "194585cc-b479-44be-83a6-423f921be1a3",
        "ParentId": "3b62f7c1-0a92-446e-b8b1-fd71b1afa77e",
        "Name": "6.Năm",
        "Description": "YR_SMSCMT_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/7.SMSCMT/Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "8.SMSFW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da8a6172-34b8-48de-b784-5457364c79a2",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "1.Ngày",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e81f434-f214-4d52-8b4b-8f36573b61bb",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "2.Tuần",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "471874f0-13b6-4c57-b378-be5781073564",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "3.Tháng",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd07c90a-ffac-404d-be9b-0f5adcb1ab33",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "4.Quý",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2cac5f92-975c-4f04-8e69-be456acb00ee",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "5.Năm",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "ParentId": "102b35ec-de63-4293-bb84-18e39e8bb84a",
        "Name": "Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2abf4f54-429c-4c96-93e1-2048e1234b33",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "0.Giờ",
        "Description": "HR_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dec2ef5e-9005-41ae-b312-d74123b3312a",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "1.Ngày",
        "Description": "Dy_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "522ef16f-f955-4809-8dc9-dec95db7fc4d",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "2.Tuần",
        "Description": "WK_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a524f7a-9efc-4b77-99b1-d22b453b6bd1",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "3.Tháng",
        "Description": "MN_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d13d76e-1049-49a8-9ad7-106ff1008cba",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "4.Quý",
        "Description": "QR_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "12f847cc-9f96-4009-8ff8-d4c1d5522981",
        "ParentId": "ce6afa1b-5a16-4ce0-90a3-2bb73e414a0a",
        "Name": "5.Năm",
        "Description": "YR_SMSFW_perform",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/8.SMSFW/Performance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bae2ce9b-943d-4a6c-83e5-6eec4d2bd6b2",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "9.ADC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/9.ADC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "ParentId": "bae2ce9b-943d-4a6c-83e5-6eec4d2bd6b2",
        "Name": "0.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c0037d1-1bc3-4621-abd2-dd30c6795e38",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "1.Giờ",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "979864bd-f0df-4050-a2fa-52f5126c6e70",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "2.Ngày",
        "Description": "DY_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff833625-f1c2-4733-b975-1b28288d8684",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "3.Tuần",
        "Description": "WK_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d82927a8-f704-4cd4-a42a-8f9b36caca45",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "4.Tháng",
        "Description": "MN_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af770623-b11d-424c-ac0a-f8d62387e1cc",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "5.Quý",
        "Description": "QR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b736735-0659-44c2-b692-b17758f8d394",
        "ParentId": "f03b53c7-29e6-4221-94ab-dbf1712ceb7f",
        "Name": "6.Năm",
        "Description": "YR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/0.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "ParentId": "bae2ce9b-943d-4a6c-83e5-6eec4d2bd6b2",
        "Name": "1.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "662692c9-3385-4e8c-9f85-fb80428f5e35",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "1.Giờ",
        "Description": "HR_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21881c78-4b02-4f07-be2c-f8004a591a80",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "2.Ngày",
        "Description": "DY_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "066ad91b-4505-441b-b0ff-41887fbb3d87",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "3.Tuần",
        "Description": "WK_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "262ef9f4-664f-4912-b19d-ab33129003df",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "4.Tháng",
        "Description": "MN_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "11c728e8-31c6-4869-a943-df73d06d4972",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "5.Quý",
        "Description": "QR_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "afead976-b6f7-4255-bb8e-f75036fa75a5",
        "ParentId": "9c99bce2-d676-4517-865b-2e3b8033dc10",
        "Name": "6.Năm",
        "Description": "YR_ADC_perfrom",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/9.ADC/1.Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6308dca6-0c00-488b-b9ff-7ddd976ec89d",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "90.USSDGW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "ParentId": "6308dca6-0c00-488b-b9ff-7ddd976ec89d",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3e62350-1f09-42f6-b0cf-c38b0e5df326",
        "ParentId": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "Name": "1.Giờ",
        "Description": "HR_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c269ccec-1271-4bca-91cb-a377568259b8",
        "ParentId": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "Name": "2.Ngày",
        "Description": "DY_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7d8ea32-6a68-41ff-a87a-65954547825a",
        "ParentId": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "Name": "3.Tuần",
        "Description": "WK_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e036d5e-a552-4b71-986c-d5e07c52f5ca",
        "ParentId": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "Name": "4.Tháng",
        "Description": "MN_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbcdba2f-b964-4af8-83fa-49c8de1c6e40",
        "ParentId": "e96bbb5b-d056-47f7-9764-6e2da6ac510c",
        "Name": "6.Năm",
        "Description": "YR_vas_ussdgw_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/1.General/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "ParentId": "6308dca6-0c00-488b-b9ff-7ddd976ec89d",
        "Name": "2.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a581baa-9489-4c34-a04c-288e73af5dd5",
        "ParentId": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "Name": "1.Giờ",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e255751e-783e-4beb-ac5e-5cebfed47cbf",
        "ParentId": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "Name": "2.Ngày",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f30ef51-bf19-40f6-90ec-ac1f9d72eb5d",
        "ParentId": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "Name": "3.Tuần",
        "Description": "WK_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "674a52d2-1eb5-4c08-99d7-a753ea3f62cf",
        "ParentId": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "Name": "4.Tháng",
        "Description": "MN_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c6e4f90-a105-45b7-bdce-5165eacd86c0",
        "ParentId": "a0b991d2-96d2-4268-97e0-ef826f591291",
        "Name": "6.Năm",
        "Description": "YR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/90.USSDGW/2.Performance/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3db0d4a4-63ea-4acf-a472-7fe4c05d4c41",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "91.ERROR CODE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c0fddd7-b20c-487e-87d7-0281ed37fc7f",
        "ParentId": "99fb92a8-bf26-4c36-adba-d6dbf871577c",
        "Name": "1.Ngày",
        "Description": "Dy_LBS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/1.LBS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ea357fa0-aa17-42a4-a9bf-67b204c791ea",
        "ParentId": "3db0d4a4-63ea-4acf-a472-7fe4c05d4c41",
        "Name": "2.MMSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/2.MMSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad4bd9cf-a706-4f6a-b963-e78e6d15730e",
        "ParentId": "ea357fa0-aa17-42a4-a9bf-67b204c791ea",
        "Name": "1.Ngày",
        "Description": "Dy_MMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/2.MMSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f20eea8-b51b-4584-9a33-03ffaf9ee35d",
        "ParentId": "3db0d4a4-63ea-4acf-a472-7fe4c05d4c41",
        "Name": "3.SMSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/3.SMSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "11069146-5bac-48e4-a5e8-b121935a1815",
        "ParentId": "3f20eea8-b51b-4584-9a33-03ffaf9ee35d",
        "Name": "1.Ngày",
        "Description": "Dy_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/3.SMSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8e7104b6-729d-4041-bcd2-ef928b7be63b",
        "ParentId": "3db0d4a4-63ea-4acf-a472-7fe4c05d4c41",
        "Name": "4.SMSFW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/4.SMSFW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bdde9e6-f7a8-46d3-927a-1c94cb0421a1",
        "ParentId": "8e7104b6-729d-4041-bcd2-ef928b7be63b",
        "Name": "1.Ngày",
        "Description": "Dy_SMSFW",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/91.ERROR CODE/4.SMSFW/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb4687ed-8a5c-4768-9ae6-ff3154ca8a93",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "92.Antispam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/92.Antispam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "ParentId": "fb4687ed-8a5c-4768-9ae6-ff3154ca8a93",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33ae8ffe-188f-4c4b-a673-831aa2787298",
        "ParentId": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "Name": "1.Ngày",
        "Description": "Dy_vas_antispam_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e814256-d6b9-4c27-b6b4-2b390c15dd05",
        "ParentId": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "Name": "2.Tuần",
        "Description": "Wk_vas_antispam_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0139fca5-3278-48b8-a46e-1bd9012e0a48",
        "ParentId": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "Name": "3.Tháng",
        "Description": "MN_vas_antispam_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15b567a5-683e-45c0-a3b9-8409de8c5caa",
        "ParentId": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "Name": "4.Quý",
        "Description": "QR_vas_antispam_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c72f2b0-f55e-42fe-a847-3fe99301c8c8",
        "ParentId": "5c336c6e-e50b-4b8c-8a7e-9f76e5125e1d",
        "Name": "5.Năm",
        "Description": "YR_vas_antispam_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/1.General/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "ParentId": "fb4687ed-8a5c-4768-9ae6-ff3154ca8a93",
        "Name": "2.Perforrmance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fc647782-05bc-427e-9165-40d5fd55023e",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "0.Giờ",
        "Description": "HR_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed55a8e3-03f5-4f7c-9e91-4fd5860f128b",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "1.Ngày",
        "Description": "Dy_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d66f76f7-6b49-4629-9806-37ee22980192",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "2.Tuần",
        "Description": "Wk_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7cd485c-21c1-41eb-b635-0c3205ff63eb",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "3.Tháng",
        "Description": "MN_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c309139-27fb-4421-a8b1-eb082adfb912",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "4.Quý",
        "Description": "QR_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de3533a6-5d6d-4245-8721-ff63a75e03bf",
        "ParentId": "3a7e318c-47fc-4cb3-b3f5-a9d6c2ae2c8b",
        "Name": "5.Năm",
        "Description": "YR_vas_antispam_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/92.Antispam/2.Perforrmance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3480043f-aeb4-4208-9f31-a75c06facb91",
        "ParentId": "f19a82ea-2e7a-4c8e-9d0e-fda4a9111dab",
        "Name": "93.CRBT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/93.CRBT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "ParentId": "3480043f-aeb4-4208-9f31-a75c06facb91",
        "Name": "1.General",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "489cf8c8-bbdd-4cdf-b42a-b0fcf3a09a61",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "0.Giờ",
        "Description": "HR_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28855c9d-8241-44ed-a58e-9b9a2414b3e5",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "1.Ngày",
        "Description": "Dy_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93778349-1934-41db-bb11-922ddac649b9",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "2.Tuần",
        "Description": "Wk_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "936f52ab-759a-4b35-b713-0b1a74d31540",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "3.Tháng",
        "Description": "MN_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "215d8d91-e6da-4f62-8c59-4663e29053f0",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "4.Quý",
        "Description": "QR_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ef367ea-d480-4473-ab3c-9f4d4107d9c3",
        "ParentId": "37c1fd2b-8e43-4bc7-ada0-27c773832750",
        "Name": "5.Năm",
        "Description": "YR_crbt_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/1.General/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "ParentId": "3480043f-aeb4-4208-9f31-a75c06facb91",
        "Name": "2.Performance",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ac60940-78a0-411f-9eac-f2c8d9d938dc",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "0.Giờ",
        "Description": "HR_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7f37e53c-dea9-4cd4-87ac-130adc8560e3",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "1.Ngày",
        "Description": "Dy_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "768fa692-d1f5-4a4c-ad62-6f59b339aa47",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "2.Tuần",
        "Description": "Wk_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91a4b521-8d1f-4ad5-8f2d-1457c418ba6e",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "3.Tháng",
        "Description": "MN_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfa0f923-20f2-45a2-a4b8-ba914087206a",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "4.Quý",
        "Description": "QR_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b845d461-1ecc-4ea1-9e5c-6948983fa957",
        "ParentId": "8dbb7282-985a-4e76-a458-c27fb809ceb7",
        "Name": "5.Năm",
        "Description": "YR_crbt_per",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/2.Performance/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "ParentId": "3480043f-aeb4-4208-9f31-a75c06facb91",
        "Name": "3.KPI Call Node",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81016433-e01a-4cbe-8344-166734f67096",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "0.Giờ",
        "Description": "HR_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bd90b8a-025d-434c-afef-43be3bd2e049",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "1.Ngày",
        "Description": "Dy_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f73b3e0c-98e3-439d-8a8f-490416c1880d",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "2.Tuần",
        "Description": "Wk_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29db254e-649d-4f4d-955a-830a5c523c4b",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "3.Tháng",
        "Description": "MN_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7cd23b5-a86c-4e46-a4d1-0842ae271085",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "4.Quý",
        "Description": "QR_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17029ef6-f43c-4f2e-8aa2-09ff5989698c",
        "ParentId": "59c04fae-1b33-466d-ad6f-9ae01c5a49c0",
        "Name": "5.Năm",
        "Description": "YR_crbt_kpi call node",
        "TypeName": "Report",
        "Path": "/noc-sopirs/7.VAS/93.CRBT/3.KPI Call Node/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e88cd684-6b72-46c8-9720-9a336b103411",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "8.IN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "1.CAPSSCP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "511be1f2-7fd0-4769-b58c-0ca1a9087c7e",
        "ParentId": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "Name": "1.Ngày",
        "Description": "Dy_INCapsSCP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32e1365d-e8ff-42bd-bbdc-1f6a3d61c11b",
        "ParentId": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "Name": "2.Tuần",
        "Description": "Wk_INCapssCP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bb1fa2e1-c2d0-47cf-84a5-cc7d073b3295",
        "ParentId": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84ec95f5-3fe8-4124-b11a-a027705f65cf",
        "ParentId": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01c27451-d597-4933-950d-6f8d8571f4cf",
        "ParentId": "3d580b61-34d4-4169-ad57-635f0bdb5dcd",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/1.CAPSSCP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "2.CPURAM",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/2.CPURAM",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "97dd9481-2ab5-4ba3-9439-0aa1f9811e89",
        "ParentId": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "Name": "1.Ngày",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/2.CPURAM/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abfb23a4-3a5f-4663-8e77-e7f64058a195",
        "ParentId": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "Name": "2.Tuần",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/2.CPURAM/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0b71247-8631-4e99-aaff-1828bc6818b3",
        "ParentId": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/2.CPURAM/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af4cc733-ee55-4d2d-8251-412ff5e7538b",
        "ParentId": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/2.CPURAM/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ddb40a63-140b-4398-bb1f-1e1c71b2a942",
        "ParentId": "944770c9-92ea-4e7e-9adb-6134294586d2",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/2.CPURAM/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "3.QoSService",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/3.QoSService",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "975db0fb-b8a0-43d1-b851-97f8087801be",
        "ParentId": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "Name": "1.Ngày",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/3.QoSService/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ea4a7ea4-64ee-4fe5-b904-6ed7740e3ec8",
        "ParentId": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "Name": "2.Tuần",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/3.QoSService/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "afc05a1b-8662-4e33-a2b4-ec3fd8db6416",
        "ParentId": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "Name": "3.Tháng",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/3.QoSService/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08cc0d42-6159-46cc-98c6-4e7c88040b09",
        "ParentId": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "Name": "4.Quý",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/3.QoSService/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c49d2b83-003e-4b00-a157-e7e04d41943b",
        "ParentId": "2c91d458-07e6-4a39-be5f-a7efe18a119e",
        "Name": "5.Năm",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/3.QoSService/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "4.SIGTRAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d8b3643-bd6d-4ea8-8872-791e979ec770",
        "ParentId": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "Name": "1.Ngày",
        "Description": "Dy_Insigtran",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbfb4093-4bea-4e27-a94a-64b7b0322ba0",
        "ParentId": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "395d0200-b196-422f-8806-a1ef02fb21f5",
        "ParentId": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f9998a8-5748-4390-b790-75150f0083ed",
        "ParentId": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c2acd40-a574-4663-9576-14b41fde7d20",
        "ParentId": "e7ca8924-0cad-45f1-979c-0b9c405e2a99",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/4.SIGTRAN/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "5.SUBS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/5.SUBS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16f6722f-e68b-4353-b455-987f07ec362c",
        "ParentId": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "Name": "1.Ngày",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/5.SUBS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "36ad8c62-bc01-4185-b838-de16d56b6aca",
        "ParentId": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/5.SUBS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f58e8784-4a7f-4370-bade-6a61dac7ad28",
        "ParentId": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/5.SUBS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4394bbeb-d753-44d5-a40c-976bc253adb6",
        "ParentId": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/5.SUBS/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1220f347-430a-4103-a50b-b04a766d1413",
        "ParentId": "e4ffa817-3851-47ef-9a26-c58cdc1c0e92",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/5.SUBS/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "ParentId": "e88cd684-6b72-46c8-9720-9a336b103411",
        "Name": "6.INICC(cũ)",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "CAPSSCP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0603934-da8b-4604-a3d3-39fc56eb4679",
        "ParentId": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "Name": "1.Ngày",
        "Description": "Dy_INCapsSCP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22cc0b16-e49a-48a5-90f4-f1f6cdfa2f19",
        "ParentId": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "Name": "2.Tuần",
        "Description": "Wk_INCapssCP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c6aba5d-c649-482a-98f0-3b8cf459692d",
        "ParentId": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "51e25806-b675-4400-b35d-8a4f0a2f46e7",
        "ParentId": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "872481b8-6782-4aa4-bcb1-eb65055fd41c",
        "ParentId": "d68571d7-1c9d-43d2-933c-0827e3ad0ac0",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSCP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "CAPSSDP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e33ad432-3756-4a65-992c-4b5e255b8580",
        "ParentId": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "Name": "1.Ngày",
        "Description": "Dy_INCapsSDP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffb2515f-a72a-42fd-969e-9585448578d9",
        "ParentId": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "Name": "2.Tuần",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94753fb7-58e5-40a8-a1f6-a8a1c314cb63",
        "ParentId": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e642737f-a6ef-47b1-9308-c0133c0057de",
        "ParentId": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bc38739-7784-4946-86bb-17af9c98fc78",
        "ParentId": "85bfdbfb-a69c-4cbe-b044-c46fc9698e3c",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CAPSSDP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "CPURAM",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a299416-a21a-4de7-aa7c-e6356223fb3f",
        "ParentId": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "Name": "1.Ngày",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa24aa0a-df81-4658-9c20-7960f49a7d02",
        "ParentId": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "Name": "2.Tuần",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a21f7f79-3a7f-4c34-b510-853f594bdb43",
        "ParentId": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd691171-7d3d-4095-9d7d-360c67d6fa15",
        "ParentId": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c50591c6-8f44-41f7-b3da-bb18eb8ffb5c",
        "ParentId": "4df5e37f-76f6-4374-9ff0-6d2daa3a7592",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/CPURAM/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "IVMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38502063-c5db-407d-b0bc-13ae516e98da",
        "ParentId": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "Name": "1.Ngày",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c8fbbba-0de5-4208-8aa8-2471d11f6251",
        "ParentId": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "Name": "2.Tuần",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "393b34bb-5036-4e1e-8a90-cd2148702b67",
        "ParentId": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "Name": "3.Tháng",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "306ff284-731d-4e44-a9ca-b7eefaedd6a2",
        "ParentId": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "Name": "4.Quý",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37e98f2e-7698-4a32-8297-78ffa4092fc0",
        "ParentId": "8c544b54-dcf8-47c3-b1a5-ecaa0152a4ee",
        "Name": "5.Năm",
        "Description": "Dy_INCPURAMHDD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/IVMS/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "QoS-Service",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff67654b-9c88-4dd6-b564-3110fb6479ef",
        "ParentId": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "Name": "1.Ngày",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ea75cc5-aada-4b53-b6a8-d9e157a0ac8f",
        "ParentId": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "Name": "2.Tuần",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15b8c0a3-01cc-4c2f-9570-9da3b5783d99",
        "ParentId": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "Name": "3.Tháng",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55a7d144-6694-4b9a-9de9-d90a7020930c",
        "ParentId": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "Name": "4.Quý",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8f60bd0-c3ef-473e-ad12-3e7f3cd48e17",
        "ParentId": "1baca687-083a-4670-a6ed-90ea4a0cd938",
        "Name": "5.Năm",
        "Description": "Dy_INQoS",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/QoS-Service/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "SIGTRAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "750b0067-5d0d-4970-b7c1-d151cf2f6f0d",
        "ParentId": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "Name": "1.Ngày",
        "Description": "Dy_Insigtran",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d821de0-f4f0-4edb-935f-57db91933c4c",
        "ParentId": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2e29003-6732-428c-961d-c2504c9c2c3f",
        "ParentId": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83ad884c-9881-4829-9927-ff39d2a8b70c",
        "ParentId": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbd14945-e3bd-4a34-a6cc-054c7df015ab",
        "ParentId": "d45ec4e3-1715-4fa6-abe6-cb62e8cec010",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SIGTRAN/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "SUBS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20c51de5-67ad-4a36-9d2f-0000410716f6",
        "ParentId": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "Name": "1.Ngày",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4521a3a-23d5-4eef-bb79-87ea4d09a832",
        "ParentId": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ccd43ba-56d4-401d-b761-1306473e2dc0",
        "ParentId": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08783ac7-7586-4b05-adaf-9161b1fa1f9a",
        "ParentId": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df371f00-d2f4-4233-a46d-cdd834e1736f",
        "ParentId": "0fbd11a3-8803-4d80-95da-0280a8aae15c",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/SUBS/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "ParentId": "a08bbe9f-47b8-4974-b839-ad64a04efc73",
        "Name": "TAPPING-IN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78cedaa0-ac90-4948-a107-289eae781837",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "0. Giờ",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8acc205-6c1d-4e13-aea4-1e858e7b5f23",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "1.Ngày",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f4573fe-a549-42ed-9f18-11c59899374d",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "2.Tuần",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b0df4de4-a00a-4fd9-8e52-fbc1baffbff9",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "3.Tháng",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21c18a8a-5481-4da2-a832-11b88b0d9bb9",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "4.Quý",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "14c15e94-fc3c-41eb-a5d6-2febcc86a54d",
        "ParentId": "89c21071-1eb3-4eba-8f60-32f2cef025da",
        "Name": "5.Năm",
        "Description": "HR_ADC_gen",
        "TypeName": "Report",
        "Path": "/noc-sopirs/8.IN/6.INICC(cũ)/TAPPING-IN/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "9.Truyền Dẫn",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5be28010-3bec-4be7-bc64-f822be938a5c",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "1.DWDM",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2bf2845e-ea07-4b72-a3d7-9acda50a1505",
        "ParentId": "5be28010-3bec-4be7-bc64-f822be938a5c",
        "Name": "1.Đứt cáp Suy hao online",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/1.Đứt cáp Suy hao online",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "403d77be-31f9-4498-852e-09560e0a91c3",
        "ParentId": "5be28010-3bec-4be7-bc64-f822be938a5c",
        "Name": "2.BC Đứt Cáp",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/2.BC Đứt Cáp",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4037062d-92fd-43f6-b1ec-c7609094f3e7",
        "ParentId": "403d77be-31f9-4498-852e-09560e0a91c3",
        "Name": "1.Ngày",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/2.BC Đứt Cáp/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a84681e-2c3d-4276-b731-865fa58b3a29",
        "ParentId": "403d77be-31f9-4498-852e-09560e0a91c3",
        "Name": "2.Tuần",
        "Description": "WK_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/2.BC Đứt Cáp/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc96f0d6-7de8-4f55-b853-8733c020377a",
        "ParentId": "403d77be-31f9-4498-852e-09560e0a91c3",
        "Name": "3.Tháng",
        "Description": "MN_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/2.BC Đứt Cáp/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b135adb4-c798-4206-a344-eb3a08aec390",
        "ParentId": "403d77be-31f9-4498-852e-09560e0a91c3",
        "Name": "4.Năm",
        "Description": "YR_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/2.BC Đứt Cáp/4.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25ad6dbc-31c4-4187-b22d-4777a7aadd22",
        "ParentId": "5be28010-3bec-4be7-bc64-f822be938a5c",
        "Name": "3.Dịch vụ",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/3.Dịch vụ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edbab97e-bc0f-4495-96dd-5e0bb7b5b8c1",
        "ParentId": "25ad6dbc-31c4-4187-b22d-4777a7aadd22",
        "Name": "1.Hướng Mất Dịch Vụ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/3.Dịch vụ/1.Hướng Mất Dịch Vụ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6918547e-7303-42f7-8544-0398aaa4f11f",
        "ParentId": "25ad6dbc-31c4-4187-b22d-4777a7aadd22",
        "Name": "2.Kênh Mất Dịch Vụ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/3.Dịch vụ/2.Kênh Mất Dịch Vụ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "444f0e29-0633-4088-9580-5e52d70824b1",
        "ParentId": "5be28010-3bec-4be7-bc64-f822be938a5c",
        "Name": "4.Tổng hợp",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/4.Tổng hợp",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "380bafb7-5c18-4687-a2cc-81306ff0d902",
        "ParentId": "444f0e29-0633-4088-9580-5e52d70824b1",
        "Name": "1.Ngày",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/4.Tổng hợp/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "101e74fc-7812-4a48-a3ae-5651cd5cc94e",
        "ParentId": "444f0e29-0633-4088-9580-5e52d70824b1",
        "Name": "2.Tuần",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/4.Tổng hợp/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e515af6-7e17-4f8b-8659-18f5f2dc9f78",
        "ParentId": "444f0e29-0633-4088-9580-5e52d70824b1",
        "Name": "3.Tháng",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/4.Tổng hợp/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2cc28190-b966-4d1d-90e1-23e301a84836",
        "ParentId": "444f0e29-0633-4088-9580-5e52d70824b1",
        "Name": "4.Năm",
        "Description": "Dy_DWDM",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/1.DWDM/4.Tổng hợp/4.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "2.Kênh truyền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "405874e9-43b2-4436-b5e1-1639430e2c7b",
        "ParentId": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "Name": "1.Kênh internet",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/1.Kênh internet",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2dd6cf79-3595-44ad-bfe7-b0e95a0e96cb",
        "ParentId": "405874e9-43b2-4436-b5e1-1639430e2c7b",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/1.Kênh internet/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a89e1f8f-5dc4-4926-8352-94d2d40f77ae",
        "ParentId": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "Name": "11.Kênh truyền Internet THP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/11.Kênh truyền Internet THP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbaad5a2-6aac-4c21-8d1c-a94872cea11a",
        "ParentId": "a89e1f8f-5dc4-4926-8352-94d2d40f77ae",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/11.Kênh truyền Internet THP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d4ff486-089e-427f-afcd-cf7cdfa1f954",
        "ParentId": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "Name": "2.Kênh liên tỉnh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/2.Kênh liên tỉnh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3a405030-7dd5-4487-8bbd-7419b2de3774",
        "ParentId": "7d4ff486-089e-427f-afcd-cf7cdfa1f954",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/2.Kênh liên tỉnh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6f788e2-dba8-4f9a-9d4a-da5bffd2b333",
        "ParentId": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "Name": "3.Kênh liên vùng",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/3.Kênh liên vùng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbe81aa4-e7a9-4888-b10f-6cb02593d220",
        "ParentId": "c6f788e2-dba8-4f9a-9d4a-da5bffd2b333",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/3.Kênh liên vùng/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0208c150-12f0-4a78-924b-4df5c466c6c1",
        "ParentId": "a4b16ff8-eb7c-42c7-b1c4-9d8cd0abdadb",
        "Name": "4.Kênh nội vùng",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/4.Kênh nội vùng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c488639f-8714-477d-b4c0-0273ebf03f6b",
        "ParentId": "0208c150-12f0-4a78-924b-4df5c466c6c1",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/2.Kênh truyền/4.Kênh nội vùng/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "3.Hiệu suất mức trạm",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "734b21e7-52f8-4dc3-b27c-00ab9a07a7d9",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "1.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/1.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41acf293-d590-4860-a420-88903d1a1504",
        "ParentId": "734b21e7-52f8-4dc3-b27c-00ab9a07a7d9",
        "Name": "1.Ngày",
        "Description": "DY_Counter_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/1.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2a8435c-3f6b-49b2-b9f2-1d18866166c4",
        "ParentId": "734b21e7-52f8-4dc3-b27c-00ab9a07a7d9",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/1.SITE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb77c998-963f-45b4-80a6-234778dd6854",
        "ParentId": "734b21e7-52f8-4dc3-b27c-00ab9a07a7d9",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/1.SITE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76051fb0-1f56-4d6a-a2ba-0b14415c905c",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "2.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/2.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b65870b2-63fb-4820-8893-f9f6b471550b",
        "ParentId": "76051fb0-1f56-4d6a-a2ba-0b14415c905c",
        "Name": "1.Ngày",
        "Description": "DY_Counter_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/2.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e251dacf-f07b-418a-bce8-f42600ee7684",
        "ParentId": "76051fb0-1f56-4d6a-a2ba-0b14415c905c",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/2.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c027245d-a3f6-4c72-bc8c-82ae93fd9c63",
        "ParentId": "76051fb0-1f56-4d6a-a2ba-0b14415c905c",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/2.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "887a4231-c472-4c24-9d39-3dc779733e9c",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "3.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/3.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc7f3efd-d0ec-4427-9910-db195572cf0c",
        "ParentId": "887a4231-c472-4c24-9d39-3dc779733e9c",
        "Name": "1.Ngày",
        "Description": "DY_Counter_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/3.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf91098d-e982-4d89-8a8e-f44be8a88ca2",
        "ParentId": "887a4231-c472-4c24-9d39-3dc779733e9c",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/3.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "522107d9-fd2e-49e8-ac10-c40257a16983",
        "ParentId": "887a4231-c472-4c24-9d39-3dc779733e9c",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/3.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "87968fc3-ab67-41fb-8786-fd3a251812f3",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "4.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/4.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e355883-67af-4f15-acbc-2381cda28f48",
        "ParentId": "87968fc3-ab67-41fb-8786-fd3a251812f3",
        "Name": "1.Ngày",
        "Description": "DY_Counter_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/4.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27828b85-5c39-4023-8db8-59a99ff4c3a0",
        "ParentId": "87968fc3-ab67-41fb-8786-fd3a251812f3",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/4.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e896812f-d9b6-4b6f-a28a-e8c665ae9361",
        "ParentId": "87968fc3-ab67-41fb-8786-fd3a251812f3",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/4.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b4eca5b-af20-4d9b-bdc6-7bf6c81da6c8",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "5.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/5.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7054883-4c77-4e83-a068-863793041389",
        "ParentId": "8b4eca5b-af20-4d9b-bdc6-7bf6c81da6c8",
        "Name": "1.Ngày",
        "Description": "DY_Counter_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/5.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3f2edaf-7ef6-4881-864d-c0014153f5c1",
        "ParentId": "8b4eca5b-af20-4d9b-bdc6-7bf6c81da6c8",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/5.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9f527e8c-994f-46d0-8675-7a4c5242552d",
        "ParentId": "8b4eca5b-af20-4d9b-bdc6-7bf6c81da6c8",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/5.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b23c1cbf-7225-4110-8469-b5298e36126d",
        "ParentId": "ab0da7b9-373f-40f7-9aeb-677d6d2fab79",
        "Name": "6.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/6.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b6dc109-06a9-4467-99c1-43972e19e696",
        "ParentId": "b23c1cbf-7225-4110-8469-b5298e36126d",
        "Name": "1.Ngày",
        "Description": "DY_Counter_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/6.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf3334b1-3258-4b03-a66a-0b646e3a3fc8",
        "ParentId": "b23c1cbf-7225-4110-8469-b5298e36126d",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/6.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "716b198f-6506-4148-b341-0bc835be0e4e",
        "ParentId": "b23c1cbf-7225-4110-8469-b5298e36126d",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/3.Hiệu suất mức trạm/6.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fdcd90a4-1ee7-4325-a736-44abd923e848",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "31.Toàn Trình IP RAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/31.Toàn Trình IP RAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "677561bc-2ec2-4ffc-ae5e-2f025e2f56f8",
        "ParentId": "fdcd90a4-1ee7-4325-a736-44abd923e848",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/31.Toàn Trình IP RAN/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0b3c57b-257c-4ec3-a5ae-3918d0b690a8",
        "ParentId": "fdcd90a4-1ee7-4325-a736-44abd923e848",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/31.Toàn Trình IP RAN/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c3e2c34-82ac-4397-abc0-b6b2392c4040",
        "ParentId": "fdcd90a4-1ee7-4325-a736-44abd923e848",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/31.Toàn Trình IP RAN/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3748c83-afff-4ff8-b88b-ba3db1d9fed8",
        "ParentId": "fdcd90a4-1ee7-4325-a736-44abd923e848",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/31.Toàn Trình IP RAN/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2855e531-fef8-4ce5-8423-43d4747d428a",
        "ParentId": "fcb5c8f5-507a-4a55-b652-1578977e7e1e",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a114867e-cc59-47e2-b2ae-a496148101cc",
        "ParentId": "2855e531-fef8-4ce5-8423-43d4747d428a",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Miền Bắc/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac7cfd59-2fea-40d5-98e3-20f8008dfecf",
        "ParentId": "2855e531-fef8-4ce5-8423-43d4747d428a",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Miền Bắc/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "40aaa9c0-5c8e-4494-88a9-ca75920092a4",
        "ParentId": "2855e531-fef8-4ce5-8423-43d4747d428a",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Miền Bắc/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc100d7c-c395-4e21-b716-84afb3f99fbc",
        "ParentId": "2855e531-fef8-4ce5-8423-43d4747d428a",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Miền Bắc/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "87661889-bbac-462e-8a1e-6bb74491511b",
        "ParentId": "853e0c49-c785-4983-a180-578f7a681746",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22103c60-1acd-4a39-8686-be65fe7207cf",
        "ParentId": "87661889-bbac-462e-8a1e-6bb74491511b",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/1.Miền Bắc/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f75d70b9-ff9a-41e6-983f-4fa8e26a29c9",
        "ParentId": "87661889-bbac-462e-8a1e-6bb74491511b",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/1.Miền Bắc/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c622f71-2d9d-48fa-8ed6-b33898bbed55",
        "ParentId": "87661889-bbac-462e-8a1e-6bb74491511b",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/1.Miền Bắc/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ee66c31-f4bf-477b-b450-8a2b011e0880",
        "ParentId": "87661889-bbac-462e-8a1e-6bb74491511b",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/1.Miền Bắc/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6cc767fb-00ec-40a7-9d6d-b3d95f62ffaa",
        "ParentId": "853e0c49-c785-4983-a180-578f7a681746",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e7aee43-6de9-41b3-8193-7ff9f9c4514f",
        "ParentId": "6cc767fb-00ec-40a7-9d6d-b3d95f62ffaa",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/2.Miền Trung/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c213ef33-fb59-4c51-9d30-7df12d16830a",
        "ParentId": "6cc767fb-00ec-40a7-9d6d-b3d95f62ffaa",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/2.Miền Trung/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bfab82da-c362-4d4e-b2cf-eb6f420ccc27",
        "ParentId": "6cc767fb-00ec-40a7-9d6d-b3d95f62ffaa",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/2.Miền Trung/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f265303f-46ec-45d5-89b7-57d2ee9524e6",
        "ParentId": "6cc767fb-00ec-40a7-9d6d-b3d95f62ffaa",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/2.Miền Trung/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8beb0a4f-81de-4cfb-bbd1-e2d47d7440b2",
        "ParentId": "853e0c49-c785-4983-a180-578f7a681746",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bfb9dab9-cc76-4bde-aed3-5722a3f2f826",
        "ParentId": "8beb0a4f-81de-4cfb-bbd1-e2d47d7440b2",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/3.Miền Nam/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9b7e75f-0aab-4511-ac44-1cac967e27f9",
        "ParentId": "8beb0a4f-81de-4cfb-bbd1-e2d47d7440b2",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/3.Miền Nam/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df52e7e1-b6f3-465a-9798-7bd2c9226fa8",
        "ParentId": "8beb0a4f-81de-4cfb-bbd1-e2d47d7440b2",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/3.Miền Nam/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99d37841-8d30-4660-8654-53cf14219985",
        "ParentId": "8beb0a4f-81de-4cfb-bbd1-e2d47d7440b2",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/1.Trạm 3G/3.Miền Nam/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "335b1518-6f2b-42a9-937f-ac28811b0d87",
        "ParentId": "fcb5c8f5-507a-4a55-b652-1578977e7e1e",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a11ea6f8-04e3-41cc-b933-b9da7738854a",
        "ParentId": "335b1518-6f2b-42a9-937f-ac28811b0d87",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Miền Trung/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e64489d4-14b4-402c-8721-b2af45f752fe",
        "ParentId": "335b1518-6f2b-42a9-937f-ac28811b0d87",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Miền Trung/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7043b9b0-db13-4a78-a478-87a83325f5b7",
        "ParentId": "335b1518-6f2b-42a9-937f-ac28811b0d87",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Miền Trung/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae49ff8f-9bcf-41e9-8ce2-42cd4b321438",
        "ParentId": "335b1518-6f2b-42a9-937f-ac28811b0d87",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Miền Trung/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e7dee1d-18e7-46b8-925d-dcaa2bedf794",
        "ParentId": "4896d5ef-82ca-40da-b0be-d009ea93f874",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "282e51cf-0c12-4b70-ac1a-cc348ac61400",
        "ParentId": "4e7dee1d-18e7-46b8-925d-dcaa2bedf794",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/1.Miền Bắc/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c86f5f8-92a5-4301-9c3a-00a183cd9799",
        "ParentId": "4e7dee1d-18e7-46b8-925d-dcaa2bedf794",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/1.Miền Bắc/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45816002-7bca-4ca0-b9cd-09638a44f65b",
        "ParentId": "4e7dee1d-18e7-46b8-925d-dcaa2bedf794",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/1.Miền Bắc/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4418bf0e-d1f3-4b8d-9b9b-4e4139681375",
        "ParentId": "4e7dee1d-18e7-46b8-925d-dcaa2bedf794",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/1.Miền Bắc/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dbf5873c-4a20-4b2a-ab44-79ea6eb17558",
        "ParentId": "4896d5ef-82ca-40da-b0be-d009ea93f874",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b59bf679-fa4d-42b4-a61a-ce56a76318dc",
        "ParentId": "dbf5873c-4a20-4b2a-ab44-79ea6eb17558",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/2.Miền Trung/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a79972b2-f448-470d-965d-766a11f06d46",
        "ParentId": "dbf5873c-4a20-4b2a-ab44-79ea6eb17558",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/2.Miền Trung/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f956e83-ea21-4b05-aae9-2bc1522db234",
        "ParentId": "dbf5873c-4a20-4b2a-ab44-79ea6eb17558",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/2.Miền Trung/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a56a1a40-9cf1-469d-a410-597761eff619",
        "ParentId": "dbf5873c-4a20-4b2a-ab44-79ea6eb17558",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/2.Miền Trung/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42d678f8-a8b7-440f-a397-0efe9d832fd9",
        "ParentId": "4896d5ef-82ca-40da-b0be-d009ea93f874",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f653c626-250f-43e3-b151-ca9f1a70d7b0",
        "ParentId": "42d678f8-a8b7-440f-a397-0efe9d832fd9",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/3.Miền Nam/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6cd6eda-13a1-4bb9-9385-d16cac8473e4",
        "ParentId": "42d678f8-a8b7-440f-a397-0efe9d832fd9",
        "Name": "2.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/3.Miền Nam/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54375d22-0d5d-4ccb-9000-b83cef582943",
        "ParentId": "42d678f8-a8b7-440f-a397-0efe9d832fd9",
        "Name": "3.Tuần",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/3.Miền Nam/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c671de1-3a76-4e71-889a-ee8ce20b0007",
        "ParentId": "42d678f8-a8b7-440f-a397-0efe9d832fd9",
        "Name": "4.Tháng",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/2.Trạm 4G/3.Miền Nam/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d380f02f-9cf3-453b-931a-ab10b4e90192",
        "ParentId": "ec53912f-75dd-48ca-9cea-d43e2e3d5cf8",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b148adee-e916-4972-b5d3-67ccc8e2e3c0",
        "ParentId": "d380f02f-9cf3-453b-931a-ab10b4e90192",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/1.Miền Bắc/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf79fdec-49af-4868-b5b2-108006e02672",
        "ParentId": "d380f02f-9cf3-453b-931a-ab10b4e90192",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/1.Miền Bắc/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "35088c71-289e-42a1-935c-d5f9832e91ff",
        "ParentId": "d380f02f-9cf3-453b-931a-ab10b4e90192",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/1.Miền Bắc/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47ed40b0-0052-4311-b5aa-8c9c1534115c",
        "ParentId": "d380f02f-9cf3-453b-931a-ab10b4e90192",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/1.Miền Bắc/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d76f0677-ca05-4e53-a197-3c3d6b0987e8",
        "ParentId": "ec53912f-75dd-48ca-9cea-d43e2e3d5cf8",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c0e7d68-c71d-462c-a523-6b393888a3ec",
        "ParentId": "d76f0677-ca05-4e53-a197-3c3d6b0987e8",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/2.Miền Trung/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9c0792f-3bda-4c66-9abe-44f2407eb128",
        "ParentId": "d76f0677-ca05-4e53-a197-3c3d6b0987e8",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/2.Miền Trung/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f16b72e-83f5-481f-a1a6-e0e6a81b9215",
        "ParentId": "d76f0677-ca05-4e53-a197-3c3d6b0987e8",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/2.Miền Trung/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a529075-4339-4d12-8789-e10057e3a05a",
        "ParentId": "d76f0677-ca05-4e53-a197-3c3d6b0987e8",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/2.Miền Trung/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b295fca-869f-41b5-a9d2-9322d25d293d",
        "ParentId": "ec53912f-75dd-48ca-9cea-d43e2e3d5cf8",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edf3f781-bf5b-4a3d-9c82-bd25b49e4e3a",
        "ParentId": "7b295fca-869f-41b5-a9d2-9322d25d293d",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/3.Miền Nam/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "335e5426-b5a9-443d-85d3-77b2889fb28c",
        "ParentId": "7b295fca-869f-41b5-a9d2-9322d25d293d",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/3.Miền Nam/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a5f6fac-8c1d-4a05-bd59-ecf4cdefeadd",
        "ParentId": "7b295fca-869f-41b5-a9d2-9322d25d293d",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/3.Miền Nam/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aabfb684-2b51-4233-84e3-c31cb0f3fda4",
        "ParentId": "7b295fca-869f-41b5-a9d2-9322d25d293d",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Các chặng khác/3.Miền Nam/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "497ee661-a830-4cee-b337-578b93299120",
        "ParentId": "fcb5c8f5-507a-4a55-b652-1578977e7e1e",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e2ffa45-0fc8-4776-83aa-45bda4128224",
        "ParentId": "497ee661-a830-4cee-b337-578b93299120",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Miền Nam/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "551a6a16-4a9c-4a1b-ae2e-3089fd1f159d",
        "ParentId": "497ee661-a830-4cee-b337-578b93299120",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Miền Nam/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae86488a-653f-41b0-8768-fba163fda189",
        "ParentId": "497ee661-a830-4cee-b337-578b93299120",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Miền Nam/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e15ce9dd-4368-490a-a527-4bdd518c2827",
        "ParentId": "497ee661-a830-4cee-b337-578b93299120",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/4.Toàn trình IP RAN/3.Miền Nam/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23eea355-f388-4eb0-8df9-475c5bd23da8",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "41.Toàn Trình IP CORE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/41.Toàn Trình IP CORE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b4d7b73-4ced-48ef-874b-33a2fb147a03",
        "ParentId": "23eea355-f388-4eb0-8df9-475c5bd23da8",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/41.Toàn Trình IP CORE/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ee05c5db-f0a5-4311-9a05-9b074c9d8916",
        "ParentId": "23eea355-f388-4eb0-8df9-475c5bd23da8",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/41.Toàn Trình IP CORE/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0069b98-a2bc-4ac9-bca6-3cb3ee8cb2bb",
        "ParentId": "23eea355-f388-4eb0-8df9-475c5bd23da8",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/41.Toàn Trình IP CORE/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ad3d5a3-b56d-4d53-82c5-9c897ba31166",
        "ParentId": "23eea355-f388-4eb0-8df9-475c5bd23da8",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/41.Toàn Trình IP CORE/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5cfae170-605f-43e7-a98b-efe82b6f11fe",
        "ParentId": "13f24b44-a57c-47f6-b6f2-00f95bdf11f5",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7a5bd89-fa59-410d-8df4-71719963484e",
        "ParentId": "5cfae170-605f-43e7-a98b-efe82b6f11fe",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/1.Miền Bắc/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce04a8eb-5d9f-4703-8100-1f3730639761",
        "ParentId": "5cfae170-605f-43e7-a98b-efe82b6f11fe",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/1.Miền Bắc/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ce8f133-97a2-49bb-af36-26f0a65c3cf0",
        "ParentId": "5cfae170-605f-43e7-a98b-efe82b6f11fe",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/1.Miền Bắc/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ccc5ce3-5611-41cf-bd4e-d09e8f1a4ad6",
        "ParentId": "5cfae170-605f-43e7-a98b-efe82b6f11fe",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/1.Miền Bắc/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93ca0103-2fc1-4c5b-8364-cf816965db43",
        "ParentId": "13f24b44-a57c-47f6-b6f2-00f95bdf11f5",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5cc0a3d6-3f84-468d-aea3-e137e4a76571",
        "ParentId": "93ca0103-2fc1-4c5b-8364-cf816965db43",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/2.Miền Trung/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2482cea-a506-4844-b085-b1d61b72bf82",
        "ParentId": "93ca0103-2fc1-4c5b-8364-cf816965db43",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/2.Miền Trung/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b08506d6-3b81-4998-a09a-5663d60fae05",
        "ParentId": "93ca0103-2fc1-4c5b-8364-cf816965db43",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/2.Miền Trung/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4765c5d-f302-4d01-9560-6fa519ccf73e",
        "ParentId": "93ca0103-2fc1-4c5b-8364-cf816965db43",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/2.Miền Trung/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8972574d-9513-4db0-b959-f4723424ed48",
        "ParentId": "13f24b44-a57c-47f6-b6f2-00f95bdf11f5",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1141ee5c-3df2-450e-9e88-ee6f2f21b244",
        "ParentId": "8972574d-9513-4db0-b959-f4723424ed48",
        "Name": "1.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/3.Miền Nam/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "025ad557-a856-4c7c-a5ec-943a131e6856",
        "ParentId": "8972574d-9513-4db0-b959-f4723424ed48",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/3.Miền Nam/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8f0588d-6662-464b-815e-39e6cd3b18ae",
        "ParentId": "8972574d-9513-4db0-b959-f4723424ed48",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/3.Miền Nam/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "19ad0db3-a6de-4e6c-8f9c-81764bd35233",
        "ParentId": "8972574d-9513-4db0-b959-f4723424ed48",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/5.Toàn trình  IP CORE/3.Miền Nam/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "6.Đo kiểm Truyền dẫn",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50e52e2b-2dc0-40e7-8996-4d1656180f72",
        "ParentId": "342ed7f0-bb84-41e5-b397-8e267c917e9c",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9d71b2d0-2fab-467c-b51e-197d4c32ecd9",
        "ParentId": "50e52e2b-2dc0-40e7-8996-4d1656180f72",
        "Name": "1.Ngày",
        "Description": "Dy_Internet_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45384e72-e627-4dff-8f47-d9c83f7b7ff6",
        "ParentId": "50e52e2b-2dc0-40e7-8996-4d1656180f72",
        "Name": "2.Tuần",
        "Description": "Wk_Internet_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64914142-33a5-4315-8aa1-64bfe948823e",
        "ParentId": "50e52e2b-2dc0-40e7-8996-4d1656180f72",
        "Name": "3.Tháng",
        "Description": "Mn_Internet_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "553591fe-87d3-4fb8-acb9-751c352d12b9",
        "ParentId": "342ed7f0-bb84-41e5-b397-8e267c917e9c",
        "Name": "2.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/2.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02a10546-0a3f-4058-b969-020ceca319aa",
        "ParentId": "553591fe-87d3-4fb8-acb9-751c352d12b9",
        "Name": "1.Ngày",
        "Description": "Dy_Internet_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/2.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2b8944ea-82af-4237-b801-e38dd6b58685",
        "ParentId": "553591fe-87d3-4fb8-acb9-751c352d12b9",
        "Name": "2.Tuần",
        "Description": "Wk_Internet_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/2.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d58233ca-5d39-4d85-9d63-6453c122d5ea",
        "ParentId": "553591fe-87d3-4fb8-acb9-751c352d12b9",
        "Name": "3.Tháng",
        "Description": "Mn_Internet_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/1.Truyền dẫn Internet/2.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e30cd712-18d7-4472-9efc-c175df52756e",
        "ParentId": "a8f93795-37ba-4b8f-a3a5-4c790c5f1a86",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/11.Truyền dẫn Internet/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "20dde56a-c920-4295-b828-46a3de7c03ef",
        "ParentId": "a8f93795-37ba-4b8f-a3a5-4c790c5f1a86",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/11.Truyền dẫn Internet/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e215b9ea-4c44-48ed-a099-17d0bd121ff7",
        "ParentId": "a8f93795-37ba-4b8f-a3a5-4c790c5f1a86",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/11.Truyền dẫn Internet/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e9261ee-490e-41d3-b215-980623df9746",
        "ParentId": "a8f93795-37ba-4b8f-a3a5-4c790c5f1a86",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/11.Truyền dẫn Internet/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0c19169-13b3-4a5f-8c8b-07b48a70f915",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83973ecc-b382-4170-acf7-9cccb365b35b",
        "ParentId": "e0c19169-13b3-4a5f-8c8b-07b48a70f915",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0021b70-4eca-4238-9e12-4d16b5643053",
        "ParentId": "e0c19169-13b3-4a5f-8c8b-07b48a70f915",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c6d2877-39c7-4422-83f3-8660b7907af8",
        "ParentId": "e0c19169-13b3-4a5f-8c8b-07b48a70f915",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5a518c3-0ce0-4f29-a9c6-2f94958244d1",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "2.Miền Bắc- Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/2.Miền Bắc- Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb976e88-d5c0-4656-b6ad-eebfea93675e",
        "ParentId": "d5a518c3-0ce0-4f29-a9c6-2f94958244d1",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MB-MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/2.Miền Bắc- Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "014fe155-3331-4860-a64a-a6a289eedbbc",
        "ParentId": "d5a518c3-0ce0-4f29-a9c6-2f94958244d1",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MB-MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/2.Miền Bắc- Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7e278bd-dc97-45c0-903e-27333e0fc994",
        "ParentId": "d5a518c3-0ce0-4f29-a9c6-2f94958244d1",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MB-MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/2.Miền Bắc- Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4f7574e-60f8-4a8b-935d-f99a1e97a13c",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "3.Miền Bắc- Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/3.Miền Bắc- Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe76f532-5f88-4e7a-a036-fc21c1d3e89b",
        "ParentId": "a4f7574e-60f8-4a8b-935d-f99a1e97a13c",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MB-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/3.Miền Bắc- Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5e06c79-3a29-4262-8cc6-f3ffece4a710",
        "ParentId": "a4f7574e-60f8-4a8b-935d-f99a1e97a13c",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MB-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/3.Miền Bắc- Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6715880-789f-428a-ad03-cac380c09e65",
        "ParentId": "a4f7574e-60f8-4a8b-935d-f99a1e97a13c",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MB-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/3.Miền Bắc- Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a81f7d0-527c-4881-bc2d-6d8ac4b6d43f",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "4.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/4.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3bd67cd2-e746-4c05-aff7-d6b782ac2ba1",
        "ParentId": "9a81f7d0-527c-4881-bc2d-6d8ac4b6d43f",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/4.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f698b1cf-91ba-4053-847b-3257bc39aa79",
        "ParentId": "9a81f7d0-527c-4881-bc2d-6d8ac4b6d43f",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/4.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d27c7ff-e71e-4e6c-af75-cb454ac82e72",
        "ParentId": "9a81f7d0-527c-4881-bc2d-6d8ac4b6d43f",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/4.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bc8a7e9-871c-410d-991e-5645bcabe6c5",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "5.Miền Nam-Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/5.Miền Nam-Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fc3b00eb-53cf-4918-ab19-0a6891aa3105",
        "ParentId": "6bc8a7e9-871c-410d-991e-5645bcabe6c5",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MN-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/5.Miền Nam-Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31ecfc2e-aeb8-4627-9bab-c100fda14a50",
        "ParentId": "6bc8a7e9-871c-410d-991e-5645bcabe6c5",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MN-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/5.Miền Nam-Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "576be3b3-50b7-4382-9977-2b0e2fcffd9a",
        "ParentId": "6bc8a7e9-871c-410d-991e-5645bcabe6c5",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MN-MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/5.Miền Nam-Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f5b44540-f2aa-4803-894d-dd7dd0c2605f",
        "ParentId": "59d9d2d8-0f95-4042-ab8e-cdc353d38e0b",
        "Name": "6.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/6.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "65cc3145-d136-4080-9007-47ba4642dda2",
        "ParentId": "f5b44540-f2aa-4803-894d-dd7dd0c2605f",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/6.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb690675-5aed-477d-8672-b01e786a99cc",
        "ParentId": "f5b44540-f2aa-4803-894d-dd7dd0c2605f",
        "Name": "2.Tuần",
        "Description": "Wk_IPBB_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/6.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d50167a9-ecfa-4894-88b7-e6c6bf4c23bf",
        "ParentId": "f5b44540-f2aa-4803-894d-dd7dd0c2605f",
        "Name": "3.Tháng",
        "Description": "Mn_IPBB_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/2.Truyền dẫn IPBB/6.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f0fb686-830e-4b69-93de-a837a7f31025",
        "ParentId": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "Name": "21.Truyền dẫn IPBB",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46f36aa1-9736-41a9-924a-0f7320d7d4b2",
        "ParentId": "8f0fb686-830e-4b69-93de-a837a7f31025",
        "Name": "0.DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB/0.DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6f79794-7109-46ca-8cf2-d28bae50a88f",
        "ParentId": "46f36aa1-9736-41a9-924a-0f7320d7d4b2",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB/0.DETAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aded201b-de9c-4672-976f-ea7e3f272586",
        "ParentId": "46f36aa1-9736-41a9-924a-0f7320d7d4b2",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB/0.DETAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a77fe7a-f32f-424d-b3c3-13926a9e39c1",
        "ParentId": "46f36aa1-9736-41a9-924a-0f7320d7d4b2",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB/0.DETAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d093acc-3d18-4f3e-b9aa-c91267bf6e19",
        "ParentId": "46f36aa1-9736-41a9-924a-0f7320d7d4b2",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/21.Truyền dẫn IPBB/0.DETAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84bdef00-5e06-4d1b-824b-f28af5d91c40",
        "ParentId": "c9112558-ba1f-4a41-9c9b-748abe27bdd6",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f15224c-e809-456e-84af-3b2470d93abf",
        "ParentId": "84bdef00-5e06-4d1b-824b-f28af5d91c40",
        "Name": "0.Giờ",
        "Description": "HR_LT_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/1.Miền Bắc/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c1f53a33-06c3-4098-92d4-d70a6e613420",
        "ParentId": "84bdef00-5e06-4d1b-824b-f28af5d91c40",
        "Name": "1.Ngày",
        "Description": "DY_LT_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce194078-5788-454d-944f-3ee57283f623",
        "ParentId": "84bdef00-5e06-4d1b-824b-f28af5d91c40",
        "Name": "2.Tuần",
        "Description": "WK_LT_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "328afaf2-bab2-4d44-8524-4478650c3729",
        "ParentId": "84bdef00-5e06-4d1b-824b-f28af5d91c40",
        "Name": "3.Tháng",
        "Description": "MN_LT_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "67cdc01c-aed0-4e78-bd72-8c63ac4d887e",
        "ParentId": "c9112558-ba1f-4a41-9c9b-748abe27bdd6",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6df53995-cbcf-46da-bded-5f43614eadf9",
        "ParentId": "67cdc01c-aed0-4e78-bd72-8c63ac4d887e",
        "Name": "0.Giờ",
        "Description": "HR_LT_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/2.Miền Trung/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34d16e22-0811-42c6-93f1-2a6a55157522",
        "ParentId": "67cdc01c-aed0-4e78-bd72-8c63ac4d887e",
        "Name": "1.Ngày",
        "Description": "DY_LT_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/2.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c14f3cd-b5e2-41eb-b8e6-eb1aa9d00f45",
        "ParentId": "67cdc01c-aed0-4e78-bd72-8c63ac4d887e",
        "Name": "2.Tuần",
        "Description": "WK_LT_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/2.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ec7ca366-c360-4399-b22a-efbb40358d73",
        "ParentId": "67cdc01c-aed0-4e78-bd72-8c63ac4d887e",
        "Name": "3.Tháng",
        "Description": "MN_LT_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn liên tỉnh/2.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0e9cf9a-6cfe-497b-95ef-3f071ceac398",
        "ParentId": "97d5807d-14a0-481f-b377-5aef6933f017",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "053ea8d9-7cd9-4e00-8774-bcda163c619b",
        "ParentId": "d0e9cf9a-6cfe-497b-95ef-3f071ceac398",
        "Name": "1.Ngày",
        "Description": "Dy_Thuê liên tỉnh_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1b4e0c2-8969-49d1-ab1e-4174836f76e5",
        "ParentId": "d0e9cf9a-6cfe-497b-95ef-3f071ceac398",
        "Name": "2.Tuần",
        "Description": "Wk_Thuê liên tỉnh_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "888f8f01-efdf-443f-8d58-bd486b16a287",
        "ParentId": "d0e9cf9a-6cfe-497b-95ef-3f071ceac398",
        "Name": "3.Tháng",
        "Description": "Mn_Thuê liên tỉnh_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b11f0025-c4f4-44ef-80e1-392a829b753e",
        "ParentId": "97d5807d-14a0-481f-b377-5aef6933f017",
        "Name": "2.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/2.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "06cfe40a-6055-4d69-9fbe-189678b9b5f8",
        "ParentId": "b11f0025-c4f4-44ef-80e1-392a829b753e",
        "Name": "1.Ngày",
        "Description": "Dy_Thuê liên tỉnh_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/2.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2036c4c8-1f6f-4a48-ac2c-1d4e7a463800",
        "ParentId": "b11f0025-c4f4-44ef-80e1-392a829b753e",
        "Name": "2.Tuần",
        "Description": "Wk_Thuê liên tỉnh_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/2.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1156b500-69ec-47e1-af0b-eac1094a2670",
        "ParentId": "b11f0025-c4f4-44ef-80e1-392a829b753e",
        "Name": "3.Tháng",
        "Description": "Mn_Thuê liên tỉnh_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/2.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5bd8d22a-88e4-4701-8961-492173354704",
        "ParentId": "97d5807d-14a0-481f-b377-5aef6933f017",
        "Name": "3.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/3.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7f92017-9b7c-4de5-84fe-41059edaed5a",
        "ParentId": "5bd8d22a-88e4-4701-8961-492173354704",
        "Name": "1.Ngày",
        "Description": "Dy_Thuê liên tỉnh_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/3.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26497e08-6640-43ca-b94c-93a6e1a47fb8",
        "ParentId": "5bd8d22a-88e4-4701-8961-492173354704",
        "Name": "2.Tuần",
        "Description": "Wk_Thuê liên tỉnh_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/3.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bae7de8e-5671-4b4b-93e7-3918bebfbb1f",
        "ParentId": "5bd8d22a-88e4-4701-8961-492173354704",
        "Name": "3.Tháng",
        "Description": "Mn_Thuê liên tỉnh_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/3.Truyền dẫn thuê liên tỉnh/3.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a6b7353-0ed8-4aed-8992-cc32bba585ee",
        "ParentId": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "Name": "31.Truyền dẫn liên tỉnh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33bb9a41-afde-4560-83ad-c2328abc3a3e",
        "ParentId": "9a6b7353-0ed8-4aed-8992-cc32bba585ee",
        "Name": "0.DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh/0.DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22621858-9868-4169-ad5d-e5e4a04abe68",
        "ParentId": "33bb9a41-afde-4560-83ad-c2328abc3a3e",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh/0.DETAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed101206-373c-4eb0-add3-fad6e0acf080",
        "ParentId": "33bb9a41-afde-4560-83ad-c2328abc3a3e",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh/0.DETAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "52d87b26-c04a-4bb9-93f1-e9c7c4485573",
        "ParentId": "33bb9a41-afde-4560-83ad-c2328abc3a3e",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh/0.DETAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ddc3c1bc-ce1b-4da5-8301-0d69c85355af",
        "ParentId": "33bb9a41-afde-4560-83ad-c2328abc3a3e",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/31.Truyền dẫn liên tỉnh/0.DETAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "171fdbea-cbac-4212-bf01-f45813106703",
        "ParentId": "e4e7dc56-49ae-4dfa-9ddf-ee809eb8f451",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6a0dd1d-356c-4f92-9deb-b3752e324657",
        "ParentId": "171fdbea-cbac-4212-bf01-f45813106703",
        "Name": "1.Ngày",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0d588f03-d58a-4768-8806-6f2ad7b09111",
        "ParentId": "171fdbea-cbac-4212-bf01-f45813106703",
        "Name": "2.Tuần",
        "Description": "Wk_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d95f811f-44bc-4ab8-8532-1effbb9242ea",
        "ParentId": "171fdbea-cbac-4212-bf01-f45813106703",
        "Name": "3.Tháng",
        "Description": "Mn_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0c6ba16a-5434-4dde-8b2b-1323cb1222af",
        "ParentId": "e4e7dc56-49ae-4dfa-9ddf-ee809eb8f451",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4d919235-6668-474e-950b-62dafd0f1917",
        "ParentId": "0c6ba16a-5434-4dde-8b2b-1323cb1222af",
        "Name": "1.Ngày",
        "Description": "Dy_Metro_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/2.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77e0e031-4565-436e-af98-451bc6f28345",
        "ParentId": "0c6ba16a-5434-4dde-8b2b-1323cb1222af",
        "Name": "2.Tuần",
        "Description": "Wk_Metro_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/2.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aca2e761-1478-4c3a-9a83-91638c2e0d7b",
        "ParentId": "0c6ba16a-5434-4dde-8b2b-1323cb1222af",
        "Name": "3.Tháng",
        "Description": "Mn_Metro_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/2.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41146185-2593-4b3c-a6fd-a07c354d7ad3",
        "ParentId": "e4e7dc56-49ae-4dfa-9ddf-ee809eb8f451",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37c350a5-fad7-4475-83d3-1201f4516f34",
        "ParentId": "41146185-2593-4b3c-a6fd-a07c354d7ad3",
        "Name": "1.Ngày",
        "Description": "Dy_Metro_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/3.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9f32ed89-e0e4-4159-8a2d-e66523946333",
        "ParentId": "41146185-2593-4b3c-a6fd-a07c354d7ad3",
        "Name": "2.Tuần",
        "Description": "Wk_Metro_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/3.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "000dd866-6309-4632-a63c-15cad21731de",
        "ParentId": "41146185-2593-4b3c-a6fd-a07c354d7ad3",
        "Name": "3.Tháng",
        "Description": "Mn_Metro_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/4.Truyền dẫn Metro/3.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dfd1340b-4675-4fc0-9474-8ad0c38f3559",
        "ParentId": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "Name": "41.Truyền dẫn Metro",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d941d1c-8ccf-48aa-844a-fcd643785213",
        "ParentId": "dfd1340b-4675-4fc0-9474-8ad0c38f3559",
        "Name": "0.DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/0.DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e45068f9-2b21-4e8b-a953-30b14d518543",
        "ParentId": "8d941d1c-8ccf-48aa-844a-fcd643785213",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/0.DETAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a4ccb45-085e-4f5f-b0ea-b838b59cbf23",
        "ParentId": "8d941d1c-8ccf-48aa-844a-fcd643785213",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/0.DETAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68c91c30-afbf-4f84-9aec-99266c5ec65c",
        "ParentId": "8d941d1c-8ccf-48aa-844a-fcd643785213",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/0.DETAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f4fae7a6-fc46-4d4c-aa1c-ce5adab26d6c",
        "ParentId": "8d941d1c-8ccf-48aa-844a-fcd643785213",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/0.DETAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "ParentId": "dfd1340b-4675-4fc0-9474-8ad0c38f3559",
        "Name": "1.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fdb456ec-bdcb-4554-a35e-dc8088ebc3f5",
        "ParentId": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6aadb430-6007-4d57-b9bc-150c83a97033",
        "ParentId": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a37df855-ee9b-4644-8417-9222951db5d2",
        "ParentId": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d019f6b3-a441-444a-9e8a-bd344a78378e",
        "ParentId": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30f821ea-315c-40cc-b2f2-8cc7f9014c84",
        "ParentId": "ca3f89ed-62cf-4abb-b263-2c99d6f07441",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/1.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "ParentId": "dfd1340b-4675-4fc0-9474-8ad0c38f3559",
        "Name": "2.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8cfbd643-8aa3-4876-b86e-96721b9e69a7",
        "ParentId": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f13cd42-e4dd-4f40-9b01-c25e93102f2c",
        "ParentId": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ddf16c96-6996-4456-ad40-cac89f2655ad",
        "ParentId": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7eff3e55-c6d7-4691-834c-03a77249ca96",
        "ParentId": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc87f1f9-80ca-4e5c-a0ee-2a68be258ec5",
        "ParentId": "86421cb7-7be6-4f0b-badb-aa52e7cd31b7",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/2.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c207c584-d002-458e-b983-40a1a4ae2840",
        "ParentId": "dfd1340b-4675-4fc0-9474-8ad0c38f3559",
        "Name": "3.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c9be5f9-7803-47c8-89dc-f76425cb5319",
        "ParentId": "c207c584-d002-458e-b983-40a1a4ae2840",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0d8832c-f2f5-4fff-8921-16b473dfdebf",
        "ParentId": "c207c584-d002-458e-b983-40a1a4ae2840",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da798d87-171f-47ff-ade5-1e0ebd1bad79",
        "ParentId": "c207c584-d002-458e-b983-40a1a4ae2840",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46ef03cd-7b4e-4d80-a87c-161ce7d4262b",
        "ParentId": "c207c584-d002-458e-b983-40a1a4ae2840",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f3259a29-af01-4a1c-a2eb-4b672e9dca6d",
        "ParentId": "c207c584-d002-458e-b983-40a1a4ae2840",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/41.Truyền dẫn Metro/3.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13bc757d-03d5-403a-97e5-83d97437e7bf",
        "ParentId": "51d0cc6f-095c-4d0e-a816-ac80b11bb910",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/51.Truyền dẫn TDMIP/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70929b91-44f7-49f2-9c25-235d410d38ce",
        "ParentId": "51d0cc6f-095c-4d0e-a816-ac80b11bb910",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/51.Truyền dẫn TDMIP/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4399ca47-75f8-4988-a929-21c9014153b4",
        "ParentId": "51d0cc6f-095c-4d0e-a816-ac80b11bb910",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/51.Truyền dẫn TDMIP/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44ec66fb-d093-4b0a-8c07-fdc26f5d4cf2",
        "ParentId": "51d0cc6f-095c-4d0e-a816-ac80b11bb910",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/51.Truyền dẫn TDMIP/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "ParentId": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "Name": "6.Truyền dẫn RAN 3G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8193659-dd65-4061-bcf0-d3ba4d711e91",
        "ParentId": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "Name": "0.DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/0.DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8dd545f8-99fa-4de0-a725-c6c4fff31e9a",
        "ParentId": "c8193659-dd65-4061-bcf0-d3ba4d711e91",
        "Name": "0.Giờ",
        "Description": "DY_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/0.DETAIL/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a75f511-c65b-4eec-b40d-0c43d730a714",
        "ParentId": "c8193659-dd65-4061-bcf0-d3ba4d711e91",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/0.DETAIL/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6df27206-f331-4a2b-9d13-1cf62bce97cd",
        "ParentId": "c8193659-dd65-4061-bcf0-d3ba4d711e91",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/0.DETAIL/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0fb8493-2971-497d-9977-0390badf6355",
        "ParentId": "c8193659-dd65-4061-bcf0-d3ba4d711e91",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran3G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/0.DETAIL/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "ParentId": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "Name": "1.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a5a275e-9c17-43e0-a753-30cc1fa2d88e",
        "ParentId": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94764613-17b9-4b05-b627-d91ea020786c",
        "ParentId": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d72cfe12-1ca5-4371-abed-a1a20bdc0799",
        "ParentId": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ed6d16a-137d-47c2-bca3-e1fbade091ec",
        "ParentId": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a45a85e1-1c49-461f-acf6-7dc98bb51db5",
        "ParentId": "818ffb40-bd34-464f-a90c-f089ddc354d9",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fbe73ab9-85d3-43b2-86e4-a1ba757fa87a",
        "ParentId": "95ff0ec6-e4fe-4289-9a3a-51e5a59ac21f",
        "Name": "0.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.Miền Bắc/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45c81d88-865a-4033-a2ff-be7c3613fba2",
        "ParentId": "95ff0ec6-e4fe-4289-9a3a-51e5a59ac21f",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c55b5a1-855c-4313-a208-e25767c1c7c3",
        "ParentId": "95ff0ec6-e4fe-4289-9a3a-51e5a59ac21f",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "088ecad3-cf10-4b4c-8374-35d9104a28b7",
        "ParentId": "95ff0ec6-e4fe-4289-9a3a-51e5a59ac21f",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran3G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9087bb57-7b16-46db-b707-839283954278",
        "ParentId": "1d941780-5760-478b-87ff-d64c4a21dc5c",
        "Name": "0.Giờ",
        "Description": "DY_TruyenDan_Ran3G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.Miền Trung/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e71b012-75e9-40c9-a9fc-7e55b7dd7a79",
        "ParentId": "1d941780-5760-478b-87ff-d64c4a21dc5c",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran3G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac9c3830-385f-4295-84e7-4d975525cc76",
        "ParentId": "1d941780-5760-478b-87ff-d64c4a21dc5c",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran3G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93a3c04c-76c5-4a74-a373-1541d143f842",
        "ParentId": "1d941780-5760-478b-87ff-d64c4a21dc5c",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran3G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "ParentId": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "Name": "2.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "380b3acd-16cf-42a3-9ca4-bebf6dfde4f4",
        "ParentId": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dcb6299a-cff0-46cc-94d4-f9063061eeb4",
        "ParentId": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a019718e-d7bf-4a57-b746-68e0ce90e897",
        "ParentId": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff3f1cf1-4144-454f-9c1c-3c5d4d0a4669",
        "ParentId": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d0316ae-02e2-43de-965c-5057ca508e1e",
        "ParentId": "898b9e9f-f9b6-4376-9c18-d956e386a6fc",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/2.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d325f6b3-24a0-49ff-811f-bc527f8ee6d2",
        "ParentId": "7d5a573c-cddf-4223-a092-339f804791b4",
        "Name": "0.Giờ",
        "Description": "DY_TruyenDan_Ran3G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.Miền Nam/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b0ecb89-29e8-4340-9198-1303d38c50ee",
        "ParentId": "7d5a573c-cddf-4223-a092-339f804791b4",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran3G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "225f12b7-9e36-4eb0-ba07-0b4ee7ac81a7",
        "ParentId": "7d5a573c-cddf-4223-a092-339f804791b4",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran3G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eaeac883-bf39-4f23-bdf6-509062ff0280",
        "ParentId": "7d5a573c-cddf-4223-a092-339f804791b4",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran3G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "ParentId": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "Name": "3.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "35120507-eef3-42e5-ac75-323205fd44de",
        "ParentId": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ecf81bef-2cc2-48f9-912a-f4da8344ddd3",
        "ParentId": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "332f51f2-934e-4bf1-9d76-3efd8a77ef5d",
        "ParentId": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcbc1221-c9a7-42b6-b53e-ff617fac727d",
        "ParentId": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b14850e-194b-4116-b15b-985b87edbbb7",
        "ParentId": "7da386e3-2780-45c6-ba06-6d1020b3082d",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/3.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "ParentId": "c5324b13-f44c-4075-810a-1d9f973dc9a6",
        "Name": "4.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d907dacf-5c3c-4238-87f3-e3ee7b3107e7",
        "ParentId": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1785894-3048-4d29-9900-885f252e5b24",
        "ParentId": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c1dbe321-b4c8-402c-ab9c-efaa87108d8a",
        "ParentId": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25df890e-4deb-4170-bf41-74941dcbe10e",
        "ParentId": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "30e926c9-6e6a-4a63-8ff1-3133a221cf69",
        "ParentId": "d0e7bb42-27a8-40bd-8a11-b32cf08d0611",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 3G/4.DISTRICT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1af4f1bb-a29f-4a58-a86b-1a3c350d39d8",
        "ParentId": "f385137a-1f94-4c7a-b9e5-eebf658413aa",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e805ef6-4afc-4dff-9fa2-065cf9691928",
        "ParentId": "1af4f1bb-a29f-4a58-a86b-1a3c350d39d8",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran4G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fc102877-6413-4ad2-a2cc-8ce7df0351e9",
        "ParentId": "1af4f1bb-a29f-4a58-a86b-1a3c350d39d8",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c3194e6f-63bf-4c11-8168-9a3b0d0cb01e",
        "ParentId": "1af4f1bb-a29f-4a58-a86b-1a3c350d39d8",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0ce9aae-ecbb-48b3-9fef-fdff89a6e226",
        "ParentId": "51505460-10cf-4612-bc55-6340ce80e584",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81709d49-7a3f-4178-8e2b-7ac6ac2d7928",
        "ParentId": "51505460-10cf-4612-bc55-6340ce80e584",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2da97b3a-c4d7-4f10-8c70-989a0c78e079",
        "ParentId": "51505460-10cf-4612-bc55-6340ce80e584",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a03b577-6f9b-4efa-94da-8d0cec64f2c9",
        "ParentId": "f385137a-1f94-4c7a-b9e5-eebf658413aa",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9c551bf-2db1-49c7-9cff-86262fb1d87a",
        "ParentId": "0a03b577-6f9b-4efa-94da-8d0cec64f2c9",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f49fd0eb-bca8-4a41-822e-cb9570a49143",
        "ParentId": "0a03b577-6f9b-4efa-94da-8d0cec64f2c9",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa9b091d-8724-41ab-aabb-60f3699eb61c",
        "ParentId": "0a03b577-6f9b-4efa-94da-8d0cec64f2c9",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/2.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0b3e28ce-0a32-477c-bff9-3cb9859b3964",
        "ParentId": "b1cf19a2-0451-424e-a385-43964112f641",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "efa9b764-dacd-4d53-a6a8-bb40e91fbcf9",
        "ParentId": "b1cf19a2-0451-424e-a385-43964112f641",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0476302-ac31-4350-877a-6d6426c0f5d8",
        "ParentId": "b1cf19a2-0451-424e-a385-43964112f641",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d7f60a1-1e66-4977-bf04-2618cd5d5889",
        "ParentId": "6002ea1d-7380-4f3b-8d36-55513548401c",
        "Name": "1.Ngày",
        "Description": "DY_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bdc3f1f4-e2c0-4e5e-b8d9-9479df8dc44e",
        "ParentId": "6002ea1d-7380-4f3b-8d36-55513548401c",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9537338-24fa-4566-95ca-1c78008e5337",
        "ParentId": "6002ea1d-7380-4f3b-8d36-55513548401c",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/6.Truyền dẫn RAN 4G/3.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a76fc74-f5f3-4cba-89dc-04941ca5ae38",
        "ParentId": "2c4c10c4-4144-4257-8648-b3997e2a1a2f",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84063500-419e-4d0c-9844-e2bfb57d0891",
        "ParentId": "9a76fc74-f5f3-4cba-89dc-04941ca5ae38",
        "Name": "0.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Bắc/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3d606fe-e1e1-4e51-95f9-5dc397471cd3",
        "ParentId": "9a76fc74-f5f3-4cba-89dc-04941ca5ae38",
        "Name": "1.Ngày",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "154fb982-82f7-40a9-9fba-db53b63bcb04",
        "ParentId": "9a76fc74-f5f3-4cba-89dc-04941ca5ae38",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d1b74eb-a742-43bd-86e5-a4f56f930dfb",
        "ParentId": "9a76fc74-f5f3-4cba-89dc-04941ca5ae38",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e62bd92-fcd7-4b0f-94b3-5fd27b40695d",
        "ParentId": "2c4c10c4-4144-4257-8648-b3997e2a1a2f",
        "Name": "1.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c4c923f3-db41-4982-909e-885418ffc8a5",
        "ParentId": "1e62bd92-fcd7-4b0f-94b3-5fd27b40695d",
        "Name": "0.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Trung/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "496db2af-8c9a-4eb9-846c-178e51f6d3d2",
        "ParentId": "1e62bd92-fcd7-4b0f-94b3-5fd27b40695d",
        "Name": "1.Ngày",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fde5b683-86a0-4438-b63b-f2e0738d6f27",
        "ParentId": "1e62bd92-fcd7-4b0f-94b3-5fd27b40695d",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b551b2a-a607-4145-8d58-aa0021b444ec",
        "ParentId": "1e62bd92-fcd7-4b0f-94b3-5fd27b40695d",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/1.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "feb13bd9-63f3-4117-bf60-3c7afab225e4",
        "ParentId": "2c4c10c4-4144-4257-8648-b3997e2a1a2f",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce6ad07a-6ca7-423f-9be7-921bbaf44cb4",
        "ParentId": "feb13bd9-63f3-4117-bf60-3c7afab225e4",
        "Name": "0.Giờ",
        "Description": "Dy_Metro_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/3.Miền Nam/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4e7b485a-6088-49d9-9a50-b8f82d00724b",
        "ParentId": "feb13bd9-63f3-4117-bf60-3c7afab225e4",
        "Name": "1.Ngày",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/3.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "048a8ca6-69be-4dda-b8fd-c93d6a8385ea",
        "ParentId": "feb13bd9-63f3-4117-bf60-3c7afab225e4",
        "Name": "2.Tuần",
        "Description": "WK_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/3.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab3cc31b-48c0-4406-8645-3c91799e01e5",
        "ParentId": "feb13bd9-63f3-4117-bf60-3c7afab225e4",
        "Name": "3.Tháng",
        "Description": "MN_TruyenDan_Ran4G_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/62.Truyền dẫn RAN 4G (MBFG)/3.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "ParentId": "9eb946a9-2600-4381-b27a-5786c2ba5104",
        "Name": "7.Truyền dẫn RAN 4G",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "69060f1e-f9e1-4e30-8d39-26523afad444",
        "ParentId": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "Name": "0.DETAIL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/0.DETAIL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18a9fc72-995c-4421-af44-6dce6e900f9f",
        "ParentId": "69060f1e-f9e1-4e30-8d39-26523afad444",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/0.DETAIL/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "339356ca-58a9-4363-8be1-3a76fcbbaff3",
        "ParentId": "69060f1e-f9e1-4e30-8d39-26523afad444",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/0.DETAIL/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c82a51f2-8775-409d-8f49-99ecc91d944d",
        "ParentId": "69060f1e-f9e1-4e30-8d39-26523afad444",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/0.DETAIL/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "887ccd60-a8d5-420b-b31f-dccc63636748",
        "ParentId": "69060f1e-f9e1-4e30-8d39-26523afad444",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/0.DETAIL/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "ParentId": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "Name": "1.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9e76476-af95-4b9d-9189-1b838c8741ad",
        "ParentId": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d631b195-959b-4a08-9270-d5e152734ecc",
        "ParentId": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8eb6e1c-3684-4c70-8f2f-82442669882a",
        "ParentId": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3aa12dd0-fff8-4001-8d26-be54383966ad",
        "ParentId": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "106365a2-7333-410f-a594-a55970ed6d29",
        "ParentId": "799e2050-94c3-4c8b-a3ea-3f497587c8ee",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/1.DVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "ParentId": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "Name": "2.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f85782e4-d07d-41de-917c-efbffa2b82ed",
        "ParentId": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df3cd00f-ab08-42d1-bd93-961b872eb008",
        "ParentId": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15f84550-a1bd-4cdd-ab71-260f8094e2b4",
        "ParentId": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61f72662-8ab4-47bf-aef4-f91f95922403",
        "ParentId": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68d2184b-aaa3-42c3-8752-2452a20b9de8",
        "ParentId": "e615ecf8-5ad2-4f07-8583-7ce3ee1c2fb6",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/2.TVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "ParentId": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "Name": "3.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f282c46-e080-43ad-9830-7966eecd265a",
        "ParentId": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3e500c74-1cfa-4577-944f-cf42dc327f95",
        "ParentId": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d9d80126-06c8-466c-9851-60039347b503",
        "ParentId": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "81e91189-b65f-493a-982c-65c4c2eec2c0",
        "ParentId": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7c35195-62cb-41c4-92fe-328fe39bf7a9",
        "ParentId": "9cec6575-cfb0-44f4-9155-9a27d7cd8cc9",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/3.PROVINCE/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0a86ce59-803f-4574-96e9-80b038290b96",
        "ParentId": "bf56a126-bbb3-4eb8-a0bf-bded793c0619",
        "Name": "4.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c734e7d1-a0fa-4df4-b557-ac1bd9f75ce5",
        "ParentId": "0a86ce59-803f-4574-96e9-80b038290b96",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08804161-f71b-4dae-86c5-0bcc03b7d062",
        "ParentId": "0a86ce59-803f-4574-96e9-80b038290b96",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4459d47-91ca-4800-b2d3-583778de93c2",
        "ParentId": "0a86ce59-803f-4574-96e9-80b038290b96",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "476e541f-d2bb-4cfe-b56c-a462f09e1325",
        "ParentId": "0a86ce59-803f-4574-96e9-80b038290b96",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "578e9909-28ef-48a5-a46c-617f778b10fa",
        "ParentId": "0a86ce59-803f-4574-96e9-80b038290b96",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn RAN 4G/4.DISTRICT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c848380-d1c7-4e27-8988-9c246172770f",
        "ParentId": "3abb9858-b8c4-497b-a0a9-04d77a6e39e5",
        "Name": "1.Miền Bắc",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/1.Miền Bắc",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5201b260-c4f8-4650-a3bf-910554d0ed5b",
        "ParentId": "1c848380-d1c7-4e27-8988-9c246172770f",
        "Name": "0.Giờ",
        "Description": "HR_TDMIP_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/1.Miền Bắc/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c238fb7c-2c88-43a6-9ffa-c53ee124abbb",
        "ParentId": "1c848380-d1c7-4e27-8988-9c246172770f",
        "Name": "1.Ngày",
        "Description": "Dy_TDMIP_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/1.Miền Bắc/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8db3806c-48ec-4a71-a98a-4e85675c93a9",
        "ParentId": "1c848380-d1c7-4e27-8988-9c246172770f",
        "Name": "2.Tuần",
        "Description": "Wk_TDMIP_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/1.Miền Bắc/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76fff00a-4082-4373-89eb-2e54427596bb",
        "ParentId": "1c848380-d1c7-4e27-8988-9c246172770f",
        "Name": "3.Tháng",
        "Description": "Mn_TDMIP_MB",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/1.Miền Bắc/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c07a688d-0320-4c1e-838d-190af556170c",
        "ParentId": "3abb9858-b8c4-497b-a0a9-04d77a6e39e5",
        "Name": "2.Miền Trung",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/2.Miền Trung",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "901c13dd-b2c1-4f0a-83f9-eb5657ca0613",
        "ParentId": "c07a688d-0320-4c1e-838d-190af556170c",
        "Name": "0.Giờ",
        "Description": "HR_TDMIP_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/2.Miền Trung/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33f681db-574f-4448-814c-50ad7603727f",
        "ParentId": "c07a688d-0320-4c1e-838d-190af556170c",
        "Name": "1.Ngày",
        "Description": "Dy_TDMIP_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/2.Miền Trung/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75f8a1b5-af75-4808-850f-b8b74ac94036",
        "ParentId": "c07a688d-0320-4c1e-838d-190af556170c",
        "Name": "2.Tuần",
        "Description": "Wk_TDMIP_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/2.Miền Trung/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42a0d42e-2876-4bc9-9e50-686c3636de06",
        "ParentId": "c07a688d-0320-4c1e-838d-190af556170c",
        "Name": "3.Tháng",
        "Description": "Mn_TDMIP_MT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/2.Miền Trung/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4942967-535c-4e7e-910a-90366c053fc6",
        "ParentId": "3abb9858-b8c4-497b-a0a9-04d77a6e39e5",
        "Name": "3.Miền Nam",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/3.Miền Nam",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b37f47f-ac60-4d2c-86fe-b2f00458cedb",
        "ParentId": "b4942967-535c-4e7e-910a-90366c053fc6",
        "Name": "0.Giờ",
        "Description": "HR_TDMIP_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/3.Miền Nam/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abe86d0e-f7a9-46de-bc14-3da5f377aace",
        "ParentId": "b4942967-535c-4e7e-910a-90366c053fc6",
        "Name": "1.Ngày",
        "Description": "Dy_TDMIP_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/3.Miền Nam/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcb67246-6348-4d63-b03c-f455f365d990",
        "ParentId": "b4942967-535c-4e7e-910a-90366c053fc6",
        "Name": "2.Tuần",
        "Description": "Wk_TDMIP_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/3.Miền Nam/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cad42902-db10-486e-a86a-8002dcb6a655",
        "ParentId": "b4942967-535c-4e7e-910a-90366c053fc6",
        "Name": "3.Tháng",
        "Description": "Mn_TDMIP_MN",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/6.Đo kiểm Truyền dẫn/7.Truyền dẫn TDMIP/3.Miền Nam/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "ParentId": "e577d475-c043-4ffe-8442-20f48b8f041b",
        "Name": "7.Tải thiết bị",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c1d81d9d-ea04-4327-bf49-106dd95e47a1",
        "ParentId": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6a8329a-91d6-4ab8-86da-24d04a0aaf1e",
        "ParentId": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e2c0ca66-2cf6-478f-9174-f0f6e0c650a8",
        "ParentId": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2f76279b-1cb3-4282-b4c1-904a07feb8ff",
        "ParentId": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "Name": "4.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4f7245ad-911d-4310-925b-91c4228ebbd6",
        "ParentId": "b67b8e5a-be28-407c-b01a-a891a8d8b20e",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/7.Tải thiết bị/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5c49e24-a553-4798-ba8b-461eaa037059",
        "ParentId": "48307140-d6d8-4bf9-9ff4-bdf070da7253",
        "Name": "2.IPBB Link SLA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/2.IPBB Link SLA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a10ea7d2-8db8-47ec-b2ea-9e5236fffcf2",
        "ParentId": "c5c49e24-a553-4798-ba8b-461eaa037059",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_SLA",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/2.IPBB Link SLA/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b33663d4-00b0-4200-abe2-f547a5c21c61",
        "ParentId": "48307140-d6d8-4bf9-9ff4-bdf070da7253",
        "Name": "3.IPBB Link Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/3.IPBB Link Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf1e60ec-4274-4c7a-bfec-7693800bfe36",
        "ParentId": "b33663d4-00b0-4200-abe2-f547a5c21c61",
        "Name": "1.Ngày",
        "Description": "Dy_IPBB_Traffic",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/3.IPBB Link Traffic/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6002aa4f-3d62-443e-8dea-557e60f68046",
        "ParentId": "48307140-d6d8-4bf9-9ff4-bdf070da7253",
        "Name": "4.SLA Data Service",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/4.SLA Data Service",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8304971d-3a58-4634-85ab-b3f4bb7e9035",
        "ParentId": "6002aa4f-3d62-443e-8dea-557e60f68046",
        "Name": "1.Ngày",
        "Description": "Dy_SLADataService",
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/4.SLA Data Service/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95aa4c05-4f24-4a1b-8d3f-2c5d40615771",
        "ParentId": "6002aa4f-3d62-443e-8dea-557e60f68046",
        "Name": "SLA_Web_Service",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/9.Truyền Dẫn/PRTG/4.SLA Data Service/SLA_Web_Service",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "90.FTTx",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6b2f5bf-2548-48ae-866c-472c6437dcf3",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "1.Mức miền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/1.Mức miền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09b51e53-5f6e-4d65-8734-c0276dbfa706",
        "ParentId": "f6b2f5bf-2548-48ae-866c-472c6437dcf3",
        "Name": "1.Ngày",
        "Description": "DY_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/1.Mức miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "48f03257-ace8-4901-afac-2789be18e6e7",
        "ParentId": "f6b2f5bf-2548-48ae-866c-472c6437dcf3",
        "Name": "2.Tuần",
        "Description": "WK_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/1.Mức miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05cade0c-6133-4a61-9ba4-9f280f383c68",
        "ParentId": "f6b2f5bf-2548-48ae-866c-472c6437dcf3",
        "Name": "3.Tháng",
        "Description": "MN_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/1.Mức miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7e5a24ea-e82e-4e84-94e4-479965889e02",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "21.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/21.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "96e0b03d-3a69-426b-afa3-75cf8179719c",
        "ParentId": "7e5a24ea-e82e-4e84-94e4-479965889e02",
        "Name": "1.Ngày",
        "Description": "DY_FTTx_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/21.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cec9ad63-a8d1-45a4-9ac0-6feff88912ea",
        "ParentId": "7e5a24ea-e82e-4e84-94e4-479965889e02",
        "Name": "2.Tuần",
        "Description": "WK_FTTx_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/21.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "757d4e49-1c8e-4ec4-a8cf-9ff10903025b",
        "ParentId": "7e5a24ea-e82e-4e84-94e4-479965889e02",
        "Name": "3.Tháng",
        "Description": "MN_FTTx_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/21.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7523a228-efde-47e3-ae95-2f05a2951398",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "22.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/22.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d20e2d76-28f5-45b4-9e76-c9eea15a7a9c",
        "ParentId": "7523a228-efde-47e3-ae95-2f05a2951398",
        "Name": "1.Ngày",
        "Description": "DY_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/22.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f94bee31-251d-4910-854a-ea3998c0f753",
        "ParentId": "7523a228-efde-47e3-ae95-2f05a2951398",
        "Name": "2.Tuần",
        "Description": "DY_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/22.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d236e0c1-415e-4816-9ee0-32892965d4d1",
        "ParentId": "7523a228-efde-47e3-ae95-2f05a2951398",
        "Name": "3.Tháng",
        "Description": "DY_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/22.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a19a81bc-caab-4e6b-8fdd-f350b246ca79",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "3.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/3.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9031e8b6-ccc7-4eed-b86e-96973f205147",
        "ParentId": "a19a81bc-caab-4e6b-8fdd-f350b246ca79",
        "Name": "1.Ngày",
        "Description": "DY_FTTx_PROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/3.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99c9eab7-c36b-4af5-aa3a-811725360813",
        "ParentId": "a19a81bc-caab-4e6b-8fdd-f350b246ca79",
        "Name": "2.Tuần",
        "Description": "WK_FTTx_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/3.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcc9d37c-3de4-4438-9f7d-bc959c70b475",
        "ParentId": "a19a81bc-caab-4e6b-8fdd-f350b246ca79",
        "Name": "3.Tháng",
        "Description": "MN_FTTx_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/3.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce332477-94ca-4a0c-917e-e34d6c55cdb7",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "4.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/4.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ca0aa1a-c8c7-46c1-a289-4eade39f53b0",
        "ParentId": "ce332477-94ca-4a0c-917e-e34d6c55cdb7",
        "Name": "1.Ngày",
        "Description": "DY_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9c6ac7db-646a-49bc-b874-634de84b53e9",
        "ParentId": "ce332477-94ca-4a0c-917e-e34d6c55cdb7",
        "Name": "2.Tuần",
        "Description": "WK_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "56d2623a-e518-42e9-878d-5c843f530c67",
        "ParentId": "ce332477-94ca-4a0c-917e-e34d6c55cdb7",
        "Name": "3.Tháng",
        "Description": "MN_FTTx_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "4.ONU",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/4.ONU",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7318fb41-d1c2-4016-aabc-97bafc9234fc",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "1.Giờ",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "503fdbc3-9e9a-49b8-9190-d1a44ebf5f27",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "2.Ngày",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb0e19ea-eeed-4f6c-bc6c-9dbfda74323f",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "3.Tuần",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45044a29-1279-4131-8bce-ab67c5380f6c",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "4.Tháng",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d5af7486-ef5a-4c87-b7d5-98c04fc6f464",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "5.Quý",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f940beb-7c70-48bb-b7b6-6d482db5d23f",
        "ParentId": "6a37bf54-9c2b-4fb2-9559-0e4d6460e671",
        "Name": "6.Năm",
        "Description": "MN_GGSNGeneral",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/4.ONU/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "871e19a3-5685-4870-a956-9b4dc29d55fa",
        "ParentId": "a9274a1c-40d8-4be0-8f3b-18d8e3061644",
        "Name": "5.Thuê bao",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.FTTx/5.Thuê bao",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "82b5db8c-768b-4a3d-9f0e-b2baa38431ff",
        "ParentId": "871e19a3-5685-4870-a956-9b4dc29d55fa",
        "Name": "1.Ngày",
        "Description": "CT_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/5.Thuê bao/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25a3f279-d977-4ef3-a9f8-6c357a17c469",
        "ParentId": "871e19a3-5685-4870-a956-9b4dc29d55fa",
        "Name": "2.Tuần",
        "Description": "CT_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/5.Thuê bao/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44438ca0-1338-475c-a63f-d9f15e915c0b",
        "ParentId": "871e19a3-5685-4870-a956-9b4dc29d55fa",
        "Name": "3.Tháng",
        "Description": "CT_FTTx",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.FTTx/5.Thuê bao/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    // {
    //     "Id": "9379d710-24a5-48ac-9607-95486c327385c",
    //     "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
    //     "Name": "CSHT",
    //     "Description": null,
    //     "TypeName": "Folder",
    //     "Path": "/noc-sopirs/90.SERVICES",
    //     "Level": 1,
    //     "Checked": false,
    //     "ParentCount": 0,
    //     "IsParent": true,
    //     "Children": null,
    //     "ReportGroups": null
    // },
   
    {
        "Id": "9379d710-24a5-48ac-9607-95486c67685c",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "90.SERVICES",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "407d71e4-2695-486f-a2df-efa1874f0afc",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "0.CSDLTram",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/0.CSDLTram",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "42019c9b-8eeb-449e-892d-067867749b9c",
        "ParentId": "407d71e4-2695-486f-a2df-efa1874f0afc",
        "Name": "CSDLTram",
        "Description": "CSDL_tram",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/0.CSDLTram/CSDLTram",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "1.Thuê bao VLR",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "57fe000d-859e-44c6-9ac4-a37e72fb6527",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "0.CELLS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/0.CELLS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d59f3896-df54-4fbf-b212-73a61ccfa81b",
        "ParentId": "57fe000d-859e-44c6-9ac4-a37e72fb6527",
        "Name": "1.Ngày",
        "Description": "DY_VLR_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/0.CELLS/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "36ca90a6-4283-49cd-8cf6-9d581fb6a6a7",
        "ParentId": "57fe000d-859e-44c6-9ac4-a37e72fb6527",
        "Name": "2.Tuần",
        "Description": "WK_VLR_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/0.CELLS/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8027ba6-2f23-4e95-bc56-7a2bc7abb24c",
        "ParentId": "57fe000d-859e-44c6-9ac4-a37e72fb6527",
        "Name": "3.Tháng",
        "Description": "MN_VLR_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/0.CELLS/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "879be8b0-12a7-4108-904b-018ecaed503f",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "1.SITES",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/1.SITES",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af20c517-d8b8-42ce-b3bb-0df5ce55f4ce",
        "ParentId": "879be8b0-12a7-4108-904b-018ecaed503f",
        "Name": "1.Ngày",
        "Description": "DY_VLR_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/1.SITES/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28eae220-a09f-42a5-ac30-dc3a0cebfec4",
        "ParentId": "879be8b0-12a7-4108-904b-018ecaed503f",
        "Name": "2.Tuần",
        "Description": "WK_VLR_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/1.SITES/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29efe6cd-1004-4ee6-bef4-931dd781f773",
        "ParentId": "879be8b0-12a7-4108-904b-018ecaed503f",
        "Name": "3.Tháng",
        "Description": "MN_VLR_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/1.SITES/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc62f484-f52e-4db2-b338-34d3627ccd6f",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "2.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/2.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54c0f11e-40a8-4523-9552-25a6a4aa27d4",
        "ParentId": "bc62f484-f52e-4db2-b338-34d3627ccd6f",
        "Name": "1.Ngày",
        "Description": "DY_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/2.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "84191820-bc9f-47d2-8ba1-662102229bf0",
        "ParentId": "bc62f484-f52e-4db2-b338-34d3627ccd6f",
        "Name": "2.Tuần",
        "Description": "WK_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/2.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d58e3ece-15ce-4dd5-9108-f44cf5409971",
        "ParentId": "bc62f484-f52e-4db2-b338-34d3627ccd6f",
        "Name": "3.Tháng",
        "Description": "MN_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/2.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "236db659-b741-4c4b-bb4e-779bd00bf03f",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "3.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/3.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af5ff96f-3e5c-4a7a-96e7-d3231cbfedf5",
        "ParentId": "236db659-b741-4c4b-bb4e-779bd00bf03f",
        "Name": "1.Ngày",
        "Description": "DY_VLR_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/3.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "df20585a-69cd-4641-ba90-8aca1ec76393",
        "ParentId": "236db659-b741-4c4b-bb4e-779bd00bf03f",
        "Name": "2.Tuần",
        "Description": "WK_VLR_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/3.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "10f86092-61cd-467f-8feb-0902b864b288",
        "ParentId": "236db659-b741-4c4b-bb4e-779bd00bf03f",
        "Name": "3.Tháng",
        "Description": "MN_VLR_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/3.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d71694d0-e7ef-4787-b43d-e15cffee4250",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "4.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/4.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "53a0e2ca-c993-4e91-a4e7-a32228207274",
        "ParentId": "d71694d0-e7ef-4787-b43d-e15cffee4250",
        "Name": "1.Ngày",
        "Description": "DY_VLR_branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/4.Branch/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8293962-e4d9-4a79-93c7-9883025f464e",
        "ParentId": "d71694d0-e7ef-4787-b43d-e15cffee4250",
        "Name": "2.Tuần",
        "Description": "WK_VLR_branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/4.Branch/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7d9c9f2-c13f-4fa6-b5e6-e8d3ee014803",
        "ParentId": "d71694d0-e7ef-4787-b43d-e15cffee4250",
        "Name": "3.Tháng",
        "Description": "MN_VLR_branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/4.Branch/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "807ec747-942a-4826-85fe-1c2411b7d1a9",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "5.CTYDV KV",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/5.CTYDV KV",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3c1ae5dc-2b7e-4f60-95f6-f43147e55ba8",
        "ParentId": "807ec747-942a-4826-85fe-1c2411b7d1a9",
        "Name": "1.Ngày",
        "Description": "DY_VLR_ctydvkv",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/5.CTYDV KV/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7f264d2-3ab3-4c8f-b8a3-01e6adc61b88",
        "ParentId": "807ec747-942a-4826-85fe-1c2411b7d1a9",
        "Name": "2.Tuần",
        "Description": "WK_VLR_ctydvkv",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/5.CTYDV KV/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b8317a0-a850-4618-b26c-5e56f1bd1c7a",
        "ParentId": "807ec747-942a-4826-85fe-1c2411b7d1a9",
        "Name": "3.Tháng",
        "Description": "MN_VLR_ctydvkv",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/5.CTYDV KV/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0577956-e1dd-41c0-a727-f605066e0efb",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "6.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/6.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16f3842f-73bf-485e-b936-367ea71aae5d",
        "ParentId": "e0577956-e1dd-41c0-a727-f605066e0efb",
        "Name": "1.Ngày",
        "Description": "DY_VLR_region",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/6.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca2fc276-bc84-4650-8148-1949461348db",
        "ParentId": "e0577956-e1dd-41c0-a727-f605066e0efb",
        "Name": "2.Tuần",
        "Description": "WK_VLR_region",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/6.TTML/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1e7d25d5-754f-4cf0-9bd1-7fa56bcf8cd2",
        "ParentId": "e0577956-e1dd-41c0-a727-f605066e0efb",
        "Name": "3.Tháng",
        "Description": "MN_VLR_region",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/6.TTML/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03c461b6-6976-4faa-ba55-df0b6a4cf7e4",
        "ParentId": "670643b7-9f55-43cf-b56e-234e38bbddc1",
        "Name": "7.CELLS NO MAPPING",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/7.CELLS NO MAPPING",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de5d8817-9ede-415d-965d-b614c095e419",
        "ParentId": "03c461b6-6976-4faa-ba55-df0b6a4cf7e4",
        "Name": "Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/1.Thuê bao VLR/7.CELLS NO MAPPING/Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "286e5271-70c7-4e21-8526-41960977577f",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "3.KQI MCA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c816bfcb-2a17-43fd-86f1-b78e82a16c1c",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "1.Thuê Bao MissCall",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/1.Thuê Bao MissCall",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cc8f9e19-6f95-42e7-ab92-ebe7c88f8f06",
        "ParentId": "c816bfcb-2a17-43fd-86f1-b78e82a16c1c",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Sub",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/1.Thuê Bao MissCall/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "901db15d-e156-4508-b172-3fc6636f4030",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "2.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/2.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bec52056-f2ec-48d8-a125-2eef1fa05b9c",
        "ParentId": "901db15d-e156-4508-b172-3fc6636f4030",
        "Name": "0.Ngày",
        "Description": "DY_MCA_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/2.Cell/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f88c4f7d-6bb4-4aa8-a0b3-73d236fcdb27",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "3.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/3.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6c4e2aaf-a691-42b3-9ba4-cbb2500a28ea",
        "ParentId": "f88c4f7d-6bb4-4aa8-a0b3-73d236fcdb27",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/3.Site/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0223eee5-414a-4d42-ab74-d721dac6a367",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "4.Tổ Viễn Thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/4.Tổ Viễn Thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ca45850-806c-447b-966e-8a9c86c2ed6b",
        "ParentId": "0223eee5-414a-4d42-ab74-d721dac6a367",
        "Name": "0.Ngày",
        "Description": "DY_MCA_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/4.Tổ Viễn Thông/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b56472b-edc9-443b-976f-9f08ff9a158a",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "5.Đài Viễn Thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/5.Đài Viễn Thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "511a5063-60fe-4342-a229-b18e42fe1c7c",
        "ParentId": "1b56472b-edc9-443b-976f-9f08ff9a158a",
        "Name": "0.Ngày",
        "Description": "DY_MCA_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/5.Đài Viễn Thông/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8b3b46c-67d9-4954-bb94-e671bd955b32",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "6.Tỉnh Thành Phố",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/6.Tỉnh Thành Phố",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dc29e268-002f-4b56-8f66-2cf643ada3e3",
        "ParentId": "c8b3b46c-67d9-4954-bb94-e671bd955b32",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/6.Tỉnh Thành Phố/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "893aca63-bb1b-4f1e-8a49-5ba7a85c8bfc",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "7.Quận Huyện",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/7.Quận Huyện",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae50cd83-a113-4269-94cb-98bbb9284d74",
        "ParentId": "893aca63-bb1b-4f1e-8a49-5ba7a85c8bfc",
        "Name": "0.Ngày",
        "Description": "DY_MCA_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/7.Quận Huyện/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46ba59db-8d40-403a-bb61-bc8285d2ed90",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "8.Chi Nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/8.Chi Nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d537c725-5953-4664-8c7a-0c72769acbca",
        "ParentId": "46ba59db-8d40-403a-bb61-bc8285d2ed90",
        "Name": "0.Ngày",
        "Description": "DY_MCA_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/8.Chi Nhánh/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22e304ee-977a-4e6a-97ba-4382a7db0487",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "9.Công ty kinh doanh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/9.Công ty kinh doanh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "effcf161-6169-4ba2-8842-bf0697fd1b03",
        "ParentId": "22e304ee-977a-4e6a-97ba-4382a7db0487",
        "Name": "0.Ngày",
        "Description": "DY_MCA_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/9.Công ty kinh doanh/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a2f6b34e-a9fb-46fd-bdb7-4a07775c8717",
        "ParentId": "0948334d-7291-4355-8197-ee3193b385c5",
        "Name": "TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7dc063d-814d-4d2f-a8e8-e824965b7deb",
        "ParentId": "a2f6b34e-a9fb-46fd-bdb7-4a07775c8717",
        "Name": "0.Ngày",
        "Description": "DY_MCA_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA NEW/TTML/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e4c9222-538c-407e-ae95-43e6d1441494",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "1.Thuê Bao MissCall",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/1.Thuê Bao MissCall",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d098615a-8bbd-4011-9df8-ccc0040c3054",
        "ParentId": "6e4c9222-538c-407e-ae95-43e6d1441494",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Sub",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/1.Thuê Bao MissCall/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7d0666a6-03f7-4194-96c9-2a7cebd5498b",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "2.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/2.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31ff1180-43d9-4cf4-970e-6199ca713303",
        "ParentId": "7d0666a6-03f7-4194-96c9-2a7cebd5498b",
        "Name": "0.Ngày",
        "Description": "DY_MCA_CELL",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/2.Cell/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c399c3ee-daf3-4770-972a-6c6527d8cdca",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "3.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/3.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "765b0be5-2024-46cc-9015-8ceee41a25dd",
        "ParentId": "c399c3ee-daf3-4770-972a-6c6527d8cdca",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/3.Site/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "27626430-872d-4f61-b2cd-0709a3b27bb3",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "4.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/4.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17973223-61d7-447b-b17a-eef83f4eb4e1",
        "ParentId": "27626430-872d-4f61-b2cd-0709a3b27bb3",
        "Name": "0.Ngày",
        "Description": "DY_MCA_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/4.TVT/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "108a2b86-707b-4d41-95f5-e97717296f9e",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "5.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/5.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bdc280d4-6cb4-4a26-b097-0812d1ebf770",
        "ParentId": "108a2b86-707b-4d41-95f5-e97717296f9e",
        "Name": "0.Ngày",
        "Description": "DY_MCA_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/5.DVT/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d2e56e19-729d-4356-bb27-2aa114d1daf9",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "6.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/6.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5d2b538-5bf1-430c-90f7-ca9ab45cd29c",
        "ParentId": "d2e56e19-729d-4356-bb27-2aa114d1daf9",
        "Name": "0.Ngày",
        "Description": "DY_MCA_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/6.Province/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd81e7a1-edce-45e3-9b6f-d96b79f6132a",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "7.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/7.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edbc0bff-a169-4e64-87e7-648cdfcfe3d5",
        "ParentId": "cd81e7a1-edce-45e3-9b6f-d96b79f6132a",
        "Name": "0.Ngày",
        "Description": "DY_MCA_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/7.District/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "131aaf60-2cd6-4993-9bc7-161456abe9f6",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "8.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/8.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7a7e378-36e5-41d9-8abd-5c5867cd2ce0",
        "ParentId": "131aaf60-2cd6-4993-9bc7-161456abe9f6",
        "Name": "0.Ngày",
        "Description": "DY_MCA_BRANCH",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/8.Branch/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d89db6c-c826-4bff-8174-328c70a63c35",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "9.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/9.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "de6fd279-6a60-4c78-b2a5-2411dc6b1dae",
        "ParentId": "3d89db6c-c826-4bff-8174-328c70a63c35",
        "Name": "0.Ngày",
        "Description": "DY_MCA_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/9.CTKD/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb9917b3-f120-4387-9085-93d80fab3ce0",
        "ParentId": "286e5271-70c7-4e21-8526-41960977577f",
        "Name": "TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37d9260a-a079-444a-a57a-0dceeb5d19be",
        "ParentId": "fb9917b3-f120-4387-9085-93d80fab3ce0",
        "Name": "0.Ngày",
        "Description": "DY_MCA_TTML",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI MCA/TTML/0.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "31e7cd22-a0b2-4d89-847c-2a538348a04c",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "3.KQI Thoại",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3dc3ca5-2e14-4058-ae5b-e37b37c569f1",
        "ParentId": "4fb58b04-5401-47a4-b7ed-27eae680b588",
        "Name": "1.Tỉ lệ thiết lập",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03244eea-4be2-4c37-815e-7c61d489505e",
        "ParentId": "d3dc3ca5-2e14-4058-ae5b-e37b37c569f1",
        "Name": "1.Thiết bị",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94de6821-d276-4eb5-ae09-df91fa9b50f8",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "0.Giờ",
        "Description": "HR_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9507d0c4-487f-4435-8353-92e776f86ac7",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "1.Ngày",
        "Description": "DY_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6aa4579-ba46-48a7-929b-b70841fc9614",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "2.Tuần",
        "Description": "WK_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "09368cba-834d-4091-aa3b-fc0d920a93cd",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "3.Tháng",
        "Description": "MN_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39d12633-a384-47b6-9c4b-d558f6ff7c12",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "4.Quý",
        "Description": "QR_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "550698d0-4592-4358-a94d-7d39909ae0ce",
        "ParentId": "03244eea-4be2-4c37-815e-7c61d489505e",
        "Name": "5.Năm",
        "Description": "YR_settingrate_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/1.Thiết bị/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "ParentId": "d3dc3ca5-2e14-4058-ae5b-e37b37c569f1",
        "Name": "2.Trunk Group",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f8ed017-a944-4b0a-aa33-622655dc247d",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "0.Giờ",
        "Description": "HR_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffd191a7-8cd4-4509-b92b-5e24def268c4",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "1.Ngày",
        "Description": "DY_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b0b547e-914b-4b94-bac9-72197dc395c2",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "2.Tuần",
        "Description": "WK_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "422b7ced-07a4-4829-89d9-854dbaa940dd",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "3.Tháng",
        "Description": "MN_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c80818a6-d622-4a06-958b-bbdd8f56c2af",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "4.Quý",
        "Description": "QR_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fdfa6d3a-c69d-4440-a93c-32eb29a123f3",
        "ParentId": "78ca2e36-00b3-440f-bf66-9822da75059a",
        "Name": "5.Năm",
        "Description": "YR_settingrate_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/1.Tỉ lệ thiết lập/2.Trunk Group/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3dd93af8-a2ad-42d9-9ca7-28c2c3f45e19",
        "ParentId": "4fb58b04-5401-47a4-b7ed-27eae680b588",
        "Name": "2.Thời gian thiết lập",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "ParentId": "3dd93af8-a2ad-42d9-9ca7-28c2c3f45e19",
        "Name": "1.Thiết bị",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29a1528d-08c8-4ce2-87bf-184bd41092d0",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "0.Giờ",
        "Description": "HR.timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a79c0c7c-3c99-4d8d-b7c5-7c5be1fcfa55",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "1.Ngày",
        "Description": "DY.timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b4371382-62d9-40c2-95f7-413171e08729",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "2.Tuần",
        "Description": "WK.timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54da723b-887a-4bbb-b532-9e5501cce1f2",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "3.Tháng",
        "Description": "MN_timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da501e03-b08e-44e3-b89d-c2338c5fc0ae",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "4.Quý",
        "Description": "QR_timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c1449a9-750c-48a2-b455-6e3d84be8a3d",
        "ParentId": "abac4211-4dc6-414b-a6c6-568c2f958f9e",
        "Name": "5.Năm",
        "Description": "YR_timeset_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/1.Thiết bị/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2ade9087-bced-4e54-8838-0069246acd02",
        "ParentId": "3dd93af8-a2ad-42d9-9ca7-28c2c3f45e19",
        "Name": "2.Trunk Group",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28c62468-5558-4354-851c-4bf4f203070d",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "0.Giờ",
        "Description": "HR_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68c97f8d-c925-4ff9-bdc9-3e5751dc1b7d",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "1.Ngày",
        "Description": "DY_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ef60623-3cee-4b8a-9d25-e52e8f1b7a9d",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "2.Tuần",
        "Description": "WK_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b032fff5-0fb0-4a97-9d77-6c9b8f4795f1",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "3.Tháng",
        "Description": "MN_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44a3faa5-3551-442e-9837-a3b63ec6e038",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "4.Quý",
        "Description": "QR_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89c06e08-3b3e-46c1-979a-d803c8964539",
        "ParentId": "2ade9087-bced-4e54-8838-0069246acd02",
        "Name": "5.Năm",
        "Description": "YR_timeset_trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/2.Thời gian thiết lập/2.Trunk Group/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dd030af8-f136-46f5-8a42-a98395025ea7",
        "ParentId": "4fb58b04-5401-47a4-b7ed-27eae680b588",
        "Name": "3.Tỷ lệ rớt",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f4747e98-355d-4347-aca9-5423a596bf79",
        "ParentId": "dd030af8-f136-46f5-8a42-a98395025ea7",
        "Name": "1.Thiết bị",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32ed8098-5b52-4735-a2fe-14a67f5d5b22",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "0.Giờ",
        "Description": "HR_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0806d38-bec1-48c8-b0fa-5f716334c359",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "1.Ngày",
        "Description": "DY_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "54e537ba-9db8-4c8c-ba6c-c928a8ade81e",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "2.Tuần",
        "Description": "WK_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "85e32a32-93b1-4009-ab03-28e0b8872457",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "3.Tháng",
        "Description": "MN_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d284f2c3-a0bf-421c-b908-0feb31a1685d",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "4.Quý",
        "Description": "QR_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cba8c561-7fa6-45e7-87d7-892de2dcd0cc",
        "ParentId": "f4747e98-355d-4347-aca9-5423a596bf79",
        "Name": "5.Năm",
        "Description": "YR_missing_DV",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/1.Thiết bị/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "ParentId": "dd030af8-f136-46f5-8a42-a98395025ea7",
        "Name": "2.Trunk Group",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0521fc27-de8d-4d2d-b84b-6699eca51405",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "0.Giờ",
        "Description": "HR_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5eaf855d-2b13-4a46-b4c1-63fb25238828",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "1.Ngày",
        "Description": "DY_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8651c7d1-f63a-45be-ada3-39a19a7dfda1",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "2.Tuần",
        "Description": "DY_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1e50a65-2bb8-4fa6-8fd5-bfd6992bed33",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "3.Tháng",
        "Description": "DY_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e29e07d8-f278-4347-9306-8e34c85f3107",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "4.Quý",
        "Description": "DY_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b3bc1db-0fc3-45ad-964c-06abf46f9b2f",
        "ParentId": "d265ad72-7e6b-4e69-b56c-31db1e95969b",
        "Name": "5.Năm",
        "Description": "DY_missing_Trunk",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/3.Tỷ lệ rớt/2.Trunk Group/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bc0baf58-a0a3-4852-9d09-a82ee9c180c0",
        "ParentId": "4fb58b04-5401-47a4-b7ed-27eae680b588",
        "Name": "4.Tỷ lệ đàm thoại",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "ParentId": "bc0baf58-a0a3-4852-9d09-a82ee9c180c0",
        "Name": "1.Thiết bị",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59885f93-9e91-4012-916c-00a7e56c6f13",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "0.Giờ",
        "Description": "HR_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3d4c2459-89c5-4ad2-b8c3-498bc9cbb09d",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b369db7c-1b9b-450f-8855-15a51d1eb881",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74bda418-2145-4bff-8870-83f242ab3467",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "daecb327-e30a-4e38-a990-707325076a0b",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "754bb687-2583-4ad1-95d9-f336fee99c87",
        "ParentId": "28ee427d-6256-4b48-a95a-66b73f9088a5",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_thietbi",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/1.Thiết bị/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "ParentId": "bc0baf58-a0a3-4852-9d09-a82ee9c180c0",
        "Name": "2.Trunk Group",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa38fe15-8936-43ff-b5b7-04fb9ccf56e4",
        "ParentId": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_trunk_group",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ccead147-3e13-465d-96d7-d3949b15013f",
        "ParentId": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_trunk_group",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d1c9621-0fd5-4df3-b502-f13d91e4ade3",
        "ParentId": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_trunk_group",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e383dd2f-3833-44b3-9bc2-6eb17391066e",
        "ParentId": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_trunk_group",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f23416ce-e600-442a-9e10-3d31399c9783",
        "ParentId": "c8993fd9-6916-441d-bd4a-dcb89a563f6d",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_trunk_group",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/1.Quốc tế/4.Tỷ lệ đàm thoại/2.Trunk Group/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "ParentId": "31e7cd22-a0b2-4d89-847c-2a538348a04c",
        "Name": "2.Trong nước",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ea7f13c-5614-44a6-b39d-0af33ce8038c",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "0.Thuê bao",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/0.Thuê bao",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e8fb0aea-2e5f-4a66-a45f-31f1c491da13",
        "ParentId": "9ea7f13c-5614-44a6-b39d-0af33ce8038c",
        "Name": "1.Ngày",
        "Description": "DY_KQI Thoai_Thue bao",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/0.Thuê bao/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a679073-0f18-4be5-a4f6-4a22e2739a57",
        "ParentId": "9ea7f13c-5614-44a6-b39d-0af33ce8038c",
        "Name": "2.Call FAIL_DROP",
        "Description": "Call FAIL/DROP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/0.Thuê bao/2.Call FAIL_DROP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1164af15-4d72-4331-b419-b4e8549c915d",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "65547dbe-7f85-45eb-b227-b0f8286d1963",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "059764c4-a8df-41e7-ac4f-b60b184cf447",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c6eb9e5b-1bdd-45f5-bf69-beb76c7a277e",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "866caed5-f147-49c5-8123-67b26b900279",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8deffe4b-fa4f-418d-9596-2a2a78cfbf07",
        "ParentId": "af751c8b-1759-46a8-a4fe-9259dc7af221",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Cell/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7556f68-3244-439c-baad-d0e536407ca2",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e96a31d-6397-4e75-8882-6d4a5809d44d",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "0.Giờ",
        "Description": "HR_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b274e13e-4132-4684-a6e1-8a3fd0bb1ac2",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1f8d6535-ce7e-4812-8d86-6b435bf4c86a",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "829bd4b8-f99c-47f4-91a2-acc4b10484d4",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9569c715-c100-4e97-bd22-29ed8b5bff21",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26666f38-5fcb-4efc-93ad-d914e20ff128",
        "ParentId": "e7556f68-3244-439c-baad-d0e536407ca2",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/1.Cell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "2.SITES",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c77b2ddf-7f2a-4ef8-bd8b-97c8efd7f592",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "0.Giờ",
        "Description": "HR_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "41599a6a-3e04-48ba-a230-62a21ec2acc1",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34854001-09ea-4a8e-a828-fdb3b40e6027",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "621cf5e7-edbf-47f6-a9f2-f52ef561542c",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15840b42-6030-4c8e-a3ad-df3246ddcfca",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8e21c722-5d85-44eb-b54e-05e429475f3a",
        "ParentId": "18a5472b-e076-43a9-9995-28e28093c1e0",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_thietbi_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/2.SITES/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "3.BSC-RNC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7f664d5-06aa-44e0-bf41-a51f66f03b02",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "0.Giờ",
        "Description": "HR_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "880bc400-b2c8-466d-beab-64b616309e56",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "256e2799-12f6-4f6d-9c1d-f3440ea58ec8",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "205d743c-9a1d-46fd-b15f-2f3439faa8f5",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a05ce9b5-3002-43cd-bdd3-8bf76544a3eb",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43699d00-8f98-4134-9d67-d8b3e30be3de",
        "ParentId": "bf20a5c8-e4e4-4890-9399-2016b2a9c6d3",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/3.BSC-RNC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e4944654-7470-459b-a310-4f262d13de73",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "4.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c3043c0-ef78-4d54-ae07-c5f2c6325a69",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "0.Giờ",
        "Description": "HR_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eda5e66d-3e3a-46b6-aefa-bf9a5b097b1a",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68dc88a5-cf28-454b-8efb-9be9384b8536",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00934ef2-7770-40dc-aabd-d1cd479c6f47",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "3.Tháng",
        "Description": "WK_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9b42e478-2eb1-4be3-963b-de1ed06f3c34",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c32047d-ae75-487d-9dea-9c765caf0361",
        "ParentId": "e4944654-7470-459b-a310-4f262d13de73",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/4.MSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3151e91-c275-4376-bf33-610d8acda3e3",
        "ParentId": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0686b743-98c9-4920-b825-cf20ff63ab06",
        "ParentId": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a7640f9-5f79-4090-ad0a-b7857327448e",
        "ParentId": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0ef282c5-5c44-4b1e-99b6-1cca1e87f7e0",
        "ParentId": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "92887b87-8947-4dea-9fc9-53a47356f32f",
        "ParentId": "9a8e9783-4fad-46df-aca5-1488f96fe7d7",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "6.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c87036d-6e5b-4288-a593-336f4bbd55c2",
        "ParentId": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8f695a5d-6d17-4a59-85ac-958e947d2281",
        "ParentId": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50b698f5-dd64-4c57-9ad0-daac5dbee24a",
        "ParentId": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33e154f1-e886-47e6-b971-fa489fd348f8",
        "ParentId": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90cc3358-bbcc-46f9-acc9-ca5511830a06",
        "ParentId": "f61084f1-00db-4f50-8d7a-6b4772ab3b8b",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/6.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "7.Chi nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6012d70-4620-492d-ad2f-8a3bf667accd",
        "ParentId": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab9ffa49-e036-4d6a-bb0a-7e7b994b50f4",
        "ParentId": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91495dc1-12e3-4139-aa88-1d35a9bac3e2",
        "ParentId": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7254caf7-9807-4f65-ab7a-d54ec04d2f32",
        "ParentId": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c945c11d-16d1-4b47-8c72-b64b778fb4c9",
        "ParentId": "1a587a9d-5a93-4a5b-a138-41739eb7040d",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/7.Chi nhánh/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a3edcaa-a0c5-4507-8b6a-4a29062eb927",
        "ParentId": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "697c4c27-155e-435c-bf2e-d30ceed78059",
        "ParentId": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5822e021-387c-4ab8-9108-e928b78dfae3",
        "ParentId": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f4b296e0-dea8-4367-9627-dbb694fb05cc",
        "ParentId": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e1d435c5-20a7-4434-ba90-5cc2ececf1f2",
        "ParentId": "aee947ba-c980-46ca-9c7f-5665cdc95378",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "8.ToVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "253cec86-7d80-493a-8678-e89f564c4798",
        "ParentId": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "47881da7-dd9e-468e-af7c-23b6276166cd",
        "ParentId": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13f266e1-7651-4656-bc84-ec0477dcc204",
        "ParentId": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21d2cf03-7436-45e4-bab3-223fbe4ec738",
        "ParentId": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74d999d9-b878-416f-bfc2-e2d2790ffe68",
        "ParentId": "1b0cb34f-108d-4c46-9bcf-10b427a44a5a",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/8.ToVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "81.DaiVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c52740c0-2c6b-4029-9702-64c7e9afd5f1",
        "ParentId": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e272083c-5da2-4b52-964b-0342ab2accfd",
        "ParentId": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e987c9ad-9bbb-4b90-8fde-41421de6b931",
        "ParentId": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f088243-e7d0-4269-87cf-3d9ec3299032",
        "ParentId": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "Name": "4.Quý",
        "Description": "QR_CDR_MSC_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d8e19319-140b-40c1-abb3-8c4de5704e4a",
        "ParentId": "1d187d05-9d87-4da8-bb53-e98b73a768f4",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/81.DaiVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "ParentId": "8e7eba3c-a801-471c-8ad0-280fcc89fbc7",
        "Name": "9.Miền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e434fee4-9ce6-4c32-a3c6-bd1f108b2408",
        "ParentId": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "Name": "1.Ngày",
        "Description": "DY_CDR_MSC_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90244fae-158a-4670-a379-8387f56721a9",
        "ParentId": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "Name": "2.Tuần",
        "Description": "WK_CDR_MSC_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f52ecabb-58e0-488b-9d33-a42ae216be1c",
        "ParentId": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "Name": "3.Tháng",
        "Description": "MN_CDR_MSC_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3325006-9b16-4818-9c49-22c2a224e7b6",
        "ParentId": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "Name": "4.Quý",
        "Description": "MN_CDR_MSC_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f206cd85-ec63-49ad-8af8-7266f12af604",
        "ParentId": "4cf0e6b4-fae1-430f-a030-0c05b05da590",
        "Name": "5.Năm",
        "Description": "YR_CDR_MSC_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/1.Tỷ lệ rớt/9.Miền/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "2.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24e2e58d-6033-4ffc-a5a8-376bd7a5ed49",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86278a0c-51f5-4253-8a7e-deb724535d9a",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "364a6442-06cb-4d27-a225-20d736d72ed4",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bdc82336-8015-4f2c-b436-d3474beb3654",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8154b6cf-c84e-475e-9229-52e50bf701bd",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c106ade-a425-4e41-9583-fa23d65f7fb9",
        "ParentId": "70d3ca90-397c-4f9e-af6c-f9ff08375623",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Site/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "447491d0-59e9-4594-8280-893f30411b26",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "0.Giờ",
        "Description": "HR_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2844e4d-bec6-4074-afd9-f8fc4110bb47",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "860f4bd4-8916-4b2d-9a44-5f61c5bce79c",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "82d7d3b8-b268-45d1-a2f7-139b837e841c",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fe85d6e3-b09b-4cd6-b0a3-8934376b22b6",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ff13677-e7d7-406e-bbdc-c09674afc189",
        "ParentId": "68174f1d-797c-40eb-95c4-bcce74ffbcd2",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_thietbi_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/1.Cell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "2.Sites",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "18b87cbe-d45b-4f9c-a14e-989332d8ef6d",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "0.Giờ",
        "Description": "HR_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5557e5a-9d03-4842-b14e-1308e0d388fa",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fce60f02-f648-44f3-83c9-0c379f81878b",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "724d68d8-8555-44ae-9c0a-cf22c8fb0780",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66dcb977-6d69-4da2-943e-104d1793ae21",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "4.Quý",
        "Description": "DY_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0449b43e-2e77-43b2-a989-10eaf139839e",
        "ParentId": "b7681ed0-1231-4835-a6e7-8bea0081776d",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_thietbi_sites",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/2.Sites/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "3.BSC-RNC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68759330-fd07-43e2-a018-b6c03cee74d7",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "0.Giờ",
        "Description": "HR_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a7744caf-7e6a-499d-ba23-a9132071533e",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17d73c41-a058-478b-9962-38173bc7da51",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eeb485bb-8e7b-44c0-a9ec-b6543bd667f3",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a6bf303b-04df-43d8-a3b0-8abc87b1f9b2",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c841154a-dba9-4d69-89e1-ecf925e5e119",
        "ParentId": "387e4e6f-1ef9-4aaf-8ce7-bc080c63bad5",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_thietbi_bsc_rnc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/3.BSC-RNC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "4.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a6d40cf1-d2b1-4dc9-9d08-b2c6ae92983e",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "0.Giờ",
        "Description": "HR_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab3a4fc3-625e-41d0-ad2a-9339081ad99e",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1cd9f1d6-366d-4c74-8a6d-4ac524527c2a",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e39811d2-967c-47e9-af39-d2ef7df1d8e7",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13cf660e-a6cc-45c1-8743-ebfb7bfb1952",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "381be505-5336-4a58-9f24-6693789e71ca",
        "ParentId": "2cd70a06-5fd4-4645-a181-4dda76afb15f",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_thietbi_msc",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/4.MSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "5.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ff425b6b-dd44-4e5e-ac68-5ad6f46cadd6",
        "ParentId": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "914346ac-9d1a-40fa-8924-e7a1a1b8bb0a",
        "ParentId": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "758a55c0-8d5d-4c96-b652-69a99f312e94",
        "ParentId": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61f463b2-8c10-4175-844f-cb4a6902c98d",
        "ParentId": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f4264c65-1cd0-434e-a3c1-24394ce0d993",
        "ParentId": "e6c8ebf0-093d-42e0-b721-2fa96a645762",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/5.District/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "6.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f58bed46-0395-4f16-8d67-62775716b342",
        "ParentId": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dff52fc0-3305-4f13-87cc-29fca3b01de5",
        "ParentId": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "77f657cc-e017-4070-9343-be5097f1643e",
        "ParentId": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a57a918-a402-4336-8baa-22b764f913c8",
        "ParentId": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e99ba518-e8fb-4db3-b5c6-d21d82d99f60",
        "ParentId": "f9ee67a5-2afb-4047-876b-d0b56a0c68a6",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/6.Province/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "7.Chi nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e0f3270-5261-41ca-b984-4eab6b3570f5",
        "ParentId": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43a66f69-6284-4e1e-a681-734f0d288086",
        "ParentId": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "32a381bd-8728-45db-aa28-2e74f24da8da",
        "ParentId": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ae7bec5-e4b6-4def-9980-38e98555728a",
        "ParentId": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7242c39c-e996-479e-b566-e27948e02592",
        "ParentId": "b3c3f35d-2172-4293-9d6d-ecd85bee5db5",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_chinhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/7.Chi nhánh/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c7e16b8f-f646-4ca1-aaa5-5acd6bc8cfef",
        "ParentId": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "22d80e01-c65f-4755-94c3-200ef8198dd4",
        "ParentId": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a56d353-2b86-45dc-8340-adba17bb4fa5",
        "ParentId": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6451de0d-3ce7-4a3d-afa7-237102beac2e",
        "ParentId": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cbe0e5d5-578b-42d2-b00c-0bc398ab2e19",
        "ParentId": "f077aac7-95b0-4f33-9a58-10464e94080e",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_ctkd",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/8.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "9.ToVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c4a7f93-63ec-4241-a894-c262794f8c41",
        "ParentId": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "58bab7c9-4a0a-451b-b3db-2b2166a8c815",
        "ParentId": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b88e9933-e6c7-422f-bc59-be71f2a16b04",
        "ParentId": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "428a7135-54cb-401b-921f-eba54563b64c",
        "ParentId": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "657c3b77-3d43-43e4-8265-1168bc302394",
        "ParentId": "6a061c7b-243f-4e36-b1ac-d4510073606c",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_tvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/9.ToVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "91.DaiVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e308eb0-c900-4251-aa92-55b987b283aa",
        "ParentId": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "94eeae36-4851-4a6c-9981-682b72e68da3",
        "ParentId": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f3aca7b-8c20-4f99-b9a5-a87a3f935fa2",
        "ParentId": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0775717c-22d5-429a-8435-3acf460b4031",
        "ParentId": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6d407ade-37be-43b2-8cd4-5a72ce31cb02",
        "ParentId": "499f704c-388e-41cb-a74e-cd6a2107e507",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_dvt",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/91.DaiVT/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "ParentId": "b51459b9-0e96-4134-8e31-c3917553aebf",
        "Name": "92.Miền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "763565f9-f0be-4f0a-acca-7cd5e51166db",
        "ParentId": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "Name": "1.Ngày",
        "Description": "DY_tyledamthoai_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44d0995d-fe79-45f9-864a-211b8feced1f",
        "ParentId": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "Name": "2.Tuần",
        "Description": "WK_tyledamthoai_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f41e4240-a85e-4889-a076-93f6acf0a54a",
        "ParentId": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "Name": "3.Tháng",
        "Description": "MN_tyledamthoai_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "db0bb246-5e0a-467c-8821-ea578667ac20",
        "ParentId": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "Name": "4.Quý",
        "Description": "QR_tyledamthoai_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d465a907-f944-4687-a970-ee5b31c60d55",
        "ParentId": "24a92363-c938-40a8-862a-e7e6764d6fe2",
        "Name": "5.Năm",
        "Description": "YR_tyledamthoai_khuvuc_mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/2.Tỷ lệ đàm thoại nhỏ/92.Miền/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c34c8185-7430-40dc-acb4-c1a2bef47a02",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "0. Giờ",
        "Description": "HR_CallSetup_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f7efceeb-88c4-49b2-8c05-0a8eb67157e7",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "33d9d7cd-8a2a-4751-b656-358c08585293",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "321d8925-bc1d-4eac-b21b-e3acb03572b0",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08d4e514-6f66-4820-8be0-cebc197bada9",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "4.Quý",
        "Description": "Qr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f1178e6e-cc9c-4a0a-8653-837db89e92f9",
        "ParentId": "b8453a69-e429-4bfe-87ae-e65708c2fee0",
        "Name": "5.Năm",
        "Description": "Yr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/1.Cell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "2.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e032e4ce-3c05-424e-94c2-f1c61f162f2c",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "0. Giờ",
        "Description": "HR_CallSetup_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2e56e3db-e689-4b06-9dcd-5aaed28af3fc",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37ecefe3-bd62-43f8-b469-c53d142b71c0",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eabd2aad-cae4-474a-a4a8-03669b886075",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34499980-ba4f-4dde-84a9-5bb177e075c2",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "4.Quý",
        "Description": "Qr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ac0ef5f0-3a4d-47ba-91c4-b4a0984768d8",
        "ParentId": "0cc60459-69cf-4456-9384-23ff2bd4183e",
        "Name": "5.Năm",
        "Description": "Yr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/2.Site/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "3.BSC_RNC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74f51a5b-8c27-443b-ab93-cc2375b26e26",
        "ParentId": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_BSC/RNC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4fd560e2-b55e-4769-9191-e231553f5452",
        "ParentId": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_BSC/RNC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c74c5d06-a385-4b16-a443-52cdf1b4c9eb",
        "ParentId": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "67fe246f-84c8-4a59-8225-665e687205a4",
        "ParentId": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "Name": "4.Quý",
        "Description": "MN_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b96a80bc-79f2-46f7-ae46-6c367e42bb3c",
        "ParentId": "26bb35a2-2a01-4f8c-9f05-f8afd04e6b82",
        "Name": "5.Năm",
        "Description": "MN_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/3.BSC_RNC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "4.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b3bdda65-e5e0-4178-803d-e0911de57d3f",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "0. Giờ",
        "Description": "HR_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a53c86d-db37-494f-a421-56cfb8fa5afb",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6b1abf6-03e1-46e6-b849-8ddf10d804f4",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45779efd-1461-4c02-a5bf-f53f471279a9",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f697822c-a67c-470e-8c39-1db6aba29362",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "4.Quý",
        "Description": "MN_CallSetup_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c9b30b5-b2d1-4ae3-abe7-c2bfc1299906",
        "ParentId": "216250df-05ac-4df1-bff4-e90e51089c1a",
        "Name": "5.Năm",
        "Description": "MN_CallSetup_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/4.MSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "5.Quận Huyện",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0100bc25-f5ce-4404-8a80-168b45c39d4d",
        "ParentId": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6a8f2c1b-7225-4b8f-a070-aaa30d58153c",
        "ParentId": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5cd423a1-8de9-4ea9-8f43-324a0a03d7e2",
        "ParentId": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf429537-29d9-4486-9ff7-498e6a6c5780",
        "ParentId": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "Name": "4.Quý",
        "Description": "Qr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3f48e1b4-791a-4cb2-bda7-f6f223c5a029",
        "ParentId": "cf0ae54b-5d4e-4213-98ba-f1b8bfc528e0",
        "Name": "5.Năm",
        "Description": "Yr_CallSetup_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Quận Huyện/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "5.Tỉnh TP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7cf4c2a9-e82f-4821-a62f-be1bbd4cafaa",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "0. Giờ",
        "Description": "HR_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a752b12-36d1-4c98-b5a2-2dd2a949ed48",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_TP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "213c397c-7922-4643-86db-221d52cdc077",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c146c6c2-48c9-4235-b4eb-b6682423e51a",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_TP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "23415d2b-6b65-4a52-80c7-6b1f18dcc70e",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "4.Quý",
        "Description": "Qr_CallSetup_TP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bf5f877d-aa73-4e3e-8296-879665b0f721",
        "ParentId": "e972fa29-3e3a-4d40-8a46-4165e384b3a0",
        "Name": "5.Năm",
        "Description": "Yr_CallSetup_TP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/5.Tỉnh TP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "7.Chi Nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cb4a4831-e97b-4b85-8e3e-47d45b55fe06",
        "ParentId": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_ChiNhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "da4406ea-5603-4408-b33e-2af16b9cbca6",
        "ParentId": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_ChiNhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0cedaa64-417f-4e7f-a714-ac7c3c9362da",
        "ParentId": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_ChiNhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c1dd480-5685-49bb-b475-58939339665f",
        "ParentId": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "Name": "4.Quý",
        "Description": "QR_CallSetup_ChiNhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d4b8cd5-968c-47f3-b8a7-39283c5f1b95",
        "ParentId": "b5d3d979-404d-40b6-904c-5e07b020115d",
        "Name": "5.Năm",
        "Description": "YR_CallSetup_ChiNhanh",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/7.Chi Nhánh/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "8.CTYKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "34076a93-e3b8-4f3e-bb96-2648fbc38a3a",
        "ParentId": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25063ed0-e870-4ce6-91ce-1b68e45300cf",
        "ParentId": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c710b2b-ea01-4038-ad97-93be08cc44ef",
        "ParentId": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5b3cd2f-df3a-4e74-b367-2013bd40a5d0",
        "ParentId": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "Name": "4.Quý",
        "Description": "QR_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c8972459-5123-4c45-bc79-7fef7cb007f8",
        "ParentId": "8bc70064-68ba-4439-ad2b-d64bc14a9e64",
        "Name": "5.Năm",
        "Description": "YR_CallSetup_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/8.CTYKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "9.Tổ viễn thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b5bbe899-a214-4a32-b841-536d10dbf9b5",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "0. Giờ",
        "Description": "HR_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/0. Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4ff97055-ee7a-4077-bee0-3e48b1b24f68",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "68319ecb-f1b5-4dd0-bf87-c4823e2dbc22",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5988bae2-0b9c-4ee4-9ae8-f1eaaeda2855",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "3.Tháng",
        "Description": "Mn_CallSetup_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "024bad75-1e47-40c8-b7aa-a68ac2396c1a",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "4.Quý",
        "Description": "Qr_CallSetup_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a8f21330-e040-4c23-921e-830429f5d2ab",
        "ParentId": "11f8d30f-85fc-4ba9-a8a5-c03f9e8fe03a",
        "Name": "5.Năm",
        "Description": "Yr_CallSetup_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/9.Tổ viễn thông/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "91.Đài viễn thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca1e2681-d314-4bb6-b952-93d4ee88f733",
        "ParentId": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1919430f-c087-483f-9321-e3ef34c434e2",
        "ParentId": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1cdfc65e-ca44-46a5-b3d6-b4dae1017f75",
        "ParentId": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91895339-8606-46cc-87db-b5bd7e1ede1c",
        "ParentId": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "Name": "4.Quý",
        "Description": "MN_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4aebbe13-1077-4063-a904-ec673caa9d56",
        "ParentId": "9bff3c83-4dcb-40e8-858e-168c5ee4d4a4",
        "Name": "5.Năm",
        "Description": "MN_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/91.Đài viễn thông/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "ParentId": "87ffdad0-a73f-4492-8fca-18af0b0e2378",
        "Name": "92.Miền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c9857bbd-f93c-481c-989e-0d24864f6eba",
        "ParentId": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "Name": "1.Ngày",
        "Description": "DY_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b9d4c3b-b546-47c4-b345-cd90d9e8985d",
        "ParentId": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "Name": "2.Tuần",
        "Description": "WK_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "46ab41e2-e9c0-47e3-bf6a-83a8d3b94998",
        "ParentId": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "Name": "3.Tháng",
        "Description": "MN_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c1171e4-209b-487c-a375-bfd36c9ae88c",
        "ParentId": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "Name": "4.Quý",
        "Description": "MN_CallSetup_Mien",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0713889b-18dc-4fdf-974c-f39b51ca4b48",
        "ParentId": "b7b4f65b-1d35-42bc-9d34-5ea68435109c",
        "Name": "5.Năm",
        "Description": "MN_CallSetup_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.Call setup/92.Miền/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "3.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e69a674e-9abb-4e59-93c8-9fb3730b65a4",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6243bcc1-5e28-45e2-adb5-488dfaab9579",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd295351-7c59-42bb-b81d-c53d6d5c25c4",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8a1888af-faad-4e2c-a051-35fc50537521",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9ec1d8f7-8028-4e29-8abb-6f46176f7145",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "610e8dff-efdc-4212-99aa-de9f9dd02e00",
        "ParentId": "d89f8093-1044-4d91-96d3-1a5b63cd555c",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/3.District/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "dca51f81-cc63-4568-870d-c966954e1724",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6041146c-b9e7-4cc8-9673-9c866f175afa",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5e1d395-3e7c-4306-aafa-22c2fd0ac392",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8ec04663-9231-45ea-89c4-a9b1532a6d83",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2860f6ac-6e0a-4f5b-aa8e-b7f492e9f1ce",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c904a4c8-3f49-45ad-8c02-4120a4d53929",
        "ParentId": "89f7195b-023c-4817-9e07-06ed6e25218e",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/4.Province/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "5.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af5d9fe6-4f20-4b1e-8879-ce4208c05ccb",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a554b54c-5e52-43ae-9695-1792a5ae5e80",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e352f2e3-3eb4-439d-84e9-59763b06b792",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5b46a139-0eec-4e68-99f6-5c4611e37eb2",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8797c956-6bd4-43eb-b678-c3d43179b456",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5968b15d-9e0a-417a-a719-b1c412c3ebca",
        "ParentId": "2c4fdc55-bd15-471d-8f8e-518cf8493082",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/5.TVT/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "6.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ce97ca70-fd62-4f1c-8ef5-04c910be39f4",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4077a344-a3e5-421e-a589-a737bc9f0b55",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ebf73418-9981-4315-a545-bcc9490901aa",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c454a78-0c62-4aa8-9571-1d561b968b27",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f46f7b9-18e5-4e7c-908c-cee0ed950741",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79f2006f-d7a9-4fb2-937a-831dc61cc185",
        "ParentId": "e0760d46-dfb4-4812-a098-bf9f2febe69d",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/6.DVT/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c49877d0-bea8-4a03-9980-d8ead302605d",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f89d685f-e84b-4a8b-b7ab-732033831a18",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a6813ad4-09be-44a9-9690-7a2a46d1fb13",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cec357aa-790d-4153-9eb1-dd761e9adaf3",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fec9824e-37f4-42b4-996b-59571eaa92ea",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17de3e82-2086-48e5-bb60-77a4afd01f26",
        "ParentId": "a3f6c2ef-9560-4de9-a202-aa654d0c29be",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/7.Branch/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0636bfbf-26b0-46e6-9720-d59406c05ba0",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd22149e-ba08-48c8-aa82-21540a659ff4",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bd2caa9f-5421-4352-a83b-6b51b03f6be1",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79ff9c49-3fb6-47e2-a0b0-b2ad205d0865",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "70fd1767-cd7a-483b-9374-303721e6680d",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b28e8888-912f-46ac-a43f-bf5fc353f959",
        "ParentId": "fcb6e7fb-d082-4f92-baf1-9158815ad00f",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/8.CTKD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5c477e27-90e6-4909-832d-8452e708c942",
        "ParentId": "3b2e9746-ea7f-4e27-8cfa-5e4772535c7c",
        "Name": "9.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44063c63-a610-48b6-86ff-48e7d25eecf6",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43e2b73a-0318-4c72-ae84-52c3641a2ccc",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed2da0a6-bfe2-4a02-8866-929e0ea15414",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "3.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "744277fb-c6da-49f3-986a-86b94550737e",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "4.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f063c5ab-aa80-4fb9-9573-c0ac78c49f2f",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "5.Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "222bb95d-9340-4bb9-80c1-fd5a6554afdc",
        "ParentId": "5c477e27-90e6-4909-832d-8452e708c942",
        "Name": "6.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/3.KQI Thoại/2.Trong nước/9.TTML/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "ParentId": "56354e06-0d62-454e-9668-fb595dc61b72",
        "Name": "1.SMSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "269d620d-31c4-4270-9768-8889c9823804",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "1.Giờ",
        "Description": "HR_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6bf94818-c209-4342-a133-bb0132601bdc",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "2.Ngày",
        "Description": "DY_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f55209e-8c5d-410c-96ab-24c82a457f46",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "3.Tuần",
        "Description": "WK_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61889686-a065-4a72-b2be-d1a3913aedf9",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "4.Tháng",
        "Description": "MN_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1c89edc7-ba9c-43d4-acac-a2f0b0dba45a",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "5.Quý",
        "Description": "QR_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a2911605-94a5-4e60-86bd-842fe90113ac",
        "ParentId": "4a82a6d5-4880-4509-b583-ad701fc50e04",
        "Name": "6.Năm",
        "Description": "YR_KQI_SMS_SMSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/1.SMSC/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "ParentId": "56354e06-0d62-454e-9668-fb595dc61b72",
        "Name": "2.SMSC-MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "123150b0-1fab-493f-bae1-0203dcb6aee1",
        "ParentId": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "Name": "1.Ngày",
        "Description": "DY_KQI_SMS_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b632ddc3-2a48-43e2-9279-9c081a0ec8f1",
        "ParentId": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "Name": "2.Tuần",
        "Description": "WK_KQI_SMS_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "773d0c16-848d-4f50-aac8-6d3637068731",
        "ParentId": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "Name": "3.Tháng",
        "Description": "MN_KQI_SMS_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5469e215-91e1-4cb3-9c4d-11f8a4ea5da8",
        "ParentId": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "Name": "4.Quý",
        "Description": "QR_KQI_SMS_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95b7026a-6a9e-4096-b980-c8addb4740de",
        "ParentId": "67f67d7d-7bb4-4dd2-a9c1-bd5771557e01",
        "Name": "5.Năm",
        "Description": "DY_KQI_SMS_MSC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/2.SMSC-MSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "ParentId": "56354e06-0d62-454e-9668-fb595dc61b72",
        "Name": "3.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "1.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "918906dc-1e92-4943-918b-48bcd7746ee5",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6081e96e-eeb2-4c08-9c07-58a1d1b53e71",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ae20f70-9a1d-4a17-a6e6-c5fcb1a1c9c1",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c868a8c3-3313-4d6f-873d-4798fba2d6d4",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "618e97b9-86e4-470a-835c-2b51ae0d57ba",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05a55696-685b-49e3-8f9e-93a3b2974da1",
        "ParentId": "fa525ad8-7bad-4b08-8492-38bbc6fb088a",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/1.Cell/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "2.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f77789bb-571a-4314-8448-fec5cc3539c8",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f0363ea0-556a-4646-8f97-e45d79578746",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "61dc7826-16f4-4c97-88a2-7d0a3139966f",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bbad694-fe84-47e5-ad5c-f928a4b10e98",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f76d3717-dade-4d7a-a76e-6894a7e5908c",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2311e521-2335-480b-8327-6bbce04ae0f8",
        "ParentId": "13914f10-e329-45d6-ae43-68cc15fc67e1",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/2.Site/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "3.BSC_RNC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9e2d7734-c51d-4171-a8ef-7ac8fd473e25",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "435407d3-9357-411d-a1cb-696118ae97cc",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed4b1ac9-97f9-4db0-ae64-3ab2ada7701d",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9049a8e-bd68-4dd5-ac31-b46ba2822dd4",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d02ee406-bab9-4bfd-bfa2-7539450c236c",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f2967e55-1ea4-4d1a-a9ba-4631c7c3cca7",
        "ParentId": "07554095-6692-4164-a7f6-59aafa2dc58c",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/3.BSC_RNC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "4.MSC",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eb35918e-efa4-4a63-af93-fb963125a32b",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fcb1b200-2860-4ad0-bb04-d09664fd3c3d",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "89431c87-197a-45a9-9fa1-598ac999860e",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a495606f-a8e5-4206-b7c4-5bb929273d7a",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9f5ec3be-507c-4576-b817-df6664347d98",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00c4e3ce-e9e5-4a4f-befc-a6148ef9445c",
        "ParentId": "e9d6603a-e9e3-4d88-9f90-6f6153e1177a",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/4.MSC/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "5.Quận Huyện",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "072b712a-7113-48cb-9096-a50ccb09572c",
        "ParentId": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "660f1cfb-0cb7-4487-9a6d-314ae82357b6",
        "ParentId": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6174c02-4399-4963-823e-0d6ceead29ca",
        "ParentId": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a43438f-55bf-4cb6-a150-87d3297c02d4",
        "ParentId": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f42cc775-7e63-4ec8-9735-04207d0c3542",
        "ParentId": "a3470b79-ae20-48e6-8041-2dbfe857596e",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/5.Quận Huyện/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "6.Tỉnh-TP",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "854106b8-b7ad-4e61-a70d-4e0118cd3d0e",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7c52928a-7a9d-431c-8975-09c40a06616a",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b9451a13-0626-46bc-9cdf-dfae36a9339d",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "66464676-62d0-4427-ac2d-bb7dc8486c87",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a82b2bb-e942-4ce3-b6c9-78bedae2da7d",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7657e9dc-3410-4703-b7cd-81a22dfc7e1d",
        "ParentId": "7235ddcd-8e2c-4116-ba4b-7d59e3dc52a2",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/6.Tỉnh-TP/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "7.Chi Nhánh",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90557ceb-2fbd-4099-8f4d-07d1acb9f4fd",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6651f570-7fa6-443b-b02b-fdffa3faace6",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5762ed7b-cc1a-4889-913d-36bf493a20da",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "43f4129e-6e35-4ab5-8b66-863f755aaf44",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c80f34b0-a4d9-4459-8661-bde0fd6fadb7",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "bbf1bd70-1fd7-4d63-b142-ca9644a829b4",
        "ParentId": "93601083-1e7a-4e00-939b-2240a0ca852b",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/7.Chi Nhánh/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79a44bb9-54b7-4b8c-a310-58bba7e9a283",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c5d7db07-19f6-4f14-abd1-4a7858a931f4",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c4caf634-f68d-4a78-8e4d-789c2bd9ee48",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "945210c6-a887-4532-a681-f80a749278e9",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00a9bce9-604b-495a-b8c5-dd644b53f6d6",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fd0182c8-e10e-435b-b197-2d43e4df8cc6",
        "ParentId": "340d7ea1-54c9-4364-a63d-29879c3a921c",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/8.CTKD/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "9.Tổ viễn thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7b5915d5-bd3b-457f-af50-88f4c6fd83b2",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "aec96805-19d7-4cfa-877e-1831a0f70979",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d3eebac7-834b-48fc-84e7-38d71616fa98",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5006ce53-5f57-422e-9099-05bac616b2da",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "74c8f0ef-f93a-4256-ace1-f84d727837ae",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e19818bc-a838-444c-9e5f-bd78c99bed8e",
        "ParentId": "15072cd2-1bff-42a6-a0a5-f98d5bd84960",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/9.Tổ viễn thông/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "91.Đài viễn thông",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "688dcf9c-fb3b-4544-8b9e-dfff152c4874",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1486959c-fba1-4412-89f8-ea3b5d09bbc8",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a48c28a9-08f8-43af-9595-ae6f060e63bf",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "080e2d3b-597f-40bc-8538-11df89b4171c",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e7455546-3165-4d49-8de2-cd3d3926b0a7",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5fbed2e0-cc4f-4a9f-bddf-16895dc51b8e",
        "ParentId": "f8f00e42-2e12-4510-847f-cf7b6ded97e1",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/91.Đài viễn thông/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "519784ad-99fb-429a-b561-f6f0119838ad",
        "ParentId": "edbeb74a-996a-4c57-8a79-1bc345a7db74",
        "Name": "92.Miền",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b560b1e3-9c72-4deb-af8e-4e65f1430b38",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "0.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/0.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7ab4ec7-6010-4604-ae25-6d486194ab43",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a13abc7-7057-49f1-98f1-ee3b348055b8",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0110a0da-4a3e-4dab-835e-e978b18b4153",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fba040c9-b0b3-41ea-b726-d78b805e71fd",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "4. Quý",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/4. Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af2f39d3-4ba1-4c7e-84d5-674894bdadca",
        "ParentId": "519784ad-99fb-429a-b561-f6f0119838ad",
        "Name": "5.Năm",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/4.KQI SMS/3.MSC/92.Miền/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "5.KQI Data",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca88112f-982c-47bd-8108-b994f5671893",
        "ParentId": "58282ae6-8c7b-4490-8e60-065134832b28",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/0.Thuê bao/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7fdbd90b-9f6d-42e9-9861-84b03d965b73",
        "ParentId": "58282ae6-8c7b-4490-8e60-065134832b28",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/0.Thuê Bao/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5171f985-bd56-461d-8758-e380ffd37a5a",
        "ParentId": "58282ae6-8c7b-4490-8e60-065134832b28",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/0.Thuê bao/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b1d765e5-0404-408b-9692-6563c8051318",
        "ParentId": "58282ae6-8c7b-4490-8e60-065134832b28",
        "Name": "2.Tuần",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/0.Thuê Bao/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02812b22-b469-440f-8e30-57b11517a506",
        "ParentId": "58282ae6-8c7b-4490-8e60-065134832b28",
        "Name": "3.Tháng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/0.Thuê Bao/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "63c884fc-c8fa-4c0e-b67c-0b14d360fc5f",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "01.Thuê bao",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/01.Thuê bao",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b89fb935-0172-4103-a644-b15f105dc110",
        "ParentId": "63c884fc-c8fa-4c0e-b67c-0b14d360fc5f",
        "Name": "1.Giờ",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/01.Thuê bao/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "39eba46d-a51f-49ae-bc5c-4c1bdb5a18eb",
        "ParentId": "63c884fc-c8fa-4c0e-b67c-0b14d360fc5f",
        "Name": "2.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/01.Thuê bao/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ffa145de-df75-4faf-bda5-55164b3a6601",
        "ParentId": "ebb1c1ad-d1d7-405c-b0b2-16aa6a8007da",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/1.Cell/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca8f8d45-c86d-4f09-8903-ead59dbb2b8a",
        "ParentId": "ebb1c1ad-d1d7-405c-b0b2-16aa6a8007da",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/1.Cell/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "234f3144-eb26-4832-b281-f736d7b20ee1",
        "ParentId": "ebb1c1ad-d1d7-405c-b0b2-16aa6a8007da",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_Cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/1.Cell/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "11.Cell",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0897f1f-2adc-43e1-9cfc-2d326c43b37e",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "1.Giờ",
        "Description": "kqi data-cell-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7a87981a-c516-418b-8246-335b505ff69c",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "2.Ngày",
        "Description": "kqi data-cell-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ee5a4e82-33cb-4c80-9593-4937ebb471d8",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "3.Tuần",
        "Description": "kqi data-cell-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "574143d1-0f32-46f4-8c3d-a8c4ff906294",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "4.Tháng",
        "Description": "kqi data-cell-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f72c521e-b818-4716-b264-cc06213704ad",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "5.Quý",
        "Description": "kqi data-cell-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b6b64f80-32dd-4927-90a6-aa64c35c0a6d",
        "ParentId": "d6caf88b-622d-4c3e-987f-4b0f49677f00",
        "Name": "6.Năm",
        "Description": "kqi data-cell-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/11.Cell/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95b32d45-67f2-46c6-90e7-62bfb792d5d2",
        "ParentId": "b8ae1103-d51b-464b-a218-90240cb7cae8",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/2.Site/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7558e689-7ca1-4d97-a2c4-eca46e82f02f",
        "ParentId": "b8ae1103-d51b-464b-a218-90240cb7cae8",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/2.Site/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f19af104-ea06-43a6-8d2a-22bb93b00ef0",
        "ParentId": "b8ae1103-d51b-464b-a218-90240cb7cae8",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_Site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/2.Site/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "17123dce-981b-413b-8414-8bc9f93ded90",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "21.Site",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e92b9ba6-3149-4504-956a-2f584058abb6",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "1.Giờ",
        "Description": "kqi data-site-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b63434f2-6cd6-4399-8904-f7b6eed475fa",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "2.Ngày",
        "Description": "kqi data-site-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "01215516-e93c-48d8-a583-082d6b8a2052",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "3.Tuần",
        "Description": "kqi data-site-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f656e889-3483-498f-b155-81c07ee7e16e",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "4.Tháng",
        "Description": "kqi data-site-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "af56ed6d-fb6c-45f5-8052-9564d69bd5c7",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "5.Quý",
        "Description": "kqi data-site-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a90360f2-832f-4dc2-9da0-dbba170c3b28",
        "ParentId": "17123dce-981b-413b-8414-8bc9f93ded90",
        "Name": "6.Năm",
        "Description": "kqi data-site-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/21.Site/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "3.District",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "16237fd6-5392-4b76-bec5-184a62297c4a",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "1.Giờ",
        "Description": "kqi data-district-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6f2b16a9-730a-4f6a-ac58-cc76a8bb9773",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "2.Ngày",
        "Description": "kqi data-district-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b2d645f0-ded1-446e-9776-ec7da284b9cc",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "3.Tuần",
        "Description": "kqi data-district-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "08ab1061-0bf1-4768-93e2-42f45567389d",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "4.Tháng",
        "Description": "kqi data-district-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2573b9dc-d332-49de-86a8-3abd220caff5",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "5.Quý",
        "Description": "kqi data-district-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "960b077e-025e-40c7-b4ac-be9ad839343c",
        "ParentId": "e6522012-11f1-4896-9782-e44f8956f3f3",
        "Name": "6.Năm",
        "Description": "kqi data-district-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.District/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "90f18394-b030-4011-b5fe-2628b396da9e",
        "ParentId": "fbdc8af6-d154-4410-9245-c17304084f90",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.Quận - Huyện/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "37659628-45fc-4084-bf89-04358d2c900f",
        "ParentId": "fbdc8af6-d154-4410-9245-c17304084f90",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.Quận - Huyện/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "864beb3f-c5eb-45c9-b48a-9f0f344e1f25",
        "ParentId": "fbdc8af6-d154-4410-9245-c17304084f90",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_District",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/3.Quận - Huyện/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "4.Province",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8b23b1df-5572-462e-aebe-fe86000790ea",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "1.Giờ",
        "Description": "kqi data-province-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "50e9bd5b-e4f0-49f7-ad3c-0022205c52ea",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "2.Ngày",
        "Description": "kqi data-province-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abf519a5-1ca6-4888-bd51-25722c045661",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "3.Tuần",
        "Description": "kqi data-province-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a808597a-9a1f-453c-b2be-b19b99d8d6e4",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "4.Tháng",
        "Description": "kqi data-province-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a4e85ed0-c817-4e4c-b407-19080d4e4709",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "5.Quý",
        "Description": "kqi data-province-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "64bde4cc-e405-41a0-88a8-749707bb46a3",
        "ParentId": "1715260d-e39a-49eb-a01b-5701338c55f4",
        "Name": "6.Năm",
        "Description": "kqi data-province-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Province/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "79edc572-7ad0-4fe8-8879-2be0ecde8bce",
        "ParentId": "9d8d5ade-fe06-40cf-a080-a4eea71f3151",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Tỉnh-TP/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6ac1c269-1efb-4e56-9a7b-c87698144c31",
        "ParentId": "9d8d5ade-fe06-40cf-a080-a4eea71f3151",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Tỉnh-TP/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "121df59d-815a-42c8-a866-e8aeb05a3574",
        "ParentId": "9d8d5ade-fe06-40cf-a080-a4eea71f3151",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_Province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/4.Tỉnh-TP/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3880e4d3-1414-4ebf-a715-4aced11f9cca",
        "ParentId": "466c5cfe-70b0-4178-85e4-6760487e7134",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_Branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.Chi Nhánh/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1fd57748-16c1-4551-984e-651692750553",
        "ParentId": "466c5cfe-70b0-4178-85e4-6760487e7134",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_Branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.Chi Nhánh/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "72da28b8-38bd-4013-86b4-c701a5f97764",
        "ParentId": "466c5cfe-70b0-4178-85e4-6760487e7134",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_Branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.Chi Nhánh/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "5.TVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0c9a50e4-2d69-44c4-8057-249d68d3518e",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "1.Giờ",
        "Description": "kqi data-tvt-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "83d50ce5-3623-4fde-b44e-511f3a83dfa7",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "2.Ngày",
        "Description": "kqi data-tvt-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "29672ad7-ac82-4022-a1bc-cf5327dd1f26",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "3.Tuần",
        "Description": "kqi data-tvt-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cf7bd462-3db3-44cb-ac31-53d2b3df82b4",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "4.Tháng",
        "Description": "kqi data-tvt-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "02412057-b976-4edf-967b-c52e86563940",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "5.Quý",
        "Description": "kqi data-tvt-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f2ce2fa-d9cd-482b-9b7f-2238ddd2ffed",
        "ParentId": "2d6bc084-79ab-4f19-85c8-9dedf8837a52",
        "Name": "6.Năm",
        "Description": "kqi data-tvt-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/5.TVT/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8109ae09-3783-4921-a15b-a6d185cd1996",
        "ParentId": "d9fb0ebb-0a57-4929-bc04-01dd1f473193",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.CTYKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d4ca7033-fee9-4e3c-813d-b8ed72a275f5",
        "ParentId": "d9fb0ebb-0a57-4929-bc04-01dd1f473193",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.CTYKD/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1dccbdd1-74d2-4c4e-95ea-60e66542d9f1",
        "ParentId": "d9fb0ebb-0a57-4929-bc04-01dd1f473193",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_CTKD",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.CTYKD/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "6.DVT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0acfd3b-e3d1-4a7c-940b-172b0caf458c",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "1.Giờ",
        "Description": "kqi data-dvt-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a5aaee1f-5000-4173-9374-35b664530aec",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "2.Ngày",
        "Description": "kqi data-dvt-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ca1ca176-0dff-405e-b6a3-8ba9e4f39aef",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "3.Tuần",
        "Description": "kqi data-dvt-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "38c4ff88-45f5-465d-a5c9-7a3d7b0caf9f",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "4.Tháng",
        "Description": "kqi data-dvt-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "45e05b0f-f09d-49ff-92db-8af72b7422e3",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "5.Quý",
        "Description": "kqi data-dvt-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "25445879-5fb8-4153-a2cd-a2bb85d01eb9",
        "ParentId": "2a0d5f36-9c5b-44b6-a846-ed3085019291",
        "Name": "6.Năm",
        "Description": "kqi data-dvt-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/6.DVT/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "7.Branch",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "4c2c8f7a-9055-43b5-916e-2fa98e30a5ff",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "1.Giờ",
        "Description": "kqi data-branch-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00453f39-b466-41b0-9251-af0ac7d51ea0",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "2.Ngày",
        "Description": "kqi data-branch-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f448cedb-079f-4a67-bd40-1b63d553e952",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "3.Tuần",
        "Description": "kqi data-branch-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2a660766-6c09-4a1f-8c20-bd3ebbda9a0b",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "4.Tháng",
        "Description": "kqi data-branch-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f24eaec6-17a6-431c-bc9e-d49c810ddafe",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "5.Quý",
        "Description": "kqi data-branch-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "76f81cc0-2e68-4a5a-af48-e6627998204b",
        "ParentId": "e5ad6ba2-5aff-4404-99af-07eedcf6673d",
        "Name": "6.Năm",
        "Description": "kqi data-branch-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Branch/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b9b79e69-51f3-4d66-8b6a-fcb9a1f3a495",
        "ParentId": "48e48f76-c90c-447f-aa9c-1cf4ade49843",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Tổ Viễn Thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab493f3a-59ba-4221-bdad-89fd9255e003",
        "ParentId": "48e48f76-c90c-447f-aa9c-1cf4ade49843",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_TVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Tổ Viễn Thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e4e7ae4-3b58-471a-a829-0a6cbaafcf5b",
        "ParentId": "48e48f76-c90c-447f-aa9c-1cf4ade49843",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/7.Tổ Viễn Thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "8.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "05c1aeac-9247-4751-9030-05265686f810",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "1.Giờ",
        "Description": "kqi data-ctkd-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99084aba-2755-44e9-b59b-6df83b14c7ca",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "2.Ngày",
        "Description": "kqi data-ctkd-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0f166b87-0ef0-4065-bc74-2ced97f92d94",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "3.Tuần",
        "Description": "kqi data-ctkd-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00bd1d27-67c4-497d-a169-e129daa763db",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "4.Tháng",
        "Description": "kqi data-ctkd-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d795569b-f48b-4273-9721-40046e9d47e1",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "5.Quý",
        "Description": "kqi data-ctkd-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6fc6fe69-0b91-49f8-b34b-b96d58ce2547",
        "ParentId": "5ba4c0c3-9ee0-41dd-97f9-909d17170554",
        "Name": "6.Năm",
        "Description": "kqi data-ctkd-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.CTKD/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86865e72-a3b7-46bd-ba8d-183ddb9af171",
        "ParentId": "6c23785f-8721-4290-84b9-89cb095bed12",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.Đài Viễn Thông/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ae9d50cb-d88c-4f77-8c97-75e188039dff",
        "ParentId": "6c23785f-8721-4290-84b9-89cb095bed12",
        "Name": "2.Tuần",
        "Description": "WK_KQI_Data_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.Đài Viễn Thông/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fb763ec0-9054-4170-a4ff-2f0eaa706f30",
        "ParentId": "6c23785f-8721-4290-84b9-89cb095bed12",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_DVT",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/8.Đài Viễn Thông/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "c0f300f7-e5d0-47b2-a4aa-9f172451cfc4",
        "ParentId": "2fbe82a1-a09a-4bd2-bc43-5c23e157a169",
        "Name": "1.Ngày",
        "Description": "DY_KQI_Data_NOC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.Miền/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "522ff420-cf61-445e-b748-16e99715dbea",
        "ParentId": "2fbe82a1-a09a-4bd2-bc43-5c23e157a169",
        "Name": "2.Tuần",
        "Description": "MN_KQI_Data_NOC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.Miền/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f8f9701-8203-4e9f-beef-b16b6531f6c2",
        "ParentId": "2fbe82a1-a09a-4bd2-bc43-5c23e157a169",
        "Name": "3.Tháng",
        "Description": "MN_KQI_Data_NOC",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.Miền/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "ParentId": "ad0b2255-f581-4f09-bd4f-b9a6cdab61fa",
        "Name": "9.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59c7b8e4-21bd-4035-a572-4d77a01323dd",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "1.Giờ",
        "Description": "kqi data-ttml-hour",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5a28b03a-e10c-4808-9b12-3749d0c19065",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "2.Ngày",
        "Description": "kqi data-ttml-day",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3e26f58-faed-4aa2-8de3-5c591534ed5c",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "3.Tuần",
        "Description": "kqi data-ttml-week",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a252444b-83c1-41c9-b7be-a5eec5dd7c24",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "4.Tháng",
        "Description": "kqi data-ttml-month",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "49332e68-6057-4768-a816-ed52bb6092f3",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "5.Quý",
        "Description": "kqi data-ttml-quarter",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/5.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "73adc7de-d304-4600-b5be-1f25d5c079c4",
        "ParentId": "86f5739a-f3f6-433e-9e76-df659cf5ada9",
        "Name": "6.Năm",
        "Description": "kqi data-ttml-year",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.KQI Data/9.TTML/6.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6b655831-f178-4a21-8457-4b499a64a560",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "5.WITALKS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0bfc005f-4029-4e0b-9785-99d604a7d426",
        "ParentId": "6b655831-f178-4a21-8457-4b499a64a560",
        "Name": "1.Chất lượng Thoại",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/1.Chất lượng Thoại",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edd9c5de-9048-4dcf-9efc-992beea33ac4",
        "ParentId": "0bfc005f-4029-4e0b-9785-99d604a7d426",
        "Name": "1.Giờ",
        "Description": "HR_witalk_cl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/1.Chất lượng Thoại/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "95f1bd9a-aa40-439e-be45-682451d9e31d",
        "ParentId": "0bfc005f-4029-4e0b-9785-99d604a7d426",
        "Name": "2.Ngày",
        "Description": "DY_witalk_cl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/1.Chất lượng Thoại/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ab5a1c82-2f53-486d-8184-f7014634d65f",
        "ParentId": "0bfc005f-4029-4e0b-9785-99d604a7d426",
        "Name": "3.Tuần",
        "Description": "WK_witalk_cl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/1.Chất lượng Thoại/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3908bc9c-d945-450e-bbca-5c8f03790d03",
        "ParentId": "0bfc005f-4029-4e0b-9785-99d604a7d426",
        "Name": "4.Tháng",
        "Description": "MN_witalk_cl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/1.Chất lượng Thoại/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a9fdd491-45e4-49cc-ba0a-8aa895d8aab6",
        "ParentId": "6b655831-f178-4a21-8457-4b499a64a560",
        "Name": "2.Chất lượng SMS",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/2.Chất lượng SMS",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5155ee22-e699-4c27-8960-ff2478441ea3",
        "ParentId": "a9fdd491-45e4-49cc-ba0a-8aa895d8aab6",
        "Name": "1.Giờ",
        "Description": "HR_witalk_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/2.Chất lượng SMS/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "75d741cf-056a-48ca-8ffc-f2582f25a353",
        "ParentId": "a9fdd491-45e4-49cc-ba0a-8aa895d8aab6",
        "Name": "2.Ngày",
        "Description": "DY_witalk_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/2.Chất lượng SMS/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "193b1d72-9e81-4148-815d-b3acf66b90db",
        "ParentId": "a9fdd491-45e4-49cc-ba0a-8aa895d8aab6",
        "Name": "3.Tuần",
        "Description": "WK_witalk_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/2.Chất lượng SMS/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "cd26723b-18e4-4d54-9684-687d654a3f9c",
        "ParentId": "a9fdd491-45e4-49cc-ba0a-8aa895d8aab6",
        "Name": "4.Tháng",
        "Description": "MN_witalk_sms",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/2.Chất lượng SMS/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "21a0fe87-27cf-4f29-9d7c-ec31d513b8ac",
        "ParentId": "6b655831-f178-4a21-8457-4b499a64a560",
        "Name": "3.Sản lượng",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/3.Sản lượng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3ffd0854-d287-4d7b-baa3-7f453d55b3a6",
        "ParentId": "21a0fe87-27cf-4f29-9d7c-ec31d513b8ac",
        "Name": "1.Giờ",
        "Description": "HR_witalk_sl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/3.Sản lượng/1.Giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8871c5ea-e2bd-49dc-b81f-ce8db728839d",
        "ParentId": "21a0fe87-27cf-4f29-9d7c-ec31d513b8ac",
        "Name": "2.Ngày",
        "Description": "DY_witalk_sl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/3.Sản lượng/2.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8737af65-b4e5-4dd8-84ca-0c98483707d5",
        "ParentId": "21a0fe87-27cf-4f29-9d7c-ec31d513b8ac",
        "Name": "3.Tuần",
        "Description": "WK_witalk_sl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/3.Sản lượng/3.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "7ce84d1d-fb4e-47cc-b115-e677062654b7",
        "ParentId": "21a0fe87-27cf-4f29-9d7c-ec31d513b8ac",
        "Name": "4.Tháng",
        "Description": "MN_witalk_sl",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/5.WITALKS/3.Sản lượng/4.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "59606463-9124-4bbb-92ed-116647233986",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "6.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/6.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "eaa07576-1059-48e4-95b6-76c1feb2ee99",
        "ParentId": "59606463-9124-4bbb-92ed-116647233986",
        "Name": "1.Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/6.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "55a178b9-a3c0-462c-8d1f-2445f24c6e18",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "7.Kiểm soát số liệu RAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1cc94924-67f4-4e6d-a5cd-ed3888ad9e91",
        "ParentId": "55a178b9-a3c0-462c-8d1f-2445f24c6e18",
        "Name": "1.Traffic",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/1.Traffic",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9187767f-c34c-4aee-8112-fd9e81bd0abc",
        "ParentId": "1cc94924-67f4-4e6d-a5cd-ed3888ad9e91",
        "Name": "Ngày",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/1.Traffic/Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "294df974-e506-4bde-9bab-5c32dee43c49",
        "ParentId": "55a178b9-a3c0-462c-8d1f-2445f24c6e18",
        "Name": "2.KPI",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/2.KPI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa758836-f540-46c7-97a6-d448e2d61732",
        "ParentId": "294df974-e506-4bde-9bab-5c32dee43c49",
        "Name": "1.Ngày",
        "Description": "DY_MTCLPROVINCE",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/2.KPI/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "467b7c92-8e9c-4527-b01e-d400c600d746",
        "ParentId": "55a178b9-a3c0-462c-8d1f-2445f24c6e18",
        "Name": "3.KPI mức giờ",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/3.KPI mức giờ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e0c61359-ba4f-43db-8abe-7f587a9bfc53",
        "ParentId": "467b7c92-8e9c-4527-b01e-d400c600d746",
        "Name": "1.CELL",
        "Description": "kpi cell mức giờ_cell",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/3.KPI mức giờ/1.CELL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d21dc110-a3ed-43c1-af5f-8f629abdbcf2",
        "ParentId": "467b7c92-8e9c-4527-b01e-d400c600d746",
        "Name": "2.SITE",
        "Description": "kpi cell mức giờ_site",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/3.KPI mức giờ/2.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "99188133-7617-4884-a250-014f30a7e988",
        "ParentId": "55a178b9-a3c0-462c-8d1f-2445f24c6e18",
        "Name": "4.DBLOAD KQI DATA",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/4.DBLOAD KQI DATA",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b36e63ab-7389-4174-a7ce-503eac2c0533",
        "ParentId": "99188133-7617-4884-a250-014f30a7e988",
        "Name": "1.Ngày",
        "Description": "DY_dbload kqi data",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/7.Kiểm soát số liệu RAN/4.DBLOAD KQI DATA/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a36b3dd1-f92a-49b6-aa7b-8b1a529e0983",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "8.Cảnh báo suy giảm chất lượng",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Cảnh báo suy giảm chất lượng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f8bbf887-8810-40ae-83fc-0259387d22d7",
        "ParentId": "9379d710-24a5-48ac-9607-95486c67685c",
        "Name": "8.Thương hiệu giới trẻ",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "ParentId": "f8bbf887-8810-40ae-83fc-0259387d22d7",
        "Name": "0.Thuê bao",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "ed85b44c-e274-4ed1-a525-c33597919d45",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "1.CELL",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/1.CELL",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2935821e-b712-4a60-a988-3fa3c9a790c9",
        "ParentId": "ed85b44c-e274-4ed1-a525-c33597919d45",
        "Name": "1.Ngày",
        "Description": "DY_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/1.CELL/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d1a7645e-4af6-46f0-9a42-57dd8ee18817",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "2.SITE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/2.SITE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "edf0077d-0252-4360-8745-cc4f3b27687d",
        "ParentId": "d1a7645e-4af6-46f0-9a42-57dd8ee18817",
        "Name": "1.Ngày",
        "Description": "DY_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/2.SITE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a0e4e012-e139-44cc-89cf-db8bb4ee92be",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "3.DISTRICT",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/3.DISTRICT",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "91b1d69c-da66-4ee3-8e0a-6a4e16fd6b4f",
        "ParentId": "a0e4e012-e139-44cc-89cf-db8bb4ee92be",
        "Name": "1.Ngày",
        "Description": "DY_VLR_district",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/3.DISTRICT/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8d1fde85-08cd-464a-ba26-f2980e772908",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "4.PROVINCE",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/4.PROVINCE",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "a814b3bc-2495-417e-b40e-a6a52acb5a54",
        "ParentId": "8d1fde85-08cd-464a-ba26-f2980e772908",
        "Name": "1.Ngày",
        "Description": "DY_VLR_province",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/4.PROVINCE/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "1ba169a3-3bfb-41b2-a895-39bc62e40a25",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "5.BRANCH",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/5.BRANCH",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "6e15a09c-7c61-4889-8d2c-6eac7a791352",
        "ParentId": "1ba169a3-3bfb-41b2-a895-39bc62e40a25",
        "Name": "1.Ngày",
        "Description": "DY_VLR_branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/5.BRANCH/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "e3f9d7e3-cacb-4c41-8597-f502b70a7433",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "6.CTKD",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/6.CTKD",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "5f20ce0d-6d24-4e85-b467-8ffed890e099",
        "ParentId": "e3f9d7e3-cacb-4c41-8597-f502b70a7433",
        "Name": "1.Ngày",
        "Description": "DY_VLR_branch",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/6.CTKD/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "fa6125a8-c4e2-4c06-80a9-02e0fd093a43",
        "ParentId": "abe55f44-340a-4aea-8cf1-bbf7aa6ff6f5",
        "Name": "7.TTML",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/7.TTML",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3478f8ac-87a6-4f6f-9aa7-0e850719d5f2",
        "ParentId": "fa6125a8-c4e2-4c06-80a9-02e0fd093a43",
        "Name": "1.Ngày",
        "Description": "DY_VLR_region",
        "TypeName": "Report",
        "Path": "/noc-sopirs/90.SERVICES/8.Thương hiệu giới trẻ/0.Thuê bao/7.TTML/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "91.Help",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/91.Help",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "44876201-c488-48e7-86b1-d2fbc62f786e",
        "ParentId": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "Name": "0.Mô tả KPI KQI",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/91.Help/0.Mô tả KPI KQI",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "3361cf02-83cd-4024-a376-7150b54368ab",
        "ParentId": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "Name": "1. Hướng dẫn truy cập",
        "Description": "Hotro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/91.Help/1. Hướng dẫn truy cập",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "9a00b5e4-fab2-4b04-af8d-d17c22229ec8",
        "ParentId": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "Name": "2. Hướng dẫn sử dụng",
        "Description": "Hotro",
        "TypeName": "Report",
        "Path": "/noc-sopirs/91.Help/2. Hướng dẫn sử dụng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b081266d-18d4-460e-b79d-33e1c271a700",
        "ParentId": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "Name": "3.Log truy cập",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/91.Help/3.Log truy cập",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "2c128b05-4d5a-48eb-9246-def18004f45c",
        "ParentId": "667ab551-dab7-4005-ba09-f4a76be9cd47",
        "Name": "4.Danh sách tọa độ Node truyền dẫn",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/91.Help/4.Danh sách tọa độ Node truyền dẫn",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b5037d10-aef3-4195-a38d-5d7a38bd4758",
        "ParentId": "1b588945-b58e-455e-8d74-4f8ed188dba5",
        "Name": "92.Báo cáo chiến dịch TUH Hà Nội",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/92.Báo cáo chiến dịch TUH Hà Nội",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b7ab067c-d030-4b7b-ba72-cd899a9ccd00",
        "ParentId": "b5037d10-aef3-4195-a38d-5d7a38bd4758",
        "Name": "TUH_HN",
        "Description": null,
        "TypeName": "Report",
        "Path": "/noc-sopirs/92.Báo cáo chiến dịch TUH Hà Nội/TUH_HN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "d7732d47-c196-40da-abf1-237b6926b0c0",
        "ParentId": "fdc5f5cb-b17d-4ed7-87c9-2f8f8ba3f87b",
        "Name": "1.OCS-NEW",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/IN/1.OCS-NEW",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "ParentId": "d7732d47-c196-40da-abf1-237b6926b0c0",
        "Name": "SIGTRAN",
        "Description": null,
        "TypeName": "Folder",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f6cc4435-9c60-4fb8-8878-3c6e9dfe3b91",
        "ParentId": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "Name": "1.Ngày",
        "Description": "Dy_Insigtran",
        "TypeName": "Report",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN/1.Ngày",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "b68e58f6-fff5-4894-9ec8-7aa2e5e81c9f",
        "ParentId": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "Name": "2.Tuần",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN/2.Tuần",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "00a5f84b-232b-45e5-8dd6-4755b09df319",
        "ParentId": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "Name": "3.Tháng",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN/3.Tháng",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "f945452d-ebc3-4927-8e33-653e4bddde4f",
        "ParentId": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "Name": "4.Quý",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN/4.Quý",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "03e83a20-ccdf-43d0-97f3-760dba592893",
        "ParentId": "8bfb29d3-f2c3-4df1-b890-0c5e46eacbd8",
        "Name": "5.Năm",
        "Description": "Dy_MSP",
        "TypeName": "Report",
        "Path": "/noc-sopirs/IN/1.OCS-NEW/SIGTRAN/5.Năm",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de",
        "Name": "NOC",
        "Description": "Dashboard/NOC",
        "TypeName": "Folder",
        "Path": null,
        "Level": 0,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_123",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo Cáo Trải Nghiệm Khách Hàng (CEI)",
        "Description": "Dashboard/Báo Cáo Trải Nghiệm Khách Hàng (CEI)",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-diem-CSAT-USSD.html",
        "Level": 0,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38_108",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38",
        "Name": "Tải PRB 4G",
        "Description": "Dashboard/Tải PRB 4G",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiNTY2NzViMWUtNjBlOS00N2Q4LThjZjMtNmFkODViMmE0ZTllIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36_109",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36",
        "Name": "Tải PRB 4G",
        "Description": "Dashboard/Tải PRB 4G",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiMjI3OWE0MjYtMmE1OS00OTZjLTk0MTQtMDI5NWZkZTYxYTAyIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_113",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo Cáo Tình Hình Mạng Lưới",
        "Description": "Dashboard/Báo Cáo Tình Hình Mạng Lưới",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-TCT-Bao-Cao-tinh-hinh-mang-luoi.html",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36_114",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36",
        "Name": "Báo cáo ứng cứu thông tin trạm",
        "Description": "Dashboard/Báo cáo ứng cứu thông tin trạm",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-bao-cao-UCTT-tram.html",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de",
        "Name": "MLMB",
        "Description": "Dashboard/MLMB",
        "TypeName": "Folder",
        "Path": null,
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37_55",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Tải PRB 4G",
        "Description": "Dashboard/Tải PRB 4G",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiNWVhYmVjYmQtZTYxNy00NmJhLTg0MjItNDVlN2E4NzBkZDllIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D&pageName=ReportSectionc78aed2063514a20caa5",
        "Level": 1,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37_56",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Tải Truyền dẫn",
        "Description": "Dashboard/Tải Truyền dẫn",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiOWNmZjZjOTYtOGE4OC00MzM0LWIyYWQtNDA2ZTRjZjJlZDQ3IiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de",
        "Name": "MLMT",
        "Description": "Dashboard/MLMT",
        "TypeName": "Folder",
        "Path": null,
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36_48",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_36",
        "Name": "Thống kê báo cáo nhanh KPI",
        "Description": "Dashboard/Thống kê báo cáo nhanh KPI",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiYTVjNmIzOGUtMGMzNi00OWFjLTk3OWEtZDY3MmE1YWYyZWIxIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38_54",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38",
        "Name": "Thống kê báo cáo nhanh KPI",
        "Description": "Dashboard/Thống kê báo cáo nhanh KPI",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiMWQ3NzA4OTEtMjE4NS00ODg5LWEyYjctYTI0NjkwNzI3NTM2IiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_124",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo cáo giám sát cảnh báo tăng đột biến",
        "Description": "Dashboard/Báo cáo giám sát cảnh báo tăng đột biến",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/Dashboard_giam_sat_alarm_tang_dot_bien.html",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_117",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "KPI CORE KQI MTCL 2022",
        "Description": "Dashboard/KPI CORE KQI MTCL 2022",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-KQI-KPI-MTCL-2022.html",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_112",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo Cáo Lưu lượng - Thuê bao",
        "Description": "Dashboard/Báo Cáo Lưu lượng - Thuê bao",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-TCT-Bao-Cao-Luu-Luong-Thue-Bao.html",
        "Level": 2,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_126",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Giám sát chất lượng",
        "Description": "Dashboard/Giám sát chất lượng",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/Dashboard_giam_sat_chat_luong.html",
        "Level": 3,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38_92",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Trạm MLL, MĐ, MFĐ",
        "Description": "Dashboard/Trạm MLL, MĐ, MFĐ",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Tram-MLL-MD-MPD.html",
        "Level": 3,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de",
        "Name": "MLMN",
        "Description": "Dashboard/MLMN",
        "TypeName": "Folder",
        "Path": null,
        "Level": 3,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": true,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37_58",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Thống kê báo cáo nhanh KPI",
        "Description": "Dashboard/Thống kê báo cáo nhanh KPI",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiNmYzNTUxNzktN2RlMi00ZTVjLThmYTktMjhmZDdkNDc4ZDEzIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 4,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_127",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Cảnh báo theo ngày",
        "Description": "Dashboard/Cảnh báo theo ngày",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/Dashboard_Canh_Bao_Theo_Ngay_MN.html",
        "Level": 4,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_128",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "KPI MTCL 2023",
        "Description": "Dashboard/KPI MTCL 2023",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/Dashboard_MTCL_2023.html",
        "Level": 5,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_110",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Thuê Bao MobiWIFI Mức Cell",
        "Description": "Dashboard/Thuê Bao MobiWIFI Mức Cell",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-MobiWifi-CellName.html",
        "Level": 5,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_59",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Thống kê thiết bị MobiHome",
        "Description": "Dashboard/Thống kê thiết bị MobiHome",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiY2Y2ZDg5NjctMGMzMS00NGU3LTkyMjAtMjBmNjRiYWQ1ZDY1IiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 5,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_95",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Cảnh báo chất lượng CORE",
        "Description": "Dashboard/Cảnh báo chất lượng CORE",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiOTIzZmUzNTUtZWEwZC00MjNkLWE4MjctYzBkM2QyZWRhMjgzIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 5,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_100",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Thuê Bao MobiWIFI",
        "Description": "Dashboard/Thuê Bao MobiWIFI",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-MobiWifi.html",
        "Level": 5,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_104",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Truyền Dẫn Online Metro Miền Bắc",
        "Description": "Dashboard/Truyền Dẫn Online Metro Miền Bắc",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Truyen-dan-metro-mien-Bac.html",
        "Level": 6,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37_60",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Thống kê biến động VLR, lưu lượng",
        "Description": "Dashboard/Thống kê biến động VLR, lưu lượng",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiNDQwNDE2ODYtZGYzNy00MmYzLTkwMjMtZDRjNjkxMzUxZWUyIiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 6,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_105",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Truyền Dẫn Online Metro Miền Trung",
        "Description": "Dashboard/Truyền Dẫn Online Metro Miền Trung",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Truyen-dan-metro-mien-Trung.html",
        "Level": 7,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37_62",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_37",
        "Name": "Thống kê handset",
        "Description": "Dashboard/Thống kê handset",
        "TypeName": "PowerBI",
        "Path": "https://app.powerbi.com/view?r=eyJrIjoiMGVmYjQzMDUtNTZjYy00NGIxLTgwNmUtMjZlZDY4ZWVhNGI4IiwidCI6IjQ4NTRlYTQzLWQ2ZGQtNDJkNy1hMjVmLWMwZThkMDY2Mzc5NSIsImMiOjEwfQ%3D%3D",
        "Level": 8,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_99",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Truyền Dẫn Online Metro Miền Nam",
        "Description": "Dashboard/Truyền Dẫn Online Metro Miền Nam",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Truyen-dan-metro-mien-Nam.html",
        "Level": 9,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_121",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo cáo FTTx",
        "Description": "Dashboard/Báo cáo FTTx",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-FTTx-AON-GPON.html",
        "Level": 10,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_38_101",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Tình Hình Mạng Lưới PCLB",
        "Description": "Dashboard/Tình Hình Mạng Lưới PCLB",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Tinh-Hinh-Mang-Luoi-PCLB.html",
        "Level": 11,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_111",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Phản Ánh Khách Hàng",
        "Description": "Dashboard/Phản Ánh Khách Hàng",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Phan-Anh-Khach-Hang.html",
        "Level": 12,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_115",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Bản đồ chất lượng dịch vụ",
        "Description": "Dashboard/Bản đồ chất lượng dịch vụ",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-ban-do-chat-luong-dich-vu.html",
        "Level": 13,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_116",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Bản đồ chất lượng thuê bao VIP",
        "Description": "Dashboard/Bản đồ chất lượng thuê bao VIP",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-ban-do-chat-luong-thue-bao-VIP.html",
        "Level": 14,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_119",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo cáo điểm HTMT (không gồm Badcell, Badlink)",
        "Description": "Dashboard/Báo cáo điểm HTMT (không gồm Badcell, Badlink)",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-diem-HTMT-Theo-Ngay-Khong-Gom-BadLink-BadCell.html",
        "Level": 15,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_118",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo cáo ứng cứu thông tin trạm",
        "Description": "Dashboard/Báo cáo ứng cứu thông tin trạm",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-ung-cuu-thong-tin-tram.html",
        "Level": 16,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_120",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Báo cáo điểm HTMT ĐKSC",
        "Description": "Dashboard/Báo cáo điểm HTMT ĐKSC",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-diem-HTMT-DKSC.html",
        "Level": 17,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    },
    {
        "Id": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26_122",
        "ParentId": "0e609f1e-abee-4d7f-aa9d-511ed36b14de_26",
        "Name": "Bản đồ chất lượng sự cố",
        "Description": "Dashboard/Bản đồ chất lượng sự cố",
        "TypeName": "PowerBI",
        "Path": "https://smartf.mobifone.vn/web_embed/tableau-Bao-cao-ban-do-chat-luong-su-co.html",
        "Level": 18,
        "Checked": false,
        "ParentCount": 0,
        "IsParent": false,
        "Children": null,
        "ReportGroups": null
    }
];
